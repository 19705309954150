import axios from "axios";
import * as Config from "../../../config";

const state = {
    evaluation: {},
    exam: {}
};
axios.defaults.headers.common = {'tokentap': '3MMuNJX3YWm9T9FbpSj4Z&'}
let mod = "eval/";
const actions = {
    loadEvaluations_enrollment({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + "evaluations/enrollment/all/" + form.id_enroll)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluations({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath2 + "evaluation/all",{params:form})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    newEvaluation_enrollment({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "evaluation/enrollment/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    duplicateEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath2 + "evaluation/duplicate", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    load_evaluationsStudent_All({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(
                    Config.apiPath +
                    "evaluation/enrollment/student/" +
                    form.id_user +
                    "/" +
                    form.id_enroll
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    load_evaluationsScoreStudent_All({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(
                    Config.apiPath +
                    "evaluation/enrollment/score/" +
                    form.id_evaluation +
                    "/" +
                    form.id_enroll
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    //TODO ADD AND UPDATE SCORE
    scoreAdd({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "score/add", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    load_Evaluation({commit, dispatch},form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+"evaluation/all",{params:form})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    load_Evaluation2({commit, dispatch},form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath2 +"evaluation/all",{params:form})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluationTrash({commit, dispatch},form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath2 +"evaluation/all_trash",{params:form})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    load_Evaluation_by_subject({commit, dispatch},form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            let url = Config.apiPath + mod + "evaluation/all_by_subject/"+form.id_subject
            if(form.id_lapse){
                url = Config.apiPath + mod + "evaluation/all_by_subject/"+form.id_subject+'?id_lapse='+form.id_lapse
            }
            axios
                .get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    uploadArchiveMessageRoom({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios(
          {
            method: 'post',
            url: Config.apiPath + mod + "rooms/"+form.get("id_room")+"/message/upload",
            data: form,
            headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
    loadEvaluation_Active({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + "student/evaluation/all/status/" + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
  
    
    loadEvaluation_Tasks_teacher({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+ "evaluation_docs/all_tasks_by_teacher/"+form.id_evaluation)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_Student_list({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath2 + "evaluation_doc/students_by_evaluation/"+form.id_evaluation+"/all")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_Tasks({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+ "evaluation_docs/all_by_evaluation/"+form.id_evaluation + form.id_student)
                .then(response => {

                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_Student({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+ "evaluation_docs/all_by_student/"+form.id_student)
                .then(response => {

                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    setFeedback_evaluation_doc({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
          
                .patch(Config.apiPath + mod+ "evaluation_docs/"+form.id_evaluation_doc+"/set_feedback",{feedback:form.feedback})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    saveEvaluation_result({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
           
                .post(Config.apiPath + mod+ "evaluation_result/save",form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_Results_by_Student({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+ "evaluation_results/all_by_student/"+form.id_student)
                .then(response => {

                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_Results_avg_by_Student({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+ "evaluation_results/all_avg_by_student/"+form.id_student+'?id_lapse='+form.id_lapse+'&id_period='+form.id_period)
                .then(response => {

                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    uploadArchiveTask({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios(
          {
            method: 'post',
            url: Config.apiPath + "upload/evaluation/student",
            data: form,
            headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
    loadEvaluation_docs_by_student({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
           
                .get(Config.apiPath2 + "evaluation_doc/all",{params:form})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    changeActive_docs_by_student({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath2 +
                    "evaluation_doc/change_status/" +
                    form.id +
                    "/" +
                    form.active
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_result_by_student({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+ "evaluation_results/all_by_evaluation/"+form.id_evaluation+"?id_student="+form.id_student)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadEvaluation_Messages({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
            .get(Config.apiPath2 + "room_message/all?order_key=id&order_value=asc&id_room="+form.room)
            .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadData_Evaluation({commit, dispatch}, data) {
        console.log(dispatch)
        console.log(commit)
        commit("LOAD_DATA_EVALUATION", data);
    },
    loadData_Exam({ commit, dispatch }, data) {
        console.log(dispatch)
        console.log(commit)
        commit("LOAD_DATA_EXAM", data);
    },
    new_Evaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath2 +"evaluation/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    
    sendSolution({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath2 +"solution_answer/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    startPollSolution({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath2 +"quiz_poll_solution/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    updatePollSolution({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath2 +"quiz_poll_solution/update/"+form.id, form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    changeActive_Evaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath +
                    "student/evaluation/change_status/" +
                    form.id +
                    "/" +
                    form.active
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    deleteEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    Config.apiPath2 +
                    "evaluation/delete/" +
                    form.id
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    restoreEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(
                    Config.apiPath2 +
                    "evaluation/restore/" +
                    form.id
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    update_Evaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath2 + "evaluation/update/" + form.id, form.params, {headers: {'Content-Type': 'multipart/form-data' }})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    delete_Evaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath +
                    "student/evaluation/change_status/" +
                    form.id +
                    "/" +
                    form.active
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

};

const mutations = {
    LOAD_DATA_EVALUATION(state, data) {
        state.evaluation = data;
    },
    LOAD_DATA_EXAM(state, data) {
        state.exam = data;
    }
};

export default {
    state,
    actions,
    mutations
};
