<template>
  <div
    style="background-image: url('https://apischool.ticktap.com.ve/file/1-1-1-20203105231857.jpg');"
  >
    <div class="col-md-12">
      <br />
      <br />
      <b-row>
        <div class="col-md-4"></div>
        <div class="col-md-2">
          <b-input-group size="sm">
            <b-img
              center
              src="https://apischool.ticktap.com.ve/file/1-1-1-2020310515011.png"
              fluid
              alt="Ticktaps Logo"
            ></b-img>
          </b-input-group>
        </div>
        <div class="col-md-2">
          <b-input-group size="sm">
            <b-img
              center
              src="https://apischool.ticktap.com.ve/file/1-1-1-20203105161259.png"
              fluid
              alt="Ticktaps Logo"
            ></b-img>
          </b-input-group>
        </div>
        <div class="col-md-4"></div>
      </b-row>
      <b-row>
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <br />
          <h3 class="text-center">Bienvenido al Registro de Representante</h3>
          <!-- <div class="row">
            <b-button type="button" variant="outline-warning" class="m-3" v-on:click="step -= 1">Atras</b-button>
            <b-button type="button" variant="outline-success" class="m-3" v-on:click="step += 1">Siguiente</b-button>
          </div>-->

          <div class="row">
            <b-card class="col-md-12">
              <div v-if="step == 0">
                <form class="w-100 p-2" method="post">
                  <div class="row">
                    <h3 class="col-md-12">Indica tu correo electrónico</h3>
                    <p class="col-md-12">Vamos a comprobar si te encuentras registrado</p>
                  </div>
                  <b-row>
                    <b-col md="7">
                      <b-form-group label="Correo Electronico" label-for="email">
                        <b-form-input
                          id="email"
                          v-model="email"

                          name="email"
                          placeholder="Ingrese Email"
                        />

                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <br />
                      <button
                        type="button"
                        v-on:click="findRepresent()"
                        class="btn btn-outline-success btn-sm"
                      ><v-icon name="search"></v-icon> Buscar</button>
                      <router-link to="/uecamag" class="btn btn-outline-warning btn-sm"><v-icon name="home"></v-icon>  Volver al inicio.</router-link>
                    </b-col>
                  </b-row>
                </form>
              </div>

              <div v-if="step == 1">
                <b-row class="center">
                  <div class="row">
                    <h3 class="col-md-12">Registro de usuario</h3>
                    <p class="col-md-12">Paso 1 - Introduce tus datos, para registrar tu usuario</p>
                  </div>
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Nombre" label-for="first_name">
                        <b-form-input
                          id="first_name"
                          v-model="first_name"

                          name="first_name"
                          placeholder="Ingrese Nombre"
                        />

                      </b-form-group>
                      <b-form-group label="Apellido" label-for="last_name">
                        <b-form-input
                          id="last_name"
                          v-model="last_name"

                          name="last_name"
                          placeholder="Ingrese Apellido"
                        />

                      </b-form-group>
                      <b-form-group label="Cédula" label-for="dni">
                        <b-input-group>
                          <template v-slot:prepend>
                            <b-form-select
                              value-field="id"
                              text-field="name"
                              v-model="dni_selected"
                              :options="dnis"
                            ></b-form-select>
                          </template>
                          <b-form-input
                            id="dni"
                            v-model="dni"

                            name="dni"
                            placeholder="Solo Números"
                          ></b-form-input>
                        </b-input-group>

                      </b-form-group>
                      <b-form-group label="Teléfono" label-for="phone">
                        <b-form-input
                          id="phone"
                          v-model="phone"

                          name="phone"
                          placeholder="Teléfono"
                        ></b-form-input>

                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Fecha de Nacimiento" label-for="birthday">
                        <date-picker
                          id="birthday"
                          name="birthday"
                          mode="single"
                          :max-date="new Date()"

                          v-model="birthday"
                          :popover="{ placement: 'bottom', visibility: 'click' }"
                        />

                      </b-form-group>
                      <b-form-group>
                        <div>
                          <div class="mb-2">Género</div>
                          <b-form-select
                            v-model="gender"
                            :options="genderList"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                      <b-form-group>
                        <div>
                          <div class="mb-2">Tipo de Sangre</div>
                          <b-form-select
                            v-model="blood_type"
                            :options="type_blood_list"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                          <p
                            style="font-size:10px"
                          >El tipo de sangre es un valor informativo, que le sirve a la escuela ante cualquier emergencia</p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Correo" label-for="email">
                        <b-form-input
                          id="email"
                          v-model="email"

                          name="email"
                          placeholder="Correo"
                          disabled
                        ></b-form-input>

                      </b-form-group>

                      <b-form-group label="Contraseña" label-for="password">
                        <b-form-input
                          id="password"
                          ref="password"
                          v-model="password"

                          name="password"
                          type="password"
                          placeholder="Indica un Password"
                        ></b-form-input>

                      </b-form-group>
                      <b-form-group label="Repita la Contraseña" label-for="password_confirm">
                        <b-form-input
                          id="password_confirm"
                          v-model="password_confirm"

                          name="password_confirm"
                          type="password"
                          placeholder="Indica un Password"
                        ></b-form-input>

                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="8">
                      <router-link to="/login" class>Ya estás registrado? Inicia sesión</router-link>
                    </b-col>
                    <b-col md="2">
                        <router-link to="/uecamag" class="btn btn-outline-warning">Volver al inicio.</router-link>
                    </b-col>
                      <b-col md="2">
                         <b-button
                          type="button"
                          v-on:click="nextStepRegister()"
                          variant="outline-success"
                          class="m-3"
                        >Continuar Registro</b-button>

                    </b-col>
                  </b-row>
                </b-row>
              </div>
              <div v-if="step == 2">
                <b-row class="center">
                  <div class="row">
                    <h3 class="col-md-12">Datos del representante</h3>
                    <p class="col-md-12">Paso 2 - Indica estos datos adicionales</p>
                  </div>
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Dirección de Vivienda" label-for="address_home">
                        <b-form-input
                          id="address_home"
                          v-model="address_home"

                          name="address_home"
                          placeholder="Domicilio"
                        />

                      </b-form-group>
                      <b-form-group label="Dirección de Oficina" label-for="address_office">
                        <b-form-input
                          id="address_office"
                          v-model="address_office"

                          name="address_office"
                          placeholder="Lugar de Trabajo"
                        ></b-form-input>

                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Actividad Comercial" label-for="commercial_activity">
                        <b-form-input
                          id="commercial_activity"
                          v-model="commercial_activity"

                          name="commercial_activity"
                          placeholder
                        />

                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Profesión" label-for="profession">
                        <b-form-input
                          id="profession"
                          v-model="profession"

                          name="profession"
                          placeholder
                        ></b-form-input>

                      </b-form-group>
                    </b-col>

                    <b-col md="12 text-right">
                      <b-button
                        type="button"
                        v-on:click="step-=1"
                        variant="outline-default"
                        class="m-3"
                      >Volver Atrás</b-button>
                      <b-button
                        type="button"
                        v-on:click="saveRepresent()"
                        variant="outline-primary"
                        class="m-3"
                      >Culminar Registro</b-button>
                    </b-col>
                  </b-row>
                </b-row>
              </div>
            </b-card>
          </div>
        </div>
        <div class="col-md-2"></div>
      </b-row>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Moment from "moment";
//import RegisterUser from "../../pages/GEN/user/Register";
import Swal from "sweetalert2";
export default {
  name: "PreRegister",
  components: {
   // RegisterUser
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      dni: "",
      // birthday: Moment().format('DD-MM-YYYY'),
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      id_rol: 2,
      id_rol_access: 1,
      gender: 1,
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      dni_selected: "V",
      dnis: [
        {
          id: "V",
          name: "V"
        },
        {
          id: "E",
          name: "E"
        }
      ],
      genderList: [
        {
          id: 1,
          name: "Masculino"
        },
        {
          id: 2,
          name: "Femenino"
        }
      ],
      blood_type: 1,
      rols_user: [],
      accessArray: [],
      type_blood_list: [],
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: Date,
      isM: "",
      user_registered: {},
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true
        }
      },
      step: 0
    };
  },
  mounted() {
    this.allRoles();
  },
  methods: {
    ...mapActions([
      "newUser",
      "publicRegisterRepresentative",
      // "loadRol",
      // "loadRol_Active",
      //   "loadAccess_Active",
      "loadBlood_Active",
      "findUserByEmail"
    ]),
    nextStepRegister() {
      let dni_check = this.dni;
      if (this.first_name == "") {
        Swal.fire({
          title: "Por favor indica un nombre",
          text: "Indica tu nombre completo",
          icon: "warning"
        });
      } else if (this.last_name == "") {
        Swal.fire({
          title: "Por favor indica un apellido",
          text: "Indica tus apellidos",
          icon: "warning"
        });
      } else if (this.dni == "") {
        Swal.fire({
          title: "Por favor indica una cédula",
          text: "Indica tus cédula, sólo con números",
          icon: "warning"
        });
      } else if (this.dni == "") {
        Swal.fire({
          title: "Por favor indica un apellido",
          text: "Indica tus apellidos",
          icon: "warning"
        });
      } else if (isNaN(dni_check)) {
        Swal.fire({
          title: "Por favor indica una cedula válida, solo números",
          text: "Debe contener mínimo 6 caractéres",
          icon: "warning"
        });
      } else if (dni_check.length < 6) {
        Swal.fire({
          title: "Por favor indica una cedula válida, solo números",
          text: "Debe contener mínimo 6 caractéres",
          icon: "warning"
        });
      } else if (this.password == "") {
        Swal.fire({
          title: "Por favor indica una contraseña",
          text:
            "El correo y la contraseña le van a permitir acceder al sistema",
          icon: "warning"
        });
      } else if (this.password != this.password_confirm) {
        Swal.fire({
          title: "Las contraseñas deben coincidir",
          text:
            "El correo y la contraseña le van a permitir acceder al sistema",
          icon: "warning"
        });
      } else {
        this.step += 1;
      }
    },
    allRoles() {
      let form = {
        active: 1
      };
      /*   this.loadRol_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.rols_user = response.data;
          }
        })
        .catch(error => {console.log(error)}); */
      this.loadBlood_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.type_blood_list = response.data;
          }
        })
        .catch(error => {console.log(error)});
      /*   this.loadAccess_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.accessArray = response.data;
          }
        })
        .catch(error => {console.log(error)}); */
    },
    validateEmail(email) {
      //const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //return re.test(String(email).toLowerCase());
      return email
    },
    findRepresent() {
      //findUserByEmail
      let validation_email = false;
      validation_email = this.validateEmail(this.email);
      console.log(validation_email);
      if (validation_email == false) {
        Swal.fire({
          title: "Por favor escribe bien el correo",
          text:
            "Asegúrate de escribir en minúsculas, sin espacios y sin carácteres especiales",
          icon: "warning"
        });
      } else if (this.email == "") {
        Swal.fire({
          title: "Por favor indica un correo",
          text: "Asegurate de indicar tu correo",
          icon: "warning"
        });
      } else {
        let form = {
          email: this.email
        };
        this.findUserByEmail(form)
          .then(response => {
            if (response.code === 200) {
              Swal.fire({
                title: "Ya este correo se encuentra registrado",
                text: "Te invitamos a iniciar sesión",
                icon: "success"
              }).then(result2 => {
                console.log(result2)
                this.$router.push({ name: "login" });
              });
            }
            if (response.code === 404) {
              Swal.fire({
                title: "Ahora, completa tus datos",
                text:
                  "A continuación completa todos tus datos para culminar tu proceso de registro",
                icon: "success"
              }).then(result2 => {
                console.log(result2)
                this.step = 1;
              });
            }
          })
          .catch(error => {console.log(error)});
      }
    },
    saveRepresent() {
      console.log("Save Represent");

      let form = {
        username: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        dni: this.dni_selected + "-" + this.dni,
        birthday: Moment(this.birthday).format("YYYY-MM-DD"),
        email: this.email,
        password: this.password,
        phone: this.phone,
        gender: this.gender,
        id_blood_type: this.blood_type,
        id_school: 1,
        commercial_activity: this.commercial_activity,
        address_home: this.address_home,
        address_office: this.address_office,
        profession: this.profession
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de registrarte?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar"
      }).then(result => {
        if (result.value) {
          this.publicRegisterRepresentative(form)
            .then(response => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Registro de usuario completado",
                  text: "Ahora podras acceder a la plataforma",
                  icon: "success"
                }).then(result2 => {
                  console.log(result2)
                  this.user_registered = response.data.data;
                  this.$router.push({ name: "login" });
                });
              }
            })
            .catch(error => {console.log(error)});
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>



<style scoped>
.content {
  margin-left: 0px !important;
}
</style>
