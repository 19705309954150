<template>
  <div id="TSchool">
  <toast />
    <router-view/>
  </div>
</template>

<script>

import toast from './components/layout/Toast';

  export default {
    name: 'TSchool',
    components: {
            toast
        },
         data() {
            return {
                toggle: true,
                menu: []
            };
        },
  };
</script>
<style scoped>
#content {
    position: relative;
}
</style>
