<template>
  <div id="AllRepresentative">
    <br/>
    <div class="content">
      <b-card title="Lista de Representantes">
        <b-row>
          <b-col md="10" style="text-align: -webkit-left">
            <p>
              A continuación, podrás visualizar una lista de representantes
              registrados.
            </p>
          </b-col>
          <b-col md="2" style="text-align: -webkit-right">
            <h5>
              Representantes Registrados: {{ fathers_count }}
            </h5>
            <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar representante</router-link>-->
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
                label="Filtrar por"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter">Limpiar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-group
                label="Por página"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table
                  id="my-table"
                  show-empty
                  small
                  fixed
                  stacked="md"
                  sticky-header="true"
                  hover
                  :primary-key="id"
                  :items="fathersTMP"
                  :fields="field"
                  :current-page="currentPage"
                  :per-page="perPage"

                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  @filtered="onFiltered"
                  :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <thead>
                <tr>
                  <th>Cédula</th>
                  <th>Nombre y Apellido</th>
                  <th>asd</th>
                  <th>asdasd</th>
                  <th>asdas</th>
                  <th>asdasdsa</th>

                </tr>
                </thead>
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title="Activado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="check-square"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title="Desactivado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="minus-square"/>

                  </b-button>

                  <b-button
                      size="sm"
                      variant="outline-info"
                      class="mr-2"
                      @click="edit_Representative(row.item)"
                  >

                    <v-icon name="eye"/>
                  </b-button>

                  <b-button
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      @click="delete_Representative(row)"
                  >

                    <v-icon name="trash"/>
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllRepresentative",
  data() {
    return {
      fathers_count: 0,
      isBusy: false,
      id: "",
      items: [],
      item_table: null,
      ttap_selected: null,
      field: [
        {
          key: "user.dni",
          label: "Cédula",
          sortable: true,
        },
        {
          key: "user.full_name",
          label: "Nombre y apellido",
          sortable: true
        },
        {
          key: "user.email",
          label: "Correo electrónico",
          sortable: true,
        },
        {
          key: "user.phone",
          label: "Teléfono",
          sortable: true
        },

        {
          key: "profession",
          label: "Profesión",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      fathers: [],
      fathersTMP: [],
      loading: false,
      error: ""
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    },
    rows() {
      return this.fathersTMP.length;
    }
  },
  mounted() {
    this.allRepresentatives();

  },
  methods: {
    ...mapActions([
      "loadRepresentatives",
      "changeActiveRepresentative",
      "loadData_Representative",
      "deleteRepresentative"
    ]),
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.fathersTMP = this.fathers;
      this.filter = ''
      return true;
    },
    searchInput() {

      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.fathers.filter(function (el, i) {
          let cadena = JSON.stringify(el.user);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            studentsTmp.push(el)
          }
        })
        this.fathersTMP = studentsTmp;
      } else {
        this.fathersTMP = this.fathers;
      }
      return true;
    },
    allRepresentatives() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school
      };
      this.loadRepresentatives(form)
          .then(response => {
            if (response.code === 200) {
              this.isBusy = false;
              this.fathers = response.data;
              this.fathersTMP = response.data;
              console.log(this.fathers.length)
              this.fathers_count = this.fathers.length
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    edit_Representative(data) {
      this.loadData_Representative(data).then(response => {
        console.log(response)
        this.$router.push("/director/representante/editar/" + data.id);
        //  this.$router.push({ name: "uecamag" });
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "¿Estás seguro de desactivarlo ahora?, Esto inhabilita al representante temporalmente y luego podrá reactivarlo de nuevo"
              : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeActiveRepresentative(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    title: title2,
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    delete_Representative(row) {
      Swal.fire({
        title: "Eliminar Representante",
        text: "¿Estás seguro de eliminar este representante? Esto hará que el representante pierda el acceso a la plataforma y no podrá ver de nuevo sus datos, si no está seguro, cambie el estatus a inactivo",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.deleteRepresentative(form)
              .then(response => {
                if (response.code === 200) {
                  this.fathers.splice(row.index, 1);
                  Swal.fire({
                    title: "Representante Eliminado",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
