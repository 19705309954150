<template>
  <div id="AllTeacher">
    <br />
    <div class="content">
      <b-card title="Gestión de Evaluaciones">
        <b-row>
          <b-col md="12">
            <p>
              Desde esta ventana podrás gestionar todo lo relacionado con evaluaciones en la aplicación.
            </p>
          </b-col>
          <b-col md="12" style="text-align: -webkit-left">
            <div>
              <b-tabs content-class="mt-3">
                <b-tab title="Clases / Evaluaciones" active>
                  <evaluations></evaluations>
                </b-tab>
                <b-tab title="Lista de Profesores">
                  <teachers></teachers>
                </b-tab>
                <b-tab title="Materias de Profesores">
                  <asignation></asignation>
                </b-tab>
                <b-tab title="Tareas Enviadas">
                  <task></task>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
        <br />
      </b-card>
    </div>
  </div>
</template>

<script>
import evaluations from "./evaluations/all";
import teachers from "./teachers/all";
import asignation from "./asignations/all";
import task from "./task/all";


export default {
  name: "ManageTeachers",
  components: {
    teachers,
    asignation,
    evaluations,
    task,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
