<template>
  <div id="DetailsExam">
    <br />
    <div class="content">
      <b-card>
        <b-row>
          <b-col md="9">
            <h3>{{ exam.title }}</h3>
          </b-col>
          <b-col md="3" class="text-right">
            <b-button
              @click="$router.go(-1)"
              class="btn btn-info py-1 px-3">
              <v-icon name="angle-left" />
              Volver
            </b-button>
          </b-col>

          <b-col md="12">
            <p>En esta sección podrás visualizar el banco de preguntas del examen, modificar sus valores o agregar nuevas preguntas</p>
          </b-col>
        </b-row>

        <b-tabs card>
          <b-tab title="Selección simple" @click="ResetVars('1')" active>
            <div style="width: 100% !important">
              <b-row class="mb-2">
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Preguntas de la sección:</strong></p>
                    <p class="my-0">{{ exam.questions_quantity1 }}</p>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Preguntas registradas:</strong></p>
                    <p class="my-0">{{ total_questions1 ? total_questions1 : 0 }}</p>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Puntaje por pregunta:</strong></p>
                    <p class="my-0">{{ exam.value_questions1 == 1 ? exam.value_questions1 + ' punto' : exam.value_questions1 + ' puntos' }}</p>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Puntaje de la sección:</strong></p>
                    <p class="my-0">{{ points_questions1  == 1 ? points_questions1 + ' punto' : points_questions1 + ' puntos'  }}</p>
                  </div>
                </b-col>
                
                <b-col md="12" class="my-3" align-self="center">
                  <h4 class="my-0 py-0" style="display: inline-block">Preguntas</h4>
                  <b-button v-if="!new_questions1" class="ml-3 py-1 btn btn-success" @click="new_questions1 = true" style="display: inline-block">
                    <font-awesome-icon icon="plus" />
                    Añadir preguntas
                  </b-button>
                  <b-button v-else class="ml-3 py-1 btn btn-dark" @click="new_questions1 = false" style="display: inline-block">
                    <font-awesome-icon icon="times" />
                    Cancelar
                  </b-button>
                </b-col>
                
                <b-col v-if="new_questions1" lg="12" class="mb-2">
                  <b-form-group
                    class="mb-0"
                    style="max-height: 100px; width: 100%; display: block"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="new_question"
                      placeholder="Pregunta"
                      style="display: inline-block; width: 95%; color: #000000;"
                      :rows="3"
                      no-resize
                      required
                      @keyup.enter="AddQuestionToExam(new_question, '1')"
                      class="mx-0"
                    >
                    </b-form-textarea>
                    <div style="display: inline-block; width: 1%"></div>
                    <div class="text-left" style="display: inline-block; width: 4%">
                      <font-awesome-icon @click="AddQuestionToExam(new_question, '1')" class="p-0 mb-3" style="cursor: pointer; display: inline-block; color: green; font-size: 35px" icon="plus-circle" />
                    </div>
                  </b-form-group>
                </b-col>

                <b-col v-if="new_questions1" lg="1"></b-col>
                <b-col v-if="new_questions1" lg="10">
                  <b-row v-for="(datax, index) of element_add['1'].questions" :key="datax.question + index" align-h="center" class="mx-auto px-auto py-1 mb-1 text-left" style="border-style: solid; border-width: 1px; border-color: green">
                    <b-col lg="1" class="py-0 mb-0 pb-0 text-center" align-self="center">
                      <font-awesome-icon @click="DeleteQuestionFromExam(index, '1')" style="cursor: pointer; color: red; font-size: 26px" icon="trash-alt" />
                    </b-col>
                    <b-col lg="11">
                      <b-row class="mx-auto px-auto mb-1">
                        <b-col lg="11" class="px-1 py-0 text-left">
                          <p class="py-0 my-0" style="color: #000000; font-size: 20px;">
                            {{ datax.question }}
                          </p>
                        </b-col>
                        <b-col lg="1" class="py-0 my-0" align-self="center">
                        </b-col>
                      </b-row>
                      <b-row v-for="(dataz, indexz) of datax.answers" :key="indexz" class="mx-auto px-auto">
                        <b-col lg="11" class="py-0 pl-0 pr-2 mb-1 text-left" align-self="center">
                          <font-awesome-icon v-if="dataz.correct == 1" class="mb-2" style="display: inline-block; width: 3%; color: green; font-size: 24px" icon="check" />
                          <div v-else style="display: inline-block; width: 3%;"></div>

                          <b-form-group
                            class="mb-0"
                            style="display: inline-block; width: 97%; color: #000000;"
                          >
                            <b-form-input
                              type="text"
                              v-model="dataz.answer"
                              :placeholder="'Respuesta #' + (indexz+1)"
                              style="color: #000000;"
                              @input="AddAnswerToQuestion(index, indexz, dataz.answer, '1')"
                              required
                              class="ml-2 mr-0"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col lg="1" class="m-0 p-0" align-self="center">
                          <font-awesome-icon v-if="dataz.answer" @click="SetCorrectAnswer(index, indexz, '1')" class="ml-2" style="cursor: pointer; color: green; font-size: 20px" icon="check-circle" />
                          <font-awesome-icon v-if="(dataz.answer || (!dataz.answer && indexz != datax.answers.length - 1)) && (indexz > 0 || datax.answers.length > 1)" class="ml-2" @click="DeleteAnswerFromQuestion(index, indexz, '1')" style="cursor: pointer; color: red; font-size: 20px" icon="trash-alt" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="new_questions1" lg="1"></b-col>

                <b-col lg="12" v-if="element_add['1'].questions.length > 0" class="mt-1 text-right">
                  <b-button
                    class="btn btn-success"
                    @click="SaveElement(1)">
                    Guardar preguntas
                  </b-button>
                </b-col>

                <b-col v-if="new_questions1" md="12">
                  <hr>
                </b-col>
              </b-row>
              
              <b-row
                v-for="(data, index) of element.section_1"
                v-bind:key="'A' + index + '-' + render1"
                class="mb-3"
              >
                <!--<b-col md="12" class="mb-2"
                  ><h5>
                    <strong style="color: #9C243C"
                      >Pregunta #{{
                        String(index + 1) +
                          " (" +
                          String(data.question.value) +
                          " pts)"
                      }}</strong
                    >
                  </h5></b-col
                >-->

                <b-col v-if="!data.editable" md="11">
                  <b-form-group :label="data.question.question">
                    <b-form-radio
                      v-for="(datax, indexy) of data.options"
                      v-bind:key="'B' + indexy"
                      v-model="datax.is_selected"
                      disabled
                      style="font-weight: normal;"
                      :value="1"
                    >
                      <span>{{ datax.answer }}</span>
                      <span class="ml-2" v-if="datax.valid == 1"
                        ><font-awesome-icon icon="check-circle" color="green"
                      /></span>
                    </b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col v-if="!data.editable" md="1" class="text-center">
                  <b-button
                    variant="outline-primary"
                    @click="EditQuestion(data, true)">
                    <font-awesome-icon icon="edit" />
                  </b-button>
                </b-col>

                <b-col v-if="data.editable" md="11">
                  <b-form-textarea
                    type="text"
                    v-model="data.question.question"
                    placeholder="Pregunta"
                    :rows="3"
                    no-resize
                    required
                    class="mx-0"
                  />

                  <b-row v-for="(datax, indexy) of data.options" v-bind:key="'B' + indexy" class="my-1">
                    <b-col md="1" class="text-right px-0" align-self="center">
                      <font-awesome-icon v-if="datax.valid == 1" icon="check" color="green" style="font-size: 20px"  />
                    </b-col>
                    <b-col md="9">
                      <b-form-input
                        v-model="datax.answer"
                        placeholder="Respuesta"
                        type="text"
                        style="font-weight: normal;"
                        :value="1"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col md="2" class="px-0 text-left" align-self="center">
                      <font-awesome-icon icon="check-circle" @click="SetCorrectAnswer2(index, indexy, 1)" color="green" style="cursor: pointer; font-size: 20px" />
                      <font-awesome-icon icon="trash-alt" @click="DeleteAnswerFromQuestion2(index, indexy, 1)" color="red" class="ml-2" style="cursor: pointer; font-size: 20px" />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="data.editable" md="1" class="text-center">
                  <b-button
                    variant="outline-primary"
                    @click="EditQuestion(data, false)">
                    <font-awesome-icon icon="edit" />
                  </b-button>
                  <br>
                  <b-button
                    class="mt-1"
                    variant="outline-success"
                    @click="UpdateElement(index, 1)">
                    <font-awesome-icon v-if="!progress" icon="save" />
                    <b-spinner v-else variant="success" class="text-center" small ></b-spinner>
                  </b-button>
                  <br>
                  <b-button
                    class="mt-1"
                    variant="outline-danger"
                    @click="DeleteQuestion(data, 1, index)">
                    <font-awesome-icon v-if="!loader" icon="trash-alt" />
                    <b-spinner v-else variant="danger" class="text-center" small ></b-spinner>
                  </b-button>
                  <br>
                  <b-button
                    v-if="data.options.length < 5"
                    class="mt-1"
                    variant="outline-info"
                    @click="AddAnswerToQuestion2(index, 1)">
                    <font-awesome-icon icon="plus-circle" />
                  </b-button>
                </b-col>
              </b-row>

              <b-row v-if="element.section_1.length == 0" class="mb-3">
                <b-col>
                  <p>No has registrado ninguna pregunta de selección simple aún. Para hacerlo, presiona el botón superior de "Añadir Preguntas"</p>
                </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab title="Verdadero/Falso" @click="ResetVars('2')">
            <div style="width: 100% !important">
              <b-row>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Preguntas de la sección:</strong></p>
                    <p class="my-0">{{ exam.questions_quantity2 }}</p>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Preguntas registradas:</strong></p>
                    <p class="my-0">{{ total_questions2 ? total_questions2 : 0 }}</p>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Puntaje por pregunta:</strong></p>
                    <p class="my-0">{{ exam.value_questions2 == 1 ? exam.value_questions2 + ' punto' : exam.value_questions2 + ' puntos' }}</p>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mx-0 text-center btn btn-info" style="cursor: default">
                    <p class="my-0"><strong>Puntaje de la sección:</strong></p>
                    <p class="my-0">{{ points_questions2  == 1 ? points_questions2 + ' punto' : points_questions2 + ' puntos'  }}</p>
                  </div>
                </b-col>

                <b-col md="12" class="my-3" align-self="center">
                  <h4 class="my-0 py-0" style="display: inline-block">Preguntas</h4>
                  <b-button v-if="!new_questions2" class="ml-3 py-1 btn btn-success" @click="new_questions2 = true" style="display: inline-block">
                    <font-awesome-icon icon="plus" />
                    Añadir preguntas
                  </b-button>
                  <b-button v-else class="ml-3 py-1 btn btn-dark" @click="new_questions2 = false" style="display: inline-block">
                    <font-awesome-icon icon="times" />
                    Cancelar
                  </b-button>
                </b-col>

                <b-col v-if="new_questions2" lg="12" class="mb-2">
                  <b-form-group
                    class="mb-0"
                    style="max-height: 100px; width: 100%; display: block"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="new_question"
                      placeholder="Pregunta"
                      style="display: inline-block; width: 95%; color: #000000;"
                      :rows="3"
                      no-resize
                      required
                      @keyup.enter="AddQuestionToExamVF(new_question, '2')"
                      class="mx-0"
                    >
                    </b-form-textarea>
                    <div style="display: inline-block; width: 1%"></div>
                    <div class="text-left" style="display: inline-block; width: 4%">
                      <font-awesome-icon @click="AddQuestionToExamVF(new_question, '2')" class="p-0 mb-3" style="cursor: pointer; display: inline-block; color: green; font-size: 35px" icon="plus-circle" />
                    </div>
                  </b-form-group>
                </b-col>

                <b-col v-if="new_questions2" lg="1"></b-col>
                <b-col v-if="new_questions2" lg="10">
                  <b-row v-for="(datax, index) of element_add['2'].questions" :key="datax.question + index" align-h="center" class="mx-auto px-auto py-1 mb-1 text-left" style="border-style: solid; border-width: 1px; border-color: green">
                    <b-col lg="1" class="py-0 mb-0 pb-0 text-center" align-self="center">
                      <font-awesome-icon @click="DeleteQuestionFromExam(index, '2')" style="cursor: pointer; color: red; font-size: 26px" icon="trash-alt" />
                    </b-col>
                    <b-col lg="11">
                      <b-row class="mx-auto px-auto mb-1">
                        <b-col lg="11" class="px-1 py-0 text-left">
                          <p class="py-0 my-0" style="color: #000000; font-size: 20px;">
                            {{ datax.question }}
                          </p>
                        </b-col>
                        <b-col lg="1" class="py-0 my-0" align-self="center">
                        </b-col>
                      </b-row>
                      <b-row v-for="(dataz, indexz) of datax.answers" :key="indexz" class="mx-auto px-auto">
                        <b-col lg="11" class="py-0 pl-0 pr-2 mb-1 text-left" align-self="center">
                          <font-awesome-icon v-if="dataz.correct == 1" class="mb-2" style="display: inline-block; width: 3%; color: green; font-size: 24px" icon="check" />
                          <div v-else style="display: inline-block; width: 3%;"></div>

                          <b-form-group
                            class="mb-0"
                            style="display: inline-block; width: 97%; color: #000000;"
                          >
                            <b-form-input
                              type="text"
                              v-model="dataz.answer"
                              :placeholder="'Respuesta #' + (indexz+1)"
                              style="color: #000000;"
                              disabled
                              @input="AddAnswerToQuestion(index, indexz, dataz.answer, '2')"
                              required
                              class="ml-2 mr-0"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col lg="1" class="m-0 p-0" align-self="center">
                          <font-awesome-icon @click="SetCorrectAnswer(index, indexz, '2')" class="ml-2" style="cursor: pointer; color: green; font-size: 20px" icon="check-circle" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="new_questions2" lg="1"></b-col>

                <b-col lg="12" v-if="element_add['2'].questions.length > 0" class="mt-1 text-right">
                  <b-button
                    class="btn btn-success"
                    @click="SaveElement(2)">
                    Guardar preguntas
                  </b-button>
                </b-col>

                <b-col v-if="new_questions2" md="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row
                v-for="(data, index) of element.section_2"
                v-bind:key="'A' + index + '-' + render2"
                class="mb-3"
              >
                <!--<b-col md="12" class="mb-2"
                  ><h5>
                    <strong style="color: #9C243C"
                      >Pregunta #{{
                        String(index + 1) +
                          " (" +
                          String(data.question.value) +
                          " pts)"
                      }}</strong
                    >
                  </h5></b-col
                >-->

                <b-col v-if="!data.editable" md="11">
                  <b-form-group :label="data.question.question">
                    <b-form-radio
                      v-for="(datax, indexy) of data.options"
                      v-bind:key="'B' + indexy"
                      v-model="datax.is_selected"
                      disabled
                      style="font-weight: normal;"
                      :value="1"
                    >
                      <span>{{ datax.answer }}</span>
                      <span class="ml-2" v-if="datax.valid == 1"
                        ><font-awesome-icon icon="check-circle" color="green"
                      /></span>
                    </b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col v-if="!data.editable" md="1" class="text-center">
                  <b-button
                    variant="outline-primary"
                    @click="EditQuestion(data, true)">
                    <font-awesome-icon icon="edit" />
                  </b-button>
                </b-col>

                <b-col v-if="data.editable" md="11">
                  <b-form-textarea
                    type="text"
                    v-model="data.question.question"
                    placeholder="Pregunta"
                    :rows="3"
                    no-resize
                    required
                    class="mx-0"
                  />

                  <b-row v-for="(datax, indexy) of data.options" v-bind:key="'B' + indexy" class="my-1">
                    <b-col md="1" class="text-right px-0" align-self="center">
                      <font-awesome-icon v-if="datax.valid == 1" icon="check" color="green" style="font-size: 20px"  />
                    </b-col>
                    <b-col md="9">
                      <b-form-input
                        v-model="datax.answer"
                        placeholder="Respuesta"
                        type="text"
                        disabled
                        style="font-weight: normal;"
                        :value="1"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col md="2" class="px-0 text-left" align-self="center">
                      <font-awesome-icon icon="check-circle" @click="SetCorrectAnswer2(index, indexy, 2)" color="green" style="cursor: pointer; font-size: 20px" />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="data.editable" md="1" class="text-center">
                  <b-button
                    variant="outline-primary"
                    @click="EditQuestion(data, false)">
                    <font-awesome-icon icon="edit" />
                  </b-button>
                  <br>
                  <b-button
                    class="mt-1"
                    variant="outline-success"
                    @click="UpdateElement(index, 2)">
                    <font-awesome-icon v-if="!progress" icon="save" />
                    <b-spinner v-else variant="success" class="text-center" small ></b-spinner>
                  </b-button>
                  <br>
                  <b-button
                    class="mt-1"
                    variant="outline-danger"
                    @click="DeleteQuestion(data, 2, index)">
                    <font-awesome-icon v-if="!loader" icon="trash-alt" />
                    <b-spinner v-else variant="danger" class="text-center" small ></b-spinner>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>

      <!--<b-card :title="title">
        <b-row>
          <b-col md="6" style="text-align: -webkit-left"> </b-col>
          <b-col md="3" style="text-align: -webkit-right"> </b-col>
          <b-col md="3" style="text-align: -webkit-right">
            <button class="btn-wine py-1 px-3" style="border-radius: 10px; border: none; color: white" @click="$router.go(-1)">Volver</button>
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>

        <b-card no-body v-if="!loading">
          <b-tabs card>
            <b-tab title="Detalles" :title-link-class="'tab-title-class'" active>
              <div style="width: 100% !important">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Licencia" label-for="license">
                      <b-form-input
                        id="license"
                        v-model="license_category"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Puntaje mínimo de aprobación" label-for="min_approval_points">
                      <b-form-input
                        type="number"
                        id="min_approval_points"
                        v-model="min_approval_points"
                        name="min_approval_points"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Duración del examen (en minutos)" label-for="duration">
                      <b-form-input
                        type="number"
                        id="duration"
                        v-model="duration"
                        name="duration"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Título" label-for="title">
                      <b-form-input
                        id="title"
                        v-model="title"
                        name="title"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="6"></b-col>

                  <b-col md="6">
                    <b-form-group label="Descripción" label-for="description">
                      <b-form-textarea
                        id="description"
                        v-model="description"
                        name="description"
                        rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>

                  <b-col md="6"></b-col>
                  
                  <b-col md="12"><hr></b-col>
                  
                  <b-col md="12">
                    <h3 class="mb-3" style="color: #9C243C">Cantidad de preguntas por sección</h3>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Preguntas - Selección simple" label-for="question_quantity1">
                      <b-form-input
                        type="number"
                        id="question_quantity1"
                        v-model="questions_quantity1"
                        name="questions_quantity1"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Preguntas - Verdadero/Falso" label-for="question_quantity2">
                      <b-form-input
                        type="number"
                        id="question_quantity2"
                        v-model="questions_quantity2"
                        name="questions_quantity2"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Preguntas - Pareamiento" label-for="question_quantity4">
                      <b-form-input
                        type="number"
                        id="question_quantity4"
                        v-model="questions_quantity4"
                        name="questions_quantity4"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Preguntas - Completación" label-for="question_quantity3">
                      <b-form-input
                        type="number"
                        id="question_quantity3"
                        v-model="questions_quantity3"
                        name="questions_quantity3"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Preguntas - Desarrollo (abiertas)" label-for="question_quantity5">
                      <b-form-input
                        type="number"
                        id="question_quantity5"
                        v-model="questions_quantity5"
                        name="questions_quantity5"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Preguntas - Desarrollo (análisis)" label-for="question_quantity6">
                      <b-form-input
                        type="number"
                        id="question_quantity6"
                        v-model="questions_quantity6"
                        name="questions_quantity6"
                        :disabled="element.active == 1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="12 text-right">
                    <button
                      @click="UpdateExam()"
                      class="btn-wine mx-2 py-1 px-3" style="border-radius: 10px; border: none; color: white">
                      <span v-show="!loading">Guardar</span>
                      <b-spinner v-show="loading" small class="align-middle" style="color: #FFFFFF"></b-spinner>
                    </button>
                    <button
                      @click="Delete(element)"
                      class="btn-dark mx-2 py-1 px-3" style="border-radius: 10px; border: none; color: white">
                      <span v-show="!loading">Eliminar</span>
                      <b-spinner v-show="loading" small class="align-middle" style="color: #FFFFFF"></b-spinner>
                    </button>
                  </b-col>
                </b-row>
              </div>
            </b-tab>      
          </b-tabs>
        </b-card>
      </b-card>-->
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex"
import Swal from "sweetalert2"
import Cookies from 'js-cookie'

export default {
  name: "DetailsExam",

  data() {
    return {
      section: '1',
      render1: 0,
      render2: 0,
      id_question: 0,
      type_question: 0,
      question: '',
      right_answer: '',
      answer: '',
      id_match_answer: 0,
      type_answer: '',
      element: {section_1: [], section_2: []},
      questionTMP: '',
      answersTMP: [],
      new_question: '',
      new_questions1: false,
      new_questions2: false,
      edit_questions1: [],
      edit_questions2: [],
      element_add: { '1': { questions:[] }, '2': { questions: [] } },
      loading: false,
      loader: false,
      progress: false,
    };
  },
  computed: {
    ...mapState({
      exam: (state) => state.evaluation.exam,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    total_questions1() {
      return this.element.section_1.length
    },
    points_questions1() {
      return this.exam.questions_quantity1 * this.exam.value_questions1
    },
    total_questions2() {
      return this.element.section_2.length
    },
    points_questions2() {
      return this.exam.questions_quantity2 * this.exam.value_questions2
    },
  },
  mounted() {
    this.FindExam()
  },
  methods: {
    ...mapActions(["loadData_Exam","Save","Get","GetAll","GetByID","Update","ChangeStatus"]),
    EditQuestion(data, state) {
      for (let item of this.element.section_1) { item.editable = false }
      for (let item of this.element.section_2) { item.editable = false }

      if (state) {
        this.questionTMP = data.question.question
        this.answersTMP = []

        for (let item of data.options) {
          this.answersTMP.push({ id: item.id, answer: item.answer, valid: item.valid })
        }
      }
      else {
        data.question.question = this.questionTMP
        data.options = []

        for (let item of this.answersTMP) {
          data.options.push(item)
        }
      }

      data.editable = state
      if (data.question.type_question == 1)       this.render1++
      else if (data.question.type_question == 2)  this.render2++
    },
    ResetVars(section) {
      if (section != this.section) {
        this.section = section
        //this.new_questions = false
        //this.element_add[section] = { questions: [] }
      }
    },
    FindExam() {
      this.loading = true

      let form = {
        api: 1,
        element: `quiz_poll/data/${this.$route.params.id}`,
      }

      this.Get(form)
        .then((response) => {
          console.log(response)
          this.loading = false

          if (response.code === 200) {
            this.loadData_Exam(response.data.exam)
            this.element = response.data

            for (let i = 0; i < this.element.section_1.length; i++) {
              this.element.section_1[i].editable = false
              this.edit_questions1.push(true)
            }

            for (let i = 0; i < this.element.section_2.length; i++) {
              this.element.section_2[i].editable = false
              this.edit_questions2.push(false)
            }
          }
          /*else if (response.code == 420 || response.code == 421) {              
            Cookies.remove('access_token')
            Cookies.remove('email')
            Cookies.remove('id_role')

            Swal.fire({
              title: '¡Atención!',
              text: 'Debe iniciar sesión nuevamente para continuar',
              icon: "warning",
              confirmButtonColor: '#9C243D',
            })

            this.$router.push({ name: 'login' })
          }*/
        })
        .catch((error) => {
          this.loading = false
          console.log(error);
        });
    },
    AddQuestionToExam(question, section) {
      if (question) {
        let questions = this.element_add[section].questions
        this.element_add[section].questions = [{ question: question, answers: [{ correct: 0, answer: null }] }].concat(questions)
        this.new_question = null
      }
    },
    AddQuestionToExamVF(question, section) {
      if (question) {
        let questions = this.element_add[section].questions
        this.element_add[section].questions = [{ question: question, answers: [{ correct: 0, answer: 'Verdadero' },{ correct: 0, answer: 'Falso' }] }].concat(questions)
        this.new_question = null
      }
    },
    DeleteQuestionFromExam(index, section) {
      this.element_add[section].questions.splice(index,1)
    },
    AddAnswerToQuestion(index1, index2, answer, section) {
      if (!answer || answer.length == 0) {
        if (index2 == this.element_add[section].questions[index1].answers.length - 2) {
          this.element_add[section].questions[index1].answers.splice(index2+1, 1)
        }
      }
      else {
        if (this.element_add[section].questions[index1].answers.length == 5) {
          return
        }
        if (index2 == this.element_add[section].questions[index1].answers.length - 1) {
          this.element_add[section].questions[index1].answers.push({ correct: 0, answer: null })
        }
      }
    },
    AddAnswerToQuestion2(index, section) {
      this.element["section_"+section][index].options.push({ id: 0, answer: null, valid: 0 })
    },
    SetCorrectAnswer(index1, index2, section) {
      for (let item of this.element_add[section].questions[index1].answers) {
        item.correct = 0
      }

      this.element_add[section].questions[index1].answers[index2].correct = 1
    },
    SetCorrectAnswer2(index1, index2, section) {
      for (let item of this.element["section_"+section][index1].options) {
        item.valid = 0
      }

      this.element["section_"+section][index1].options[index2].valid = 1
    },
    DeleteAnswerFromQuestion(index1, index2, section) {
      this.element_add[section].questions[index1].answers.splice(index2,1)
    },
    DeleteAnswerFromQuestion2(index1, index2, section) {
      this.element["section_"+section][index1].options.splice(index2,1)
    },
    SaveElement(type) {
      const validation = this.element_add[String(type)].questions.length == 0

      if (validation) {
        Swal.fire({
          title: "Completa los campos",
          text: "Debe registrar al menos una pregunta",
          confirmButtonText: "Aceptar",
          icon: "warning",
        });
        return
      }

      let key = 0

      for (let i = 0; i < this.element_add[String(type)].questions.length; i++) {
        let ans_correct = 0

        for (let j = 0; j < this.element_add[String(type)].questions[i].answers.length; j++) {
          if (!this.element_add[String(type)].questions[i].answers[j].answer && j != this.element_add[String(type)].questions[i].answers.length - 1) {
            this.element_add[String(type)].questions[i].answers.splice(j,1)
          }

          if (this.element_add[String(type)].questions[i].answers.length == 0) {
            this.element_add[String(type)].questions.splice(i,1)
          }

          if (this.element_add[String(type)].questions.length == 0) {
            Swal.fire({
              title: "Verifique las preguntas",
              text: "Se han eliminado automáticamente las preguntas que no tuvieran respuestas y ha acabado en que no existe ninguna pregunta válida",
              confirmButtonText: "Aceptar",
              icon: "warning",
            });
            return
          }

          ans_correct += this.element_add[String(type)].questions[i].answers[j] ? this.element_add[String(type)].questions[i].answers[j].correct : 0
        }

        if ((this.element_add[String(type)].questions[i].answers.length <= 2 && type == 1) || (this.element_add[String(type)].questions[i].answers.length < 2 && type == 2)) {
          Swal.fire({
            title: "Verifique las preguntas",
            text: "Recuerde que todas las preguntas deben tener por lo menos 2 posibles respuestas",
            confirmButtonText: "Aceptar",
            icon: "warning",
          });
          return
        }

        if (ans_correct == 0) {
          Swal.fire({
            title: "Verifique las preguntas",
            text: "Olvidó indicar la respuesta correcta para una de sus preguntas",
            confirmButtonText: "Aceptar",
            icon: "warning",
          });
          return
        }
      }

      if (key == 1) {
        Swal.fire({
          title: "Verifique las preguntas",
          text: "Recuerde que todas las preguntas deben tener por lo menos 2 posibles respuestas y debe indicarse cuál es la opción correcta para cada pregunta",
          confirmButtonText: "Aceptar",
          icon: "warning",
        });
        return
      }

      this.progress = true

      let form = {
        api: 1,
        element: 'question',
        data: {
          id_quiz_poll: this.exam.id,
          questions: JSON.stringify(this.element_add[String(type)].questions),
          type_question: type
        }
      }

      this.Save(form)
        .then((response) => {
          if (response.code == 200) {
            this.progress = false;

            for (let item of response.data) {
              this.element["section_"+type].push(item)
            }

            if (this.element_add[String(type)].questions.length > response.data.length) {
              Swal.fire({
                title: this.element_add[String(type)].questions.length > 1 ? 'Preguntas añadidas correctamente' : 'Pregunta añadida correctamente',
                text: "Sin embargo, una de las preguntas ya se encontraba registrada, por lo cual se omitió en el proceso de guardado",
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: 'Aceptar',
                timer: 1500,
              });
            }
            else {
              Swal.fire({
                title: this.element_add[String(type)].questions.length > 1 ? 'Preguntas añadidas correctamente' : 'Pregunta añadida correctamente',
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            }

            this.element_add[String(type)] = {questions: []}
            
            if (type == 1)        this.new_questions1 = false
            else if (type == 2)   this.new_questions2 = false
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: 'Ya existe un elemento con estos datos',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: 'Ha ocurrido un error',
          });
        });
    },
    UpdateElement(index, section) {
      let elementx = this.element["section_"+section][index]
      const validation = !elementx.question.question 

      if (validation) {
        Swal.fire({
          title: "Completa los campos",
          text: "El campo de pregunta no puede quedar vacío",
          confirmButtonText: "Aceptar",
          icon: "warning",
        });
        return
      }

      let ans_correct = 0

      for (let j = 0; j < elementx.options.length; j++) {
        if (!elementx.options[j].answer) {
          Swal.fire({
            title: "Verifique las respuestas",
            text: "Al parecer ha dejado alguna respuesta vacía, en caso de ser innecesaria, recuerde que puede eliminarla",
            confirmButtonText: "Aceptar",
            icon: "warning",
          });
          return
        }

        ans_correct += elementx.options[j] ? elementx.options[j].valid : 0
      }

      if ((elementx.options.length <= 2 && section == 1) || (elementx.options.length < 2 && section == 2)) {
        Swal.fire({
          title: "Verifique las respuestas",
          text: "Recuerde que todas las preguntas deben tener por lo menos 2 posibles respuestas",
          confirmButtonText: "Aceptar",
          icon: "warning",
        });
        return
      }

      if (ans_correct == 0) {
        Swal.fire({
          title: "Verifique las respuestas",
          text: "Olvidó indicar la respuesta correcta para la pregunta",
          confirmButtonText: "Aceptar",
          icon: "warning",
        });
        return
      }

      let form = {
        api: 1,
        element: 'question',
        update: 'update',
        id: elementx.question.id,
        data: {
          question: elementx.question.question,
          answers: JSON.stringify(elementx.options)
        }
      }

      Swal.fire({
        title: "Confirmación",
        text: "¿Está seguro de modificar esta pregunta y sus respuestas?",
        confirmButtonText: "Aceptar",
        cancelButtonText: 'Cancelar',
        icon: "question",
      }).then((result) => {
        if (result) {
          this.progress = true

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                this.progress = false;

                this.element["section_"+section][index] = response.data
                this.element["section_"+section][index].editable = false

                Swal.fire({
                  title: 'Pregunta actualizada correctamente',
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });

                //this.element_add = {questions: []}
                //this.new_questions = false
              } else {
                this.progress = false;
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "warning",
                  title: 'Ya existe un elemento con estos datos',
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.progress = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: 'Ha ocurrido un error',
              });
            });
        }
      })
    },
    DeleteQuestion(data, section, index) {
      Swal.fire({
        title: "Eliminar",
        text: "¿Estás seguro que deseas eliminar esta pregunta?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        //confirmButtonColor: "#9C243D",
        cancelButtonColor: "gray",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.loader = true

          let form = {
            api: 1,
            element: `question`,
            id: data.question.id,
            status: 2,
          }

          this.ChangeStatus(form)
            .then((response) => {
              this.loader = false

              if (response.code === 200) {
                this.element["section_"+section].splice(index, 1)

                Swal.fire({
                  title: "Pregunta eliminada",
                  confirmButtonColor: "#9C243C",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                })
              }
              else if (response.code == 420 || response.code == 421) {              
                Cookies.remove('access_token')
                Cookies.remove('email')
                Cookies.remove('id_role')

                Swal.fire({
                  title: '¡Atención!',
                  text: 'Debe iniciar sesión nuevamente para continuar',
                  icon: "warning",
                  confirmButtonColor: '#9C243D',
                })

                this.$router.push({ name: 'login' })
              }
            })
            .catch((error) => {
              this.loader = false
              console.log(error)
            })
        }
      })
    },
  },
}

</script>

<style>
.tab-title-class {
  color: #9C243D !important;
}
</style>
