<template>
  <div
    
  >
    <div class="col-md-12">
      <br />
      <br />
      <b-row>
        <div class="col-md-4"></div>
        <div class="col-md-2">
          <b-input-group size="sm">
            <b-img
              center
              src="https://i.ibb.co/F8fJWF0/ueeal-Logo.png"
              height="100"
              fluid
              alt="Ticktaps Logo"
            ></b-img>
          </b-input-group>
        </div>
        <div class="col-md-2">
          <b-input-group size="sm">
            <b-img
              center
              src="https://i.ibb.co/2sfQS31/logonew.png"
              fluid
              alt="Ticktaps Logo"
            ></b-img>
          </b-input-group>
        </div>
        <div class="col-md-4"></div>
      </b-row>
      <b-row>
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <br />
          <h3 class="text-center">Bienvenido a la solicitud de cupo</h3>
          <!-- <div class="row">
            <b-button type="button" variant="outline-warning" class="m-3" v-on:click="step -= 1">Atras</b-button>
            <b-button type="button" variant="outline-success" class="m-3" v-on:click="step += 1">Siguiente</b-button>
          </div>-->

          <div class="row">
            <b-card class="col-md-12">
              <div>
                <b-row class="center">
                  <div class="row">
                    <div class="col-md-10">
                      <p class="col-md-10">
                        Por favor, le pedimos que sea sincero en la información que va a registrar, para evaluar su solicitud, nos comunicaremos con usted,
                        para que asista a una cita en la escuela, luego de evaluar la información indicada a continuación:
                      </p>
                    </div>
                    <div class="col-md-2">
                      <router-link :to="{ name: 'ueealweb'}" class="btn btn-outline-warning btn-sm">Volver al inicio</router-link>
                    </div>
                  </div>
                  <b-card title="Datos del estudiante">
                    <b-row>
                      <b-col md="4">
                        <b-form-group label="Nombre del estudiante" label-for="student_name">
                          <b-form-input
                            id="student_name"
                            v-model="student_name"
                           
                            name="student_name"
                            placeholder="Nombre"
                          />
                       
                        </b-form-group>

                        <b-form-group label="Fecha de nacimiento" label-for="student_birthdate">
                          <date-picker
                            id="student_birthdate"
                            name="student_birthdate"
                            mode="single"
                            :max-date="new Date()"
                           
                            v-model="student_birthdate"
                            :popover="{ placement: 'bottom', visibility: 'click' }"
                          />
                        
                        </b-form-group>
                        
                      </b-col>
                      <b-col md="4">
                        
                        <b-form-group
                          label="¿Posee alguna condición médica?"
                          label-for="student_condition"
                        >
                          <b-form-input
                            id="student_condition"
                            v-model="student_condition"
                            name="student_condition"
                            placeholder
                          ></b-form-input>
                        
                        </b-form-group>
                        <b-form-group
                          label="Posee el historial médico de su representado"
                          label-for="student_medical_history"
                        >
                          <b-form-input
                            id="student_medical_history"
                            v-model="student_medical_history"
                            name="student_medical_history"
                            placeholder
                          ></b-form-input>
                        
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Grado que desea cursar" label-for="level_request">
                          <b-form-input
                            id="level_request"
                            v-model="level_request"
                           
                            name="level_request"
                            placeholder
                          ></b-form-input>
                        
                        </b-form-group>
                        <b-form-group
                          label="¿El estudiante tiene hermanos en la escuela?"
                          label-for="student_family_school"
                        >
                          <b-form-select v-model="student_family_school" :options="familyOptions"></b-form-select>
                        
                        </b-form-group>
                        <b-form-group
                          v-if="student_family_school=='Si'"
                          label="Indica el grado y sección de su hermano"
                          label-for="grade"
                        >
                          <b-form-input id="grade" v-model="grade" name="grade" placeholder></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <br />
                  <b-card title="Datos del representante">
                    <b-row>
                      <b-col md="4">
                        <b-form-group label="Nombre y apellido" label-for="rep_names">
                          <b-form-input
                            id="rep_names"
                            v-model="rep_names"
                           
                            name="rep_names"
                            placeholder
                          />
                       
                        </b-form-group>
                        <b-form-group label="Cédula" label-for="rep_dni">
                          <b-form-input
                            id="rep_dni"
                            v-model="rep_dni"
                           
                            name="rep_dni"
                            placeholder
                          ></b-form-input>
                     
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Teléfono" label-for="rep_phone">
                          <b-form-input
                            id="rep_phone"
                            v-model="rep_phone"
                           
                            name="rep_phone"
                            placeholder
                          />
                      
                        </b-form-group>
                        <b-form-group label="Teléfono de Casa" label-for="rep_home_phone">
                          <b-form-input
                            id="rep_home_phone"
                            v-model="rep_home_phone"
                           
                            name="rep_home_phone"
                            placeholder
                          ></b-form-input>
                       
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Correo electrónico" label-for="rep_email">
                          <b-form-input
                            id="rep_email"
                            v-model="rep_email"
                           
                            name="rep_email"
                            placeholder
                          ></b-form-input>
                       
                        </b-form-group>
                        <b-form-group label="Nacionalidiad" label-for="rep_nacionality">
                          <b-form-input
                            id="rep_nacionality"
                            v-model="rep_nacionality"
                           
                            name="rep_nacionality"
                            placeholder
                          ></b-form-input>
                       
                        </b-form-group>
                      </b-col>

                      <!--  <b-col md="12 text-right">
                        <b-button
                          type="button"
                          v-on:click="saveRepresent()"
                          variant="outline-primary"
                          class="m-3"
                        >Enviar Solicitud</b-button>
                      </b-col>-->
                    </b-row>
                  </b-card>
                  <b-card title="Datos adicionales" class="col-md-12">
                    <b-row>
                      <b-col class="col-md-10">
                        <b-form-group label label-for="rep_names">
                          <p>Solo puedes generar una solicitud, si quieres inscribir a mas de un alumno, en este campo puedes indicarlo, e indicar sus datos.</p>
                          <p>
                            <strong>Es importante que indiques el grado que desean cursar los estudiantes adicionales</strong>
                          </p>

                          <b-textarea
                            id="request_observation"
                            v-model="request_observation"
                           
                            name="request_observation"
                            placeholder="Si quieres inscribir a otros estudiantes, o tu solicitud necesita indicar otros datos, puedes hacerlo aquí."
                          />
                      
                        </b-form-group>
                      </b-col>
                      <b-col md="2 text-right">
                        <b-button
                          type="button"
                          v-on:click="saveRepresent()"
                          variant="outline-primary"
                          class="m-3"
                        >Enviar Solicitud</b-button>
                      </b-col>
                    </b-row>
                    <b-row></b-row>
                  </b-card>
                </b-row>
              </div>
            </b-card>
          </div>
        </div>
        <div class="col-md-2"></div>
      </b-row>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Moment from "moment";
//import RegisterUser from "../../pages/GEN/user/Register";
import Swal from "sweetalert2";
export default {
  name: "PreRegister",
  components: {
    //RegisterUser
  },
  data() {
    return {
      student_name: "",
      student_birthdate: "",
      religion: "",
      church: "",
      level_request: "",
      rep_names: "",
      rep_dni: "",
      rep_phone: "",
      rep_home_phone: "",
      rep_email: "",
      rep_nacionality: "",
      student_family_school: "",
      student_condition: "",
      student_medical_history: "",
      id_school: "",
      request_observation: "",
      grade: "",
      familyOptions: [
        {
          value: "Si",
          text: "Si"
        },
        {
          value: "No",
          text: "No"
        }
      ],
      step: 0
    };
  },
  mounted() {
    //this.allRoles();
  },
  methods: {
    ...mapActions([
      "newUser",
      "publicRequestRepresentative",
      "findUserByEmail"
    ]),
    saveRepresent() {
      console.log("Save Represent");
      if (
        this.student_name == "" ||
        this.rep_names == "" ||
        this.rep_email == ""
      ) {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de completar toda la información",
          icon: "warning"
        });
      } else {
        let form = {
          student_name: this.student_name,
          student_birthdate: Moment(this.student_birthdate).format(
            "YYYY-MM-DD"
          ),
          /* religion: this.religion,
          church: this.church, */
          level_request: this.level_request,
          rep_names: this.rep_names,
          rep_dni: this.rep_dni,
          rep_phone: this.rep_phone,
          rep_home_phone: this.rep_home_phone,
          rep_email: this.rep_email,
          rep_nacionality: this.rep_nacionality,
          student_family_school: this.student_family_school + " " + this.grade,
          student_condition: this.student_condition,
          student_medical_history: this.student_medical_history,
          request_observation: this.request_observation,
          id_school: 7
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de enviar esta solicitud?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar"
        }).then(result => {
          if (result.value) {
            this.publicRequestRepresentative(form)
              .then(response => {
                if (response.code === 200) {
                  Swal.fire({
                    title: "Solicitud enviada",
                    text: "Pronto nos pondremos en contacto",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2)
                    this.$router.push({ name: "ueealweb" });
                  });
                }
                if (response.code === 409) {
                  Swal.fire({
                    title: "Registro Previo",
                    text:
                      "Ya estos datos fueron registrados previamente, por favor compruebe",
                    icon: "warning"
                  }).then(result2 => {
                    console.log(result2)
                    //  this.$router.push({ name: "uecamag" });
                  });
                }
                if (response.code === 410) {
                  Swal.fire({
                    title: "Cédula previamente registrada",
                    text:
                      "Encontramos una solicitud con esta cédula previamente registrada",
                    icon: "warning"
                  }).then(result2 => {
                    console.log(result2)
                    //  this.$router.push({ name: "uecamag" });
                  });
                }
                if (response.code === 411) {
                  Swal.fire({
                    title: "Correo Previamente Registrado",
                    text: "Por favor comprueba el email",
                    icon: "warning"
                  }).then(result2 => {
                    console.log(result2)
                    //   this.$router.push({ name: "uecamag" });
                  });
                }
              })
              .catch(error => {console.log(error)});
          } else {
            this.loading = false;
          }
        });
      }
    }
  }
};
</script>



<style scoped>
.content {
  margin-left: 0px !important;
}
</style>