<template>
  <div id="MassiveImport">
    <br />
    <div class="content">
      <b-card>
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
             Importacion de JSON
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
         
            <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar profesores</router-link>-->
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="6" class="my-1">
                <b-form-group
              label=""
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mt-3"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="jsonurl"
                
                  type="text"
                  placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button @click="readJson"
                    >Leer</b-button
                  >
                </b-input-group-append>
              </b-input-group>
                </b-form-group>
          </b-col>
          <b-col lg="6">
         
          </b-col>
        
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
                <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                hover
                :primary-key="id"
                :items="students"
                :fields="field"
           
              >
                <!--                :filter="filter"-->
                <thead></thead>
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(type)="row">
                  <p v-if="row.item.id_type_evaluation == 1">Clase</p>
                  <p v-if="row.item.id_type_evaluation == 2">Tarea</p>
                  <p v-if="row.item.id_type_evaluation == 3">Exámen</p>
                </template>
                <template v-slot:cell(actions)="row">
                  <!-- <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title="Activado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="check-square"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title="Desactivado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="minus-square"/>

                  </b-button>-->

                  <b-button
                      size="sm"
                      variant="outline-primary"
                      class="mr-2"
                      @click="sendRegister(row)"
                  >

                    <v-icon name="save"/>
                  </b-button>

                

                   

                   
                
                  

                  <!--  <b-button
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      @click="delete_Teacher(row)"
                  >

                    <v-icon name="trash"/>
                  </b-button>-->
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
             
       
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>


import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "MassiveImport",
  data() {
    return {
      fathers_count: 0,
      isBusy: false,
      id: "",
      items: [],
      item_table: null,
      ttap_selected: null,
      modalShow:false,
      jsonurl:'',
      field: [
        {
          key: "s_dni",
          label: "S DNI",
          sortable: true,
        },
        {
          key: "s_last_name",
          label: "S Apellido",
          sortable: true,
        },
        {
          key: "s_first_name",
          label: "S Nombre",
          sortable: true,
        },

        {
          key: "s_email",
          label: "S Email",
          sortable: true,
        },
        {
          key: "r_dni",
          label: "R DNI",
          sortable: true,
        },
        {
          key: "r_last_name",
          label: "R APELLIDO",
          sortable: true,
        },
        {
          key: "r_first_name",
          label: "R NOMBRE",
          sortable: true,
        },
        {
          key: "r_email",
          label: "R EMAIL",
          sortable: false,
        },
        {
          key: "actions",
          label: "Detalles",
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      fathers: [],
      teachers: [],
      teacher: "",
      fathersTMP: [],
      loading: false,
      error: "",
      actual_evaluation:{},
      students:[]
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathersTMP.length;
    },
  },
  mounted() {
    //this.readJson();
  },
  methods: {
    ...mapActions([
    "getJson",
    "sendMassive"
    ]),
    readJson(){
        console.log('READING JSON: '+ this.jsonurl)
        this.getJson(this.jsonurl)
        .then((response) => {
          this.isBusy = false;
          console.log("evaluations get");
          console.log(response.data);
         
            this.students = response.data;
         
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    detail_Evaluation(row){
        this.modalShow = true;
        console.log(row.item)
        this.actual_evaluation = row.item;
    },
    sendRegister(row) {
      Swal.fire({
        title: "Guardar registro",
        text:
          "Guardar Registro",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Registrar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            s_dni:row.item.s_dni,
            s_last_name:row.item.s_last_name,
            s_first_name:row.item.s_first_name,
            s_phone:row.item.s_phone,
            s_gender:row.item.s_gender,
            s_email:row.item.s_email,
            s_birthday:row.item.s_birthday,
            s_password:row.item.s_password,
            r_dni:row.item.r_dni,
            r_last_name:row.item.r_last_name,
            r_first_name:row.item.r_first_name,
            r_email:row.item.r_email,
            r_gender:row.item.r_gender,
            r_phone:row.item.r_phone,
            r_password:row.item.r_password,
            id_section:row.item.id_section,
            id_school:row.item.id_school
          };
          this.sendMassive(form)
            .then((response) => {
              if (response.code === 200) {
                this.fathers.splice(row.index, 1);
                Swal.fire({
                   title: response.message.message,
                   text:row.item.r_email,
                  icon: "success",
                });
              }
              if (response.code === 409) {
                   Swal.fire({
                  title: response.message,
                  icon: "warning",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
