import axios from "axios";
import * as Config from "../../../config";

const state = {
    typeEvaluation: {}
};

const actions = {
    load_TypeEvaluation({commit, dispatch}) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "eval/type_evaluation/all")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadTypeEvaluation_Active({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(
                    Config.apiPath + "eval/type_evaluation/all/status/" + form.active
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadData_TypeEvaluation({commit, dispatch}, data) {
        console.log(dispatch)
        console.log(commit)
        commit("LOAD_DATA_TYPE_EVALUATION", data);
    },
    new_TypeEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "eval/type_evaluation/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    changeActive_TypeEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + "eval/type_evaluation/change_status/" + form.id + "/" + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    update_TypeEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + "eval/type_evaluation/update/" + form.id, form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    delete_TypeEvaluation({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath +
                    "eval/type_evaluation/change_status/" +
                    form.id +
                    "/" +
                    form.active
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};

const mutations = {
    LOAD_DATA_TYPE_EVALUATION(state, data) {
        state.typeEvaluation = data;
    }
};

export default {
    state,
    actions,
    mutations
};
