import axios from "axios";
import { makeMutations } from "../../helpers";
import * as Config from "../../../config";

const state = {
  settings: {
    loading: false,
    upload_percentage: 0,
    ms_loading: 0,
    open_swipe: false
  }
};
let mod = 'gen/'
const actions = {
  stopLoading({ commit }) {
    commit("STOP_LOADING");
  },
  get_counters({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "counters/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  get_teacher_counters({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "teacher/counters/" + form.id_teacher)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  get_root_counters({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    console.log(form)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "root_counters")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  uploadArchive({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios(
      {
        method: 'post',
        url: Config.apiPath + "upload_cloud",
        data: form,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudent_archives({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "archives/user/" + form.id_user,{
          params: form
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  update_upload_percentage({ commit }, percentage) {
    commit("UPLOAD_PERCENTAGE", percentage);
  },

  update_ms_loading({ commit }, ms) {
    commit("MS_LOADING", ms);
  },

  swipe_refresh({ commit }, open_swipe) {
    commit("SWIPE_REFRESH", open_swipe);
  },

  loadDashboard({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DASHBOARD");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "dashboard/data")
        .then(response => {
          commit("LOAD_DASHBOARD_OK", response.data);
          resolve();
        })
        .catch(error => {
          commit("LOAD_DASHBOARD_FAIL");
          reject(error.response.data);
        });
    });
  },

  loadAdminDashboard({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_ADMIN_DASHBOARD");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "dashboard/admin-data")
        .then(response => {
          commit("LOAD_ADMIN_DASHBOARD_OK", response.data);
          resolve();
        })
        .catch(error => {
          commit("LOAD_ADMIN_DASHBOARD_FAIL");
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  ...makeMutations(
    [
      "CHECK_LOGIN",
      "LOGIN",
      "REGISTER",
      "UPDATE_PROFILE",
      "LOAD_DASHBOARD",
      "LOAD_ADMIN_DASHBOARD",
      "LOAD_ENTRIES",
      "LOAD_ALL_ENTRIES",
      "LOAD_ENTRY",
      "STORE_ENTRY",
      "UPDATE_ENTRY",
      "DELETE_ENTRY",
      "LOAD_USERS",
      "LOAD_USER",
      "UPDATE_USER",
      "DELETE_USER",
      "LOAD_WEEKLY_REPORT",
      "LOAD_EVENTS_BY_DATE",
      "LOAD_EVENTS_BY_ID",

      "STORE_EVENT",

      "LOAD_EVENTS",

      "LOAD_CATEGORIES",

      "CHANGE_ACTIVE_EVENT",

      "STORE_SHARE",

      "STORE_FAVORITE",

      "LOAD_EVENTS_SHARE",

      "LOAD_USER_POINT",

      "LOAD_PLEASURES_CATEGORY_USER",

      "LOAD_CATEGORY",

      "SEARCH_EVENTS",
      "SET_SCHOOL"
    ],
    state => {
      state.settings.loading = true;
      state.settings.upload_percentage = 0;
      state.settings.ms_loading = 0;
    }
  ),

  ...makeMutations(
    [
      "STOP_LOADING",
      "CHECK_LOGIN_OK",
      "CHECK_LOGIN_SPA",
      "CHECK_LOGIN_FAIL",
      "SET_SCHOOL",
      "LOGIN_OK",
      "LOGIN_FAIL",
      "REGISTER_OK",
      "REGISTER_FAIL",
      "UPDATE_PROFILE_OK",
      "UPDATE_PROFILE_FAIL",
      "LOAD_DASHBOARD_FAIL",
      "LOAD_ADMIN_DASHBOARD_FAIL",
      "LOAD_ENTRIES_OK",
      "LOAD_ENTRIES_FAIL",
      "LOAD_ALL_ENTRIES_OK",
      "LOAD_ALL_ENTRIES_FAIL",
      "LOAD_ENTRY_OK",
      "LOAD_ENTRY_FAIL",
      "STORE_ENTRY_OK",
      "STORE_ENTRY_FAIL",
      "UPDATE_ENTRY_OK",
      "UPDATE_ENTRY_FAIL",
      "DELETE_ENTRY_OK",
      "DELETE_ENTRY_FAIL",
      "LOAD_USERS_OK",
      "LOAD_USERS_FAIL",
      "LOAD_USER_OK",
      "LOAD_USER_FAIL",
      "UPDATE_USER_OK",
      "UPDATE_USER_FAIL",
      "DELETE_USER_OK",
      "DELETE_USER_FAIL",
      "LOAD_WEEKLY_REPORT_OK",
      "LOAD_WEEKLY_REPORT_FAIL",
      "LOAD_EVENTS_BY_DATE_OK",
      "LOAD_EVENTS_BY_DATE_FAIL",
      "LOAD_EVENTS_BY_ID_OK",
      "LOAD_EVENTS_BY_ID_FAIL",

      "STORE_EVENT_OK",
      "STORE_EVENT_FAIL",

      "LOAD_EVENTS_OK",
      "LOAD_EVENTS_FAIL",

      "LOAD_CATEGORIES_OK",
      "LOAD_CATEGORIES_FAIL",

      "CHANGE_ACTIVE_EVENT_OK",
      "CHANGE_ACTIVE_EVENT_FAIL",

      "STORE_SHARE_OK",
      "STORE_SHARE_FAIL",

      "STORE_FAVORITE_OK",
      "STORE_FAVORITE_FAIL",

      "LOAD_EVENTS_SHARE_OK",
      "LOAD_EVENTS_SHARE_FAIL",

      "LOAD_USER_POINT_OK",
      "LOAD_USER_POINT_FAIL",

      "LOAD_PLEASURES_CATEGORY_USER_OK",
      "LOAD_PLEASURES_CATEGORY_USER_FAIL",

      "LOAD_CATEGORY_OK",
      "LOAD_CATEGORY_FAIL",

      "SEARCH_EVENTS_OK",
      "SEARCH_EVENTS_FAIL"
    ],
    state => {
      state.settings.loading = false;
      state.settings.upload_percentage = 0;
      state.settings.ms_loading = 0;
    }
  ),

  UPLOAD_PERCENTAGE(state, percentage) {
    state.settings.upload_percentage = percentage;
  },

  MS_LOADING(state, ms) {
    state.settings.ms_loading = ms;
  },

  SWIPE_REFRESH(state, open_swipe) {
    state.settings.open_swipe = open_swipe;
  }
};

export default {
  state,
  actions,
  mutations
};
