<template>
  <div id="AllReport">
    <br />
    <div class="content">
      <b-card title="Lista de Pagos Reportados">
        <b-row>
          <b-col md="6" style="text-align: -webkit-left">
            <p>
              A continuación, podrás visualizar una lista de los pagos
              registrados por los representantes.
            </p>
          </b-col>
          <b-col md="6" style="text-align: -webkit-right">
            <p><strong>Estado de Pagos</strong></p>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col" style="background-color: #eeefef">
                    Sin verificar
                  </th>
                  <th scope="col" style="background-color: #e8ffcd">
                    No se pudo verificar
                  </th>
                  <th scope="col" style="background-color: #eeefef">
                    Verificado
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{ count_pay_1 }}</th>
                  <td>{{ count_pay_2 }}</td>
                  <td>{{ count_pay_3 }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <br />
        <b-modal hide-footer id="modal-pay" title="Indicar Estado de Pago">
          <b-list-group>
            <b-list-group-item>
              <p>
                Estudiante:
                <strong>{{ payment_selected.student.user.full_name }}</strong>
              </p>
            </b-list-group-item>
            <b-list-group-item>
              Representate:
              <strong>{{
                payment_selected.student.parent1.user.full_name
              }}</strong>
            </b-list-group-item>
            <b-list-group-item v-if="payment_selected.bank_school">
              Banco de la institución: <strong>{{ payment_selected.bank_school.name }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Banco de Origen: <strong>{{ payment_selected.bank.name }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Referencia: <strong>{{ payment_selected.reference }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Nombre del titular: <strong>{{ payment_selected.detail_user ? payment_selected.detail_user : "No disponible" }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Cédula del titular: <strong>{{ payment_selected.dni_user }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Monto: <strong>{{ payment_selected.amount }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Tasa de Cambio usada:
              <strong>{{ payment_selected.quote }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Monto en USD: <strong>{{ payment_selected.amount_usd }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Fecha de pago: <strong>{{ payment_selected.date ? payment_selected.date.substr(0, 10) : "No disponible" }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Registrado: <strong>{{ payment_selected.created_at }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Concepto: <strong>{{ payment_selected.concept }}</strong>
            </b-list-group-item>
            
          </b-list-group>

          <br />
          <hr />
          <b-form-group label="Indica el Estado">
            <b-form-select
              v-model="status_pay_selected"
              :options="status_payments"
            ></b-form-select>
          </b-form-group>
          <hr />
          <b-button class="mt-3" block @click="checkPay"
            >Cambiar Estado</b-button
          >
        </b-modal>
        <b-tabs card>
          <b-tab title="Sin Verificar" active>
            <!-- TAB CONTENT -->
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filtrar por"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      v-on:keyup="searchInput1"
                      type="text"
                      placeholder="Escribe para buscar"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="clearFilter"
                        >Limpiar</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Por página"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3 px-3">
              <b-col sm="12">
                <div>
                  <b-table
                    style="font-size: 13px"
                    id="my-table"
                    show-empty
                    small
                    hover
                    fixed
                    striped
                    stacked="md"
                    :filter-debounce="300"
                    :items="paymentsTMP1"
                    :fields="field"
                    :current-page="currentPagePayment1"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                    :busy="isBusy"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-dark my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Cargando...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(dateCreated)="row">
                      {{ row.item.created_at }}
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button
                        v-if="row.item.status == 1"
                        size="sm"
                        variant="outline-primary"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 2"
                        size="sm"
                        variant="outline-danger"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 3"
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="viewDetail(row.item)"
                      >
                        <v-icon name="photo-video" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col sm="12">
                <div>
                  <p class="mt-3">Página actual: {{ currentPagePayment1 }}</p>
                  <b-pagination
                    v-model="currentPagePayment1"
                    :total-rows="rowsPayment1"
                    :per-page="perPage"
                    aria-controls="my-table"
                    size="sm"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
            <!-- END TAB CONTENT -->
          </b-tab>
          <b-tab title="No se pudo verificar">
            <!-- TAB CONTENT -->
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filtrar por"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      v-on:keyup="searchInput2"
                      type="text"
                      placeholder="Escribe para buscar"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="clearFilter"
                        >Limpiar</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Por página"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3 px-3">
              <b-col sm="12">
                <div>
                  <b-table
                    style="font-size: 13px"
                    id="my-table2"
                    show-empty
                    small
                    hover
                    fixed
                    striped
                    stacked="md"
                    :filter-debounce="300"
                    :items="paymentsTMP2"
                    :fields="field"
                    :current-page="currentPagePayment2"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                    :busy="isBusy"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-dark my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Cargando...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(dateCreated)="row">
                      {{ row.item.created_at }}
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button
                        v-if="row.item.status == 1"
                        size="sm"
                        variant="outline-primary"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 2"
                        size="sm"
                        variant="outline-danger"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 3"
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="viewDetail(row.item)"
                      >
                        <v-icon name="photo-video" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col sm="12">
                <div>
                  <p class="mt-3">Página actual: {{ currentPagePayment2 }}</p>
                  <b-pagination
                    v-model="currentPagePayment2"
                    :total-rows="rowsPayment2"
                    :per-page="perPage"
                    aria-controls="my-table2"
                    size="sm"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
            <!-- END TAB CONTENT -->
          </b-tab>
          <b-tab title="Verificados">
            <!-- TAB CONTENT -->
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filtrar por"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      v-on:keyup="searchInput3"
                      type="text"
                      placeholder="Escribe para buscar"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="clearFilter"
                        >Limpiar</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Por página"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3 px-3">
              <b-col sm="12">
                <div>
                  <b-table
                    style="font-size: 13px"
                    id="my-table3"
                    show-empty
                    small
                    hover
                    fixed
                    striped
                    stacked="md"
                    :filter-debounce="300"
                    :items="paymentsTMP3"
                    :fields="field"
                    :current-page="currentPagePayment3"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                    :busy="isBusy"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-dark my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Cargando...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(dateCreated)="row">
                      {{ row.item.created_at }}
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button
                        v-if="row.item.status == 1"
                        size="sm"
                        variant="outline-primary"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 2"
                        size="sm"
                        variant="outline-danger"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 3"
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="viewDetail(row.item)"
                      >
                        <v-icon name="photo-video" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col sm="12">
                <div>
                  <p class="mt-3">Página actual: {{ currentPagePayment3 }}</p>
                  <b-pagination
                    v-model="currentPagePayment3"
                    :total-rows="rowsPayment3"
                    :per-page="perPage"
                    aria-controls="my-table3"
                    size="sm"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
            <!-- END TAB CONTENT -->
          </b-tab>
          <!--<b-tab title="Cobros de Mensualidad">
            <b-row>
              <b-col md="8">
                <b-button v-b-modal.modal-1
                  >Generar Cobro de Mensualidad</b-button
                >
              </b-col>
              <b-col md="4">
                <b-form-select
                  v-model="month"
                  :options="months"
                  v-on:change="allPaymentGenerated"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
            </b-row>

            <hr />
            <b-modal id="modal-1" hide-footer title="Generación de Cobro">
              <p style="font-size: 12px">
                Esta acción le permite generar el cobro masivo a todos los
                representantes de la institución
              </p>
              <p style="font-size: 12px">
                Al ejecutarlo, los representantes verán en sus cuentas reflejado
                el cobro del mes en la sección de Pagos
              </p>
              <p style="font-size: 12px">
                <strong
                  >Es recomendable que ejecute esta acción una vez al
                  mes</strong
                >
              </p>
              <form
                style="width: 100% !important"
                method="post"
                @submit.prevent="onSubmitGeneratePay"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Periodo" label-for="period">
                      <b-form-input
                        id="period"
                        v-model="period"
                        name="period"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Monto a Cobrar"
                      label-for="description"
                      description="Basado en la mensualidad actual configurada para la escuela"
                    >
                      <b-form-input
                        id="amount"
                        v-model="amount"
                        name="description"
                        placeholder
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Mes a Cobrar" label-for="description">
                      <b-form-select
                        v-model="month"
                        :options="months"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      class="mt-3"
                      variant="outline-primary"
                      type="submit"
                      pill
                      block
                      >Ejecutar Cobro de Mensualidad</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </b-modal>
           
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filtrar por"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      v-on:keyup="searchInput4"
                      type="text"
                      placeholder="Escribe para buscar"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="clearFilter"
                        >Limpiar</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Por página"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3 px-3">
              <b-col sm="12">
                <div>
                  <b-table
                    style="font-size: 13px"
                    id="my-table4"
                    show-empty
                    small
                    hover
                    fixed
                    striped
                    stacked="md"
                    :filter-debounce="300"
                    :items="paymentsGenerated"
                    :fields="fieldGenerated"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                    :busy="isBusy"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-dark my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Cargando...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(dateCreated)="row">
                      {{ row.item.created_at }}
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button
                        v-if="row.item.status == 1"
                        size="sm"
                        variant="outline-primary"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 2"
                        size="sm"
                        variant="outline-danger"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                      <b-button
                        v-if="row.item.status == 3"
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        title=""
                        @click="checkShow(row.item)"
                      >
                        <v-icon name="money-bill" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col sm="12">
                <div>
                  <p class="mt-3">Página actual: {{ currentPage }}</p>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table4"
                    size="sm"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
         
          </b-tab>-->
        </b-tabs>
      </b-card>
      <b-modal
        v-model="modalShow"
        id="modal-payment-reported"
        title="Detalle de Pago Reportado"
      >
        <p v-if="payment_selected.image" class="my-4">
          Captura del pago enviado
        </p>
        <p v-if="!payment_selected.image" class="my-4">
          Este pago no tiene una imagen adjunta
        </p>
        <img
          v-if="payment_selected.image"
          width="100%"
          :src="payment_selected.image"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function (e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Moment from "moment";
export default {
  name: "AllReport",
  data() {
    return {
      isBusy: false,
      items: [],
      item_table: null,
      ttap_selected: null,
      payment: {
        user: {
          student: "",
        },
      },
      amount: "",
      period: "",
      month: 1,
      id_period: "",
      payment_selected: {
        student: {
          user: {
            full_name: "",
          },
          parent1: {
            user: {
              full_name: "",
            },
          },
        },
        bank: {
          name: "",
        },
      },
      status_pay_selected: 1,
      status_payments: [
        { value: 1, text: "Sin verificar" },
        { value: 2, text: "No se pudo verificar" },
        { value: 3, text: "Verificado" },
      ],

      field: [
        {
          key: "student.user.full_name",
          label: "Estudiante",
          sortable: true,
        },
        {
          key: "bank.name",
          label: "Emisor",
          sortable: true,
        },
        {
          key: "concept",
          label: "Concepto",
          sortable: true,
        },
         {
          key: "reference",
          label: "Referencia",
          sortable: true,
        },
        {
          key: "amount",
          label: "Monto Bs.",
          sortable: true,
        },

        {
          key: "amount_usd",
          label: "USD",
          sortable: true,
        },

        {
          key: "dateCreated",
          label: "Fecha",
          sortable: true,
        },
        {
          key: "status_label",
          label: "Estatus",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      fieldGenerated: [
        {
          key: "student.user.full_name",
          label: "Estudiante",
          sortable: true,
        },
        {
          key: "month_label",
          label: "Mes",
          sortable: true,
        },
        {
          key: "amount",
          label: "Monto",
          sortable: true,
        },

        {
          key: "created_at",
          label: "Fecha",
          sortable: true,
        },
      ],
      rows: 0,
      months: [
        {
          id: 1,
          name: "Enero",
        },
        {
          id: 2,
          name: "Febrero",
        },
        {
          id: 3,
          name: "Marzo",
        },
        {
          id: 4,
          name: "Abril",
        },
        {
          id: 5,
          name: "Mayo",
        },
        {
          id: 6,
          name: "Junio",
        },
        {
          id: 7,
          name: "Julio",
        },
        {
          id: 8,
          name: "Agosto",
        },
        {
          id: 9,
          name: "Septiembre",
        },
        {
          id: 10,
          name: "Octubre",
        },
        {
          id: 11,
          name: "Noviembre",
        },
        {
          id: 12,
          name: "Diciembre",
        },
      ],
      perPage: 10,
      currentPagePayment1: 1,
      currentPagePayment2: 1,
      currentPagePayment3: 1,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      payments: [],
      paymentsTMP1: [],
      paymentsTMP1_temp: [],
      paymentsTMP2: [],
      paymentsTMP2_temp: [],
      paymentsTMP3: [],
      paymentsTMP3_temp: [],
      paymentsGenerated: [],
      loading: false,
      error: "",
      count_pay_1: 0,
      count_pay_2: 0,
      count_pay_3: 0,
      modalShow: false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rowsPayment1() {
      return this.paymentsTMP1.length;
    },
    rowsPayment2() {
      return this.paymentsTMP2.length;
    },
    rowsPayment3() {
      return this.paymentsTMP3.length;
    },
  },
  mounted() {
    this.allPayments(1);
    this.allPayments(2);
    this.allPayments(3);
    this.allConfigs();
    this.allPaymentGenerated();
  },
  methods: {
    ...mapActions([
      "loadStudent_school",
      "updateStudentPayReported",
      "loadStudentPaymentsBySchool",
      "loadSchools_Configs",
      "set_homeStudent",
      "changeActiveStudent",
      "loadData_Student",
      "deleteStudent",
      "generatePayments",
      "loadStudentBalanceByMonth",
    ]),
    viewDetail(item) {
      console.log(item);
      this.payment_selected = item;
      this.modalShow = true;
    },
    clearFilter() {
      this.paymentsTMP1 = this.paymentsTMP1_temp;
      this.paymentsTMP2 = this.paymentsTMP2_temp;
      this.paymentsTMP3 = this.paymentsTMP3_temp;
      this.paymentsGenerated = this.paymentsGenerated_temp;
      this.paymentsTMP = this.payments;
      this.filter = "";
      return true;
    },
    searchInput1() {
      let filterTMP = this.filter;
      let findedTmp = [];

      if (filterTMP) {
        this.paymentsTMP1.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            findedTmp.push(el);
          }
        });
        this.paymentsTMP1 = findedTmp;
      } else {
        this.paymentsTMP1 = this.paymentsTMP1_temp;
      }
      return true;
    },
    searchInput2() {
      let filterTMP = this.filter;
      let findedTmp = [];

      if (filterTMP) {
        this.paymentsTMP2.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            findedTmp.push(el);
          }
        });
        this.paymentsTMP2 = findedTmp;
      } else {
        this.paymentsTMP2 = this.paymentsTMP2_temp;
      }
      return true;
    },
    searchInput3() {
      let filterTMP = this.filter;
      let findedTmp = [];

      if (filterTMP) {
        this.paymentsTMP3.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            findedTmp.push(el);
          }
        });
        this.paymentsTMP3 = findedTmp;
      } else {
        this.paymentsTMP3 = this.paymentsTMP3_temp;
      }
      return true;
    },
    searchInput4() {
      let filterTMP = this.filter;
      let findedTmp = [];

      if (filterTMP) {
        this.paymentsGenerated.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            findedTmp.push(el);
          }
        });
        this.paymentsGenerated = findedTmp;
      } else {
        this.paymentsGenerated = this.paymentsGenerated_temp;
      }
      return true;
    },
    allPayments(status) {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        status: status,
      };
      this.loadStudentPaymentsBySchool(form)
        .then((response) => {
          this.isBusy = false;
          if (response.code === 200) {
            this.payments = response.data;
            if (status == 1) {
              this.count_pay_1 = 0;
              this.paymentsTMP1 = this.payments;
              this.paymentsTMP1_temp = this.paymentsTMP1;
              for (let i in this.payments) {
                console.log(i);
                this.count_pay_1 += 1;
              }
            }
            if (status == 2) {
              this.count_pay_2 = 0;
              this.paymentsTMP2 = this.payments;
              this.paymentsTMP2_temp = this.paymentsTMP2;
              for (let i in this.payments) {
                console.log(i);
                this.count_pay_2 += 1;
              }
            }
            if (status == 3) {
              this.count_pay_3 = 0;
              this.paymentsTMP3 = this.payments;
              this.paymentsTMP3_temp = this.paymentsTMP3;
              for (let i in this.payments) {
                console.log(i);
                this.count_pay_3 += 1;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allConfigs() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        rol: 99,
      };
      this.loadSchools_Configs(form)
        .then((response) => {
          if (response.code === 200) {
            //this.amount = response.data.amount;
            this.period = response.data.school.period.name;
            console.log("PERIOD:" + this.period);
            this.id_period = response.data.school.period.id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allPaymentGenerated() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        month: this.month,
      };
      this.loadStudentBalanceByMonth(form)
        .then((response) => {
          if (response.code === 200) {
            this.paymentsGenerated = response.data.transactions;
            this.paymentsGenerated_temp = response.data.transactions;
            this.isBusy = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    alert() {
      alert("Proximamente esta funcionalidad estara lista");
    },
    edit_Student(data) {
      this.loadData_Student(data).then((response) => {
        console.log(response);
        this.$router.push("/estudiante/editar/" + data.id);
      });
    },

    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 = data.active === 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de desactivarlo ahora?"
          : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActiveStudent(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    checkPay(data) {
      this.loading = true;

      let form = {
        id: this.payment_selected.id,
        status: this.status_pay_selected,
        id_period: this.id_period,
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de editar el pago de este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
      }).then((result) => {
        if (result.value) {
          this.updateStudentPayReported(form)
            .then((response) => {
              if (response.code === 200) {
                data.status_pay = this.status_pay_selected;
                this.allPayments(1);
                this.allPayments(2);
                this.allPayments(3);
                this.$bvModal.hide("modal-pay");
                Swal.fire({
                  title: "Listo",
                  text: "El estado de pago ha sido modificado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    checkShow(data) {
      console.log(data);

      this.$bvModal.show("modal-pay");
      this.payment_selected = data;
      //this.payment = data;
      this.status_pay_selected = data.status;
      console.log(this.payment_selected);
    },
    delete_Student(row) {
      Swal.fire({
        title: "Eliminar Estudiante",
        text: "¿Estás seguro de eliminar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.deleteStudent(form)
            .then((response) => {
              if (response.code === 200) {
                this.users.splice(row.index, 1);
                Swal.fire({
                  title: "Estudiante Eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.currentPagePayment1 = 1;
      this.currentPagePayment2 = 1;
      this.currentPagePayment3 = 1;
    },
    onSubmitGeneratePay() {
      if (this.amount == "") {
        Swal.fire({
          title: "Escribe el monto a cobrar",
          text: "Asegurate de escribir el monto a cobrar",
          icon: "warning",
        });
      } else {
        // this.loading = true;
        const date = new Date();
        const year = Moment(date).year();

        let form = {
          id_school: this.me_rol.id_school,
          id_creator: this.me.id,
          amount: this.amount,
          month: this.month,
          year: year,
          id_period: this.id_period,
        };
        console.log(form);

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de generar este cobro? Esta acción le debitará el monto seleccionado a todos los representantes activos en el periodo",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar",
        }).then((result) => {
          if (result.value) {
            this.generatePayments(form)
              .then((response) => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Cobro del mes generado",
                    text: "Ahora todos los representantes veran reflejado su cobro",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$bvModal.hide("modal-1");
                  });
                }
                if (response.code == 409) {
                  Swal.fire({
                    title: "Cobros previamente generados",
                    text: "Un cobro con los parametros indicados ya fue realizado previamente",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$bvModal.hide("modal-1");
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        });
      }
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
