import axios from "axios";
import * as Config from "../../../config";

const state = {
  assistances: []
};

const actions = {
  all_assistance({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "assistance/log_assistance/all")
        .then(response => {
          resolve(response.data);
          commit("GET_ALL_ASSISTANCE", response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  allAssistance_user({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            "assistance/log_assistance/all_by_user/" +
            form.id_user
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  GET_ALL_ASSISTANCE(state, data) {
    state.assistances = data;
  },
  CHECK_ASSISTANCE_OK() {},
  
  CHECK_ASSISTANCE() {}
};

export default {
  state,
  actions,
  mutations
};
