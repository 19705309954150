<template>
  <div id="AllTeacher">
    <br />
    <div class="content">
      <b-card>
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              En este listado se muestran las clases, evaluaciones y tareas,
              creadas por los profesores.
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <h5>Registros: {{ fathers_count }}</h5>
            <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar profesores</router-link>-->
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="3" class="my-1">
            <b-form-group
              label=""
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mt-3"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  v-on:keyup="searchInput"
                  type="text"
                  placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter"
                    >Limpiar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col md="6">
                Escuela:
                <b-form-select
                  id="perPageSelect"
                  value-field="id"
                  text-field="name"
                  @input="allTeachers"
                  v-model="actual_school"
                  size="sm"
                  :options="school"
                />
              </b-col>
              <b-col md="6">
                Profesor:
                <b-form-select
                  id="perPageSelect"
                  value-field="id"
                  text-field="user.full_name"
                  @input="allEvaluations"
                  v-model="teacher"
                  size="sm"
                  :options="teachers"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="3" class="my-1">
            <b-form-group
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mt-3"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                hover
                :primary-key="id"
                :items="fathersTMP"
                :fields="field"
                :current-page="currentPage"
                :per-page="perPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <thead></thead>
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(type)="row">
                  <p v-if="row.item.id_type_evaluation == 1">Clase</p>
                  <p v-if="row.item.id_type_evaluation == 2">Tarea</p>
                  <p v-if="row.item.id_type_evaluation == 3">Exámen</p>
                </template>
                <template v-slot:cell(actions)="row">
                  <!-- <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title="Activado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="check-square"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title="Desactivado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="minus-square"/>

                  </b-button>-->

                  <b-button
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      @click="delete_Evaluation(row)"
                  >

                    <v-icon name="trash"/>
                  </b-button>

                    <b-button
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      @click="detail_Evaluation(row)"
                  >

                    <v-icon name="eye"/>

                   
                  </b-button>
                  

                  <!--  <b-button
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      @click="delete_Teacher(row)"
                  >

                    <v-icon name="trash"/>
                  </b-button>-->
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
                <b-modal v-model="modalShow" title="Detalle de la clase">
                    
                    
                   <b-list-group>
                        <b-list-group-item><strong>Título:</strong><br>{{actual_evaluation.title}}</b-list-group-item>
  <b-list-group-item><strong>Descripción:</strong><br>{{actual_evaluation.description}}</b-list-group-item>
  <b-list-group-item><strong>Fecha:</strong><br>{{actual_evaluation.deadline}}</b-list-group-item>
 
   <b-list-group-item><strong>Documento:</strong><br><a v-if="actual_evaluation.url_document" :href="actual_evaluation.url_document" target="a_blank">Ver Documento</a></b-list-group-item>
 <b-list-group-item><strong>Imagen:</strong><br><a v-if="actual_evaluation.url_image" :href="actual_evaluation.url_image" target="a_blank">Ver Imagen</a></b-list-group-item>
 <b-list-group-item><strong>Link:</strong><br><a v-if="actual_evaluation.url_link" :href="actual_evaluation.url_link" target="a_blank">Ver Link</a></b-list-group-item>
 <b-list-group-item><strong>Video:</strong><br><a v-if="actual_evaluation.url_video" :href="actual_evaluation.url_video" target="a_blank">Ver Video</a></b-list-group-item>
 <b-list-group-item><strong>Nota de Voz:</strong><br><a v-if="actual_evaluation.url_voice" :href="actual_evaluation.url_voice" target="a_blank">Ver Nota de voz</a></b-list-group-item>
<b-list-group-item><strong>Creada:</strong><br>{{actual_evaluation.created_at}}</b-list-group-item>
<b-list-group-item><strong>Actualizada:</strong><br>{{actual_evaluation.updated_at}}</b-list-group-item>

</b-list-group>
                    
                </b-modal>
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllTeacher",
  data() {
    return {
      fathers_count: 0,
      isBusy: false,
      id: "",
      items: [],
      item_table: null,
      ttap_selected: null,
      modalShow:false,
      field: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "teacher.user.full_name",
          label: "Profesor",
          sortable: true,
        },
        {
          key: "section.name",
          label: "Sección",
          sortable: true,
        },

        {
          key: "subject.name",
          label: "Materia",
          sortable: true,
        },
        {
          key: "title",
          label: "Título",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "lapse.name",
          label: "Momento",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Creado",
          sortable: false,
        },
        {
          key: "actions",
          label: "Detalles",
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      fathers: [],
      teachers: [],
      teacher: "",
      fathersTMP: [],
      loading: false,
      error: "",
      actual_evaluation:{}
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathersTMP.length;
    },
  },
  mounted() {
    this.allSchool();
  },
  methods: {
    ...mapActions([
      "load_Evaluation2",
      "changeActiveTeacher",
      "loadData_Teacher",
      "deleteTeacher",
      "loadData_Evaluation",
      "loadTeachers",
      "loadSchool",
    ]),
    detail_Evaluation(row){
        this.modalShow = true;
        console.log(row.item)
        this.actual_evaluation = row.item;
    },
    allSchool() {
      this.isBusy = true;
      this.loadSchool()
        .then((response) => {
          this.isBusy = false;
          console.log("evaluations get");
          if (response.code === 200) {
            this.school = response.data;
            this.actual_school = this.school[0].id;
            console.log("evaluations obtained");
            this.allTeachers();
              this.allEvaluations();

            this.schoolTmp = this.school;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    delete_Evaluation(row) {
      Swal.fire({
        title: "Eliminar Evaluación",
        text:
          "¿Estás seguro de eliminar esta evaluación?, esta eliminación, hará que se pierda acceso completo a esta clase",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009900",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
          };
          this.deleteEvaluation(form)
            .then((response) => {
              if (response.code === 200) {
                this.fathers.splice(row.index, 1);
                Swal.fire({
                  title: "Evaluacion Eliminada",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    allTeachers() {
      this.isBusy = true;
      let form = {
        id_school: this.actual_school,
      };

      this.loadTeachers(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.teachers = response.data;
            this.teacher = this.teachers[0].id
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.fathersTMP = this.fathers;
      this.filter = "";
      return true;
    },
    accessClass(evaluation) {
      this.loadData_Evaluation(evaluation).then((response) => {
        console.log(response);
        this.$router.push("/director/evaluacion/detalle");
      });
    },
    searchInput() {
      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.fathersTMP.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          cadena = cadena.toLowerCase();
          cadena = clearAccents(cadena);

          if (cadena.indexOf(filterTMP.toLowerCase(), i) > -1) {
            studentsTmp.push(el);
          }
        });
        this.fathersTMP = studentsTmp;
      } else {
        this.fathersTMP = this.fathers;
      }

      return true;
    },
    allEvaluations() {
      this.isBusy = true;
      let form = {
        id_school: this.actual_school,
        active: 1,
      };
      if (this.teacher) {
        form["id_teacher"] = this.teacher;
      }
      form["order_key"] = "id";
      form["order_value"] = "desc";
      this.load_Evaluation2(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.fathers = response.data;
            this.fathersTMP = response.data;
            console.log(this.fathers.length);
            this.fathers_count = this.fathers.length;
           // this.allTeachers();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit_Teacher(data) {
      this.loadData_Teacher(data).then((response) => {
        console.log(response);
        this.$router.push("/director/evaluaciones/profesor/editar/" + data.id);
        //  this.$router.push({ name: "uecamag" });
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de Desactivarlo Ahora?"
          : "¿Estás seguro de Activarlo Ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActiveTeacher(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    delete_Teacher(row) {
      Swal.fire({
        title: "Eliminar Profesor",
        text:
          "¿Estás seguro de eliminar este Profesor?, esta eliminación, hará que el profesor pierda acceso a sus materias",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.deleteTeacher(form)
            .then((response) => {
              if (response.code === 200) {
                this.fathers.splice(row.index, 1);
                Swal.fire({
                  title: "Profesor Eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
