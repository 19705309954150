<template>
  <div id="AllUsers">
    <br/>
    <div class="content">
      <b-card title="Lista de representantes">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              A continuación podrás visualizar una lista de los representantes
              registrados.
            </p>
          </b-col>
           
          <b-col md="4" style="text-align: -webkit-right">
          
          </b-col>
        </b-row>
        <br/>

        <b-row>
           <b-col lg="4" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
          <b-col lg="4" class="lmy-1">
            <b-form-group
                label="Por página"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
            </b-form-group>
          </b-col>
           <b-col lg="4" class="lmy-1">
            <b-form-group
                label="Escuela"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
               
            >
              <b-form-select  value-field="id"
                text-field="name" @change="load_Users" id="perPageSelect" v-model="actual_school" size="sm" :options="schoolsList"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-1">
          <b-col sm="12">
            <div>
              <b-table style="font-size:14px"
               show-empty
      small
      stacked="md"
      :items="usersTmp"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"

                       id="my-table"
                     
                       :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="changeStatus(row.item)"
                  >
                    <v-icon name="check-square" color="#fff"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="changeStatus(row.item)"
                  >
                    <v-icon name="minus-square" color="#fff"/>
                  </b-button>
                    <b-button
                      v-if="row.item.active === 2"
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover                     
                  >
                    <v-icon name="minus-square" color="#fff"/>
                  </b-button>
                  <b-button
                      size="sm"
                      variant="outline-primary"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="editUser(row.item)"
                  >
                    <v-icon name="eye" color="#fff"/>
                  </b-button>
                </template>
              </b-table>
              <b-modal hide-footer id="modal-pay" title="Indicar Solvencia">
                <p class="my-4">
                  Por favor indica el estado de pago de este estudiante
                  <strong>{{ student.user.full_name }}</strong>
                </p>
                <br/>
                <b-form-select v-model="status_pay_selected" :options="status_payments"></b-form-select>
                <b-button class="mt-3" block @click="checkPay">Marcar Solvencia</b-button>
              </b-modal>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>

         

        </b-row>

      </b-card>
    </div>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllUsers",
  data() {
    return {
     
      isBusy: false,
      items: [],
      item_table: null,
      ttap_selected: null,
      student: {
        user: {
          name: ""
        }
      },
      status_pay_selected: 1,
      status_payments: [
        {value: 1, text: "No Revisado"},
        {value: 2, text: "Insolvente"},
        {value: 3, text: "Solvente"}
      ],

      fields: [
        {
          key: "id",
          label: "ID Rep.",
          sortable: true
        },
         {
          key: "user.id",
          label: "ID Usuario",
          sortable: true
        },
        {
          key: "user.full_name",
          label: "Nombre y Apellido",
          sortable: true
        },
        {
          key: "user.email",
          label: "Correo Electrónico",
          sortable: true
        },
        {
          key: "user.created_at",
          label: "Registrado",
          sortable: true
        },
        {
          key: "user.updated_at",
          label: "Actualizado",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20,50,100,500],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      users: [],
      usersTmp: [],
      loading: false,
      error: "",
      actual_school:0,
      schoolsList:[]
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return [];
    },
    rows() {
      return this.usersTmp.length;
    }
  },
  mounted() {
    this.load_Schools();
  },
  methods: {
    ...mapActions([
      "allUsersAdmin",
      "changeActiveUser",
      "updateStudent",
      "updateStudentPay",
      "set_homeStudent",
      "changeActiveStudent",
      "loadData",
      "deleteStudent",
      "deleteTotalUser",
      "loadRepresentatives",
      "loadSchool"
    ]),
     
    clearFilterUser() {
      this.usersTmp = this.users;
      this.filter = ''
      return true;
    },
    searchInputUser() {
       let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.usersTmp.filter(function (el, i) {
         
          let cadena = JSON.stringify(el);
          cadena = cadena.toLowerCase()
          cadena = clearAccents(cadena);
          
          if (cadena.indexOf(filterTMP.toLowerCase(), i) > -1) {
            studentsTmp.push(el)
          }
        })
        this.usersTmp = studentsTmp;
      } else {
        this.usersTmp = this.users;
      }

      return true;
    },
    load_Schools(){
      

        this.loadSchool()
          .then(response => {
            this.isBusy = false
            if (response.code === 200) {
              this.schoolsList = response.data;
              this.actual_school = this.schoolsList[0].id;
              console.log(this.schoolsList[0].id)
              console.log(this.actual_school)
              this.load_Users();
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    load_Users() {
      this.isBusy = true
      let form={}
      if(this.actual_school > 0){
        form['id_school'] = this.actual_school
      }
        
      
      this.loadRepresentatives(form)
          .then(response => {
            this.isBusy = false
            if (response.code === 200) {
              this.users = response.data;
              this.usersTmp = this.users;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    alert() {
      alert("Proximamente esta funcionalidad estara lista");
    },
    editUser(data) {
      this.loadData(data).then(response => {
        console.log(response)
        this.$router.push("usuario/detalle/" + data.id);
      });
    },
    show_Student(data) {
      this.loadData_Student(data).then(response => {
        console.log(response)
        this.$router.push("../director/estudiante/detalle/" + data.id);
      });
    },
    homeStudent(data) {
      Swal.fire({
        title: "Atención!",
        text: "¿Esta seguro de cambiar al entorno a estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#37bd25",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, Cambiar."
      }).then(result => {
        if (result.value) {
          this.set_homeStudent(data).then(response => {
            console.log(response)
            this.$router.push("/estudiante");
          });
        }
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 = data.active === 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "¿Estás seguro de desactivarlo ahora?"
              : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            status: active
          };
          this.changeActiveUser(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    title: title2,
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    checkPay(data) {
      this.loading = true;

      let form = {
        id: this.student.id,
        status_pay: this.status_pay_selected
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de editar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
        showLoaderOnConfirm: true,
        timerProgressBar: true,
      }).then(result => {
        if (result.value) {
          this.updateStudentPay(form)
              .then(response => {
                if (response.code === 200) {
                  data.status_pay = this.status_pay_selected;
                  this.allStudent();
                  this.$bvModal.hide("modal-pay");
                  Swal.fire({
                    title: "Listo",
                    text: "El Estudiante ha sido modificado",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2)
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        } else {
          this.loading = false;
        }
      });
    },
    checkShow(data) {
      this.$bvModal.show("modal-pay");

      this.student = data;
      this.status_pay_selected = data.status_pay;
    },
    delete_Student(row) {
      Swal.fire({
        title: "Eliminar Estudiante",
        text: "¿Estás seguro de eliminar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.deleteStudent(form)
              .then(response => {
                if (response.code === 200) {
                  this.users.splice(row.index, 1);
                  Swal.fire({
                    title: "Estudiante Eliminado",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}

</style>
