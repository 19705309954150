<template>
  <div id="DetailStudent">
    <br />
    <div class="content">
      <b-card
        :title="
          'Detalle del Estudiante: ' +
          this.student.user.first_name +
          ' ' +
          this.student.user.last_name
        "
      >
        <b-row>
          <b-col md="6" style="text-align: -webkit-left">
            <p>
              A continuación, podrá ver todo el detalle del estudiante, y
              modificar su información.
            </p>
          </b-col>
          <b-col md="3" style="text-align: -webkit-right">
            <a v-if="image" :href="image" target="a_blank"
              ><img class="img-fluid" style="max-width: 100px" :src="image"
            /></a>
          </b-col>
          <b-col md="3" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{ name: 'director-students-all' }"
              ><v-icon name="angle-left"></v-icon> Listado de
              estudiantes</router-link
            >
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Datos Personales" active>
              <form
                v-if="!loading"
                style="width: 100% !important"
                method="post"
                @submit.prevent="onSubmitPersonal"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nombre" label-for="first_name">
                      <b-form-input
                        id="first_name"
                        v-model="first_name"
                        name="first_name"
                        placeholder="Ingrese Nombre"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Apellido" label-for="last_name">
                      <b-form-input
                        id="last_name"
                        v-model="last_name"
                        name="last_name"
                        placeholder="Ingrese Apellido"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Cédula" label-for="dni">
                      <b-input-group>
                        <template v-slot:prepend>
                          <b-form-select
                            value-field="id"
                            text-field="name"
                            v-model="dni_selected"
                            :options="dnis"
                          ></b-form-select>
                        </template>
                        <b-form-input
                          id="dni"
                          v-model="dni"
                          name="dni"
                          type="number"
                          :formatter="formatNumber"
                          placeholder="Solo Números"
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Fecha de Nacimiento"
                      label-for="birthday"
                    >
                      <date-picker
                        id="birthday"
                        name="birthday"
                        mode="single"
                        :max-date="new Date()"
                        v-model="birthday"
                        :popover="{ placement: 'bottom', visibility: 'click' }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Teléfono" label-for="phone">
                      <b-form-input
                        id="phone"
                        v-model="phone"
                        name="phone"
                        placeholder="Teléfono"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Registrado" label-for="phone">
                      <label>{{
                        moment(created_at).format("DD-MM-YYYY hh:mm a")
                      }}</label>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Correo" label-for="email">
                      <b-form-input
                        id="email"
                        v-model="email"
                        name="email"
                        placeholder="Correo"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Tipo de Sangre</div>
                        <b-form-select
                          v-model="blood_type"
                          :options="bloodTypeArray"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Actualizado" label-for="phone">
                      <label>{{
                        moment(updated_at).format("DD-MM-YYYY hh:mm a")
                      }}</label>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3"
                      >Modificar datos personales</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Datos de Estudiante" active>
              <form
                v-if="!loading"
                style="width: 100% !important"
                method="post"
                @submit.prevent="onSubmitStudent"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Sección" label-for="email">
                      <b-form-select
                        v-model="section"
                        :options="sectionList"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Código de Seguro"
                      label-for="security_health"
                    >
                      <b-form-input
                        id="security_health"
                        v-model="security_health"
                        name="security_health"
                        placeholder="DNI"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Tipo de Estudiante" label-for="phone">
                      <b-form-select
                        v-model="typeStudent"
                        :options="typeStudentList"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Condición del Estudiante"
                      label-for="birthday"
                    >
                      <b-form-select
                        v-model="condition"
                        :options="conditionList"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Sección Anterior" label-for="email">
                      <b-form-select
                        v-model="old_section"
                        :options="sectionList"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"> </b-col>
                  <b-col md="4"> </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3"
                      >Modificar datos de estudiante</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Credenciales">
              <form
                v-if="!loading"
                style="width: 100% !important"
                method="post"
                @submit.prevent="onSubmitPassword"
              >
                <b-row>
                  <b-col md="12">
                    <p>
                      Desde este formulario puede actualizar la clave de acceso
                      del estudiante a la plataforma.
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Contraseña" label-for="password">
                      <b-form-input
                        id="password"
                        v-model="password"
                        name="password"
                        placeholder
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Repita la contraseña"
                      label-for="password_confirm"
                    >
                      <b-form-input
                        id="password_confirm"
                        v-model="password_confirm"
                        name="password_confirm"
                        placeholder
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3"
                      >Modificar acceso de estudiante</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Archivos del Estudiante">
              <b-row>
                <b-col md="12">
                  <b-modal
                    id="modal-upload"
                    title="Subir archivo de estudiante"
                    hide-footer
                  >
                    <b-form
                      id="uploadForm"
                      class="row"
                      method="post"
                      @submit.prevent="uploadArchive"
                    >
                      <div class="col-md-12">
                        <b-form-group
                          label="Nombre del documento que va a subir"
                          label-for="file_name"
                        >
                          <b-form-input
                            id="file_name"
                            v-model="file_name"
                            name="file_name"
                            placeholder="Nombre del archivo"
                          ></b-form-input>
                          <br />
                          <b-form-input
                            id="file_description"
                            v-model="file_description"
                            name="file_description"
                            placeholder="Descripión del archivo"
                          ></b-form-input>
                        </b-form-group>

                        <!-- Styled -->
                        <b-form-file
                          v-model="fileToUpload"
                          :state="Boolean(fileToUpload)"
                          placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                          drop-placeholder="Arrastre el archivo hasta aquí..."
                        ></b-form-file>
                        <div class="mt-3">
                          Archivo Seleccionado:
                          {{ fileToUpload ? fileToUpload.name : "" }}
                        </div>
                      </div>

                      <div class="col-md-4"></div>

                      <div class="col-12 text-left">
                        <b-button
                          :disabled="progress != false"
                          pill
                          type="submit"
                          variant="outline-success"
                          class="m-3"
                          >Subir Archivo</b-button
                        >

                        <b-spinner
                          variant="outline-success"
                          v-show="progress"
                          label="Text Centered"
                        ></b-spinner>
                      </div>
                    </b-form>
                  </b-modal>
                  <b-button v-b-modal.modal-upload class="send_message">
                    Adjuntar Archivo
                    <v-icon name="paperclip"></v-icon>
                  </b-button>
                </b-col>
              </b-row>
              <br />
              <b-list-group>
                <b-list-group-item v-for="file in files" :key="file.id">
                  <b-row> </b-row>
                  <b-row>
                    <b-col md="3">
                      <strong> {{ file.name }}</strong>
                    </b-col>
                    <b-col md="3">
                      {{ file.description }}
                    </b-col>
                    <b-col md="2"> Formato: {{ file.format }} </b-col>
                    <b-col md="2">
                      <a :href="file.url" target="a_blank">Ver Archivo</a>
                    </b-col>
                    <b-col md="2">
                      <b-button
                        @click="deleteFile(file)"
                        variant="outline-danger"
                        size="sm"
                        >Eliminar</b-button
                      >
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
            <b-tab title="Notas y Resultados">
              <b-row>
                <b-col md="6">
                  <h3>Últimas evaluaciones</h3>
                  <div class="overflow-auto" style="max-height: 500px">
                    <b-table
                      style="font-size: 12px"
                      id="my-table"
                      show-empty
                      responsive
                      small
                      hover
                      fixed
                      stacked="md"
                      :filter-debounce="400"
                      primary-key="id"
                      :items="evaluations_results"
                      :fields="field"
                    ></b-table>
                  </div>
                </b-col>

                <b-col md="6">
                  <h3>Promedios</h3>
                  <b-row>
                    <b-col md="5">
                      <b-form-group>
                        <div>
                          <div class="mb-2">Periodo</div>
                          <b-form-select
                            v-model="period"
                            :options="periods"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <b-form-group>
                        <div>
                          <div class="mb-2">Momento</div>
                          <b-form-select
                            v-model="lapse"
                            :options="lapses"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <br />
                      <b-button syze="sm" @click="loadEvaluationsResultsAVG"
                        ><v-icon name="search"></v-icon
                      ></b-button>
                    </b-col>
                  </b-row>

                  <div class="overflow-auto" style="max-height: 500px">
                    <b-table
                      style="font-size: 12px"
                      id="my-table"
                      show-empty
                      responsive
                      small
                      hover
                      fixed
                      stacked="md"
                      :filter-debounce="400"
                      primary-key="id"
                      :items="evaluations_avg_results"
                      :fields="field_avg"
                    ></b-table>
                    <p style="font-size: 11px">
                      Estos promedios son valores referenciales basados en los
                      resultados registrados por los profesores en cada
                      evaluación, si estos datos no se muestran correctamente,
                      se debe a que algunos profesores no han cargado sus
                      resultados, no tome estos datos como valores definitivos.
                    </p>
                  </div>
                </b-col>
              </b-row>
              <br />
            </b-tab>
            <b-tab title="Conexión">
              <Connection :student="student"></Connection>
            </b-tab>
            <b-tab title="Asistencia">
              <p>
                Próximamente podrás hacer el marcaje de asistencia de tus
                alumnos de forma presencial o online
              </p>
            </b-tab>
            <b-tab title="Estadísticas">
              <b-row>
                <b-col>
                  
                    <b-card title="Cantidad de tareas subidas">
                      <b-row>
                        <b-col md="12">
                          <p><strong>Formato de las tareas:</strong></p>
                          <p>
                            <strong>Imagen: </strong
                            >{{ stats.evaluation_image_docs_count }} enviadas.
                          </p>

                          <p>
                            <strong>Documento: </strong
                            >{{ stats.evaluation_doc_docs_count }} enviadas.
                          </p>

                          <p>
                            <strong>Total: </strong
                            >{{ stats.evaluation_docs_count }} tareas enviadas.
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
                  
                </b-col>
                <b-col>
                  
                    <b-card title="Mensajes enviados">
                      <b-row>
                        <b-col md="12">
                          <p><strong>Cantidad de mensajes:</strong></p>
                          <p>
                            <strong>En clases: </strong
                            >{{ stats.count_room_message }} enviados.
                          </p>

                          <p>
                            <strong>En grupos: </strong
                            >{{ stats.count_room_group_message }} enviados.
                          </p>

                          <p>
                            <strong>Chat privados: </strong
                            >{{ stats.count_private_message }} enviados.
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
              
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Moment from "moment";
import Connection from "./connection";
export default {
  name: "DetailStudent",
  components: {
    Connection,
  },
  data() {
    return {
      progress: false,
      moment: Moment,
      fileToUpload: "",
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      field: [
        {
          key: "evaluation.teacher.user.full_name",
          label: "Profesor",
          sortable: true,
        },
        {
          key: "evaluation.subject.name",
          label: "Materia",
          sortable: true,
        },
        {
          key: "evaluation.title",
          label: "Evaluación",
          sortable: true,
        },

        {
          key: "feedback",
          label: "Feedback",
          sortable: true,
        },
        {
          key: "result",
          label: "Resultado",
          sortable: true,
        },
      ],
      field_avg: [
        {
          key: "teacher.user.full_name",
          label: "Profesor",
          sortable: true,
        },
        {
          key: "subject.name",
          label: "Materia",
          sortable: true,
        },

        {
          key: "avg",
          label: "Promedio",
          sortable: true,
        },
      ],
      files: [],
      //USER DATA
      first_name: "",
      last_name: "",
      dni: "",
      birthday: new Date(),
      username: "",
      email: "",
      image: "",
      password: "",
      password_confirm: "",
      phone: "",
      blood_type: 1,
      bloodTypeArray: [],
      condition: 1,
      conditionList: [],
      typeStudent: 1,
      typeStudentList: [],
      section: 1,
      old_section: 1,
      sectionList: [],
      security_health: "",
      //REPRESENTATIVE DATA
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      id_user: "",
      id_student: "",
      //PASSWORD
      dni_selected: "V",
      dnis: [
        {
          id: "V",
          name: "V",
        },
        {
          id: "E",
          name: "E",
        },
         {
          id: "R",
          name: "R",
        },
      ],
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: new Date(),
      isM: "",
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true,
        },
      },
      updated_at: "",
      created_at: "",
      evaluations_results: [],
      evaluations_avg_results: [],
      periods: [],
      period: "",
      lapse: "",
      lapses: [],
      stats: [],
    };
  },
  computed: {
    ...mapState({
      student: (state) => state.student.student,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.allStats();
    this.allSelects();
    this.studentLoad();
    this.allFiles();
    this.allPeriods();
    this.allLapses();
  },
  methods: {
    ...mapActions([
      "updateStudent",
      "updateUser",
      "loadBlood_Active",
      "loadCondition",
      "addToastMessage",
      "loadTypeStudent",
      "loadSection_school",
      "loadUserFiles",
      "uploadArchiveUser",
      "deleteUserFile",
      "loadEvaluation_Results_by_Student",
      "loadEvaluation_Results_avg_by_Student",
      "loadPeriod_school",
      "loadLapse_school",
      "loadStudent_Stats",
    ]),
    formatNumber(e) {
      return String(e).substring(0, 8);
    },
    /* goEnvironment() {
     
   
      Cookies.set("id_rol", 1);
     Cookies.set("from_admin", 1);

      this.$store.commit("LOGIN_OK", { data: this.authe, item: data });
   
        this.$router.push("/estudiante");

      this.addToastMessage({
        text: "Bienvenido",
        icon: "success",
      });
    },*/
    deleteFile(data) {
      Swal.fire({
        title: "Estas seguro de eliminar el archivo",
        text: "Se procede a eliminar el archivo del estudiante",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: 2,
          };
          this.deleteUserFile(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "El archivo fue eliminado",
                  icon: "success",
                });
                this.allFiles();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    uploadArchive() {
      let data = new FormData();
      //let formData = new FormData(document.getElementById("uploadForm"));

      data.append("archive", this.fileToUpload);
      let name = this.file_name;
      let description = this.file_description;
      let type = 1;
      let id_user = this.id_user;
      let id_school = this.me_rol.id_school;
      let id_user_uploaded = this.me_rol.id;

      data.append("name", name);
      data.append("description", description);
      data.append("type", type);
      data.append("id_user", id_user);
      data.append("id_school", id_school);
      data.append("id_user_uploaded", id_user_uploaded);

      console.log(data);
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de subir este Archivo para el estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Subir",
      }).then((result) => {
        if (result.value) {
          this.progress = true;
          this.uploadArchiveUser(data)
            .then((response) => {
              if (response.code === 200) {
                (this.progress = false),
                  Swal.fire({
                    title: "Subida de Archivo Completada",
                    text:
                      "Su archivo de estudiante ha sido almacenado correctamente",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.allFiles();
                    this.$bvModal.hide("modal-upload");
                    // this.$router.push({ name: "representative_childrens" });
                  });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Ocurrio un error",
                text:
                  "Comprueba tu conexion con internet, e intentalo mas tarde",
                icon: "warning",
              });
              this.progress = false;
              console.log(error);
            });
        }
      });
    },
    allFiles() {
      let params = {
        id_user: this.id_user,
        id_school: this.me_rol.id_school,
        type: 1,
      };
      this.loadUserFiles(params)
        .then((response) => {
          if (response.code === 200) {
            this.files = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allSelects() {
      let form = {
        active: 1,
      };

      this.loadBlood_Active(form)
        .then((response) => {
          if (response.code === 200) {
            this.bloodTypeArray = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loadCondition(form)
        .then((response) => {
          if (response.code === 200) {
            this.conditionList = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loadTypeStudent(form)
        .then((response) => {
          if (response.code === 200) {
            this.typeStudentList = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadSection_school(form2)
        .then((response) => {
          if (response.code === 200) {
            this.sectionList = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    studentLoad() {
      if (!this.student.id) {
        this.$router.push("/director/estudiantes");
      }
      console.log(this.student);
      this.id = this.student.id;
      this.id_student = this.student.id;
      this.id_user = this.student.user.id;

      this.first_name = this.student.user.first_name;
      this.username = this.student.user.username;
      this.last_name = this.student.user.last_name;
      if(this.student.dni){
        this.dni = this.student.user.dni.substr(2);
      }
      
      if (this.student.user.birthday) {
        this.birthday = new Date(this.student.user.birthday);
      } else {
        this.birthday = new Date();
      }

      this.email = this.student.user.email;
      this.image = this.student.user.image;
      console.log(this.student.user.image);
      this.phone = this.student.user.phone;
      this.blood_type = this.student.user.id_blood_type;
      //STUDENT DATA
      this.section = this.student.id_section;
      this.old_section = this.student.id_old_section;
      this.condition = this.student.id_condition;
      this.security_health = this.student.security_health;
      this.typeStudent = this.student.id_student_type;
      this.created_at = this.student.user.created_at;
      this.updated_at = this.student.user.updated_at;

      this.loadEvaluationResults();
      //
    },
    allStats() {
      console.log(this.student.id);
      this.loadStudent_Stats(this.student.id)
        .then((response) => {
          console.log(response);
          if (response.code == 200) {
            this.stats = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allLapses() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.lapses = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allPeriods() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        active: "",
      };
      this.loadPeriod_school(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.periods = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadEvaluationResults() {
      let form = {
        id_student: this.id_student,
      };
      this.loadEvaluation_Results_by_Student(form)
        .then((response) => {
          this.isBusy = false;
          if (response.code === 200) {
            this.evaluations_results = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadEvaluationsResultsAVG() {
      let form = {
        id_student: this.id_student,
        id_period: this.period,
        id_lapse: this.lapse,
      };
      this.loadEvaluation_Results_avg_by_Student(form)
        .then((response) => {
          this.isBusy = false;
          if (response.code === 200) {
            this.evaluations_avg_results = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmitPassword() {
      console.log("Updating Password");
      if (this.password_confirm != this.password) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning",
        });
      } else if (this.password == "") {
        Swal.fire({
          title: "Escribe una contraseña",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning",
        });
      } else {
        this.loading = true;

        let form = {
          password: this.password,
          id_user: this.id_user,
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de cambiar la contraseña de este estudiante?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar",
        }).then((result) => {
          if (result.value) {
            this.updateUser(form)
              .then((response) => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La contraseña del estudiante ha sido actualizada",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.push({
                      name: "director-students-all",
                    });
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      }
    },
    onSubmitPersonal() {
      let form = {
        first_name: this.first_name,
        last_name: this.last_name,
        // dni: this.dni_selected + "-" + this.dni,
        // dni: this.dni,
        birthday: Moment(this.birthday).format("YYYY-MM-DD"),
        email: this.email,
        password: this.password,
        phone: this.phone,
        id_blood_type: this.blood_type,
        id_user: this.id_user,
      };
      if (this.dni != "") {
        form.dni = this.dni_selected + "-" + this.dni;
      }

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar",
      }).then((result) => {
        if (result.value) {
          this.updateUser(form)
            .then((response) => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "El estudiante ha sido actualizado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                  this.$router.push({
                    name: "director-students-all",
                  });
                });
              }
              if (response.code == 409) {
                Swal.fire({
                  title: "Cédula existente",
                  text:
                    "Un usuario con esta cédula ya se encuentra registrado, el usuario es: " +
                    response.data.full_name,
                  icon: "warning",
                });
              }
              if (response.code == 410) {
                Swal.fire({
                  title: "Correo existente",
                  text:
                    "Un estudiante con esta correo ya se encuentra registrado, el usuario es: " +
                    response.data.full_name,
                  icon: "warning",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    onSubmitStudent() {
      this.loading = true;

      let form = {
        id_condition: this.condition,
        id_student_type: this.typeStudent,
        security_health: this.security_health,
        id_section: this.section,
        id_old_section: this.old_section,
        id_school: this.me_rol.id_school,
        id_user: this.id_user,
        id: this.id_student,
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar",
      }).then((result) => {
        if (result.value) {
          this.updateStudent(form)
            .then((response) => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "El estudiante ha sido actualizado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                  this.$router.push({
                    name: "director-students-all",
                  });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
