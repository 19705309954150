<template>
  <div id="NewStudent">
    <br />
    <div class="content">
      <b-card title="Registrar un nuevo profesor">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              Por favor completa los campos para registrar un nuevo profesor,
              que será tu representado
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{ name: 'director-evaluations-all' }"
            >
              <v-icon name="angle-left" /> Volver a Profesores</router-link
            >
          </b-col>
          <br />
          <hr align="center" noshade="noshade" size="2" width="100%" />
        </b-row>

        <form
          style="width: 100% !important"
          method="post"
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col md="12"> </b-col>
            <b-col md="4">
              <b-form-group label="Correo del profesor" label-for="first_name">
                <b-form-input
                  id="email"
                  v-model="email"
                  name="first_name"
                  placeholder="Nombre completo"
                />
                <b-button
                  type="button"
                  @click="find_UserByEmail"
                  variant="outline-success"
                  class="m-3"
                  >Buscar usuario <v-icon name="search" />
                </b-button>
              </b-form-group>
              <b-col md="12 text-right"> </b-col>
            </b-col>
            <b-col md="4">
              <b-form-group label="Nombre" label-for="first_name">
                <b-form-input
                  id="first_name"
                  v-model="user.full_name"
                  name="first_name"
                  disabled
                />
              </b-form-group>
              <b-form-group label="Identificación" label-for="first_name">
                <b-form-input
                  id="first_name"
                  v-model="user.dni"
                  name="first_name"
                  disabled
                />
              </b-form-group>
              <b-form-group label="Teléfono" label-for="first_name">
                <b-form-input
                  id="first_name"
                  v-model="user.phone"
                  disabled
                  name="first_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Estudios del profesor"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="studies"
                  name="first_name"
                />
              </b-form-group>
              <b-form-group
                label="Habilidades del profesor"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="skill1"
                  name="first_name"
                />
              </b-form-group>
              <b-form-group
                label="Otras habilidades del profesor"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="skill2"
                  name="first_name"
                />
              </b-form-group>
            </b-col>

            <b-col md="6"></b-col>
            <b-col md="6"></b-col>
            <b-col md="6"></b-col>
          </b-row>

          <hr align="center" noshade="noshade" size="2" width="100%" />

          <b-col md="12 text-right">
            <b-button type="submit" variant="outline-success" class="m-3"
              >Registrar nuevo profesor <v-icon name="save" />
            </b-button>
          </b-col>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "NewStudent",
  data() {
    return {
      //TODO LIST OF FATHER
      fathers: [],
      //USER DATA
      user: {
        full_name: "",
        dni: "",
        phone: "",
      },
      studies: "",
      skill1: "",
      skill2: "",

      email: "",
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathers.length;
    },
  },
  mounted() {
   
  },
  methods: {
    ...mapActions([
      "newStudentFromRepresentative",
      "findUserByEmail",
      "addToastMessage",
      "newTeacher"
    ]),
    find_UserByEmail() {
      this.isBusy = true;
      let form = {
        email: this.email,
      };
      this.findUserByEmail(form)
        .then((response) => {
          if (response.code === 200) {
            this.user = response.data;
          }
           if (response.code === 404) {
            Swal.fire({
          title: "Usuario no encontrado",
          text: "No hemos encontrado un usuario con este correo",
          icon: "warning",
        });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if (this.studies == "" || this.skill1 == "" || this.skill2 == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar todos los datos del profesor",
          icon: "warning",
        });
      } else {
        this.loading = true;
        console.log(this.me);
        let form = {
          id_user: this.user.id,
          studies: this.studies,
          skill1: this.skill1,
          skill2: this.skill2,
          id_school: this.me_rol.id_school, // datos de la escuela
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de registrar este profesor?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.newTeacher(form)
              .then((response) => {
                console.log("RESPONSE");
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "El profesor ha sido registrado",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.push("/director/evaluaciones");
                  });
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                if (response.code == 409) {
                  Swal.fire({
                    title: "Profesor previamente registrado",
                    text:
                      "Encontramos una solicitud con estos datos previamente registrados",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                    //  this.$router.push({ name: "uecamag" });
                  });
                }               
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
