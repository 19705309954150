import axios from "axios";
import * as Config from "../../../config";

const state = {
  communications: {
    current_page: 1,
    data: []
  },
  type: {}
};

const actions = {
  loadCommunications({ commit, dispatch }, params) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "SCHOOL/all", { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadDataCommunications({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_COMMUNICATIONS", data);
  },
  newCommunication({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "SCHOOL", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateCommunication({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + "SCHOOL/" + form.id, form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteCommunication({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + "SCHOOL/change/" + form.id + "/" + form.active)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_COMMUNICATIONS(state, data) {
    state.communications = data;
  }
};

export default {
  state,
  actions,
  mutations
};
