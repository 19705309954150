import axios from "axios";
import * as Config from "../../../config";

const state = {
  personal: {}
};

const actions = {
  loadPersonal_school({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "personal/obrero/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  new_Personal({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "personal/obrero/save", form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActive_Teacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            "student/student/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  delete_Teacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            "student/student/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
};
