<template>
  <div id="detailUser">
    <br />
    <div class="content">
      <b-card :title="'Detalle del usuario:' + this.userEdit.full_name">
        <b-row>
          <b-col md="6" style="text-align: -webkit-left">
            <p>
              A continuación podrá ver todo el detalle del usuario, y modificar
              su información
            </p>
          </b-col>
          <b-col md="2" style="text-align: -webkit-right">
             <a v-if="image" :href="image" target="a_blank"><img class="img-fluid" style="max-width:100px" :src="image"></a>
             
               </b-col>
          <b-col md="2" style="text-align: -webkit-right">
            <b-button variant="outline-danger" size="sm" v-b-modal.modal-deleting
              >Eliminar Usuario</b-button
            >
          </b-col>
          <b-col md="2" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{ name: 'goadmin-user-all' }"
              >Listado de Usuarios
            </router-link>
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Datos Personales" active>
              <form
                v-if="!loading"
                style="width: 100% !important"
                method="post"
                @submit.prevent="onSubmitPersonal"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nombre" label-for="first_name">
                      <b-form-input
                        id="first_name"
                        v-model="first_name"
                        required
                        name="first_name"
                        placeholder="Ingrese Nombre"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Apellido" label-for="last_name">
                      <b-form-input
                        id="last_name"
                        v-model="last_name"
                        required
                        name="last_name"
                        placeholder="Ingrese Apellido"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <label for="dni">Cedula</label>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-select
                          v-model="prefix_dni"
                          :options="[
                            { id: 'R-', name: 'R' },
                            { id: 'V-', name: 'V' },
                            { id: 'J-', name: 'J' },
                          ]"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                        id="dni"
                        v-model="dni"
                        required
                        type="number"
                        name="dni"
                        placeholder="DNI"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Fecha de Nacimiento"
                      label-for="birthday"
                    >
                      <date-picker
                        id="birthday"
                        name="birthday"
                        mode="single"
                        :max-date="new Date()"
                        v-model="birthday"
                        :popover="{ placement: 'bottom', visibility: 'click' }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Teléfono" label-for="phone">
                      <b-form-input
                        id="phone"
                        v-model="phone"
                        type="number"
                        name="phone"
                        placeholder="Teléfono"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4">
                    <b-form-group label="Correo" label-for="email">
                      <b-form-input
                        id="email"
                        v-model="email"
                        required
                        type="email"
                        name="email"
                        placeholder="Correo"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Tipo de Sangre</div>
                        <b-form-select
                          v-model="blood_type"
                          :options="bloodTypeArray"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3"
                      >Modificar</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Credenciales">
              <form
                v-if="!loading"
                style="width: 100% !important"
                method="post"
                @submit.prevent="onSubmitPassword"
              >
                <b-row>
                  <b-col md="12">
                    <p>
                      Desde este formulario puede actualizar la clave de acceso
                      del usuario a la plataforma
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Contraseña" label-for="password">
                      <b-form-input
                        id="password"
                        v-model="password"
                        type="password"
                        name="password"
                        placeholder="Contraseña"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Repita la contraseña"
                      label-for="password_confirm"
                    >
                      <b-form-input
                        id="password_confirm"
                        v-model="password_confirm"
                        type="password"
                        name="password_confirm"
                        placeholder="Contraseña"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3"
                      >Modificar contraseñas
                    </b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Roles">
              <checkRol />
            </b-tab>
            <b-tab title="Conexión">
              <conection/>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
      <b-modal id="modal-deleting" title="Eliminacion de Usuario">
        <p class="my-4">
          Por favor indica un password, esta eliminacion no puede ser recuperada
        </p>
        <b-form-group label="Contrasena" label-for="first_name">
          <b-form-input
            id="password"
            v-model="password_admin"
            name="password"
            placeholder="Contrasena de Eliminacion"
            required
          ></b-form-input>
        </b-form-group>
        <b-button variant="outline-danger" @click="deleteUser" size="sm"
          >Eliminar Usuario</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Moment from "moment";
import checkRol from "./check_rol.vue";
import conection from './conection.vue';

export default {
  name: "detailUser",
  components: {
    checkRol,
    conection
  },
  data() {
    return {
      //USER DATA
      id_user: "",
      password_admin: "",
      first_name: "",
      last_name: "",
      dni: "",
      prefix_dni: "V-",
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      blood_type: 1,
      bloodTypeArray: [],
      condition: 1,
      conditionList: [],
      typeStudent: 1,
      typeStudentList: [],
      section: 1,
      sectionList: [],
      security_health: "",
      image:"",
      //REPRESENTATIVE DATA
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      id: "",
      id_student: "",
      //PASSWORD
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: new Date(),
      isM: "",
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true,
        },
      },
    };
  },
  computed: {
    ...mapState({
      userEdit: (state) => state.users.user,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.allSelects();
    this.userLoad();
  },
  methods: {
    ...mapActions([
      "updateStudent",
      "updateUser",
      "loadBlood_Active",
      "loadCondition",
      "addToastMessage",
      "loadTypeStudent",
      "loadSection_school",
      "deleteTotalUser",
    ]),
    deleteUser() {
      Swal.fire({
        title: "Eliminacion de Usuario",
        text: "Realice esta accion solo si esta totalmente seguro",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: this.userEdit.id,
            password: this.password_admin,
          };
          this.deleteTotalUser(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Eliminado correctamente",
                  icon: "success",
                });
                this.id_user = "";
                this.password = "";
                this.getRoles();
                this.$bvModal.hide("modal-deleting");
                this.$router.push("../goadmin/usuarios");
              }
              if (response.code === 403) {
                Swal.fire({
                  title: "Password incorrecto",
                  icon: "warning",
                });

                this.password = "";
              }
              if (response.code === 404) {
                Swal.fire({
                  title:
                    "Ocurrio un error, seleccione nuevamente al elemento a eliminar",
                  icon: "warning",
                });
                this.getRoles();
                this.$bvModal.hide("modal-1");

                this.password = "";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    allSelects() {
      let form = {
        active: 1,
      };

      this.loadBlood_Active(form)
        .then((response) => {
          if (response.code === 200) {
            this.bloodTypeArray = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    userLoad() {
      if (!this.userEdit.id) {
        this.$router.push("../../usuarios");
      }
      console.log(this.userEdit);
      this.id = this.userEdit.id;

      this.first_name = this.userEdit.first_name;
      this.username = this.userEdit.username;
      this.last_name = this.userEdit.last_name;
      let dniTmp = this.userEdit.dni.split("-");

      if (dniTmp.length > 1) {
        this.prefix_dni = dniTmp[0] + "-";
        this.dni = dniTmp[1];
      } else {
        this.dni = dniTmp[0];
      }

      if (this.userEdit.birthday) {
        this.birthday = new Date(this.userEdit.birthday);
      } else {
        this.birthday = new Date();
      }

      this.email = this.userEdit.email;
      this.image = this.userEdit.image;
      this.phone = this.userEdit.phone;
      this.blood_type = this.userEdit.id_blood_type;
    },
    onSubmitPassword() {
      if (this.password_confirm != this.password) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning",
        });
      } else if (this.password == "") {
        Swal.fire({
          title: "Escribe una contraseña",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning",
        });
      } else {
        this.loading = true;

        let form = {
          password: this.password,
          id_user: this.id,
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de cambiar la contraseña de este usuario?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar",
        }).then((result) => {
          if (result.value) {
            this.updateUser(form)
              .then((response) => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La contraseña del usuario ha sido actualizada",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.push({
                      name: "goadmin-user-all",
                    });
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      }
    },
    onSubmitPersonal() {
      let form = {
        first_name: this.first_name,
        last_name: this.last_name,
        dni: this.prefix_dni + "" + this.dni,
        birthday: Moment(this.birthday).format("YYYY-MM-DD"),
        email: this.email,
        phone: this.phone,
        id_blood_type: this.blood_type,
        id_user: this.id,
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este usuario?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar",
      }).then((result) => {
        if (result.value) {
          this.updateUser(form)
            .then((response) => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "El usuario ha sido actualizado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                  this.$router.push({ name: "goadmin-user-all" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    onSubmitStudent() {
      this.loading = true;

      let form = {
        id_condition: this.condition,
        id_student_type: this.typeStudent,
        security_health: this.security_health,
        id_section: this.section,
        id_school: this.me_rol.id_school,
        id_user: this.id_user,
        id: this.id_student,
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar",
      }).then((result) => {
        if (result.value) {
          this.updateStudent(form)
            .then((response) => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "El estudiante ha sido actualizado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                  this.$router.push({
                    name: "director-students-all",
                  });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
