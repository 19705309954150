<template>
  <div id="StudentBalance">
    <br/>
    <div class="content">
      <b-card title="Balance de Pagos">
        <b-row>
          <b-col md="10" style="text-align: -webkit-left">
            <p>En esta ventana podrás visualizar todos los pagos que haz realizado, y saber tu saldo actualizado.
              Recuerda esperar que la administración verifique tus pagos reportados, para visualizar el saldo
              actualizado</p>
          </b-col>
          <b-col md="2" style="text-align: -webkit-right">
            <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar representante</router-link>-->
          </b-col>
        </b-row>
        <div class="mb-2">Seleccione a su representado</div>
        <b-row>
          <b-col md="4">
            <b-form-select
                v-model="student"
                :options="students"
                value-field="id"
                text-field="user.full_name"
            ></b-form-select>

            <p
                style="font-size:10px"
            >La información reflejada va a depender del estudiante que seleccione</p>
            <b-spinner variant="outline-success" v-show="progress" label="Text Centered"></b-spinner>
          </b-col>
          <b-col md="4">
            <b-button size="sm" variant="outline-primary" v-on:click="findBalance">
              <i class="fa fa-search"/> Cargar Balance
            </b-button>
          </b-col>
          <b-col md="4">
            <h1 v-if="balance_number > 0" style="color:green">Bs. {{ balance_number }}</h1>
            <h1 v-if="balance_number < 0" style="color:red">Bs. {{ balance_number }}</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Pagos Reportados" active>
                  <b-col md="12">

                    <br/>
                    <p>Este listado indica los reportes de pago que usted ha registrado</p>
                   <!-- <b-list-group>
                      <b-list-group-item>
                        <b-row>
                          <b-col md="2"># Referencia</b-col>
                          <b-col md="2">Concepto</b-col>
                          <b-col md="2">Monto</b-col>
                          <b-col md="2">Estado</b-col>
                          <b-col md="2">Fecha de Registro</b-col>
                          
                        </b-row>
                      </b-list-group-item>
                      <b-list-group-item v-for="pay in payments" v-bind:key="pay.id">
                        <b-row>
                          <b-col md="2">
                            <strong>#{{ pay.reference }}</strong>
                          </b-col>
                          <b-col md="2">{{ pay.concept }}</b-col>

                          <b-col md="2">
                            <strong>Bs. {{ pay.amount }}</strong>
                          </b-col>
                          <b-col md="2">{{ pay.status_label }}</b-col>
                          <b-col md="2">{{ pay.created_at }}</b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>-->

                     <table class="table">
                          <thead>
                            <tr>
                              <th scope="col"># Referencia</th>
                              <th scope="col">Concepto</th>
                              <th scope="col">Monto</th>
                              <th scope="col">Estado</th>
                               <th scope="col">Fecha</th>
                                 <th scope="col">Imagen</th>
                                 <th scope="col">Acciones</th>
                               
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="pay in payments" v-bind:key="pay.id">
                           
                              <td>{{ pay.reference }}</td>
                              <td>{{ pay.concept }}</td>
                              <td>{{ pay.amount }}</td>
                               <td>{{ pay.status_label }}</td>
                                <td>{{ pay.created_at }}</td>
                                 <td><a :href="pay.image" target="a_blank"><img width="100" :src="pay.image"></a></td>
                              <td><b-button variant="white" @click="checkShow(pay)">
                                <v-icon name="eye" />
                                </b-button></td>
                            </tr>
                          
                          </tbody>
                        </table>


                  </b-col>
                </b-tab>
                <b-tab title="Balance de Pagos">
                  <b-col md="12">

                    <br/>
                    <p>Este listado indica los montos abonados a su cuenta, y debitados por concepto de mensualidad</p>
                   <!-- <b-list-group>
                      <b-list-group-item>
                        <b-row>
                          <b-col md="2">Mes</b-col>
                          <b-col md="2">Periodo</b-col>
                          <b-col md="2">Monto</b-col>
                          <b-col md="3">Tipo</b-col>

                          <b-col md="3">Fecha de Registro</b-col>
                        </b-row>
                      </b-list-group-item>
                      <b-list-group-item v-for="bal in balances" v-bind:key="bal.id">
                        <b-row>
                          <b-col md="2">
                            <strong>{{ bal.month_label }}</strong>
                          </b-col>
                          <b-col md="2">{{ bal.period.name }}</b-col>
                          <b-col md="2">{{ bal.amount }}</b-col>
                          <b-col md="3">{{ bal.type_label }}

                          </b-col>

                          <b-col md="3">{{ bal.created_at }}</b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>-->

                    <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Mes</th>
                              <th scope="col">Periodo</th>
                              <th scope="col">Monto</th>
                              <th scope="col">Tipo</th>
                               <th scope="col">Fecha</th>
                               
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="bal in balances" v-bind:key="bal.id">
                              <th scope="row"> <strong>{{ bal.month_label }}</strong></th>
                              <td >{{ bal.period ? bal.period.name : "No determinado"}}</td>
                              <td>{{ bal.amount }}</td>
                              <td>{{ bal.type_label }}</td>
                               <td>{{ bal.created_at }}</td>
                              
                            </tr>
                          
                          </tbody>
                        </table>
                  </b-col>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
        <br/>
      </b-card>
      <b-modal hide-footer id="modal-pay2" title="Estado de Pago">
          <b-list-group>
            <b-list-group-item v-if="payment_selected.bank_school">
              Banco de la institución: <strong>{{ payment_selected.bank_school.name }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Banco de Origen: <strong>{{ payment_selected.bank.name }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Referencia: <strong>{{ payment_selected.reference }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Nombre del titular: <strong>{{ payment_selected.detail_user }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Cédula del titular: <strong>{{ payment_selected.dni_user }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Monto: <strong>{{ payment_selected.amount }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Tasa de Cambio usada:
              <strong>{{ payment_selected.quote }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Monto en USD: <strong>{{ payment_selected.amount_usd }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Fecha de pago: <strong>{{ payment_selected.date.substr(0, 10) }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Registrado: <strong>{{ payment_selected.created_at }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              Concepto: <strong>{{ payment_selected.concept }}</strong>
            </b-list-group-item>
            
          </b-list-group>

          
        </b-modal>
    </div>
    
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "StudentBalance",
  data() {
    return {
      id: "",
      students: [],
      payments: [{
        bank: {
          name: ""
        }
      }],
      payment_selected: {
        
        bank: {
          name: "",
        },
        bank_school: {
          name: ""
        },
        reference: "",
        concept: "",
        created_at:"",
        amount_usd: 0,
        quote: 0,
        amount: 0
      },
      balances: [],
      progress: true,
      student: "",
      loading: false,
      error: "",
      balance_number: 0
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    },
    rows() {
      return this.fathers.length;
    }
  },
  mounted() {
    this.allStudents();
  },
  methods: {
    ...mapActions([
      "loadStudent_by_representative",
      "loadStudentPayments",
      "loadStudentBalances",
      "loadData_Representative",
      "deleteRepresentative"
    ]),
    alert() {
      alert("Próximamente");
    },
    checkShow(data) {
      console.log(data);

      this.$bvModal.show("modal-pay2");
      this.payment_selected = data;
    },
    findBalance() {
      let form = {
        id_student: this.student
      };
      this.progress = true;
      this.loadStudentPayments(form)
          .then(response => {
            this.progress = false;
            if (response.code === 200) {
              this.payments = response.data;
            }
          })
          .catch(error => {
            console.log(error)
          });

      this.loadStudentBalances(form)
          .then(response => {
            this.progress = false;
            if (response.code === 200) {
              this.balances = response.data.transactions;
              this.balance_number = response.data.balance;
              console.log(this.balances)
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    allStudents() {
      let form = {
        id_father: this.me_rol.id
      };
      this.progress = true;
      this.loadStudent_by_representative(form)
          .then(response => {
            this.progress = false;
            if (response.code === 200) {
              this.students = response.data;
              this.student = this.students[0].id;
              this.findBalance()
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    edit_Representative(data) {
      this.loadData_Representative(data).then(response => {
        console.log(response)
        this.$router.push("/director/representante/editar/" + data.id);
        //  this.$router.push({ name: "uecamag" });
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "¿Estás seguro de Desactivarlo Ahora?"
              : "¿Estás seguro de Activarlo Ahora?";
      Swal.fire({
        title: title,
        text: text,
        type: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeActiveRepresentative(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    title: title2,
                    type: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    delete_Representative(row) {
      Swal.fire({
        title: "Eliminar Representante",
        text: "¿Estás seguro de eliminar este Representante?",
        type: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.deleteRepresentative(form)
              .then(response => {
                if (response.code === 200) {
                  this.fathers.splice(row.index, 1);
                  Swal.fire({
                    title: "Representante Eliminado",
                    type: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
