<template>
  <div>
    <br />
    <div class="content">
      <h2 class="text-center">¡Bienvenido a Clasy Estudiante!</h2>
      <!-- <p>Este el panel de control que permite administrar los datos de tus materias y estudiantes</p>
      <p style="color:orange">Este panel aún se encuentra en desarrollo, solo puedes visualizar tus clases, tareas y evalaciones, pero aún no puedes adjuntar tus tareas ni ver tus notas, para hacerlo debes usar la aplicación móvil.</p> -->

      <!--<br />-->

      <b-row>
        <b-col md="6">
          <p v-if="resp.user" class="my-0 py-0" style="font-size: 24px"><strong>Estudiante:</strong> {{ resp.user.full_name }}</p>
          <!--<h4 >Estudiante: <span> {{ resp.user.full_name }}</span></h4>-->
        </b-col>
        <b-col md="6">
          <p v-if="resp.parent1" class="my-0 py-0" style="font-size: 24px"><strong>Representante:</strong> {{ resp.parent1.user.first_name + ' ' + resp.parent1.user.last_name }}</p>
          <!--<h5>
            Representante: <span v-if="resp.parent1">{{ resp.parent1.user.first_name }}
            {{ resp.parent1.user.last_name }}</span>
          </h5>-->
        </b-col>
        <!--<b-col md="4">
          <b-button
            variant="outline-primary"
            size="sm"
            pill
            block
            @click="goToSubject()"
            ><v-icon name="angle-right"></v-icon> Acceder a materias</b-button
          >
        </b-col>-->
      </b-row>
       
     
     
      <b-row align-v="center">
        <b-col md="12">
          <hr />
        </b-col>

        <b-col md="9">
          <h2 b-col md="12">Cartelera</h2>
        </b-col>

        <b-col md="3">
          <b-button
            variant="primary"
            size="sm"
            pill
            block
            class="py-2"
            style="font-size: 18px"
            @click="goToSubject()">
            Acceder a materias
            <font-awesome-icon class="ml-1" icon="angle-right" />
          </b-button>
        </b-col>

        <b-col md="12" class="text-center" align-self="center">
          <br>
          <b-spinner v-if="loading" variant="outline-primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>

      <!--<b-row>
        <div v-for="notice in notices" :key="notice.id" class="col-md-6">
          <hr />
          <b-list-group>
            <b-list-group-item>
              <b-row>
                <b-col md="10">
                  <h4>{{ notice.title }}</h4>
                </b-col>
                <b-col md="2">
                  <b-avatar
                    variant="outline-primary"
                    :src="notice.user.image"
                  ></b-avatar>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <p>
                {{ notice.description }}
              </p></b-list-group-item
            >

            <b-list-group-item>
              <b-row>
                <b-col md="6">
                  {{ notice.created_at | from_now }}
                </b-col>
                <b-col md="6">
                  <span
                    ><strong
                      >{{ notice.subject.name }} -
                      {{ notice.section.name }}</strong
                    ></span
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-row>-->

      <b-row align-h="center" v-for="notice in notices" :key="notice.id">
        <b-col md="2"></b-col>
        <b-col
          md="8"
          class="mb-3 px-2"
        >
          <b-list-group>
            <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
              <b-col md="12" class="m-0 p-0 py-2 text-center" style="background-color: #002446; color: #FFFFFF; cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;">
                {{ notice.subject.name + ' - ' + notice.section.name }}
              </b-col>
            </b-row>

            <b-list-group-item>
              <b-row align-v="center">
                <b-col md="12" align-self="center">
                  <div class="py-0 text-left" style="display: inline-block; width: 10%">
                    <img :src="notice.user.image" style="border-radius: 50%; height: 45px; aspect-ratio: 1; object-fit: cover" />
                  </div>
                  <div class="my-0 py-0 text-left" style="display: inline-block; width: 90%">
                    <p class="my-0 py-0">{{ notice.user.first_name + ' ' + notice.user.last_name }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <h4>{{ notice.title }}</h4>
              <p class="my-auto py-auto">{{ notice.description }}</p>           
              <p class="my-0 py-0 text-right" style="color: gray">{{ moment(notice.created_at).local().format('D MMM YYYY, h:mm a') }}</p>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="2"></b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "Welcome",

  data() {
    return {
      moment: moment,
      notices: [],
      feed: {
        title: "",
        description: "",
      },
      loading:false,
      resp: {
        parent1: {
          user: {
            first_name: "Cargando...",
            last_name: "",
          },
        },
      },
    };
  },
  filters: {
    from_now: function(created_at) {
      console.log("Filters");
      return moment(created_at).fromNow();
    },
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.loadState();
    this.loadFeed();
  },

  methods: {
    ...mapActions(["LoadDataStudent", "load_Feed", "loadData_SubjectSection"]),
    loadState() {
      this.LoadDataStudent(this.me_rol.id_student)
        .then((response) => {
          if (response.code === 200) {
            this.resp = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToSubject() {
      this.$router.push("/estudiante/materias");
    },
    loadFeed() {
      // let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.me_rol.id_section;
      const self = this;
      //LOAD FEEDS
      let form3 = {
        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        order_key: "id",
        order_value: "desc",
        active: 1,
      };
      this.loading = true;
      //LOAD EVALUATIONS
      this.load_Feed(form3)
        .then((response) => { 
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            this.notices = resp;
            // this.classes = resp.classes;
            // this.tasks = resp.tasks;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
