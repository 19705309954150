/* eslint-disable */
import axios from "axios"
import * as Config from "../../config"
import Cookies from "js-cookie"

const state = {
  coaches: [],
  coach_index: 0,
  solutions: [],
  solution_index: 0
}

let access_token = Cookies.get("access_token")

axios.defaults.headers.common = { Authorization: "Bearer " + access_token }
axios.defaults.headers["tokentap"] = "8knd6qN2Zfw&6DDdYuv"

const actions = {
  Post({ commit }, form) {
    console.log(commit)

    let query_params = ''
    let params = ['active', 'query', 'page', 'limit', 'order_key', 'order_value']

    if (form.query) {
      for (let param of params) {
        if (form.query[param]) query_params += param + '=' + form.query[param] + '&'
      }
    }

    if (form.columns) {
      for (let col of form.columns) {
        if (form.params[col]) query_params += col + '=' + form.params[col] + '&'
      }
    }

    let endpoint = `${form.element}?${query_params}`

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPathx[form.api] + endpoint, form.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Get({ commit }, form) {
    console.log(commit)

    let query_params = ''
    let params = ['active', 'query', 'page', 'limit', 'order_key', 'order_value']

    if (form.query) {
      for (let param of params) {
        if (form.query[param]) query_params += param + '=' + form.query[param] + '&'
      }
    }

    if (form.columns) {
      for (let col of form.columns) {
        if (form.params[col]) query_params += col + '=' + form.params[col] + '&'
      }
    }

    let endpoint = `${form.element}?${query_params}`

    console.log(endpoint)

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPathx[form.api] + endpoint)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  GetAll({ commit }, form) {
    console.log(commit)

    let query_params = ''
    let params = ['active', 'query', 'page', 'limit', 'order_key', 'order_value']

    if (form.query) {
      for (let param of params) {
        if (form.query[param]) query_params += param + '=' + form.query[param] + '&'
      }
    }

    if (form.columns) {
      for (let col of form.columns) {
        if (form.params[col]) query_params += col + '=' + form.params[col] + '&'
      }
    }

    let endpoint = `${form.element}/all?${query_params}`

    console.log(endpoint)

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPathx[form.api] + endpoint)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  GetByID({ commit }, form) {
    console.log(commit)

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPathx[form.api] + `${form.element}/${form.id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Save({ commit }, form) {
    console.log(commit)

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPathx[form.api] + `${form.element}/save`, form.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  SaveAdmin({ commit }, form) {
    console.log(commit)

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPathx[form.api] + `${form.element}/save_admin`, form.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Update({ commit }, form) {
    console.log(commit)

    /*
    form = {
      element: 'product',
      update?: 'update',
      id?: 1,
      status?: 1,
      is_master?: false,
      query: {
        active?: 1,
        query?: 'hola',
        page?: 1,
        limit?: 10,
        order_key?: 'id',
        order_value?: 'desc'
      },
      columns: ['id_category','id_product']
      params: {
        id_category: 1,
        id_product: 1
      },
      data: {
        (Datos a la hora de un POST o un PUT)
      }
    }
    */

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPathx[form.api] + `${form.element}/${form.update}/${form.id}`, form.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  UpdateFormData({ commit }, form) {
    console.log(commit)

    /*
    form = {
      element: 'product',
      update?: 'update',
      id?: 1,
      status?: 1,
      is_master?: false,
      query: {
        active?: 1,
        query?: 'hola',
        page?: 1,
        limit?: 10,
        order_key?: 'id',
        order_value?: 'desc'
      },
      columns: ['id_category','id_product']
      params: {
        id_category: 1,
        id_product: 1
      },
      data: {
        (Datos a la hora de un POST o un PUT)
      }
    }
    */

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPathx[form.api] + `${form.element}/${form.update}/${form.id}`, form.data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  ChangeStatus({ commit }, form) {
    console.log(commit)

    /*
    form = {
      element: 'product',
      id?: 1,
      status?: 1,
      is_master?: false,
      query: {
        active?: 1,
        query?: 'hola',
        page?: 1,
        limit?: 10,
        order_key?: 'id',
        order_value?: 'desc'
      },
      columns: ['id_category','id_product']
      params: {
        id_category: 1,
        id_product: 1
      },
      data: {
        (Datos a la hora de un POST o un PUT)
      }
    }
    */

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPathx[form.api] + `${form.element}/change_status/${form.id}/${form.status}`, form.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

const mutations = {
  save_coaches(state, data) {
    state.coaches = data
  },
  save_coach_index(state, data) {
    state.coach_index = data
  },
  change_coach(state, data) {
    state.coaches[state.coach_index].id_definitive_license = data.id_definitive_license
    state.coaches[state.coach_index].definitive_license = data.definitive_license
  },
  save_solutions(state, data) {
    state.solutions = data
  },
  save_solution_index(state, data) {
    state.solution_index = data
  },
  change_solution(state, data) {
    state.solutions[state.solution_index].result = data.result
    state.solutions[state.solution_index].checked = data.checked
  },
}

export default {
  state,
  actions,
  mutations,
}
