import Vue from "vue";
//import Vue from 'vue/dist/vue.min.js'
import axios from "axios";
import store from "./vuex/store"; // vuex store instance
import App from "./App.vue";
import router from "./router/routes";
import Cookies from "js-cookie";

const isOnline = require('is-online');

(async () => {
	console.log('ONLINE:'+ await isOnline());
	//=> true
})();
//BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Fontawesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
require('./fontawesome');

//import UUID from "vue-uuid";

window.axios = require("axios");


import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
//import "./global-components";
//import AudioVisual from 'vue-audio-visual'
 
//Vue.use(AudioVisual)
//Sidebar Menu
//import VueSidebarMenu from "vue-sidebar-menu";
//import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
//import "./assets/css/custom.css";
import Datepicker from 'v-calendar/lib/components/date-picker.umd'

Vue.component('date-picker', Datepicker)

import "sweetalert2/src/sweetalert2.scss";

import swal from 'sweetalert2';
window.Swal = swal;
//window.Swal = require("sweetalert2");

window.Moment = require("moment");
window.Moment.locale("es");


//import VueRecord from '@codekraft-studio/vue-record'

//Vue.use(VueRecord)

//import AudioRecorder from 'vue-audio-recorder'

//Vue.use(AudioRecorder)
//FONT AWESOME
// or import all icons if you don't care about bundle size
//import 'vue-awesome/icons'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/users'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/user-graduate'
import 'vue-awesome/icons/user-tie'
import 'vue-awesome/icons/credit-card'
import 'vue-awesome/icons/file-alt'
import 'vue-awesome/icons/bell'
import 'vue-awesome/icons/search'
import 'vue-awesome/icons/money-bill'
import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/minus-square'
import 'vue-awesome/icons/check-square'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/question'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/hand-pointer'
import 'vue-awesome/icons/thumbs-down'
import 'vue-awesome/icons/thumbs-up'
import 'vue-awesome/icons/clipboard-list'
import 'vue-awesome/icons/cog'
import 'vue-awesome/icons/save'
import 'vue-awesome/icons/angle-left'
import 'vue-awesome/icons/angle-right'
import 'vue-awesome/icons/school'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/download'
import 'vue-awesome/icons/paper-plane'
import 'vue-awesome/icons/sync'
import 'vue-awesome/icons/key'
import 'vue-awesome/icons/chevron-down'
import 'vue-awesome/icons/play-circle'
import 'vue-awesome/icons/paperclip'
import 'vue-awesome/icons/photo-video'
import 'vue-awesome/icons/newspaper'
import 'vue-awesome/icons/calendar'
import 'vue-awesome/icons/sign-in-alt'
import 'vue-awesome/icons/clock'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/comments'
import 'vue-awesome/icons/file-pdf'
import 'vue-awesome/icons/microphone'
/* Register component with one of 2 methods */

import Icon from 'vue-awesome/components/Icon'


// globally (in your main .js file)
Vue.component('v-icon', Icon)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';

//Socket.io
import VueSocketIO from 'vue-socket.io'
import * as Config from "./config"

let opts = { transports: ['websocket'] }
let end = Config.apiPath2.indexOf('/api/1.0')
let url = Config.apiPath2.substring(0, end)

Vue.use(new VueSocketIO({
  debug: false,
  connection: url,
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  },
  options: opts
}))

axios.interceptors.request.use(
  function(config) {
    config["headers"] = {
      Authorization: "Bearer " + Cookies.get("access_token"),
      tokentap:'3MMuNJX3YWm9T9FbpSj4Z&',
      Accept: "application/json"
    };
    config["onUploadProgress"] = progressEvent => {
      store.dispatch(
        "update_upload_percentage",
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    };

    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (
      typeof error.response.data.data === "object" &&
      error.response.data.code === 400
    ) {
      for (var key in error.response.data.data) {
        store.dispatch("addToastMessage", {
          text:
            error.response.data.data[key][0] ||
            "Request error status: " + error.response.status,
          icon: "danger"
        });
      }
    } else {
      /* store.dispatch('addToastMessage', {
             text: error.response.data.message || 'Request error status: ' + error.response.status,
             type: 'danger'
         });*/
    }
    return Promise.reject(error);
  }
);

Vue.config.ignoredElements = [/^ion-/];
/* eslint-disable no-new */
/*new Vue({
  el: "#app",
  store: store,
  router,
  components: {
    App
  },
  template: "<App/>"
});*/

new Vue({
  render: h => h(App),
  store,
  router,
  components: { App }
}).$mount('#app')


