<template>
  <div id="SchoolData">
    <form
      style="width: 100% !important"
      method="post"
      @submit.prevent="onSubmitSchool"
    >
      <b-row align-v="center">
        <b-col md="8">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nombre" label-for="school_name">
                <b-form-input
                  id="school_name"
                  v-model="school_name"
                  name="school_name"
                  placeholder="Ingrese Nombre"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="RIF" label-for="rif">
                <b-form-input
                  id="rif"
                  v-model="rif"
                  name="rif"
                  placeholder="RIF"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Dirección" label-for="address">
                <b-form-input
                  id="address"
                  v-model="address"
                  name="address"
                  placeholder="Dirección"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Teléfono" label-for="phone">
                <b-form-input
                  id="phone"
                  v-model="phone"
                  
                  name="phone"
                  placeholder="Teléfono"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Capacidad" label-for="capacity">
                <b-form-input
                  id="capacity"
                  v-model="capacity"
                 
                  name="capacity"
                  placeholder="Capacidad"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Dirección web" label-for="website">
                <b-form-input
                  id="website"
                  v-model="website"
                  name="website"
                  placeholder="Dirección web"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4 text-center">
          <img :src="img" height="150px" width="auto" v-show="!progress2" alt />
          <b-spinner
            variant="outline-primary"
            v-show="progress2"
            label="Text Centered"
          ></b-spinner>

          <div></div>

          <b-button class="mt-3" @click="changePicture">
            <strong>Cambiar logo</strong>
          </b-button>
        </b-col>

        <b-col md="12 text-right">
          <b-button v-show="!loading" type="submit" variant="outline-success" class="m-3"
            >Modificar</b-button
          >
          <b-spinner
            variant="outline-primary"
            v-show="loading"
            label="Text Centered"
          ></b-spinner>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "SchoolData",
  data() {
    return {
      img: "https://escuela.ticktaps.com/img/logonew.0e8f8a5c.png",
      school_name: null,
      rif: null,
      capacity: null,
      phone: null,
      address:null,
      website: null,
      progress2: false,
      loading: false

    };
  },
  computed:{
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted(){
    this.getSchoolData();
  },
  methods: {
    ...mapActions(["loadSchool_byId", "updateSchool"]),
    onSubmitSchool(){
      this.loading = true;

          let form = {
            id: this.me_rol.id_school,
            address: this.address,
            phone: this.phone,
            capacity:this.capacity,
            name:this.name,
            dni: this.rif,
            website: this.website
          };

          Swal.fire({
            title: "Confirmación",
            text: "¿Estás seguro de actualizar a estos datos?",
            icon: "question",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Actualizar"
          }).then(result => {
            if (result.value) {
              this.updateSchool(form)
                .then(response => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "Los datos han sido actualizado",
                      icon: "success"
                    })
                    this.loading=false
                  }
                })
                .catch(error => {console.log(error); this.loading = false});
            } else {
              this.loading = false;
            }
          });
    },
    getSchoolData(){
      let form = {
        id: this.me_rol.id_school
      }
      this.loadSchool_byId(form)
      .then((response) => {
          this.progress = false;
          if (response.code === 200) {
            (this.school_name = response.data.name),
              (this.rif = response.data.dni),
              (this.address = response.data.address),
              this.phone = response.data.phone,
              this.capacity = response.data.capacity
              this.website = response.data.website
              //this.img = response.data.img_logo
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async changePicture() {
      const { value: file } = await Swal.fire({
        title: "Seleccionar logo",
        input: "file",
        inputAttributes: {
          accept: "image/*",
          "aria-label": "Upload your profile picture",
        },
      });

      if (file) {
        this.progress2 = true;
        let datos = new FormData();
        let logo = file;
        datos.append("logo", logo);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editLogo({
            logo: datos,
            id_school: this.this.me_rol.id_school,
          })
            .then((data) => {
              console.log(data);
              this.progress2 = false;
              if (data.code == 200) {
                this.img = data.data.logo;
                Swal.fire({
                  title: "Imagen subida",
                  imageUrl: e.target.result,
                  imageAlt: "The uploaded picture",
                });
              } else if (data.code == 400) {
                Swal.fire({
                  title: "Error al subir la imagen",
                  icon: "error",
                  imageAlt: "The uploaded picture",
                });
              }
            })
            .catch((data) => {
              this.progress2 = false;
              console.log("catch");
              console.log(data);
            });
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style>
</style>