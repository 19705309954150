<template>
  <div id="RegisterPayStudent">
    <br />
    <div class="content">
      <b-card :title="'Registrar Pago en el estudiante: ' + full_name">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              En el siguiente formulario podrás reportar un pago realizado a la
              institución, recuerda que los pagos realizados desde otras
              entidades bancarías toman 1 o 2 días hábiles para hacerse
              efectivos.
            </p>
            <p>
              <strong
                >Mientras detalles más tu reporte, será más fácil validar tu
                pago</strong
              >
            </p>
          </b-col>

          <b-col md="4" style="text-align: -webkit-right">
            <b-button
              pill
              variant="outline-primary"
              :to="{ name: 'representative_childrens' }"
              >Volver a Representados</b-button
            >
            <br />
            <b-form-group
              class="pt-3 px-2"
              label-for="phone"
              description="Este monto se actualiza automático, basado en el monto transferido entre la tasa de cambio"
            >
              <b-input-group prepend="USD " class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="amount"
                  v-model="amount_usd"
                  v-money="money"
                  disabled
                  name="amount"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  placeholder="Monto de su pago"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button pill size="sm" variant="outline-success" v-b-modal.modal-1
          >Necesito ayuda</b-button
        >

        <b-modal id="modal-1" title="Ayuda en Pagos">
          <p class="my-4">
            A continuación te explicamos algunas reglas para el registro de tu
            pago.
          </p>
          <p>
            1) Si te equivocaste al hacer la transferencia, no te preocupes,
            puedes realizar otro reporte del pago faltante.
          </p>
          <p>
            2) Si tienes 2 o más alumnos debes reportar el pago independiente
            por cada alumno. Esto facilitará comprobar tu pago.
          </p>
          <p>
            3) Si te equivocaste e hiciste un solo pago para los dos alumnos,
            reporta el pago para ambos alumnos. Esto facilitará comprobar tu
            pago.
          </p>
          <p>
            4) El monto en USD es un valor informativo, basado en el monto que
            transferiste y la tasa de cambio que usaste.
          </p>
          <p>
            5) Cualquier duda administrativa con respecto a los pagos, debes
            comunicarte con la institución.
          </p>
        </b-modal>
        <hr align="center" noshade="noshade" size="2" width="100%" />
        <b-form method="post" @submit.prevent="onSubmit" style="width:100%">
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Banco de la institución educativa"
                label-for="bank_school"
              >
                <b-form-select
                  value-field="id"
                  text-field="bank.name"
                  v-model="bank_school"
                  @change="loadBankData()"
                  :options="bank_school_options"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Datos para su pago:" label-for="bank_school">
                <p>{{ detail_bank.account }}</p>
                <p>{{ detail_bank.phone }}</p>
                <p>{{ detail_bank.dni }}</p>
                <hr />
                <div id="al"></div>
                <div id="texto"></div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Concepto de Pago" label-for="concept">
                <b-form-input
                  id="concept"
                  v-model="concept"
                  name="concept"
                  placeholder="Ej: Pago de Marzo"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Cédula de la persona que hizo el pago"
                label-for="dni"
              >
                <b-input-group>
                  <template v-slot:prepend>
                    <b-form-select
                      value-field="id"
                      text-field="name"
                      v-model="dni_selected"
                      :options="dnis"
                    ></b-form-select>
                  </template>
                  <b-form-input
                    id="dni"
                    v-model="dni_user"
                    name="dni"
                    placeholder="Solo Números"
                    v-mask="'########'"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Nombre de la persona que hizo el pago"
                label-for="detail_user"
              >
                <b-form-input
                  id="detail_user"
                  v-model="detail_user"
                  name="detail_user"
                  placeholder=""
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Banco de la persona que hizo el pago"
                label-for="birthday"
              >
                <b-form-select
                  value-field="id"
                  text-field="name"
                  v-model="bank_user"
                  :options="bank_user_options"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                label="Fecha en la que se hizo el pago"
                label-for="date"
              >
                <b-form-datepicker
                  id="date"
                  name="date"
                  mode="single"
                  :date-format-options="{
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    weekday: 'short',
                  }"
                  :max="new Date()"
                  v-model="date"
                  :popover="{ placement: 'bottom', visibility: 'click' }"
                />
              </b-form-group>
              <b-form-group
                label="Número de Referencia / Transferencia"
                label-for="reference"
              >
                <b-form-input
                  id="reference"
                  v-model="reference"
                  name="reference"
                  placeholder="Solo Números"
                  v-mask="'############'"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <div class="col-md-3">
              <b-form-group label="Monto Transferido" label-for="phone">
                <b-input-group prepend="Bs. " class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    @keydown="
                      $event.key === '-' ? $event.preventDefault() : null
                    "
                    id="amount"
                    v-model="amount"
                    v-money="money"
                    name="amount"
                    placeholder="Monto de su pago"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group label="Tasa de cambio que uso" label-for="phone">
                <b-input-group prepend="Bs. " class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-on:change="calcQuote"
                    id="amount"
                    @keydown="
                      $event.key === '-' ? $event.preventDefault() : null
                    "
                    v-model="quote"
                    v-money="money"
                    name="amount"
                    placeholder="Tasa en la que hizo el cambio"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group label="Captura de transferencia">
                <b-form-file
                  accept="image/*"
                  v-model="fileToUpload"
                  placeholder="Seleccione un archivo o arrastrelo hasta aquí..."
                  drop-placeholder="Arrastre el archivo hasta aquí..."
                ></b-form-file>
              </b-form-group>
            </div>
          </b-row>
          <div class="col-12 text-right">
           
            <b-button pill type="submit" variant="outline-success" class="m-3"
              >Registrar Pago</b-button
            >
             <b-spinner variant="outline-success" v-if="loading" label="Spinning"></b-spinner>
          </div>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar";
}
</style>

<script>
import { mapState, mapActions } from "vuex";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "UpdateStudent",
  data() {
    return {
      id: "",
      full_name: "",
      id_student: 1,
      concept: "",
      reference: "",
      dni_user: "",
      bank_user: "",
      bank_school: "",
      date: "",
      detail_user: "",
      bank_user_options: [],
      bank_school_options: [],
      fileToUpload: null,
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      amount: 0,
      amount_usd: 0,
      quote: 0,
      dni_selected: "V",
      dnis: [
        {
          id: "V",
          name: "V",
        },
        {
          id: "E",
          name: "E",
        },
        {
          id: "J",
          name: "J",
        },
        {
          id: "G",
          name: "G",
        },
      ],
      loading:false,
      money: {
        decimal: ",",
        thousands: ".",
        // prefix: 'Bs ',
        //suffix: ' #',
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      status: 1,
      detail_bank: {},
    };
  },
  computed: {
    ...mapState({
      student: (state) => state.student.student,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.allRoles();
    this.studentLoad();
  },
  methods: {
    ...mapActions([
      "updateStudent",
      "registerStudentPay",
      "loadBanks",
      "loadBankSchools",
      "addToastMessage",
      "loadRol_Active",
      "loadCondition_Active",
    ]),
    formatAmount(amount){
      let amount_formatted = amount.split(".").join("");
        amount_formatted = amount_formatted.split(",").join(".");
        amount_formatted = parseFloat(amount_formatted).toFixed(2)
        return amount_formatted;
    },
    calcQuote() {
     
          console.log(this.amount_usd);
        let amount_formatted = this.formatAmount(this.amount);
        console.log(amount_formatted);
       // let amount_usd_formatted = formatAmount(this.amount_usd);
        let quote_formatted = this.formatAmount(this.quote);
        console.log(quote_formatted);


      if (parseFloat(this.quote) != 0) {
        console.log('Calculating')
          this.amount_usd = parseFloat(
          amount_formatted / quote_formatted
        ).toFixed(2);
      } else {
        console.log('Not Calculating')
        this.amount_usd = 0;
      }

   
    },
    loadBankData() {
      console.log(this.bank_school);
      for (let i in this.bank_school_options) {
        var rest = this.bank_school_options[i];
        console.log(rest);
        if (rest.id == this.bank_school) {
          this.detail_bank = rest;
        }
      }
    },
    allRoles() {
      /*let form = {
        active: 1
      };*/
      let formSchool = {
        id_school: this.me_rol.id_school,
      };
      this.loadBanks()
        .then((response) => {
          if (response.code === 200) {
            this.bank_user_options = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loadBankSchools(formSchool)
        .then((response) => {
          if (response.code === 200) {
            this.bank_school_options = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    studentLoad() {
      if (this.student.id) {
        this.id = this.student.id;
        this.id_student = this.student.id;
        this.full_name = this.student.user.full_name;
      } else {
        this.$router.push({ name: "representative_childrens" });
      }
    },
    onSubmit() {
      console.log(parseFloat(this.amount));
      if (this.concept == "") {
        this.$bvToast.toast("Indica el concepto de pago", {
          title: "Completa los datos",
          variant: "warning",
          autoHideDelay: 4000,
          // appendToast: append
        });
      } else if (this.dni_user == "") {
        this.$bvToast.toast("Indica la cédula de la persona que transfirió", {
          title: "Completa los datos",
          variant: "warning",
          autoHideDelay: 4000,
          // appendToast: append
        });
      } else if (parseFloat(this.amount) <= 0) {
        this.$bvToast.toast("Indica el monto transferido", {
          title: "Completa los datos",
          variant: "warning",
          autoHideDelay: 4000,
          // appendToast: append
        });
      } else if (this.reference == '' || this.detail_user =='' || this.id_bank_user == '' || this.id_bank_school == '' || this.quote == '') {
        this.$bvToast.toast("Debes indicar todos los datos", {
          title: "Completa los datos",
          variant: "warning",
          autoHideDelay: 4000,
          // appendToast: append
        });
      } else {
        this.loading =true;
        let amount_formatted = this.formatAmount(this.amount);

        // let amount_usd_formatted = this.formatAmount(this.amount_usd);

         let quote_formatted = this.formatAmount(this.quote);
     

        let data = new FormData();
        //let formData = new FormData(document.getElementById("uploadForm"));

        data.append("archive", this.fileToUpload);

        data.append("id_student", this.id_student);
        data.append("concept", this.concept);
        data.append("date", this.date);
        data.append("reference", this.reference);
        data.append("detail_user", this.detail_user);
        data.append("dni_user", this.dni_selected + "-" + this.dni_user);
        data.append("id_bank_user", this.bank_user);
        data.append("id_bank_school", this.bank_school);
        data.append("amount", amount_formatted);
        data.append("amount_usd", this.amount_usd);
        data.append("quote", quote_formatted);
        data.append("id_school", this.me_rol.id_school);

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de guardar este Pago?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.registerStudentPay(data)
              .then((response) => {
                 this.loading = false;
                if (response.code === 200) {
                  Swal.fire({
                    title: "Reporte Registrado",
                    text:
                      "Su reporte de pago ha sido registrado, ahora debe esperar que la administración valide los datos enviados",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.push({ name: "representative_childrens" });
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                 this.loading = false;
                Swal.fire({
                  title: "Ocurrio un error al reportar su pago",
                  text:
                    "Intente de nuevo, y asegurate de escribir correctamente los datos del pago",
                  icon: "warning",
                });
              });
             
          }else{
            this.loading =false;
          }
        });
      }
    },
  },
};
</script>
