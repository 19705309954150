<template>
  <div>
    <br />
    <div class="content">
      <b-row>
        <b-col md="8">
          <h2 style="display: inline-block">Materias > {{ subject_detail.subject.name }}</h2>
          <p class="my-0 py-0" v-if="subject_detail.teacher">
            <strong>Periodo:</strong> {{subject_detail.period.name}} - 
            <span v-if="subject_detail.id_teacher2 == 0"><strong>Profesor:</strong> {{subject_detail.teacher.user.first_name + ' ' + subject_detail.teacher.user.last_name}}</span>
            <span v-else><strong>Profesores:</strong> {{subject_detail.teacher.user.first_name + ' ' + subject_detail.teacher.user.last_name}} y {{subject_detail.teacher2.user.first_name + ' ' + subject_detail.teacher2.user.last_name}}</span>
          </p>
        </b-col>
        <b-col md="4" class="text-right" align-self="center">
          <router-link
            style="display: inline-block"
            class="btn btn-info"
            :to="{ name: 'student_subjects' }">
            <v-icon name="angle-left" />
            Volver a materias
          </router-link>
        </b-col>
      </b-row>

      <hr />
      
      <!--<b-row>
        <b-col md="1">
          <b-avatar
          v-if="subject_detail.teacher"
            variant="outline-info"
            :src="subject_detail.teacher.user.image"
          ></b-avatar>
        </b-col>
        <b-col md="8">
          <h2>Materias > {{ subject_detail.subject.name }}</h2>
        </b-col>
       
        <b-col md="3">
          <router-link
            class="btn btn-outline-primary"
            :to="{ name: 'student_subjects' }"
            ><v-icon name="angle-left"></v-icon> Volver a materias</router-link
          >
        </b-col>

        <hr />
      </b-row>-->

      <b-row align-content="center">
        <b-col md="5">
          <p class="my-0 py-0">Visualiza tus clases, evaluaciones y cartelera</p>
        </b-col>
        
        <b-col md="7">
          <b-form-group>
            <p class="my-0 py-0 text-right" style="font-size: 14px; display: inline-block; width: 28%">Selecciona un momento:</p>
            <div style="display: inline-block; width: 2%;"></div>
            <b-form-select
              class="my-0 py-0"
              @change="loadState(lapse)"
              v-model="lapse"
              :options="lapseList"
              value-field="id"
              size="sm"
              text-field="name"
              style="display: inline-block; width: 40%"
            ></b-form-select>
            <b-button class="my-0 py-1" variant="outline-primary" size="sm" v-on:click="loadSelects()" style="display: inline-block; width: 30%">Volver a cargar clases</b-button>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <hr class="my-0 py-0">
        </b-col>
      </b-row>

      <!-- <p style="color: red">
        <strong
          >Esta sección se encuentra aún en proceso de desarrollo, y podría
          notar algunas fallas, le recomendamos realizar sus gestiones, y
          creación de evaluaciones desde la aplicación móvil</strong
        >
      </p>-->

      <!--<b-row v-if="loading">
        <b-col md="12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="outline-primary"
              style="width: 3rem; height: 3rem"
              label="Large Spinner"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>-->

      <b-row>
        <b-col md="12">
          <b-tabs card>
           
            <b-tab title="Clases" @click="$route.query.tab != 'clases' ? $router.replace({ query: { tab: 'clases', lapse: lapse } }) : ''" :active="$route.query.tab == 'clases'">
              <b-row v-if="loading" align-h="center">
                <b-col md="12" class="text-center">
                  <b-spinner
                    variant="outline-success"
                    style="width: 3rem; height: 3rem" />
                </b-col>
              </b-row>

              <b-row v-else>
                <b-col md="4" v-for="task in tasks" :key="task.id" class="mb-3 px-2">
                  <b-list-group>
                    <b-popover
                      :target="'popover-target-' + task.created_at"
                      triggers="hover"
                      placement="top"
                    >
                      <template #title>
                        <h4 v-if="task.status_evaluation == 1" class="text-center my-0">
                          Clase planificada
                        </h4>
                        <h4 v-if="task.status_evaluation == 2" class="text-center my-0">
                          Clase iniciada
                        </h4>
                        <h4 v-if="task.status_evaluation == 3" class="text-center my-0">
                          Clase culminada
                        </h4>
                        <h4 v-if="task.status_evaluation == 4" class="text-center my-0">
                          Clase cerrada
                        </h4>
                      </template>

                      <p v-if="task.status_evaluation == 1" class="my-0">
                        Las clases planificadas, sólo estarán disponibles cuando
                        el profesor decida iniciar la clase, los alumnos aún no
                        pueden acceder a esta clase
                      </p>
                      <p v-if="task.status_evaluation == 2" class="my-0">
                        Puedes acceder en cualquier momento a esta clase
                        mientras esté iniciada
                      </p>
                      <p v-if="task.status_evaluation == 3" class="my-0">
                        La clase fue culminada por el profesor, los alumnos
                        pueden acceder a los contenidos pero no podrán
                        interactuar en los chats
                      </p>
                      <p v-if="task.status_evaluation == 4" class="my-0">
                        La clase se encuentra cerrada, los alumnos no tienen
                        acceso a esta clase
                      </p>
                    </b-popover>

                    <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                      <b-col :id="'popover-target-' + task.created_at" md="12" class="m-0 p-0 text-center" style="width: 100%">
                        <b-button
                          v-if="task.status_evaluation == 1"
                          class="py-1"
                          variant="primary"
                          style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                          >Clase planificada</b-button
                        >
                        <b-button
                          v-if="task.status_evaluation == 2"
                          class="py-1"
                          variant="success"
                          style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                          >Clase iniciada</b-button
                        >
                        <b-button
                          v-if="task.status_evaluation == 3"
                          class="py-1"
                          variant="warning"
                          style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                          >Clase culminada</b-button
                        >
                        <b-button
                          v-if="task.status_evaluation == 4"
                          class="py-1"
                          variant="danger"
                          style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                          >Clase cerrada</b-button
                        >
                      </b-col>
                      <!--<b-col md="2" class="m-0 p-0 text-center" style="width: 100%">
                        <b-button
                          variant="danger"
                          class="py-1"
                          style="border-radius: 0px 5px 0px 0px; height: 100%; width: 100%"
                          @click="delete_Evaluation(task, 'Clase', 'clase', index)">
                          <v-icon name="trash" color="white" style="font-size: 18px" />
                        </b-button>
                      </b-col>-->
                    
                    </b-row>

                    <div v-if="task.url_image" class="card-img-top obj" :style="`background-image: url(${task.url_image}); background-size: 100%; border-radius: 0px`">
                      <br>
                      <br>
                      <br>
                      <br>
                      <br>
                    </div>
                    <div v-else class="card-img-top obj" style="background-image: url(https://storage.googleapis.com/ticktaps-school/school-banner2.jpeg); background-position: 0px -20px; border-radius: 0px; background-size: 100%;">
                      <br>
                      <br>
                      <br>
                      <br>
                      <br>
                    </div>

                    <b-badge
                      variant="info"
                      class="py-2"
                      style="border-radius: 0px; height: 100%; width: 100%">
                      {{ task.lapse.name }}
                    </b-badge>

                    <b-list-group-item>
                      <h4 class="text-center">{{ task.title }}</h4>
                      <hr>

                      <p class="py-auto my-auto">
                        {{ task.description.length > 80 ? task.description.substr(0, 80) + '...' : task.description }}
                      </p>
                      <hr>

                      <p class="py-auto my-auto" style="font-size: 12px">
                        <strong>Fecha:</strong> {{ task.deadline | format_date }}
                      </p>
                      <p class="py-auto my-auto" style="font-size: 12px">
                        <strong>Fecha tope: </strong>
                        <span>{{ task.tasks_deadline | format_date }}</span>
                      </p>
                      <p class="py-auto my-auto" style="font-size: 12px">
                        <strong>Creada el:</strong> {{ task.created_at | format_date }}
                      </p>
                      <p class="py-auto mt-0 mb-auto" style="font-size: 12px">
                        <strong>Últ. modificación el:</strong> {{ task.updated_at | format_date }}
                      </p>
                      
                    </b-list-group-item>

                    <b-row>
                      <b-col md="12">
                        <b-button
                          variant="dark"
                          class="px-0 mx-0"
                          style="border-radius: 0px 0px 5px 5px; width: 100%"
                          @click="accessClass(task)">
                          Acceder a clase
                          <v-icon name="angle-right" class="ml-2" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-list-group>

                </b-col>
              </b-row>

              <b-row v-if="tasks.length == 0" align-h="center">
                <b-col md="12" class="text-center">
                  <p>No hay ningúna clase registrada... </p>
                </b-col>
              </b-row>
              
              <!--<b-row>
                <div v-for="task in tasks" :key="task.id" class="col-md-4">
                  <hr />
                  <b-list-group>
                    <b-list-group-item v-if="task.url_image">
                      <div class="text-center obj" v-if="task.url_image">
                        <img
                          class="card-img-top"
                          :src="task.url_image"
                          alt=""
                        />
                      </div>
                    </b-list-group-item>

                    <b-list-group-item>
                      <h4>{{ task.title }}</h4>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p>
                        {{ task.description.substr(0, 80) }} ...
                      </p></b-list-group-item
                    >
                    <b-list-group-item>
                      <p style="font-size: 11px">
                        <strong>Fecha:</strong> {{ task.deadline }}
                      </p>
                    </b-list-group-item>
                    <b-popover
                      :target="'popover-target-' + task.created_at"
                      triggers="hover"
                      placement="top"
                    >
                      <template #title
                        ><h4 v-if="task.status_evaluation ==1">Clase planificada</h4>
                        <h4 v-if="task.status_evaluation ==2">Clase iniciada</h4>
                        <h4 v-if="task.status_evaluation ==3">Clase culminada</h4>
                        <h4 v-if="task.status_evaluation ==4">Clase cerrada</h4></template
                      >
                     <p v-if="task.status_evaluation == 1">Las clases planificadas, solo estarán disponibles cuando el profesor decida iniciar la clase, los alumnos aún no pueden acceder a esta evaluación</p>
                    <p v-if="task.status_evaluation == 2">Puedes acceder en cualquier momento a esta clase mientras este iniciada</p>
                   <p v-if="task.status_evaluation == 3">La clase fue culminada por el profesor, los alumnos pueden acceder a los contenidos pero no podrán interactuar en los chats.</p>
                   <p v-if="task.status_evaluation == 4">La clase se encuentra cerrada, los alumnos no tienen acceso a esta clase</p>
                  
                    </b-popover>
                    <b-list-group-item
                      :id="'popover-target-' + task.created_at"
                    >
                      <b-badge
                        v-if="task.status_evaluation == 1"
                        variant="outline-primary"
                        pill
                        >Clase planificada</b-badge
                      >
                      <b-badge
                        v-if="task.status_evaluation == 2"
                        variant="outline-success"
                        pill
                        >Clase iniciada</b-badge
                      >
                      <b-badge
                        v-if="task.status_evaluation == 3"
                        variant="outline-warning"
                        pill
                        >Clase culminada</b-badge
                      >
                      <b-badge
                        v-if="task.status_evaluation == 4"
                        variant="outline-danger"
                        pill
                        >Clase cerrada</b-badge
                      ></b-list-group-item
                    >
                    <b-list-group-item
                      ><p style="font-size: 10px">
                        <strong>Creada el:</strong> {{ task.created_at }}
                      </p>
                      <p style="font-size: 10px">
                        <strong>Actualizada:</strong> {{ task.updated_at | from_now }}
                      </p>

                      <p style="font-size: 10px">
                        <strong>Entrega de Tarea hasta:</strong>
                        {{ task.tasks_deadline }}
                      </p>
                    </b-list-group-item>
                    <b-list-group-item
                      ><b-badge variant="outline-primary">{{
                        task.lapse.name
                      }}</b-badge></b-list-group-item
                    >
                  </b-list-group>
                  <b-list-group-item >
                    <b-row>
                      <b-col md="12">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          pill
                          block
                          @click="accessClass(task)"
                          ><v-icon name="angle-right"></v-icon> Acceder a
                          clase</b-button
                        >
                      </b-col>
                     
                    </b-row>
                  </b-list-group-item>
                </div>
              </b-row>-->
            </b-tab>
             <b-tab title="Exámenes" @click="$route.query.tab != 'examenes' ? $router.replace({ query: { tab: 'examenes', lapse: lapse } }) : ''" :active="$route.query.tab == 'examenes'">
        
          <b-row v-if="loading" align-h="center">
            <b-col md="12" class="text-center">
              <b-spinner
                variant="outline-success"
                style="width: 3rem; height: 3rem" />
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col md="4" v-for="exam in exams" :key="exam.id" class="mb-3 px-2">
              <b-list-group>
                <b-popover
                  :target="'popover-target-' + exam.created_at"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>
                    <h4 v-if="exam.active == 0" class="text-center my-0">
                      Examen inhabilitado
                    </h4>
                    <h4 v-if="exam.active == 1 && exam.time_passed == 0" class="text-center my-0">
                      Examen habilitado
                    </h4>
                    <h4 v-if="exam.active == 1 && exam.time_passed == 1" class="text-center my-0">
                      Examen concluido
                    </h4>
                  </template>

                  <p v-if="exam.active == 0" class="my-0">
                    Los exámenes inhabilitados no están aún disponibles
                    para ser contestados por los estudiantes
                  </p>
                  <p v-if="exam.active == 1 && exam.time_passed == 0" class="my-0">
                    El examen está disponible para que los estudiantes puedan
                    responderlo, siempre que sea antes de la fecha máxima indicada
                  </p>
                  <p v-if="exam.active == 1 && exam.time_passed == 1" class="my-0">
                    El examen ya ha concluido, por lo cual ya pasó la fecha máxima
                    de aceptar respuestas de los estudiantes
                  </p>
                </b-popover>

                <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                  <b-col v-if="exam.active == 0" md="2" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      variant="danger"
                      class="py-1"
                      style="border-radius: 5px 0px 0px 0px; height: 100%; width: 100%"
                      @click="DeleteExam(exam)">
                      <v-icon name="trash" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                  <b-col v-if="exam.active == 0" :id="'popover-target-' + exam.created_at" md="8" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-if="exam.active == 0"
                      class="py-1"
                      variant="dark"
                      style="cursor: default; border-radius: 0px 0px 0px 0px; height: 100%; width: 100%;"
                      >Examen inhabilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 0"
                      class="py-1"
                      variant="success"
                      style="cursor: default; border-radius: 0px 0px 0px 0px; height: 100%; width: 100%;"
                      >Examen habilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 1"
                      class="py-1"
                      variant="danger"
                      style="cursor: default; border-radius: 0px 0px 0px 0px; height: 100%; width: 100%;"
                      >Examen concluido</b-button
                    >
                  </b-col>
                  <b-col v-if="exam.active == 0" md="2" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-b-modal.modal-activate
                      variant="success"
                      class="py-1"
                      style="border-radius: 0px 5px 0px 0px; height: 100%; width: 100%"
                      @click="activeTMP(exam)">
                      <font-awesome-icon icon="check-circle" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                  
                  <b-col v-if="exam.active == 1" :id="'popover-target-' + exam.created_at" md="12" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-if="exam.active == 0"
                      class="py-2"
                      variant="primary"
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Examen inhabilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 0"
                      class="py-1"
                      variant="success"
                      style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                      >Examen habilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 1"
                      class="py-1"
                      variant="warning"
                      style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                      >Examen concluido</b-button
                    >
                  </b-col>
                </b-row>

                <b-badge
                  variant="info"
                  class="py-2"
                  style="border-radius: 0px; height: 100%; width: 100%">
                  {{ exam.lapse.name }}
                </b-badge>

                <b-list-group-item>
                  <h4 class="text-center">{{ exam.title }}</h4>
                  <hr>

                  <p class="py-auto my-auto">
                    {{ exam.description.length > 80 ? exam.description.substr(0, 80) + '...' : exam.description }}
                  </p>
                  <hr>

                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Total preguntas: </strong>
                    <span>{{ exam.questions_quantity }}</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Duración: </strong>
                    <span>{{ exam.duration }} minutos</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Puntaje: </strong>
                    <span>{{ exam.points }} puntos</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Puntaje mínimo de aprobación: </strong>
                    <span>{{ exam.min_approval_points }} puntos</span>
                  </p>
                  <p v-if="exam.active == 1" class="py-auto my-auto" style="font-size: 12px">
                    <strong>Disponible desde:</strong> {{ exam.available_from | format_date }}
                  </p>
                  <p v-if="exam.active == 1" class="py-auto my-auto" style="font-size: 12px">
                    <strong>Disponible hasta: </strong>
                    <span>{{ exam.available_until | format_date}}</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Creado el:</strong> {{ exam.created_at | format_date }}
                  </p>
                </b-list-group-item>

                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="dark"
                      class="px-0 mx-0"
                      style="border-radius: 0px 0px 5px 5px; width: 100%"
                      @click="accessExam(exam)">
                      Acceder a examen
                      <v-icon name="angle-right" class="ml-2" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group>

            </b-col>
          </b-row>

          <b-row v-if="!loading && exams.length == 0" align-h="center">
            <b-col md="12" class="text-center">
              <p>No hay ningún examen registrado en el momento seleccionado... </p>
            </b-col>
          </b-row>
        </b-tab>
           

            <b-tab title="Cartelera" @click="$route.query.tab != 'cartelera' ? $router.replace({ query: { tab: 'cartelera', lapse: lapse } }) : ''" :active="$route.query.tab == 'cartelera'">
              <b-row v-if="loading" align-h="center" >
                <b-col md="12" class="text-center">
                  <b-spinner
                    variant="outline-success"
                    style="width: 3rem; height: 3rem" />
                </b-col>
              </b-row>

              <b-row v-else align-h="center" v-for="notice in notices" :key="notice.id">
                <b-col md="2"></b-col>
                <b-col
                  md="8"
                  class="mb-3 px-2"
                >
                  <b-list-group>
                    <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                      <b-col md="12" class="m-0 p-0 py-1 text-center" style="color: #FFFFFF; background-color: #002446; cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;">
                        Publicada: {{ moment(notice.created_at).local().format('D MMM YYYY, h:mm a') }}
                      </b-col>
                    </b-row>

                    <b-list-group-item>
                      <h4>{{ notice.title }}</h4>
                      <p class="my-auto py-auto">{{ notice.description }}</p>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col md="2"></b-col>
              </b-row>

              <b-row v-if="!loading && notices.length == 0" align-h="center">
                <b-col md="12" class="text-center">
                  <p>No hay ningúna publicación registrada... </p>
                </b-col>
              </b-row>
              
              <!--<b-row>
                <div
                  v-for="notice in notices"
                  :key="notice.id"
                  class="col-md-6"
                >
                  <hr />
                  <b-list-group>
                    <b-list-group-item>
                      <h4>{{ notice.title }}</h4>
                    </b-list-group-item>
                    <b-list-group-item>
                      <p>
                        {{ notice.description }}
                      </p></b-list-group-item
                    >

                    <b-list-group-item>
                      <b-row>
                        <b-col md="10">
                          <p style="font-size: 10px">
                            <strong>Creada el:</strong>
                            {{ notice.created_at | from_now }}
                          </p>
                        </b-col>
                        <b-col md="2">
                        
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-row>-->
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "SubjectDetail",

  data() {
    return {
      moment: moment,
      evaluations: [],
      classes: [],
       exam: {},
      exams: [],
      tasks: [],
      lapseList: [],
  notices: [],
      feed: {
        title: "",
        description: "",
      },
        lapse: {},
      loading: false,
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
    };
  },
  computed: {
    ...mapState({
      subject_section: (state) => state.subjectSection.subject_section,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    
    this.lapse = this.$route.query.lapse ? this.$route.query.lapse : this.me_rol.id_actual_lapse
    this.loadSelects();
    this.subjectLoad();
  
    //this.loadState();
  },
   filters: {
    from_now: function(created_at) {
      console.log("Filters");
      return moment(created_at).fromNow();
    },
    format_date: function(created_at) {
      if (created_at && created_at.length > 10) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")+' - '+moment(created_at).format("h:mm a");
      }
      if (created_at && created_at.length <= 10) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")
      }
      else {
        return 'No definida'
      }
    },
  },
  methods: {
    ...mapActions([
      "GetAll",
      "loadData_Evaluation",
      "load_Evaluation_by_subject",
      "loadData_SubjectSection",
      "loadLapse_school",
      "loadData_Exam",
      "addToastMessage",
      "loadEvaluations",
      "load_Feed",
    ]),
      accessExam(exam) {
      this.loadData_Exam(exam).then((response) => {
        this.$router.push("/estudiante/materia/examen/presentar");
      });
    },
    getAllExams(lapse){
      console.log('LAPSO:'+lapse)
       let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.subject_detail.id_section;
      const self = this;

      let form4 = {
        api: 1,
        element: 'quiz_poll',
        query: {
          order_key: 'id',
          order_value: 'desc',
        },
        columns: ['id_subject','id_section','id_lapse','id_school','active'],
        params: {
          id_subject: id_subject,
          id_section: id_section,
          id_school:id_school,
          active:1        
        }
      }
       if(lapse > 0){
         console.log('ASign lapse:'+lapse)
        form4['params']['id_lapse'] = lapse
      }

      console.log(form4)

      this.loading = true;
      //LOAD EVALUATIONS
      this.GetAll(form4)
        .then((response) => {
          console.log(response)
          if (response.code === 200) {
            this.exams = response.data
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    createClass(type) {
      this.loadData_SubjectSection(this.subject_section).then((response) => {
        console.log(response);
        this.$router.push("/estudiante/materia/evaluacion/nuevo/" + type);
      });
    },
    accessClass(evaluation) {

      if(evaluation.status_evaluation == 1){
        this.addToastMessage({
                    text: "La clase esta planificada, pronto podrás acceder",
                    type: "success",
                  });

      }else if(evaluation.status_evaluation == 3){
         this.addToastMessage({
                    text: "La clase esta culminada, no podrás escribir mensajes, ni subir tareas",
                    type: "warning",
                  });
                   this.loadData_Evaluation(evaluation).then((response) => {
        console.log(response);
        this.$router.push("/estudiante/materia/evaluacion/detalle");
      });

      }else if(evaluation.status_evaluation == 4){
           this.addToastMessage({
                    text: "La clase esta cerrada, no puedes acceder",
                    type: "danger",
                  });
      }else{
         this.loadData_Evaluation(evaluation).then((response) => {
        console.log(response);
        this.$router.push("/estudiante/materia/evaluacion/detalle");
      });
      }
     
    },
    subjectLoad() {
      if (!this.subject_section.id) {
        this.$router.push("/estudiante/materias");
      }

      this.subject_detail = this.subject_section;

      //
    },
    loadSelects() {
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form2)
        .then((response) => {
          if (response.code === 200) {
            this.lapseList = response.data.reverse();
            if (this.lapse == 0)  this.lapse = response.data[0].id
            console.log('LOADED LAPSES')
            this.loadState(this.lapse)

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadState(lapse) {
      console.log('LOADING EVALUATIONS')
      this.$router.replace({ query: { tab: this.$route.query.tab, lapse: lapse }})

      let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.subject_detail.id_section;
      const self = this;

      let form = {
        id_subject: id_subject,
        id_type_evaluation: 2,
        id_school:id_school,
        id_period:id_period,
        id_section:id_section,
        active:1
      };
      if(lapse > 0){
        form['id_lapse'] = lapse
      }
      this.loading = true;
      this.loadEvaluations(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
        
            this.tasks = resp;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
        //LOAD EVALUATIONS
        this.getAllExams(lapse)
        //LOAD FEEDS
         let form3 = {
        id_subject: id_subject,

        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        order_key: "id",
        order_value: "desc",
        active: 1,
      };
      this.loading = true;
      //LOAD EVALUATIONS
      this.load_Feed(form3)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            this.notices = resp;
            // this.classes = resp.classes;
            // this.tasks = resp.tasks;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.obj {
  overflow: hidden;
}

.obj img {
  transition: all 1.5s ease;
  max-width: 15rem;
  max-height: 8rem;
  object-fit: cover;
}

.obj:hover img {
  transform: scale(1.5);
}
</style>