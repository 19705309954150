import axios from "axios";
import * as Config from "../../../config";

const state = {
  product: {}
};
let mod = "pay/";
const actions = {
  
  loadData_Product({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_PRODUCT", data);
  },
  loadBanks({ commit, dispatch }, params) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "banks/all", { params })
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadBankSchools({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "banks/school/"+form.id_school )
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateStudentPayReported({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod + "student_pay/update/" + form.id, form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  registerStudentPay({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "student_pay/save_pay", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudentPayments({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student_pay/student/"+form.id_student )
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudentPaymentsBySchool({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student_pay/school/"+form.id_school+'?status='+form.status )
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  generatePayments({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "student_balance/generate", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudentBalances({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student_balance/student/"+form.id_student )
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudentBalanceByMonth({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student_balance/school/"+form.id_school+"/month/"+form.month )
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
};

const mutations = {
  LOAD_DATA_PRODUCT(state, data) {
    state.product = data;
  }
};

export default {
  state,
  actions,
  mutations
};
