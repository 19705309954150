<template>
  <div>
    <br />
    <div class="content">
      <b-row>
        <b-col md="4">
          <h2>{{ exam_detail.title }}</h2>
          <p>{{ exam_detail.description }}</p>
          
          <b-list-group>
            <!-- <b-list-group-item
              >
              <b-badge v-if="exam_detail.active == 1" variant="success">Exámen Presentado</b-badge>
              <b-badge v-if="exam_detail.active == 2" variant="success">Exámen disponible para presentar</b-badge>
              </b-list-group-item
            >-->
           
            <b-list-group-item
              >Total de preguntas:
              {{ exam_detail.questions_quantity }}</b-list-group-item
            >
            <b-list-group-item>
              {{ exam_detail.questions_quantity1 }} de selección simple, con un
              valor de
              {{ exam_detail.value_questions1 }} puntos.</b-list-group-item
            >
            <b-list-group-item
              >{{ exam_detail.questions_quantity2 }} de verdadero / falso con un
              valor de
              {{ exam_detail.value_questions2 }} puntos.</b-list-group-item
            >
            <b-list-group-item
              >Valor del examen: {{ getTotalExam() }} puntos</b-list-group-item
            >
            <b-list-group-item
              >Duración del examen:
              {{ exam_detail.duration }} minutos</b-list-group-item
            >
            <b-list-group-item
              >Fecha de examen:
              {{ exam_detail.available_from }}</b-list-group-item
            >
            <b-list-group-item
              >Fecha máxima para presentar:
              {{ exam_detail.available_until }}</b-list-group-item
            >
            <b-list-group-item>
              <b-button variant="primary" v-if="exam_detail.active == 1" @click="start_exam"
                >Iniciar examen ></b-button
              >
             
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="8">
          <b-row>
            <b-card
              v-if="showQuestions && quiz_poll_detail"
              title="Preguntas del examen"
              sub-title="Lee cuidadosamente las preguntas, y respondelas antes de que finalice el tiempo del examen"
            >
              <b-card-text>
                <b-badge>Tiempo restante: 115 minutos</b-badge>
              </b-card-text>
              <div style="max-height:600px;overflow-y: auto;">
                <b-card
                  title="Selección simple"
                  v-if="quiz_poll_detail.exam[0].questions.length > 0"
                >
                  <div
                    v-for="question in quiz_poll_detail.exam[0].questions"
                    :key="question.id"
                  >
                    <b-row>
                      <b-col md="10">
                        <p>
                          <strong>{{ question.question.question }}</strong>
                        </p>
                      </b-col>
                      <b-col md="2">
                        <p style="font-size:11px">
                          {{ question.question.value }} Puntos
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-radio-group
                          stacked
                          v-model="question.solution"
                          :options="question.answers"
                          class="mb-3"
                          @change="sendResponse(question)"
                          value-field="id"
                          text-field="answer"
                          disabled-field="notEnabled"
                        ></b-form-radio-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>

                <b-card
                  title="Verdadero y Falso"
                  v-if="
                    quiz_poll_detail.exam[1].questions &&
                      quiz_poll_detail.exam[1].questions.length > 0
                  "
                >
                  <div
                    v-for="question in quiz_poll_detail.exam[1].questions"
                    :key="question.id"
                  >
                    <b-row>
                      <b-col md="10">
                        <p>
                          <strong>{{ question.question.question }}</strong>
                        </p>
                      </b-col>
                      <b-col md="2">
                        <p style="font-size:11px">
                          {{ question.question.value }} Puntos
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-radio-group
                          stacked
                          v-model="question.solution"
                          :options="question.answers"
                          class="mb-3"
                          @change="sendResponse(question)"
                          value-field="id"
                          text-field="answer"
                          disabled-field="notEnabled"
                        ></b-form-radio-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </div>
            <br>
               <b-button variant="primary" @click="finish_exam"
                >Finalizar examen ></b-button
              >
            </b-card>
            <b-card>
              <p>
                Debes leer bien las instrucciones de tu profesor, no podrás
                abrir este examen desde otra computadora o dispositivo al mismo
                tiempo.
              </p>
             <b-img fluid src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/evaluations/evaluation2447/room4862/archives/2022-05-10_071752-67ZMkJrb-756194.jpeg?authuser=1"></b-img>
            </b-card>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "ExamPresentation",

  data() {
    return {
      moment: moment,
      evaluations: [],
      classes: [],
      //  exam: {},
      exam_detail: {},
      exams: [],
      tasks: [],
      lapseList: [],
      notices: [],
      feed: {
        title: "",
        description: "",
      },
      lapse: {},
      loading: false,
      showQuestions: false,
      quiz_poll_detail: {},
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
    };
  },
  computed: {
    ...mapState({
      subject_section: (state) => state.subjectSection.subject_section,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
      exam: (state) => state.evaluation.exam,
    }),
  },
  mounted() {
    this.lapse = this.$route.query.lapse
      ? this.$route.query.lapse
      : this.me_rol.id_actual_lapse;

    this.examLoad();

    //this.loadState();
  },
  filters: {
    from_now: function(created_at) {
      console.log("Filters");
      return moment(created_at).fromNow();
    },
    format_date: function(created_at) {
      if (created_at && created_at.length > 10) {
        return (
          moment(created_at).format("D") +
          " de " +
          moment(created_at).format("MMMM") +
          " de " +
          moment(created_at).format("YYYY") +
          " - " +
          moment(created_at).format("h:mm a")
        );
      }
      if (created_at && created_at.length <= 10) {
        return (
          moment(created_at).format("D") +
          " de " +
          moment(created_at).format("MMMM") +
          " de " +
          moment(created_at).format("YYYY")
        );
      } else {
        return "No definida";
      }
    },
  },
  methods: {
    ...mapActions([
      "GetAll",
      "loadData_Evaluation",
      "load_Evaluation_by_subject",
      "loadData_SubjectSection",
      "loadLapse_school",
      "loadData_Exam",
      "addToastMessage",
      "loadEvaluations",
      "load_Feed",
      "startPollSolution",
      "sendSolution",
      "updatePollSolution",
    ]),
    sendResponse(question) {
      console.log("Sending response");
      console.log(question);
      let form = {
        id_quiz_poll_solution: this.quiz_poll_detail.quiz_poll.id,
        id_question: question.question.id,
        id_answer: question.solution,
      };
      this.sendSolution(form)
        .then((response) => {
          if (response.code == 200) {
            console.log("Response sended");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    start_exam() {
      let form = {
        id_user: this.me.id,
        id_quiz_poll: this.exam_detail.id,
      };
      Swal.fire({
        title: "Inicio de examen",
        text:
          "¿Ya leiste todas las instrucciones?, recuerdas que tendrás " +
          this.exam_detail.duration +
          " minutos para presentar el examen, no puedes cerrar esta ventana",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Iniciar Examen",
      }).then((result) => {
        if (result.value) {
          this.showQuestions = true;
          this.startPollSolution(form)
            .then((response) => {
              if (response.code == 200) {
                this.quiz_poll_detail = response.data;
                Swal.fire({
                  title: "Examen iniciado",
                  text:
                    "No actualices esta ventana, y enfócate en resolver correctamente antes de que culmine el tiempo",
                  icon: "success",
                });
              }
               if (response.code == 409) {
                this.quiz_poll_detail = response.data;
                Swal.fire({
                  title: "Examen previamente presentado",
                  text:
                    "No puedes presentar de nuevo un exámen que ya finalizaste",
                  icon: "warning",
                });
              }
              if (response.code == 408) {
                this.quiz_poll_detail = response.data;
                Swal.fire({
                    title: "Ya superaste la cantidad máxima de intentos",
                   text:
                    "Comunícate con tu profesor, ya superaste la cantidad máxima de intentos definido por el profesor al crear el exámen",
                  icon: "warning",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    finish_exam() {
      let form = {
        id: this.quiz_poll_detail.id_quiz_poll_solution,
        finished: 1,
      };
      Swal.fire({
        title: "Finalización de examen",
        text:
          "¿Ya respondiste todas las preguntas?, después de finalizar el exámen no podrás revertir esta acción. El profesor ya podrá corregirlo.",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Finalizar Examen",
      }).then((result) => {
        if (result.value) {
          this.showQuestions = true;
          this.updatePollSolution(form)
            .then((response) => {
              if (response.code == 200) {
                this.quiz_poll_detail = response.data;
                Swal.fire({
                  title: "Examen finalizado",
                  text:
                    "Ahora el profesor podrá corregir tu exámen presentado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    accessExam(exam) {
      this.loadData_Exam(exam).then((response) => {
        this.$router.push("/estudiante/materia/examen/" + exam.id + "/detalle");
      });
    },

    examLoad() {
      if (!this.exam.id) {
        this.$router.push("/estudiante/materias");
      }

      this.exam_detail = this.exam;

      //
    },
    getTotalExam() {
      let total_question1 =
        this.exam_detail.questions_quantity1 *
        this.exam_detail.value_questions1;
      let total_question2 =
        this.exam_detail.questions_quantity2 *
        this.exam_detail.value_questions2;
      return parseInt(total_question1 + total_question2);
    },
    loadSelects() {
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form2)
        .then((response) => {
          if (response.code === 200) {
            this.lapseList = response.data.reverse();
            if (this.lapse == 0) this.lapse = response.data[0].id;
            console.log("LOADED LAPSES");
            this.loadState(this.lapse);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadState(lapse) {
      console.log("LOADING EVALUATIONS");
      this.$router.replace({
        query: { tab: this.$route.query.tab, lapse: lapse },
      });

      let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.subject_detail.id_section;
      const self = this;

      let form = {
        id_subject: id_subject,
        id_type_evaluation: 2,
        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        active: 1,
      };
      if (lapse > 0) {
        form["id_lapse"] = lapse;
      }
      this.loading = true;
      this.loadEvaluations(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);

            this.tasks = resp;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //LOAD EVALUATIONS
      this.getAllExams(lapse);
      //LOAD FEEDS
      let form3 = {
        id_subject: id_subject,

        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        order_key: "id",
        order_value: "desc",
        active: 1,
      };
      this.loading = true;
      //LOAD EVALUATIONS
      this.load_Feed(form3)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            this.notices = resp;
            // this.classes = resp.classes;
            // this.tasks = resp.tasks;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.obj {
  overflow: hidden;
}

.obj img {
  transition: all 1.5s ease;
  max-width: 15rem;
  max-height: 8rem;
  object-fit: cover;
}

.obj:hover img {
  transform: scale(1.5);
}
</style>
