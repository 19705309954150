<template>
  <div id="headMasters">
    <br/>
    <div class="content">
      <b-card title="Lista de directores">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              A continuación podrás visualizar una lista de todos los directores.
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <b-button
                size="xl"
                variant="outline-primary"
                class="mr-2"
                title=""
                
                v-b-modal.modalAddEditHeadMasters
            
            >Registrar director
            </b-button>
          </b-col>
        </b-row>
        <br/>

        <b-row>
          <b-col lg="6" class="lmy-1">
            <b-form-group
                label="Filtrar por"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInputHeadMasters"
                    type="text"
                    placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilterHeadMasters">Limpiar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="lmy-1">
            <b-form-group
                label="Por página"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-1">
          <b-col sm="12">
            <div>
              <b-table style="font-size:14px"
                       id="my-table-hm"
                       show-empty
                       small
                       hover
                       fixed
                       stacked="md"
                       :filter-debounce="400"
                       primary-key="id"
                       :items="headMastersTmp"
                       :fields="field"
                       :current-page="currentPage"
                       :per-page="perPage"
                       :filter-included-fields="filterOn"
                       :sort-by.sync="sortBy"
                       :sort-desc.sync="sortDesc"
                       :sort-direction="sortDirection"
                       @filtered="onFiltered"
                       :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="changeActiveHeadMasters(row.item)"
                  >
                    <v-icon name="check-square"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="changeActiveHeadMasters(row.item)"
                  >
                    <v-icon name="minus-square" color="#fff"/>
                  </b-button>
                  <b-button
                      size="sm"
                      variant="outline-primary"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="showModalHeadMasters(row.item)"
                  >
                    <v-icon name="edit" color="#fff"/>
                  </b-button>
                </template>
              </b-table>
              <b-modal hide-footer @hidden="resetModal" id="modalAddEditHeadMasters"
                       :title="editHeadMasters.id?'Modificar Registro':'Guardar Registro'">
                <form style="width: 100%!important;" method="post" @submit.prevent="onSubmitHeadMasters">
                  <b-form-group label="Escuela" label-for="school">
                    <b-form-select
                        id="school"
                        v-model="school"
                        :options="schools"
                        value-field="id"
                        text-field="name"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group label="Usuario" label-for="user">
                   <v-select label="email" v-model="user" :options="users"></v-select>
                    <!--<b-form-select
                        id="user"
                        v-model="user"
                        :options="users"
                        value-field="id"
                        text-field="full_name"
                    ></b-form-select>-->
                  </b-form-group>
                  <b-form-group label="Profesión" label-for="profession">
                    <b-form-input
                        id="profession"
                        v-model="profession"
                        name="profession"
                    />
                  </b-form-group>
                  <b-button class="mt-3" type="submit" block size="xl" variant="outline-primary">
                    {{ editHeadMasters.id ? 'Modificar' : 'Guardar' }}
                  </b-button>
                </form>
              </b-modal>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table-hm"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>


      </b-card>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function (e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "headMasters",
  data() {
    return {
      isBusy: false,
      items: [],
      item_table: null,
      ttap_selected: null,
      student: {
        user: {
          name: "",
        },
      },
      field: [
        {
          key: "user.id",
          label: "id",
          sortable: true,
        },
        {
          key: "user.full_name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "profession",
          label: "Profesión",
          sortable: true,
        },
        {
          key: "school.name",
          label: "Escuela",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      profession: "",
      user: "",
      users: [],
      school: "",
      schools: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20, 100, 500],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      headMasters: [],
      headMastersTmp: [],
      loading: false,
      error: "",
      editHeadMasters: {},
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return [];
    },
    rows() {
      return this.headMastersTmp.length;
    },
  },
  mounted() {
    this.loadSelects();
    this.loadHeadMasters();
  },
  updated() {
    console.log(this.user);
  },
  methods: {
    ...mapActions([
      "load_HeadMasters",
      "changeActive_HeadMasters",
      "loadUsers_Active",
      "loadSchools_Active",
      "new_HeadMasters",
      "update_HeadMasters",
    ]),
    clearFilterHeadMasters() {
      this.headMastersTmp = this.headMasters;
      this.filter = "";
      return true;
    },
    searchInputHeadMasters() {
      let filterTMP = this.filter;
      let hMTmp = [];
      if (filterTMP) {
        this.headMasters.filter(function (el, i) {
          let cadena = JSON.stringify(el.user);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            hMTmp.push(el);
          }
        });
        this.headMastersTmp = hMTmp;
      } else {
        this.headMastersTmp = this.headMasters;
      }

      return true;
    },
    loadSelects() {
      let form = {
        active: 1,
      };
      this.loadUsers_Active(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.users = response.data;
            this.user = this.users[0];
            console.log('here')
            console.log(this.users)
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loadSchools_Active(form)
        .then((response) => {
          if (response.code === 200) {
            this.schools = response.data;
            this.school = this.schools[0].id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadHeadMasters() {
      this.isBusy = true;
      this.load_HeadMasters()
        .then((response) => {
          this.isBusy = false;
          if (response.code === 200) {
            this.headMasters = response.data;
            this.headMastersTmp = this.headMasters;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideModalHeadMasters() {
      this.editHeadMasters = {};
      this.$bvModal.hide("modalAddEditHeadMasters");
    },
    showModalHeadMasters(data) {
      this.editHeadMasters = data;
      this.user = this.users[0];
      this.school = this.schools[0].id;
      this.profession = "";
      if (data.id) {
        this.user = this.editHeadMasters.user.full_name;
        this.school = this.editHeadMasters.id_school;
        this.profession = this.editHeadMasters.profession;
      }
      this.$bvModal.show("modalAddEditHeadMasters");
    },
    onSubmitHeadMasters() {
      if (this.profession.trim() === "") {
        Swal.fire({
          title: "Completa el campo",
          text:
            "Asegúrate de indicar una profesión para el director e intenta de nuevo",
          icon: "warning",
        });
      } else {
        this.loading = true;
        let form = {
          id: this.editHeadMasters.id ? this.editHeadMasters.id : null,
          id_user: this.user.id,
          id_school: this.school,
          profession: this.profession,
        };
        Swal.fire({
          title: "Confirmación",
          text: this.editHeadMasters.id
            ? "¿Estás seguro de modificar este registro?"
            : "¿Estás seguro de guardar este registro?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: this.editHeadMasters.id ? "Modificar" : "Guardar",
        }).then((result) => {
          if (result.value) {
            this.loading = false;
            if (form.id) {
              this.update_HeadMasters(form)
                .then((response) => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "Información Modificada",
                      icon: "success",
                    }).then(() => {
                      this.hideModalHeadMasters();
                      this.loadHeadMasters();
                    });
                  }
                  if (response.code !== 200) {
                    Swal.fire({
                      title: "Ocurrio un error",
                      text: response.message,
                      icon: "warning",
                    }).then((result2) => {
                      console.log(result2);
                    });
                  }
                })
                .catch(() => {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                  });
                });
            } else {
              this.new_HeadMasters(form)
                .then((response) => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "Información Guardada",
                      icon: "success",
                    }).then(() => {
                      this.hideModalHeadMasters();
                      this.loadHeadMasters();
                    });
                  }
                  if (response.code !== 200) {
                    Swal.fire({
                      title: "Ocurrio un error",
                      text: response.message,
                      icon: "warning",
                    }).then((result2) => {
                      console.log(result2);
                    });
                  }
                })
                .catch(() => {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                  });
                });
            }
          }
        });
      }
    },
    changeActiveHeadMasters(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 =
        data.active === 1
          ? "Desactivado correctamente"
          : "Activado correctamente";
      let text =
        data.active === 1
          ? "¿Estás seguro de desactivarlo ahora?"
          : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: title,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActive_HeadMasters(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetModal() {
      this.profession = "";
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
