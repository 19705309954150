<template>
  <div id="NewStudent">
    <br />
    <div class="content">
      <b-card>
        <b-row>
          <b-col md="12">
            <h3>Crear {{ label }} en {{ subject_detail.subject.name }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              Por favor completa todos los campos para realizar un registro
              correcto de su {{ label }}
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <a class="btn btn-outline-primary btn-sm" @click="$router.go(-1)">
              <v-icon name="angle-left" /> Volver a la Materia</a
            >
          </b-col>
          <br />
          <hr align="center" noshade="noshade" size="2" width="100%" />
        </b-row>

        <form
          style="width: 100% !important"
          method="post"
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col md="12"> </b-col>
            <b-col md="4">
              <b-form-group label="Fecha" label-for="first_name">
                <date-picker
                  id="birthday"
                  name="birthday"
                  mode="single"
                  :max-date="new Date()"
                  v-model="deadline"
                  :popover="{ placement: 'bottom', visibility: 'click' }"
                />
              </b-form-group>
              <b-form-group label="Hora" label-for="first_name">
                <b-form-timepicker
                  v-model="time"
                  locale="en"
                ></b-form-timepicker>
              </b-form-group>
              <b-form-group label="Momento" label-for="first_name">
                <b-form-select
                  v-model="lapse"
                  :options="lapseList"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Título" label-for="first_name">
                <b-form-input
                  id="first_name"
                  v-model="title"
                  name="first_name"
                />
              </b-form-group>
              <b-form-group label="Descripción" label-for="first_name">
                <b-form-textarea
                  id="first_name"
                  v-model="description"
                  name="first_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="URL Video a compartir (Opcional)"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="url_video"
                  name="first_name"
                />
              </b-form-group>
              <b-form-group
                label="URL a compartir (Opcional)"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="url_link"
                  name="first_name"
                />
              </b-form-group>
            </b-col>

            <b-col md="6"></b-col>
            <b-col md="6"></b-col>
            <b-col md="6"></b-col>
          </b-row>

          <hr align="center" noshade="noshade" size="2" width="100%" />

          <b-col md="12 text-right">
            <b-button type="submit" variant="outline-success" class="m-3"
              >Registrar {{ label }} <v-icon name="save" />
            </b-button>
            
          </b-col>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import Moment from "moment";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "NewStudent",
  data() {
    return {
      //TODO LIST OF FATHER
      fathers: [],
      //USER DATA
      user: {
        full_name: "",
        dni: "",
        phone: "",
      },

      type_evaluation: 0,
      id_lapse: "",
      id_teacher: "",
      title: "",
      description: "",
      deadline: "",
      id_subject: "",
      id_school: "",
      url_video: "",
      url_link: "",
      id_section: "",
      email: "",
      label: "",
      time: "",
      lapse: "",
      lapseList: [],
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
    };
  },
  computed: {
    ...mapState({
      subject_section: (state) => state.subjectSection.subject_section,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathers.length;
    },
  },
  mounted() {
    // console.log('route', this.$route.params);
    this.type_evaluation = this.$route.params.type;
    if (this.type_evaluation == 1) {
      this.label = "Clase";
    }
    if (this.type_evaluation == 2) {
      this.label = "Tarea";
    }
    if (this.type_evaluation == 3) {
      this.label = "Exámen";
    }
    this.loadSelects();
    this.subjectLoad();
  },
  methods: {
    ...mapActions([
      "newStudentFromRepresentative",
      "findUserByEmail",
      "addToastMessage",
      "newTeacher",
      "loadLapse_school",
      "new_Evaluation",
    ]),
    subjectLoad() {
      if (!this.subject_section.id) {
        this.$router.push("/profesor/materias");
      }
      console.log("SUBJECT SECTION");
      console.log(this.subject_section);
      this.subject_detail = this.subject_section;
    },
    loadSelects() {
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form2)
        .then((response) => {
          if (response.code === 200) {
            this.lapseList = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    find_UserByEmail() {
      this.isBusy = true;
      let form = {
        email: this.email,
      };
      this.findUserByEmail(form)
        .then((response) => {
          if (response.code === 200) {
            this.user = response.data;
          }
          if (response.code === 404) {
            Swal.fire({
              title: "Usuario no encontrado",
              text: "No hemos encontrado un usuario con este correo",
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if (
        this.deadline == "" ||
        this.time == "" ||
        this.title == "" ||
        this.description == ""
      ) {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar todos los datos de la clase",
          icon: "warning",
        });
      } else {
        this.loading = true;
        console.log(this.me);
        let dead = Moment(this.deadline).format("YYYY-MM-DD");
        console.log(dead + " " + this.time);
        let form = {
          id_type_evaluation: this.type_evaluation,
          id_lapse: this.lapse,
          id_teacher: this.me_rol.id_teacher,
          title: this.title,
          description: this.description,
          deadline: dead + " " + this.time,
          id_subject: this.subject_section.subject.id,
          id_school: this.me_rol.id_school,
          url_video: this.url_video,
          url_link: this.url_link,
          id_section: this.subject_section.section.id,
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de registrar esta clase?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.new_Evaluation(form)
              .then((response) => {
                console.log("RESPONSE");
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La clase ha sido registrada",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.go(-1);
                  });
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                if (response.code == 409) {
                  Swal.fire({
                    title: "Profesor previamente registrado",
                    text:
                      "Encontramos una solicitud con estos datos previamente registrados",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                    //  this.$router.push({ name: "uecamag" });
                  });
                }
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "error",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
