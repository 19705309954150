<template>
  <div id="AllStudent">
    <br/>
    <div class="content">
      <b-card title="Papelera de Reciclaje">
        <b-row>
          <b-col md="9" style="text-align: -webkit-left">
            <p>
              A continuación podrás visualizar todas las clases que haz eliminado, y puedes restaurarlas si así lo necesitas.
            </p>
          </b-col>
          <b-col md="3" style="text-align: -webkit-right">
           <!-- <p><strong>Estado de Estudiantes</strong></p>
              <b-list-group size="sm">
            <b-list-group-item style="padding:10px;max-height:30px"><p style="font-size:12px">Sin Verificar:{{count_pay_1}}</p></b-list-group-item>
            <b-list-group-item style="padding:10px;max-height:30px"><p style="font-size:12px">Insolventes:{{count_pay_2}}</p></b-list-group-item>
            <b-list-group-item style="padding:10px;max-height:30px"><p style="font-size:12px">Solventes: {{count_pay_3}}</p></b-list-group-item>
           
          </b-list-group>-->
          
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col lg="4" class="lmy-1">
            Filtrar
            <b-form-group
               
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter">Limpiar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
           


          </b-col>
          <b-col lg="4" class="lmy-1">
            Mostrar
            <b-form-group
               
                class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-1">
          <b-col sm="12">
            <div>
              <b-table style="font-size:14px"
                       id="my-table"
                       show-empty
                       small
                       hover
                       fixed
                       stacked="md"
                       :filter-debounce="400"
                       primary-key="id"
                       :items="evaluationsTmp"
                       :fields="field"
                       :current-page="currentPage"
                       :per-page="perPage"

                       :filter-included-fields="filterOn"
                       :sort-by.sync="sortBy"
                       :sort-desc.sync="sortDesc"
                       :sort-direction="sortDirection"
                       @filtered="onFiltered"
                       :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                 
                <template v-slot:cell(actions)="row">
                 

               
               
                  <b-button size="sm" variant="outline-primary" class="mr-2" @click="restore(row.item)">
                    <v-icon name="check"/> Restaurar
                  </b-button>
                </template>
              </b-table>
             
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllStudent",
  data() {
    return {
      isBusy: true,
      items: [],
      item_table: null,
      ttap_selected: null,
      student: {
        user: {
          name: ""
        }
      },
      status_pay_selected: 1,
      status_payments: [
        {value: 1, text: "No Revisado"},
        {value: 2, text: "Insolvente"},
        {value: 3, text: "Solvente"}
      ],

      field: [
        {
          key: "title",
          label: "Título",
          sortable: true
        },
        {
          key: "subject.name",
          label: "Materia",
          sortable: true
        },
        {
          key: "section.name",
          label: "Sección",
          sortable: true
        },
        {
          key: "lapse.name",
          label: "Lapso",
          sortable: true
        },
        {
          key: "deadline",
          label: "Fecha de clase",
          sortable: true
        },
       
        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      evaluations: [],
      evaluationsTmp: [],
      loading: false,
      error: "",
      count_pay_1:0,
      count_pay_2:0,
      count_pay_3:0,
      subject_sections:[],
      section:''
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return [];


    },
    rows() {
      return this.evaluationsTmp.length;
    }
  },
  mounted() {
    this.allEvaluationTrash();
    this.allSubjectSections();
  },
  methods: {
    ...mapActions([
      "loadEvaluationTrash",
      "restoreEvaluation"
    
    ]),
    clearFilter() {
      this.evaluationsTmp = this.evaluations;
      this.filter = ''
      return true;
    },
    searchInput() {

      let filterTMP = this.filter;
      let evaluationsTmp = [];
      if (filterTMP) {
        this.evaluations.filter(function (el, i) {
          let cadena = JSON.stringify(el.user);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            evaluationsTmp.push(el)
          }
        })
        this.evaluationsTmp = evaluationsTmp;
      } else {
        this.evaluationsTmp = this.evaluations;
      }

      return true;

    },
    allEvaluationTrash() {
      this.isBusy = true
      let form = {
        id_teacher: this.me_rol.id_teacher,
        order_key:'id',
        order_value:'desc'
      };
      
      this.loadEvaluationTrash(form)
          .then(response => {
            this.isBusy = false
            if (response.code === 200) {
              this.evaluations = response.data;
              this.evaluationsTmp = this.evaluations;

           
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
      restore(subject) {
      console.log("Updating Password");
      
        this.loading = true;

        let form = {
          id: subject.id_old
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de restaurar esta clase?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Restaurar",
        }).then((result) => {
          if (result.value) {
            this.restoreEvaluation(form)
              .then((response) => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La evaluación ha sido restaurada, podrá visualizarla en la lista de materias",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  
                  });
                }
                 if (response.code == 403) {
                  Swal.fire({
                    title: "Evaluacion existente",
                    text: "Al parecer esta evaluación ya existe, y no necesita ser restaurada",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                  
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      
    },
     allSubjectSections() {
      this.isBusy = true
      let form = {
        id_teacher: this.me_rol.id_teacher
      };
      this.loadSubjectSection2(form)
          .then(response => {
            this.isBusy = false
            if (response.code === 200) {
              this.subject_sections = response.data;
  

           
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    alert() {
      alert("Proximamente esta funcionalidad estara lista");
    },
    edit_Student(data) {
      this.loadData_Student(data).then(response => {
        console.log(response)
        this.$router.push("../profesor/estudiante/editar/" + data.id);
      });
    },
    show_Student(data) {
      this.loadData_Student(data).then(response => {
        console.log(response)
        this.$router.push("../profesor/estudiante/detalle/" + data.id);
      });
    },
   
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
   onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}

</style>
