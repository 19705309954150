import axios from "axios";
import * as Config from "../../../config";

const state = {
  blood: {},
  bloods: []
};

const actions = {
  loadBlood_Active({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "gen/type_blood/all/status/" + form.active)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
};
