<template>
  <div :style="{ backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')' }">
    <b-container>
      <br />
      <br />
      <b-row>
        <b-col md="3">
        </b-col>
         <b-col md="6">
          <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="outline-primary"><h2>Bienvenido a Clasy</h2></b-button>
      </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text><span>Clasy es un software que busca mejorar los procesos de la escuela, y digitalizar la educación con herramientas webs y móviles.</span>
          <br><br>
          <span>A continuación, te mostramos algunas consultas comunes que podrían ayudarte a comprender mejor a Clasy.</span></b-card-text>
       
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="outline-default"><h4>¿Cómo veo las tareas y clases de mi representado?</h4></b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpane2">
        <b-card-body>
          <b-card-text><p>Para ver las clases, tareas y evaluaciones, necesitas acceder al sistema usando el correo y contraseña del alumno.</p>
          
          <p>El sistema te permite acceder como estudiante y como representante, y para manejar toda la parte académica debes acceder como estudiante.</p></b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

  <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="outline-default"><h4>¿Cómo entro a Clasy con mi teléfono?</h4></b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpane">
        <b-card-body>
          <b-card-text><p>Clasy posee dos formas de acceder, vía web, o vía aplicación móvil. </p>
          
          <p>Para descargar la aplicación móvil esta disponible en Play Store para Android, desde:</p>
          <a href="https://play.google.com/store/apps/details?id=com.ticktaps.school" target="a_blank">Descargar Aplicación para Android</a><br><br>
            <a href="https://apps.apple.com/us/app/ticktaps-school/id1511448165" target="a_blank">Descargar Aplicación para iOS / Iphone</a>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-4 variant="outline-default"><h4>Funciones como representante:</h4></b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpane">
        <b-card-body>
          <b-card-text><p>Clasy te permite como representante una serie de funciones interesantes</p>
          
          <p>Puedes registrar los pagos de mensualidad de tus representados</p>
           <p>Puedes descargar el boletín de tus alumnos</p>
            <p>Puedes recibir noticias del colegio en tiempo real</p>
            <p>Puedes chatear con los profesores para comentar cualquier información referente a tus alumnos</p>
        
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-5 variant="outline-default"><h4>Funciones como estudiante:</h4></b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpane">
        <b-card-body>
          <b-card-text><p>Clasy te permite como estudiante una serie de funciones interesantes:</p>
          
          <p>Puedes ver las clases que envían los profesores.</p>
           <p>Puedes subir tus documentos en las tareas que envían tus profesores.</p>
            <p>Puedes recibir noticias del colegio en tiempo real.</p>
            <p>Puedes chatear con los profesores para comentar cualquier información referente a tus materias.</p>
            <p>Puedes escribir e interactuar en canales y foros de cada clase.</p>
        
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

     <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-6 variant="outline-default"><h4>¿Qué hacer si hay una duda?</h4></b-button>
      </b-card-header>
      <b-collapse id="accordion-6" accordion="my-accordion" role="tabpane">
        <b-card-body>
          <b-card-text><p>Si tienes dudas académicas o de uso del sistema, intenta solicitarle ayuda a tu profesor directo, los primeros días es normal confundirse, o no saber como usar la aplicación.</p>
          
          <p>Si tienes algún problema técnico, tenemos habilitado un número de contacto para soporte vía whatsapp, entra al siguiente link y guarda el contacto por si lo necesitas a futuro.</p>
          
            <a href="https://api.whatsapp.com/send?phone=584120574720&text=Hola%20Ticktaps" target="a_blank">Guardar el número de Soporte</a>
          </b-card-text>
        </b-card-body>
      </b-collapse>
      
    </b-card>
  </div>
  <br>
      <br>
       <router-link
                        :to="{name: 'login'}"
                        style="text-align:center"
                      >Todo listo, comenzar y acceder a Clasy</router-link>
        <!--<a href="/login"><span></span></a>-->
        </b-col>
         <b-col md="3">
        </b-col>
      </b-row>
          
        

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </b-container>
  </div>
</template>

<script>

export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      btnLogin: false,
      email: "",
      password: "",
      listRoles: [],
      auth: {},
    };
  },

};
</script>