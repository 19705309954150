<template>
  <div id="NewStudent">
    <br />
    <div class="content">
      <b-card>
        <b-row>
          <b-col md="9">
            <h3>Crear examen en {{ subject_detail.subject.name }}</h3>
          </b-col>
          <b-col md="3" class="text-right">
            <b-button
              @click="$router.go(-1)"
              class="btn btn-info py-1 px-3">
              <v-icon name="angle-left" />
              Volver
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="10">
            <p class="my-0 py-0">Por favor complete todos los campos</p>
            <p>Si desea conocer más información sobre qué responder en alguno  de ellos, sólo presione el botón de ayuda</p>
          </b-col>
          <b-col md="2" class="text-right" align-self="center">
            <b-button
              v-b-modal.modal-help
              class="btn btn-info">
              Ayuda
              <font-awesome-icon icon="info-circle" style="" />
            </b-button>
          </b-col>
        
          <b-col md="6" class="mt-2">
            <b-row>
              <b-col md="12">
                <h5 style="color: green">Detalles del examen</h5>
              </b-col>

              <b-col md="6">
                <b-form-group label="Momento">
                  <b-form-select
                    v-model="lapse"
                    :options="lapseList"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Puntaje mín. de aprobación" label-for="min_approval_points">
                  <b-form-input
                    type="number"
                    id="min_approval_points"
                    v-model="min_approval_points"
                    name="min_approval_points"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Duración del examen (min)" label-for="duration">
                  <b-form-input
                    type="number"
                    id="duration"
                    v-model="duration"
                    name="duration"
                  ></b-form-input>
                </b-form-group>
              </b-col>
                <b-col md="6">
                <b-form-group label="Cantidad máxima de intentos" label-for="attempts">
                  <b-form-input
                    type="number"
                    id="attempts"
                    v-model="attempts"
                    name="attempts"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Título" label-for="title">
                  <b-form-input
                    id="title"
                    v-model="title"
                    name="title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              
              <b-col md="12">
                <b-form-group label="Descripción" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    name="description"
                    rows="3"
                    no-resize
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" class="mt-2">
            <b-row>
              <b-col md="12">
                <h5 style="color: green">Cantidad de preguntas por sección / Puntaje</h5>
              </b-col>

              <b-col md="8">
                <b-form-group label="Selección simple" label-for="question_quantity1">
                  <b-form-input
                    type="number"
                    id="question_quantity1"
                    v-model="questions_quantity1"
                    name="questions_quantity1"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Puntaje" label-for="value_questions1">
                  <b-form-input
                    type="number"
                    id="value_questions1"
                    v-model="value_questions1"
                    name="value_questions1"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-form-group label="Verdadero/Falso" label-for="question_quantity2">
                  <b-form-input
                    type="number"
                    id="question_quantity2"
                    v-model="questions_quantity2"
                    name="questions_quantity2"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Puntaje" label-for="value_questions2">
                  <b-form-input
                    type="number"
                    id="value_questions2"
                    v-model="value_questions2"
                    name="value_questions2"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <!--<b-col md="8">
                <b-form-group label="Pareamiento" label-for="question_quantity4">
                  <b-form-input
                    type="number"
                    id="question_quantity4"
                    v-model="questions_quantity4"
                    name="questions_quantity4"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Puntaje" label-for="value_questions1">
                  <b-form-input
                    type="number"
                    id="value_questions1"
                    v-model="questions_quantity1"
                    name="questions_quantity1"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-form-group label="Completación" label-for="question_quantity3">
                  <b-form-input
                    type="number"
                    id="question_quantity3"
                    v-model="questions_quantity3"
                    name="questions_quantity3"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Puntaje" label-for="value_questions1">
                  <b-form-input
                    type="number"
                    id="value_questions1"
                    v-model="questions_quantity1"
                    name="questions_quantity1"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-form-group label="Desarrollo (abiertas)" label-for="question_quantity5">
                  <b-form-input
                    type="number"
                    id="question_quantity5"
                    v-model="questions_quantity5"
                    name="questions_quantity5"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Puntaje" label-for="value_questions1">
                  <b-form-input
                    type="number"
                    id="value_questions1"
                    v-model="questions_quantity1"
                    name="questions_quantity1"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-form-group label="Desarrollo (análisis)" label-for="question_quantity6">
                  <b-form-input
                    type="number"
                    id="question_quantity6"
                    v-model="questions_quantity6"
                    name="questions_quantity6"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              
              <b-col md="4">
                <b-form-group label="Puntaje" label-for="value_questions1">
                  <b-form-input
                    type="number"
                    id="value_questions1"
                    v-model="questions_quantity1"
                    name="questions_quantity1"
                  ></b-form-input>
                </b-form-group>
              </b-col>-->

              <b-col md="12">
                <p><strong>Puntaje total del examen: </strong> {{ total_points }} puntos</p>
              </b-col>
            </b-row>
          </b-col>  
            
          
          <b-col md="12 text-right">
            <b-button
              @click="SaveElement"
              class="btn btn-success mb-3" style="width: 100px">
              <span v-show="!loading">Guardar</span>
              <b-spinner v-show="loading" small class="align-middle" style="color: #FFFFFF"></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <br>

    <b-modal id="modal-help" title="Ayuda" size="lg" hide-footer>
      <b-row>
        <b-col md="12">
          <p>
            <strong>- Momento: </strong>
            Se refiere al lapso al cual pertenecerá el examen. Entendiendo que el mismo se creará para la materia y sección dentro de la cual se está: {{ subject_detail.subject.name }} - {{ subject_detail.section.name }}
          </p>
          <p>
            <strong>- Puntaje mínimo de aprobación: </strong>
            Puntaje a partir del cual el examen es considerado como aprobado. Por ejemplo, si se trata de un examen de 20 puntos, pudiera indicarse como puntaje mínimo de aprobación 10 puntos
          </p>
          <p>
            <strong>- Duración del examen: </strong>
            Tiempo del cual dispondrá el estudiante para responder el examen. En caso de cumplirse este tiempo, el examen le sería cerrado automáticamente dejándolo hasta el punto que alcanzó el estudiante. Debe ser indicado en minutos y en números enteros
          </p>
          <p>
            <strong>- Título: </strong>
            Título del examen
          </p>
          <p>
            <strong>- Descripción: </strong>
            Descripción del examen. En este campo se pueden incluir los temas que lo componen y la estructura del mismo
          </p>
          <p>
            <strong>- Cantidad de preguntas por sección: </strong>
            Para cada una de las secciones se debe indicar la cantidad de preguntas que aparecerán al estudiante al momento de presentar su examen. En caso de que no se requieran preguntas de determinada sección, se deja la cantidad en 0. En cuanto al puntaje, se refiere al puntaje que tendrá cada una de las preguntas que correspondan a dicha sección. Por ejemplo, un examen de 20 puntos, tendrá 20 preguntas de selección simple y 10 de verdadero/falso, las de selección simple tendrán un valor de 0,5 puntos cada una (para un total de 10 puntos en esa sección) mientras que las de verdadero/falso tendrán un puntaje de 1 punto cada una (para un total de 10 puntos en esa sección) y así se obtiene el total de 20 puntos del examen
          </p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

export default {
  name: "NewStudent",
  data() {
    return {
      progress: false,
      moment: moment,
      min_approval_points: 0,
      questions_quantity1: 0,
      questions_quantity2: 0,
      questions_quantity3: 0,
      questions_quantity4: 0,
      questions_quantity5: 0,
      value_questions1: 0,
      value_questions2: 0,
      value_questions3: 0,
      value_questions4: 0,
      value_questions5: 0,
      duration: 0,
      attempts:3,
      title: "",
      description: "",
      loading: false,
      lapse: 0,
      lapseList: [{ id: 0, name: 'Selecciona uno'}],
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
    };
  },
  computed: {
    ...mapState({
      subject_section: (state) => state.subjectSection.subject_section,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathers.length;
    },
    total_points() {
      let total = 0
      let questions_quantity = []
      let value_questions = []

      if (!this.questions_quantity1)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity1)
      if (!this.questions_quantity2)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity2)
      if (!this.questions_quantity3)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity3)
      if (!this.questions_quantity4)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity4)
      if (!this.questions_quantity5)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity5)

      if (!this.value_questions1)    value_questions.push(0)
      else                           value_questions.push(this.value_questions1)
      if (!this.value_questions2)    value_questions.push(0)
      else                           value_questions.push(this.value_questions2)
      if (!this.value_questions3)    value_questions.push(0)
      else                           value_questions.push(this.value_questions3)
      if (!this.value_questions4)    value_questions.push(0)
      else                           value_questions.push(this.value_questions4)
      if (!this.value_questions5)    value_questions.push(0)
      else                           value_questions.push(this.value_questions5)

      for (let i = 0; i < 5; i++) {
        total += questions_quantity[i] * value_questions[i] 
      }

      return total
    }
  },
  mounted() {
    this.loadSelects();
    this.subjectLoad();
  },
  filters: {
    from_now: function(created_at) {
      return moment(created_at).fromNow();
    },
    format_date: function(created_at) {
      if (created_at && created_at.length > 10) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")+' - '+moment(created_at).format("h:mm a");
      }
      if (created_at && created_at.length <= 10) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")
      }
      else {
        return 'No definida'
      }
    },
  },
  methods: {
    ...mapActions([
      "Save",
      "GetByID",
      "loadData_Evaluation",
      "newStudentFromRepresentative",
      "findUserByEmail",
      "addToastMessage",
      "newTeacher",
      "loadLapse_school",
      "new_Evaluation",
    ]),
    subjectLoad() {
      if (!this.subject_section.id) {
        this.$router.push("/profesor/materias");
      }
      console.log("SUBJECT SECTION");
      console.log(this.subject_section);
      this.subject_detail = this.subject_section;
    },
    loadSelects() {
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form2)
        .then((response) => {
          if (response.code === 200) {
            for (let item of response.data) {
              this.lapseList.push({ id: item.id, name: item.name })
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SaveElement() {
      const id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_teacher = this.me_rol.id;
      const id_section = this.subject_detail.id_section;

      if (!this.questions_quantity1)    this.questions_quantity1 = 0
      if (!this.questions_quantity2)    this.questions_quantity2 = 0
      if (!this.questions_quantity3)    this.questions_quantity3 = 0
      if (!this.questions_quantity4)    this.questions_quantity4 = 0
      if (!this.questions_quantity5)    this.questions_quantity5 = 0

      if (!this.value_questions1)    this.value_questions1 = 0
      if (!this.value_questions2)    this.value_questions2 = 0
      if (!this.value_questions3)    this.value_questions3 = 0
      if (!this.value_questions4)    this.value_questions4 = 0
      if (!this.value_questions5)    this.value_questions5 = 0

      let validation = this.lapse == 0 || this.title.trim() == '' || this.description.trim() == '' || this.min_approval_points == 0 || this.duration == 0

      if (validation) {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de que todos los campos estén llenos",
          icon: "warning",
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Aceptar",
        })
        return
      }

      let validation3 = this.duration <= 0

      if (validation3) {
        Swal.fire({
          title: "Duración incorrecta",
          text: "La duración del examen debe ser mayor a cero",
          icon: "warning",
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Aceptar",
        })
        return
      }

      let validation4 = this.min_approval_points <= 0

      if (validation4) {
        Swal.fire({
          title: "Puntaje incorrecto",
          text: "El puntaje mínimo de aprobación del examen debe ser mayor a cero",
          icon: "warning",
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Aceptar",
        })
        return
      }

      let validation5 = this.value_questions1 < 0 || 
        this.value_questions2 < 0 || 
        this.value_questions3 < 0 || 
        this.value_questions4 < 0 || 
        this.value_questions5 < 0

      if (validation5) {
        Swal.fire({
          title: "Puntaje incorrecto",
          text: "El puntaje de las preguntas debe ser mayor a cero",
          icon: "warning",
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Aceptar",
        })
        return
      }
      
      let form = {
        api: 1,
        element: 'quiz_poll',
        data: {
          id_school: id_school,
          id_subject: id_subject,
          id_teacher: id_teacher,
          id_section: id_section,
          id_lapse: this.lapse,
          type: 1,
          min_approval_points: this.min_approval_points,
          questions_quantity1: String(this.questions_quantity1),
          questions_quantity2: String(this.questions_quantity2),
          questions_quantity3: String(this.questions_quantity3),
          questions_quantity4: String(this.questions_quantity4),
          questions_quantity5: String(this.questions_quantity5),
          value_questions1: String(this.value_questions1),
          value_questions2: String(this.value_questions2),
          value_questions3: String(this.value_questions3),
          value_questions4: String(this.value_questions4),
          value_questions5: String(this.value_questions5),
          duration: this.duration,
          attempts: this.attempts,
          title: this.title,
          description: this.description,
        
        }
      }

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de guardar un nuevo examen? Una vez registrado, no podrás modificar esta información",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "success",
        cancelButtonColor: "gray",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
      }).then((result) => {
        if (result.value) {
          this.loading = true
          
          this.Save(form)
            .then((response) => {
              this.loading = false

              if (response.code == 200) {
                Swal.fire({
                  title: "Examen guardado",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                })
                
                this.$router.go(-1)
              }
              /*else if (response.code == 420 || response.code == 421) {              
                Cookies.remove('access_token')
                Cookies.remove('email')
                Cookies.remove('id_role')

                Swal.fire({
                  title: '¡Atención!',
                  text: 'Debe iniciar sesión nuevamente para continuar',
                  icon: "warning",
                  confirmButtonColor: '#9C243D',
                })

                this.$router.push({ name: 'login' })
              }*/
              else if (response.code == 409) {
                Swal.fire({
                  title: "Examen existente",
                  text: "Ya existe un examen para esta materia con esos datos",
                  icon: "warning",
                  confirmButtonColor: "#9C243C",
                })
              }
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
            });
        }
        else {
          this.loading = false
        }
      })
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
