<template>
  <div id="TableTeacher">
    <br/>
    <div class="content">
      <b-card title="Administre sus tablas maestras">
        <br/>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Tipos de escuelas" active>
              <table-type-school></table-type-school>
            </b-tab>
            <b-tab title="Tipos de evaluaciones">
              <table-type-evaluation></table-type-evaluation>
            </b-tab>
            <b-tab title="Tipos de estudiantes">
              <table-type-student></table-type-student>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import TableTypeEvaluation from "@/components/pages/goadmin/tableMaster/components/typeEvaluation";
import TableTypeSchool from "@/components/pages/goadmin/tableMaster/components/typeSchool";
import TableTypeStudent from "@/components/pages/goadmin/tableMaster/components/typeStudent";

export default {
  name: "TableTeacher",
  components: {TableTypeStudent, TableTypeSchool, TableTypeEvaluation},
  data() {
    return {};
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    ...mapActions([]),
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
