
<template>
<keep-alive max="5">
  <div>
    <br />
    <div class="content">
      <h2>Materias</h2>
      <p>Acá puedes visualizar todas las materias donde impartes clases</p>
      <hr>

      <b-row class="row" v-if="loading">
        <b-col md="12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="outline-success"
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="px-2">
        <b-col
          md="4"
          v-for="subject in subjects"
          :key="subject.id"
          class="mb-4 px-2"
          style="height: 200px;"
        > 
          <div v-if="subject.subject.url_img" :style="`background-image: url(${subject.subject.url_img}); background-size: 100%; border-radius: 5px 5px 0px 0px; max-height: 180px`">
            <b-row align-v="center" align-h="center">
              <b-col md="12" class="text-center pt-2">
                <img v-if="me_rol.id == subject.id_teacher" :src="subject.teacher.user.image" alt="" height="70rem" style="box-shadow: 2px 2px 5px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                <img v-if="me_rol.id == subject.id_teacher2" :src="subject.teacher2.user.image" alt="" height="70rem" style="box-shadow: 2px 2px 5px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                <b-avatar v-if="(me_rol.id == subject.id_teacher && !subject.teacher.user.image) || (me_rol.id == subject.id_teacher2 && !subject.teacher2.user.image)" variant="outline-info" size="4rem"></b-avatar>
              </b-col>
              <b-col md="12" class="pb-2">
                <h3 class="text-center ml-0 mb-1" style="font-size: 2rem; color: #FFFFFF; text-shadow: 2px 2px 5px #000000;">{{ subject.subject.name }}</h3>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 1rem; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Sección:</strong> {{ subject.section.name }} </p>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 16px; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Periodo:</strong> {{ subject.period.name }} </p>
              </b-col>
                <!--<img src="https://storage.googleapis.com/ticktaps-school/school-banner1.jpeg" alt="" width="100%" height="100px" style="border-radius: 5px 5px 0px 0px; object-fit: cover;">-->
            </b-row>
          </div>

          <div v-else style="background-image: url(https://storage.googleapis.com/ticktaps-school/school-banner1.jpeg); background-size: 100%; border-radius: 5px 5px 0px 0px; max-height: 180px">
            <b-row align-v="center" align-h="center">
              <b-col md="12" class="text-center pt-2">
                <img size="4em" v-if="me_rol.id == subject.id_teacher" :src="subject.teacher.user.image" alt="" height="70rem" style="box-shadow: 2px 2px 5px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover;" />
                <img size="4em" v-if="me_rol.id == subject.id_teacher2" :src="subject.teacher2.user.image" alt="" height="70rem" style="box-shadow: 2px 2px 5px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover;" />
              </b-col>
              <b-col md="12" class="pb-2">
                <h3 class="text-center ml-0 mb-1" style="font-size: 2rem; color: #FFFFFF; text-shadow: 2px 2px 5px #000000;">{{ subject.subject.name }}</h3>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 1rem; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Sección:</strong> {{ subject.section.name }} </p>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 16px; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Periodo:</strong> {{ subject.period.name }} </p>
              </b-col>
                <!--<img src="https://ik.imagekit.io/eswqag3k3o6/school2/docs/student1/2021-09-30_015920-newproject-2pXZM1.jpg" alt="" width="100%" height="100px" style="border-radius: 5px 5px 0px 0px; object-fit: cover;">-->
            </b-row>
          </div>

          <b-row align-h="center" class="mb-2">
            <b-col md="12" align-self="center">
              <b-button
                class="px-0 mx-0"
                @click="insideSubject(subject)"
                style="border-radius: 0px 0px 5px 5px; width: 100%"
                variant="primary"
                >Entrar a la materia<v-icon class="ml-2" name="angle-right"></v-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!--<div class="row">
        <div
          v-for="subject in subjects"
          :key="subject.id"
          class="col-md-4 mb-2"
        >
          <b-card>
            <b-row align-h="center">
              <b-col>
                <h3>{{ subject.subject.name }}</h3>
                <p>
                  <strong>{{ subject.teacher.user.full_name }}</strong>
                </p>
              </b-col>
              <b-col>
                <b-avatar
                
                  variant="outline-info"
                  :src="subject.teacher.user.image"
                ></b-avatar>
              </b-col>
            </b-row>

            <hr />
            <b-row>
              <b-col>
                <p>
                  <strong>Sección: <br /></strong>{{ subject.section.name }}
                </p>
              </b-col>
              <b-col>
                <p><strong>Periodo:<br /></strong>{{ subject.period.name }}</p>
              </b-col>
            </b-row>

            <hr />
            <b-button
              @click="insideSubject(subject)"
              pill
              block
              variant="outline-success"
              >Entrar a la materia <v-icon name="angle-right"></v-icon
            ></b-button>
          </b-card>
        </div>
      </div>-->
    </div>
  </div>
</keep-alive>
</template>
<script>

/* eslint-disable */
import { mapState, mapActions } from "vuex"

export default {
  name: "Welcome",

  data() {
    return {
      loading: false,
      subjects: [],
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    console.log(this.me_rol)
    this.loadState();
  },

  methods: {
    ...mapActions([
      "loadSubjects_by_user",
      "loadData_SubjectSection",
      "loadSubjectSection",
    ]),
    insideSubject(subject) {
      this.loadData_SubjectSection(subject).then((response) => {
        console.log(response);
        this.$router.push("/profesor/materia/" + subject.subject.id + '?tab=clases');
      });

      //this.$router.push("/profesor/materia/"+subject.subject.id);
    },
    loadState() {
      const id_school = this.me_rol.id_school;
      const self = this;

      let form = {
        //id_user: this.me.id,
        // id_rol:2,
        id_teacher: this.me_rol.id,
        id_school: id_school,
        active: 1,
      };
      this.loading = true;
      this.loadSubjectSection(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            console.log(resp);
            this.subjects = resp;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>