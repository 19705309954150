<template>
  <div>
    <br />
    <div class="content">
      <h2>Reportes de la Escuela</h2>
      <p>
        Este el panel encontrará reportes de toda la información almacenada en
        su institución
      </p>
      <br />
      <br />
      <div class="row">
        <div class="col-md-4">
          <b-card title="Reporte de Alumnos">
            <b-card-text
              >Descarga un archivo PDF con el listado de alumnos</b-card-text
            >
            <b-button @click="modalShow = !modalShow">Generar Reporte</b-button>

            <b-modal
              :modal-footer="false"
              title="Descarga de Reporte de Estudiantes"
              v-model="modalShow"
            >
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <b-form-group
                        label="Descargar por Sección"
                        label-for="email"
                      >
                        <b-form-select
                          v-model="section"
                          :options="sectionList"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(1)"
                        ><v-icon name="download"></v-icon></b-button
                    ></b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <p>Descargar reporte de todos</p>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(2)"
                        ><v-icon name="download"></v-icon
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Reporte de Representantes">
            <b-card-text
              >Descarga un archivo PDF con el listado de
              representantes</b-card-text
            >
            <b-button @click="modalShowRep = !modalShowRep"
              >Generar Reporte</b-button
            >

            <b-modal
              :modal-footer="false"
              title="Descarga de Reporte de Representantes"
              v-model="modalShowRep"
            >
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <b-form-group
                        label="Descargar por Sección"
                        label-for="email"
                      >
                        <b-form-select
                          v-model="section"
                          :options="sectionList"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(3)"
                        ><v-icon name="download"></v-icon></b-button
                    ></b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <p>Descargar reporte de todos</p>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(4)"
                        ><v-icon name="download"></v-icon
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card title="Reporte de Solvencias">
            <b-card-text
              >Descarga un archivo PDF con el listado de estudiantes, solventes
              e insolventes</b-card-text
            >
            <b-button @click="modalShowSolv = !modalShowSolv"
              >Generar Reporte</b-button
            >

            <b-modal
              :modal-footer="false"
              title="Descarga de Reporte de Solvencias"
              v-model="modalShowSolv"
            >
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <b-form-group
                        label="Descargar por Sección"
                        label-for="email"
                      >
                        <b-form-select
                          v-model="section"
                          :options="sectionList"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(5)"
                        ><v-icon name="download"></v-icon></b-button
                    ></b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <p>Descargar reporte de todos</p>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(6)"
                        ><v-icon name="download"></v-icon
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Reporte por Género">
            <b-card-text
              >Descarga un archivo PDF con el listado de estudiantes, según su
              género</b-card-text
            >
            <b-button @click="modalShowGen = !modalShowGen"
              >Generar Reporte</b-button
            >

            <b-modal
              :modal-footer="false"
              title="Descarga de Reporte por Género"
              v-model="modalShowGen"
            >
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <b-form-group
                        label="Descargar por Sección"
                        label-for="email"
                      >
                        <b-form-select
                          v-model="section"
                          :options="sectionList"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(7)"
                        ><v-icon name="download"></v-icon></b-button
                    ></b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <p>Descargar reporte de todos</p>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(8)"
                        ><v-icon name="download"></v-icon
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card title="Reporte de transferencias">
            <b-card-text
              >Descarga un archivo PDF con el listado de
              transferencias</b-card-text
            >
            <b-button @click="modalShowTrans = !modalShowTrans"
              >Generar Reporte</b-button
            >

            <b-modal
              :modal-footer="false"
              title="Descarga de Reporte de transferencias"
              v-model="modalShowTrans"
            >
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <b-form-group
                        label="Descargar por Banco del Emisor de Transferencia"
                        label-for="email"
                      >
                        <b-form-select
                          v-model="bank"
                          :options="bankList"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(9)"
                        ><v-icon name="download"></v-icon></b-button
                    ></b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col md="10">
                      <p>Descargar reporte de todos</p>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="outline-success" @click="downloadReport(10)"
                        ><v-icon name="download"></v-icon
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "Welcome",

  data() {
    return {
      modalShow: false,
      modalShowRep: false,
      modalShowSolv: false,
      modalShowTrans: false,
      modalShowGen: false,
      section: "",
      bank:"",
      bankList:[],
      student:"",
      studentList:[],
      sectionList: [],
      form: {
        student_count: 0,
        representatives_count: 0,
        teacher_count: 0,
      },
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      auth_token: (state) => state.auth.token.token,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    let form = {
      id_school: this.me_rol.id_school,
    };
    this.loadSection_school(form)
      .then((response) => {
        if (response.code === 200) {
          this.sectionList = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.loadBanks()
        .then((response) => {
          if (response.code === 200) {
            this.bankList = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
  },

  methods: {
    ...mapActions(["loadSection_school","loadBanks"]),
    downloadReport(type) {
      if (type == 1) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/estudiante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token +
          "&section=" +
          this.section;
        window.open(url, "_blank");
      }
      if (type == 2) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/estudiante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token;
        window.open(url, "_blank");
      }
      if (type == 3) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/representante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token +
          "&section=" +
          this.section;
        window.open(url, "_blank");
      }
      if (type == 4) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/representante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token;
        window.open(url, "_blank");
      }
      //SOLVENCES
      if (type == 5) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/solvencias/estudiante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token +
          "&section=" +
          this.section;
        window.open(url, "_blank");
      }
      if (type == 6) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/solvencias/estudiante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token;

        window.open(url, "_blank");
      }
      //GENDER
      if (type == 7) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/genero/estudiante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token +
          "&section=" +
          this.section;

        window.open(url, "_blank");
      }
      if (type == 8) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/genero/estudiante?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token;

        window.open(url, "_blank");
      }
       //PAYMENTS
      if (type == 9) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/pagos?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token +
          "&id_bank=" +
          this.bank;

        window.open(url, "_blank");
      }
      if (type == 10) {
        let url =
          "https://doc.ticktaps.com/descarga/reporte/pagos?filter=" +
          this.auth_token +
          "&tok=" +
          this.me_rol.token;

        window.open(url, "_blank");
      }
    },
  },
};
</script>
