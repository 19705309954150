<template>
  <div id="AllRequest">
    <br/>
    <div class="content">
      <b-card title="Lista de Solicitudes de Cupo">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              En esta lista puedes visualizar las solicitudes que se han hecho para nuevos ingresos desde la página web
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar representante</router-link>-->
          </b-col>
        </b-row>
        <br/>
        <b-modal id="request_detail_modal" title="Detalle de la Solicitud">

          <p><strong>Datos de estudiante</strong></p>
          <b-list-group>
            <b-list-group-item>Nombre de Estudiante: {{ this.request_detail.student_name }}</b-list-group-item>
            <b-list-group-item>Fecha de Nacimiento: {{ this.request_detail.student_birthdate ? this.request_detail.student_birthdate.substr(0, 10).split('-').reverse().join('-') : "No" }}</b-list-group-item>
            <b-list-group-item>Religion: {{ this.request_detail.religion ? this.request_detail.religion : "Sin información" }}</b-list-group-item>
            <b-list-group-item>Iglesia: {{ this.request_detail.church ? this.request_detail.church : "Sin información" }}</b-list-group-item>
            <b-list-group-item>Nivel que solicita: {{ this.request_detail.level_request }}</b-list-group-item>
          </b-list-group>
          <p><strong>Datos del Representante</strong></p>
          <b-list-group>
            <b-list-group-item>Nombre de Representante: {{ this.request_detail.rep_names }}</b-list-group-item>
            <b-list-group-item>Ced. Representante: {{ this.request_detail.rep_dni }}</b-list-group-item>
            <b-list-group-item>Telf.: {{ this.request_detail.rep_phone }}</b-list-group-item>
            <b-list-group-item>Telf de Casa: {{ this.request_detail.rep_home_phone ? this.request_detail.rep_home_phone : "No" }}</b-list-group-item>
            <b-list-group-item>Email: {{ this.request_detail.rep_email }}</b-list-group-item>
            <b-list-group-item>Nacionalidad: {{ this.request_detail.rep_nacionality }}</b-list-group-item>
          </b-list-group>
          <p><strong>Datos Adicionales</strong></p>
          <b-list-group>
            <b-list-group-item>Posee Familia en el colegio: {{ this.request_detail.student_family_school }}
            </b-list-group-item>
            <b-list-group-item>Condición Médica: {{ this.request_detail.student_condition }}</b-list-group-item>
            <b-list-group-item>Historia Médica.: {{ this.request_detail.student_medical_history }}</b-list-group-item>
            <b-list-group-item>Observacion adicional: {{ this.request_detail.request_observation ? this.request_detail.request_observation : "Ninguna" }}</b-list-group-item>

          </b-list-group>
        </b-modal>
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
                label="Filtrar por"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter">Limpiar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-group
                label="Por página"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table
                  id="my-table"
                  show-empty
                  small
                  fixed
                  stacked="md"
                  :items="reqTmp"
                  :fields="field"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  @filtered="onFiltered"
                  :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">


                  <b-button
                      size="sm"
                      variant="outline-info"
                      class="mr-2"
                      @click="view_detail_Representative(row.item)"
                  >
                    <v-icon name="eye"></v-icon>
                  </b-button>


                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";

export default {
  name: "AllRequest",
  data() {
    return {
      isBusy: false,
      id: "",
      items: [],
      item_table: null,
      ttap_selected: null,
      field: [
        {
          key: "rep_names",
          label: "Nombre Representante",
          sortable: true
        },
        {
          key: "rep_phone",
          label: "Teléfono",
          sortable: true
        },
        {
          key: "level_request",
          label: "Grado que Solicita",
          sortable: true
        },
        {
          key: "student_family_school",
          label: "Posee hermanos en la escuela",
          sortable: true
        },

        {
          key: "created_at",
          label: "Registrado",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      req: [],
      reqTmp: [],
      loading: false,
      request_detail: {},
      error: ""
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    },
    rows() {
      return this.reqTmp.length;
    }
  },
  mounted() {
    this.allRepRequest();
  },
  methods: {
    ...mapActions([
      "loadRepresentativeRequest",
      "changeActiveRepresentative",
      "loadData_Representative",
      "deleteRepresentative"
    ]),
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.reqTmp = this.req;
      this.filter = ''
      return true;
    },
    searchInput() {

      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.req.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            studentsTmp.push(el)
          }
        })
        this.reqTmp = studentsTmp;
      } else {
        this.reqTmp = this.req;
      }
      return true;
    },
    allRepRequest() {
      this.isBusy = true
      //let form = {
      // id_school: this.me_rol.id_school
      //};
      this.loadRepresentativeRequest(this.me_rol.id_school)
          .then(response => {
            if (response.code === 200) {
              this.isBusy = false
              this.req = response.data;
              this.reqTmp = response.data;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    view_detail_Representative(row) {
      this.request_detail = row;
      this.$bvModal.show('request_detail_modal')

    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
