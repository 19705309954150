import axios from "axios";
import * as Config from "../../../config";

const state = {
  access: {}
};

const actions = {
  loadAccess() {
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "access/all")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadAccess_Active(form) {
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "access/all/status/" + form.active)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Access({ commit }, data) {
    commit("LOAD_DATA_ACCESS", data);
  },
  newAccess(form) {
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "access/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateAccess(form) {
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + "access/update/" + form.id, form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveAccess(form) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + "access/change_status/" + form.id + "/" + form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteAccess(form) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + "access/change_status/" + form.id + "/" + form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_ACCESS(state, data) {
    state.access = data;
  }
};

export default {
  state,
  actions,
  mutations
};
