import axios from "axios";
import * as Config from "../../../config";

const state = {
  condition: {}
};

const actions = {
  loadCondition({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "eval/student_condition/all")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadCondition_Active({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "eval/student_condition/all/status/" + form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Condition({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_CONDITION", data);
  },
  newCondition({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "eval/student_condition/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateCondition({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + "eval/student_condition/update/" + form.id,
          form
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveCondition({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            "eval/student_condition/change_status/" +
            form.id +
            "/" +
            form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteCondition({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            "eval/student_condition/change_status/" +
            form.id +
            "/" +
            form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_CONDITION(state, data) {
    state.condition = data;
  }
};

export default {
  state,
  actions,
  mutations
};
