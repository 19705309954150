import axios from "axios";
import * as Config from "../../../config";

const state = {
  typeStudent: {}
};

const actions = {
  loadTypeStudent({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "eval/student_type/all")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadTypeStudent_Active({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "eval/student_type/all/status/" + form.active)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_TypeStudent({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_TYPE_STUDENT", data);
  },
  newTypeStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "eval/student_type/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateTypeStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + "eval/student_type/update/" + form.id, form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveTypeStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            "eval/student_type/change_status/" +
            form.id +
            "/" +
            form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTypeStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            "eval/student_type/change_status/" +
            form.id +
            "/" +
            form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_TYPE_STUDENT(state, data) {
    state.typeStudent = data;
  }
};

export default {
  state,
  actions,
  mutations
};
