<template>
  <div id="AllSchools">
    <br/>
    <div class="content">
      <b-card title="Lista de Escuelas">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              A continuación podrás visualizar una lista de los escuelas
              registrados..
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <router-link class="btn btn-outline-primary" :to="{name:'goadmin-school-new'}">Registrar escuelas</router-link>
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col lg="6" class="lmy-1">
            <b-form-group
                label="Filtrar por"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter">Limpiar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="lmy-1">
            <b-form-group
                label="Por página"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-1">
          <b-col sm="12">
            <div>
              <b-table style="font-size:14px"
                       id="my-table"
                       show-empty
                       small
                       hover
                       fixed
                       stacked="md"
                       :filter-debounce="400"
                       primary-key="id"
                       :items="schoolTmp"
                       :fields="field"
                       :current-page="currentPage"
                       :per-page="perPage"

                       :filter-included-fields="filterOn"
                       :sort-by.sync="sortBy"
                       :sort-desc.sync="sortDesc"
                       :sort-direction="sortDirection"
                       @filtered="onFiltered"
                       :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="changeStatus(row.item)"
                  >
                    <v-icon name="check-square" color="#fff"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="changeStatus(row.item)"
                  >
                    <v-icon name="minus-square" color="#fff"/>
                  </b-button>
                  <b-button
                      size="sm"
                      variant="outline-primary"
                      class="mr-2"
                      title=""
                      v-b-tooltip.hover
                      @click="editSchool(row.item)"
                  >
                    <v-icon name="edit" color="#fff"/>
                  </b-button>
                </template>
              </b-table>

            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllSchools",
  data() {
    return {
      isBusy: false,
      items: [],
      item_table: null,
      ttap_selected: null,
      student: {
        user: {
          name: ""
        }
      },
      status_pay_selected: 1,
      status_payments: [
        {value: 1, text: "No Revisado"},
        {value: 2, text: "Insolvente"},
        {value: 3, text: "Solvente"}
      ],

      field: [
         {
          key: "id",
          label: "ID",
          sortable: true
        },
        {
          key: "code",
          label: "Code",
          sortable: true
        },
        {
          key: "name",
          label: "Nombre de la escuela",
          sortable: true
        },
        {
          key: "dni",
          label: "Documento",
          sortable: true
        },
       
        {
          key: "schoolConfig[0].code_inscription",
          label: "Código inscripción",
          sortable: true
        },
        {
          key: "schoolConfig[0].block_unsolvent",
          label: "Bloqueo",
          sortable: true
        },
        {
          key: "token",
          label: "Token de reportes",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20,50,100,500],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      school: [],
      schoolTmp: [],
      loading: false,
      error: ""
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return [];
    },
    rows() {
      return this.schoolTmp.length;
    }
  },
  mounted() {
    this.allSchool();
  },
  methods: {
    ...mapActions([
      "loadSchool",
      "changeActiveSchool",
      "loadData_School",
      "updateSchool",
      "updateStudentPay",
      "set_homeStudent",
      "changeActiveStudent",
      "loadData_Student",
      "deleteStudent"
    ]),
    clearFilter() {
      this.schoolTmp = this.school;
      this.filter = ''
      return true;
    },
    searchInput() {

      let filterTMP = this.filter;
      let schoolTmp = [];
      if (filterTMP) {
        this.school.filter(function (el, i) {
          let cadena = JSON.stringify(el.user);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            schoolTmp.push(el)
          }
        })
        this.schoolTmp = schoolTmp;
      } else {
        this.schoolTmp = this.school;
      }

      return true;

    },
    allSchool() {
      this.isBusy = true
      this.loadSchool()
          .then(response => {
            this.isBusy = false
            if (response.code === 200) {
              this.school = response.data;
              this.schoolTmp = this.school;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    editSchool(data) {
      this.loadData_School(data).then(response => {
        console.log(response)
        this.$router.push("escuela/detalle/" + data.id);
      });
    },
    changeStatus(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 = data.active === 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "¿Estás seguro de desactivarlo ahora?"
              : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            status: active
          };
          this.changeActiveSchool(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    title: title2,
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    delete_Student(row) {
      Swal.fire({
        title: "Eliminar Estudiante",
        text: "¿Estás seguro de eliminar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.deleteStudent(form)
              .then(response => {
                if (response.code === 200) {
                  this.users.splice(row.index, 1);
                  Swal.fire({
                    title: "Estudiante Eliminado",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}

</style>
