import axios from "axios";
import * as Config from "../../../config";

const state = {
    headMasters: {}
};
let mod = 'gen/';

const actions = {
    load_HeadMasters({commit, dispatch}) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + mod+"headmaster/all")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadHeadMasters_School({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + mod+"headmaster/all/school/" + form.id_school)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadData_HeadMasters({commit, dispatch}, data) {
        console.log(dispatch)
        console.log(commit)
        commit("LOAD_DATA_HEAD_MASTERS", data);
    },
    new_HeadMasters({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + mod+"headmaster/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    changeActive_HeadMasters({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + mod+"headmaster/change_status/" + form.id + "/" + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    update_HeadMasters({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + mod+"headmaster/update/" + form.id, form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    delete_HeadMasters({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + mod+"headmaster/change_status/" + form.id + "/" + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};

const mutations = {
    LOAD_DATA_HEAD_MASTERS(state, data) {
        state.HeadMasters = data;
    }
};

export default {
    state,
    actions,
    mutations
};
