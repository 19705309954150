<template>
  <div id="AllConsult">
    <br />
    <div class="content">
      <b-card title="Consultas">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>Las consultas permiten que los representantes, y usuarios puedan votar, y tomar decisiones basados en estos resultados</p>
          </b-col>
          <b-col md="2" style="text-align: -webkit-right">
            <b-button v-b-modal.modalConsult>Crear nueva consulta</b-button>
          </b-col>
          <b-col md="2" style="text-align: -webkit-right">
            <h5>Consultas Creadas: {{notifications_count}}</h5>

            <b-modal id="modalConsult" hide-footer title="Crear nueva consulta">

                 <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"

                @submit.prevent="onSubmitConsult"
              >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Título" label-for="title">
                    <b-form-input id="title" v-model="title" name="title" placeholder></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Descripción" label-for="description">
                    <b-form-input id="description" v-model="description" name="description" placeholder></b-form-input>
                  </b-form-group>
                 
                </b-col>
                                <b-col md="12">
                 <b-button class="mt-3" variant="outline-primary" type="submit" pill block>Guardar</b-button>
                                </b-col>
              </b-row>
                 </form>
            </b-modal>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
                label="Filtrar por"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter">Limpiar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="my-1">
            <b-form-group
              label="Por página"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                :primary-key="id"
                :items="notificationsTMP"
                :fields="field"
                :current-page="currentPage"
                :per-page="perPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.active === 1"
                    size="sm"
                    variant="outline-success"
                    class="mr-2"
                    title="Activado"
                    @click="changeStatus(row.item)"
                  >
                    <v-icon name="check-square" />
                  </b-button>
                  <b-button
                    v-if="row.item.active === 0"
                    size="sm"
                    variant="outline-warning"
                    class="mr-2"
                    title="Desactivado"
                    @click="changeStatus(row.item)"
                  >
                    <v-icon name="minus-square" />
                  </b-button>

                 <!-- <b-button
                    size="sm"
                    variant="outline-info"
                    class="mr-2"
                    @click="edit_Consult(row.item)"
                  >
                    <v-icon name="edit" />
                  </b-button>-->

                  <b-button
                    size="sm"
                    variant="outline-danger"
                    class="mr-2"
                    @click="deleteConsult(row)"
                  >
                    <v-icon name="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "AllConsult",
  data() {
    return {
      notifications_count: 0,
      isBusy: false,
      id: "",
      items: [],
      item_table: null,
      ttap_selected: null,
      field: [
        {
          key: "title",
          label: "Título",
          sortable: true
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true
        },
         {
          key: "vote_yes",
          label: "Votos - Si",
          sortable: true
        },
         {
          key: "vote_no",
          label: "Votos - No",
          sortable: true
        },
       

        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      title:'',
      description:'',
      type:0,
      types:[
          {
              id:0,
              name:'Públicas - Visibles en el sitio web',
          },
           {
              id:1,
              name:'Visible a Estudiantes',
          }, {
              id:2,
              name:'Visible solo a Profesores',
          }, {
              id:3,
              name:'Visible a Representantes',
          }, {
              id:4,
              name:'Visible a Personal Administrativo',
          }
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      notifications: [],
      notificationsTMP: [],
      loading: false,
      error: ""
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.notificationsTMP.length;
    }
  },
  mounted() {
    this.allConsults();
  },
  methods: {
    ...mapActions([
      "load_Consult",
      "changeActiveConsult",
      "loadData_Consult",
      "delete_Consult",
      "new_Consult"
    ]),
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.reqTmp = this.req;
      this.filter = ''
      return true;
    },
    searchInput() {

      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.notifications.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            studentsTmp.push(el)
          }
        })
        this.notificationsTMP = studentsTmp;
      } else {
        this.notificationsTmp = this.notifications;
      }
      return true;
    },
    allConsults() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        rol: 99
      };
      this.load_Consult(form)
        .then(response => {
          if (response.code === 200) {
            this.isBusy = false;
            this.notifications = response.data;
            this.notificationsTMP = response.data;
            console.log(this.notifications.length);
            this.notifications_count = this.notifications.length;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    edit_Consult(data) {
      this.loadData_Consult(data).then(response => {
        console.log(response);
        this.$router.push("/director/representante/editar/" + data.id);
        //  this.$router.push({ name: "uecamag" });
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de desactivarlo ahora?"
          : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeActiveConsult(form)
            .then(response => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success"
                });
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
    deleteConsult(row) {
      Swal.fire({
        title: "Eliminar Consulta",
        text: "¿Estás seguro de eliminar esta consulta?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.delete_Consult(form)
            .then(response => {
              if (response.code === 200) {
                this.notifications.splice(row.index, 1);
                Swal.fire({
                  title: "Consulta Eliminada",
                  icon: "success"
                });
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
     onSubmitConsult() {
     if (this.title == "") {
        Swal.fire({
          title: "Escribe un título",
          text:
            "Asegurate de escribir un título y una descripción",
          icon: "warning"
        });
      } else {
        this.loading = true;

        let form = {
            id_school:this.me_rol.id_school,
          title: this.title,
          description: this.description,         
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro guardar esta consulta?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar"
        }).then(result => {
          if (result.value) {
            this.new_Consult(form)
              .then(response => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La consulta ha sido registrada correctamente, ahora los representantes podran votar sobre esta consulta",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2);
                    this.allConsults()
                  
                    this.$bvModal.hide('modalConsult')
                  });
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
