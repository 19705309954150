<template>
  <div id="DetailTeacher">
    <br />
    <div class="content">
      <b-card :title="'Detalle del Profesor: '+this.teacher.user.first_name+' '+this.teacher.user.last_name">
        <b-row>
          <b-col md="6" style="text-align: -webkit-left">
            <p>A continuación podrá ver todo el detalle del profesor, y modificar su información</p>
          </b-col>
          <b-col md="3" style="text-align: -webkit-right">
             <a v-if="image" :href="image" target="a_blank"><img class="img-fluid" style="max-width:100px" :src="image"></a>
             
               </b-col>
          <b-col md="3" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-evaluations-all'}"
            >Volver a evaluaciones</router-link>
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Datos Personales" active>
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
                @submit.prevent="onSubmitPersonal"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nombre" label-for="first_name">
                      <b-form-input
                        id="first_name"
                        v-model="first_name"
                        
                        name="first_name"
                        placeholder="Ingrese Nombre"
                      ></b-form-input>
                    
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Apellido" label-for="last_name">
                      <b-form-input
                        id="last_name"
                        v-model="last_name"
                        
                        name="last_name"
                        placeholder="Ingrese Apellido"
                      ></b-form-input>
                     
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Cedula" label-for="dni">
                      <b-form-input
                        id="dni"
                        v-model="dni"
                        
                        name="dni"
                        placeholder="DNI"
                      ></b-form-input>
                    
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Fecha de Nacimiento" label-for="birthday">
                      <date-picker
                        id="birthday"
                        name="birthday"
                        mode="single"
                        :max-date="new Date()"
                        
                        v-model="birthday"
                        :popover="{ placement: 'bottom', visibility: 'click' }"
                      />
                  
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Teléfono" label-for="phone">
                      <b-form-input
                        id="phone"
                        v-model="phone"
                       
                        name="phone"
                        placeholder="Teléfono"
                      ></b-form-input>
                
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4">
                    <b-form-group label="Correo" label-for="email">
                      <b-form-input
                        id="email"
                        v-model="email"
                        
                        name="email"
                        placeholder="Correo"
                      ></b-form-input>
                
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Tipo de Sangre</div>
                        <b-form-select
                          v-model="blood_type"
                          :options="bloodTypeArray"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3">Modificar</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Datos de Profesor" active>
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
                @submit.prevent="onSubmitTeacher"
              >
                <b-row>
                  <b-col md="4">
                      <b-form-group label="Estudios" label-for="security_health">
                     
                            <b-form-input
                        id="studies"
                        v-model="studies"
                       
                        name="studies"
                       
                      ></b-form-input>
                    </b-form-group>
                   
                  </b-col>
                  <b-col md="4">
                     <b-form-group label="Habilidades" label-for="security_health">
                     
                            <b-form-input
                        id="skill1"
                        v-model="skill1"
                       
                        name="skill1"
                        
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Otras habilidades" label-for="security_health">
                     
                            <b-form-input
                        id="skill2"
                        v-model="skill2"
                       
                        name="skill2"
                      
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                   
                  </b-col>
                  <b-col md="4">
                   
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4">
                  
                  </b-col>
                  <b-col md="4">
                   
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3">Modificar</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Credenciales">
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
                @submit.prevent="onSubmitPassword"
              >
                <b-row>
                  <b-col md="12">
                    <p>Desde este formulario puede actualizar la clave de acceso del representante a la plataforma</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Contraseña" label-for="password">
                      <b-form-input
                        id="password"
                        v-model="password"
                        
                        name="password"
                        placeholder
                      ></b-form-input>
                   
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Repita la contraseña" label-for="password_confirm">
                      <b-form-input
                        id="password_confirm"
                        v-model="password_confirm"
                        
                        name="password_confirm"
                        placeholder
                      ></b-form-input>
                   
                    </b-form-group>
                  </b-col>

                  <b-col md="12 text-right">
                    <b-button
                      type="submit"
                      variant="outline-success"
                      class="m-3"
                    >Modificar acceso de profesor</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Estadísticas">
              <b-row>
                <b-col>
                  
                    <b-card title="Materias y estudiantes">
                      <b-row>
                        <b-col md="12">
                          
                          <p>
                            <strong>Materias asignadas: </strong
                            >{{ stats.subject_count }} materias.
                          </p>

                          <p>
                            <strong>Cantidad de estudiantes: </strong
                            >{{ stats.student_count}} estudiantes.
                          </p>

                    
                        </b-col>
                      </b-row>
                    </b-card>
              
                </b-col>
                <b-col>
                  
                    <b-card title="Evaluaciones">
                      <b-row>
                        <b-col md="12">
                          <p><strong>Cantidad de evaluaciones:</strong></p>
                          <p>
                            <strong>Tareas: </strong
                            >{{ stats.evaluation_count_task }} subidas.
                          </p>

                          <p>
                            <strong>Examenes: </strong
                            >{{ stats.evaluation_count_exam }} subidas.
                          </p>

                          <p>
                            <strong>Total actividades: </strong
                            >{{ stats.evaluation_count_class }} realizadas.
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
                  
                </b-col>
                <b-col>
                  
                    <b-card title="Mensajes enviados">
                      <b-row>
                        <b-col md="12">
                          <p><strong>Cantidad de mensajes:</strong></p>
                          <p>
                            <strong>En clases: </strong
                            >{{ stats.count_room_message }} enviados.
                          </p>

                          <p>
                            <strong>En grupos: </strong
                            >{{ stats.count_room_group_message }} enviados.
                          </p>

                          <p>
                            <strong>Chat privados: </strong
                            >{{ stats.count_private_message }} enviados.
                          </p>
                        </b-col>
                      </b-row>
                    </b-card>
              
                </b-col>
                
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
import Moment from "moment";
export default {
  name: "DetailTeacher",
  data() {
    return {
      //USER DATA
      first_name: "",
      last_name: "",
      dni: "",
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      blood_type: 1,
      bloodTypeArray: [],
      condition:1,
      conditionList:[],
      typeTeacher:1,
      typeTeacherList:[],
      section:1,
      sectionList:[],
      studies:"",
      skill1:"",
      skill2:"",
      stats: [],
      image:"",
      //REPRESENTATIVE DATA
      commercial_activity:"",
      address_home:"",
      address_office:"",
      profession:"",
      id_user:"",
      id_student:"",
      //PASSWORD
    
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: new Date(),
      isM: "",
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true
        }
      }
    };
  },
  computed: {
    ...mapState({
      teacher: state => state.teacher.teacher,
      me_rol: state=> state.auth.me_rol
    }),
    sortOptions() {
      return this.field
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    this.allStats();
    this.allSelects();
    this.teacherLoad();
  },
  methods: {
    ...mapActions([
      "updateTeacher",
      "updateUser",
      "loadBlood_Active",
      "loadCondition",
      "addToastMessage",
      "loadTypeTeacher",
      "loadSection_school",
      "loadTeacher_Stats"
    ]),
    allSelects() {
      let form = {
        active: 1
      };

      this.loadBlood_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.bloodTypeArray = response.data;
          }
        })
        .catch(error => {console.log(error)});
          this.loadCondition(form)
        .then(response => {
          if (response.code === 200) {
            this.conditionList = response.data;
          }
        })
        .catch(error => {console.log(error)});
        
        let form2 = {
          id_school:this.me_rol.id_school
        }
          this.loadSection_school(form2)
        .then(response => {
          if (response.code === 200) {
            this.sectionList = response.data;
          }
        })
        .catch(error => {console.log(error)});
        
    },
    allStats() {
      this.loadTeacher_Stats(this.teacher.id)
        .then((response) => {
          console.log(response);
          if (response.code == 200) {
            this.stats = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    teacherLoad() {
      if (!this.teacher.id) {
        this.$router.push("/director/evaluaciones");
      }
      console.log(this.teacher);
      this.id = this.teacher.id;
      this.id_teacher = this.teacher.id;
      this.id_user = this.teacher.user.id;

      this.first_name = this.teacher.user.first_name;
      this.username = this.teacher.user.username;
      this.last_name = this.teacher.user.last_name;
      this.dni = this.teacher.user.dni;
      if (this.teacher.user.birthday) {
        this.birthday = new Date(this.teacher.user.birthday);
      } else {
        this.birthday = new Date();
      }

      this.email = this.teacher.user.email;
      this.image = this.teacher.user.image;
      this.phone = this.teacher.user.phone;
      this.blood_type = this.teacher.user.id_blood_type;
      //STUDENT DATA
      this.studies = this.teacher.studies;
      this.skill1 = this.teacher.skill1;
      this.skill2 = this.teacher.skill2;
    
      //*/

    },
    onSubmitPassword() {
      console.log('Updating Password')
      if (this.password_confirm != this.password) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning"
        });
      } else if (this.password == "") {
        Swal.fire({
          title: "Escribe una contraseña",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning"
        });
      } else {
        this.loading = true;

        let form = {
          password: this.password,
           id_user: this.id_user
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás aeguro de cambiar la contraseña de este profesor?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar"
        }).then(result => {
          if (result.value) {
            this.updateUser(form)
              .then(response => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La contraseña del profesor ha sido actualizada",
                    icon: "success"
                  }).then(result2 => {
                      console.log(result2)
                    this.$router.push({
                      name: "director-evaluations-all"
                    });
                  });
                }
              })
              .catch(error => {console.log(error)});
          } else {
            this.loading = false;
          }
        });
      }
    },
    onSubmitPersonal() {
    

          let form = {
            first_name: this.first_name,
            last_name: this.last_name,
            dni: this.dni,
            birthday: Moment(this.birthday).format("YYYY-MM-DD"),
            email: this.email,
            password: this.password,
            phone: this.phone,
            id_blood_type: this.blood_type,
            id_user: this.id_user
          };

          Swal.fire({
            title: "Confirmación",
            text: "¿Estás seguro de actualizar a este profesor?",
            icon: "question",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Actualizar"
          }).then(result => {
            if (result.value) {
              this.updateUser(form)
                .then(response => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "El profesor ha sido actualizado",
                      icon: "success"
                    }).then(result2 => {
                        console.log(result2)
                      this.$router.push({
                        name: "director-evaluations-all"
                      });
                    });
                  }
                })
                .catch(error => {console.log(error)});
            } else {
              this.loading = false;
            }
         
       
      });
    },
     onSubmitTeacher() {
    
          this.loading = true;

          let form = {
            skill1: this.skill1,
            skill2: this.skill2,
            studies: this.studies,
           id_user:this.id_user,
           id_school: this.me_rol.id_school,
            id: this.id_teacher
          };

          Swal.fire({
            title: "Confirmación",
            text: "¿Estás seguro de actualizar a este profesor?",
            icon: "question",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Actualizar"
          }).then(result => {
            if (result.value) {
              this.updateTeacher(form)
                .then(response => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "El profesor ha sido actualizado",
                      icon: "success"
                    }).then(result2 => {
                        console.log(result2)
                      this.$router.push({
                        name: "director-evaluations-all"
                      });
                    });
                  }
                })
                .catch(error => {console.log(error)});
            } else {
              this.loading = false;
            }
          });
        
      
    }
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
