<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1" v-for="ts in tasks" :key="ts.id">
      <b-card-header
        header-tag="header"
        class="p-1"
        role="tab"
        @click="showDetail(ts)"
      >
        <b-row>
          <b-col sm="7">
            <p
              v-b-toggle="'collapse-' + ts.id"
              variant="outline-info"
              style="padding: 5px"
              v-if="ts.user"
            >
              {{ ts.user.full_name }}
            </p>
          </b-col>
          <b-col sm="2">
            <p style="font-size: 10px">Archivos</p>
             <p style="font-size: 22px"> <strong>{{ ts.documents_count }}</strong></p>
           
          </b-col>
          <b-col sm="2">
            <p style="font-size: 10px">Nota</p>
              <p style="font-size: 22px"><strong> {{ ts.grade_result }}</strong></p>
          </b-col>
        </b-row>
      </b-card-header>
      <!--  <b-collapse :id="'collapse-'+ts.id" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
           
          </b-card-text>
        </b-card-body>
      </b-collapse> -->
    </b-card>

    <b-modal
      v-model="modalShow"
      size="lg"
      ref="my-modal"
      hide-footer
    
    >
      <div class="d-block text-center">
        <b-row>
          <b-col md="12">
             <b-list-group>
                  <b-list-group-item>
                  <h4 v-if="task_detail.user">  Alumno: {{task_detail.user.full_name}}</h4>
                  </b-list-group-item>
              <b-list-group-item>
                <b-row>
              <b-col md="3">
                <p>Nota de Alumno en esta evaluación</p>
              </b-col>
              <b-col md="2">
                <b-form>
                  <b-form-input
                    min="0"
                    max="100"
                    id="inline-form-input-name"
                    type="number"
                    v-model="task_detail.grade_result"
                    name="first_name"
                  />
                </b-form>
              </b-col>
              <b-col md="4">
                <b-textarea
                  id="inline-form-input-name"
                  type="number"
                  placeholder="Feedback General"
                  v-model="task_detail.grade_feedback"
                  name="first_name"
                />
              </b-col>
              <b-col md="3">
                <b-button
                  @click="saveResultEvaluation(task_detail)"
                  variant="outline-primary"
                  >Guardar Nota <v-icon name="save"></v-icon
                ></b-button>
              </b-col>
            </b-row>
              </b-list-group-item>
             </b-list-group>

            
            <br />
            <br />
            <b-list-group>
              <b-list-group-item>
                <p>
                  <strong>{{ archive_label }}</strong>
                </p>
              </b-list-group-item>
              <b-list-group-item
                v-for="task in task_detail.documents"
                :key="task.id"
              >
                <b-row>
                  <b-col md="8">
                    <p><strong>Título:</strong> {{ task.name }}</p>
                    <a :href="task.url" target="a_blank">Ver Archivo</a>
                    <p style="font-size: 9px; color: gray">
                      <strong>Enviado:</strong> {{ task.created_at }}
                    </p>
                  </b-col>
                  <b-col md="4">
                    <b-textarea v-model="task.feedback"></b-textarea>
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="setFeedbackToEvaluationDoc(task)"
                      >Dar retroalimentación</b-button
                    >
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
      <script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
import { integer } from "vee-validate/dist/rules";
export default {
  //props: ["messages", "room"],
  name: "TasksEvaluations",
  data() {
    return {
      evaluations: [],
      modalShow: false,
      archive_label: "",
      task_detail: {
        user: {
          full_name: "",
        },
      },
      tasks: [],
    };
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluation.evaluation,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.getTasks();
    console.log("MOUNTING");
  },
  methods: {
    ...mapActions([
      "new_Message",
      "loadEvaluation_Messages",
      "delete_Chat_Message",
      "loadEvaluation_Tasks_teacher",
      "setFeedback_evaluation_doc",
      "saveEvaluation_result",
    ]),
    getTasks() {
      //GET TASKS BY EVALUATION
      let form = {
        id_evaluation: this.evaluation.id,
      };
      this.loadEvaluation_Tasks_teacher(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            this.tasks = resp;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setFeedbackToEvaluationDoc(task) {
      console.log("GETTIN TASKS");
      //GET TASKS BY EVALUATION
      let form = {
        id_evaluation_doc: task.id,
        feedback: task.feedback,
      };
      this.setFeedback_evaluation_doc(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            this.tasks = resp;

            this.$bvToast.toast("Retroalimentación enviada", {
              title: "Guardado correctamente",
              type: "success",
              autoHideDelay: 5000,
              // appendToast: append
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveResultEvaluation(task_detail) {
      console.log("SAVING TASKS RESULT");
      //GET TASKS BY EVALUATION
      let form = {
        id_evaluation: this.evaluation.id,
        id_student: task_detail.id,
        result: task_detail.grade_result,
        feedback: task_detail.grade_feedback,
      };
      this.saveEvaluation_result(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;

            this.getTasks();

            this.$bvToast.toast("Nota asignada al estudiante", {
              title: "Guardado correctamente",
              variant: "success",
              autoHideDelay: 5000,
              // appendToast: append
            });
            this.modalShow = false;
          }
          if (response.code === 201) {
            let resp = response.data;

            this.getTasks();

            this.$bvToast.toast("Nota asignada al estudiante", {
              title: "Actualizado correctamente",
              variant: "success",
              autoHideDelay: 5000,
              // appendToast: append
            });
            this.modalShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showDetail(task) {
      this.modalShow = true;
      this.task_detail = task;

      console.log("TASK LENGHT:" + Object.keys(task.documents).length);
      if (Object.keys(task.documents).length > 0) {
        this.archive_label = "Archivos de este estudiante";
      } else {
        this.archive_label = "No hay documentos asociados a este estudiante";
      }
    },
    scrollToEnd: function () {
      var content = this.$refs.container;
      console.log(content);
      console.log("scrollTop:" + content.scrollTop);
      if (content.scrollHeight > content.scrollTop) {
        content.scrollTop = content.scrollHeight;
      }
    },
  },
};
</script>

      
  <style scoped>
body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}
.container {
  overflow-y: scroll;
}
.chat_window {
  position: relative;
  width: calc(100% - 20px);
  max-width: 800px;
  height: 650px;
  margin-top: -30px;
  border-radius: 10px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  overflow: hidden;
}

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 15px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}
.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}
.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.top_menu .buttons .button.close {
  background-color: #002d4a;
}
.top_menu .buttons .button.minimize {
  background-color: #d4ffe0;
}
.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}
.top_menu .title {
  text-align: center;
  color: #bcbdc0;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  padding: 20px 10px 0 10px;
  margin: 0;
  height: 570px;
  overflow: scroll;
}
.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.messages .message.left .text_wrapper {
  background-color: #ffffff;
  margin-left: 20px;
}
.messages .message.left .text_wrapper::after,
.messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}
.messages .message.left .text {
  color: #000000;
}
.messages .message.right .avatar {
  background-color: #012a82;
  float: right;
}
.messages .message.right .text_wrapper {
  background-color: #ffffff;
  margin-right: 20px;
  float: right;
}
.messages .message.right .text_wrapper::after,
.messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #ffffff;
}
.messages .message.right .text {
  color: #000000;
}
.messages .message.appeared {
  opacity: 1;
}
.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}
.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}
.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}

.bottom_wrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  position: absolute;
  bottom: 0;
}
.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}
.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
}
.bottom_wrapper .send_message {
  width: 70px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
}
.bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #fff;
}
.bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}

.message_template {
  display: none;
}
</style>