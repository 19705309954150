<template>
  <div id="newUser">
    <br/>
    <div class="content">
      <b-card title="Nuevo Usuario">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>A continuación podrás agregar la información del usuario</p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <router-link
                class="btn btn-outline-primary"
                :to="{name:'goadmin-user-all'}"
            >Listado de Usuarios
            </router-link>
          </b-col>
        </b-row>
        <br/>
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Datos Personales" active>
              <form
                  v-if="!loading"
                  style="width: 100%!important;"
                  method="post"
                  @submit.prevent="onSubmitUser()"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nombre" label-for="first_name">
                      <b-form-input
                          id="first_name"
                          v-model="first_name"
                          name="first_name"
                          placeholder="Ingrese Nombre"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Apellido" label-for="last_name">
                      <b-form-input
                          id="last_name"
                          v-model="last_name"
                          required
                          name="last_name"
                          placeholder="Ingrese Apellido"
                      ></b-form-input>

                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <label for="dni">Cedula</label>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-select
                            v-model="prefix_dni"
                              :options="[{id:'V-',name:'V'},{id:'J-',name:'J'}]"
                              value-field="id"
                              text-field="name"
                          ></b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                            id="dni"
                            v-model="dni"
                            required
                            type="number"
                            name="dni"
                            placeholder="DNI"
                        ></b-form-input>
                    </b-input-group>                    
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Fecha de Nacimiento" label-for="birthday">
                      <date-picker
                          id="birthday"
                          name="birthday"
                          mode="single"
                          :max-date="new Date()"
                          v-model="birthday"
                          :popover="{ placement: 'bottom', visibility: 'click' }"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Teléfono" label-for="phone">
                      <b-form-input
                          id="phone"
                          v-model="phone"
                          name="phone"
                          required
                          type="number"
                          placeholder="Teléfono"
                      ></b-form-input>

                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Correo" label-for="email">
                      <b-form-input
                          id="email"
                          v-model="email"
                          required
                          type="email"
                          name="email"
                          placeholder="Correo"
                      ></b-form-input>

                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Tipo de Sangre</div>
                        <b-form-select
                            v-model="blood_type"
                            :options="bloodTypeArray"
                            value-field="id"
                            text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3">Registar</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Credenciales" disabled>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex';
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
import Moment from "moment";

export default {
  name: "newUser",
  data() {
    return {
      //USER DATA
      first_name: "",
      last_name: "",
      dni: "",
      prefix_dni:"V-",
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      blood_type: 1,
      bloodTypeArray: [],
      condition: 1,
      conditionList: [],
      typeStudent: 1,
      typeStudentList: [],
      section: 1,
      sectionList: [],
      security_health: "",
      //REPRESENTATIVE DATA
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      id: "",
      id_student: "",
      //PASSWORD
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: new Date(),
      isM: "",
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true
        }
      }
    };
  },
  computed: {
    ...mapState({
      userEdit: state => state.users.user,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  mounted() {
    this.allSelects();
  },
  methods: {
    ...mapActions([
      "updateStudent",
      "newUser",
      "loadBlood_Active",
      "loadCondition",
      "addToastMessage",
      "addToastMessageArray",
      "loadTypeStudent",
      "loadSection_school"
    ]),
    allSelects() {
      let form = {
        active: 1
      };

      this.loadBlood_Active(form)
          .then(response => {
            if (response.code === 200) {
              this.bloodTypeArray = response.data;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    onSubmitUser() {

      if (this.first_name == ' ' || this.last_name == ' ' || this.dni == ' ' || this.phone == ' ') {
        this.addToastMessage({
          text: "No puedes dejar campos vacios",
          type: "warning",
        })
        return false
      }
      let form = {
        first_name: this.first_name,
        last_name: this.last_name,
        dni: this.prefix_dni+''+this.dni,
        birthday: Moment(this.birthday).format("YYYY-MM-DD"),
        email: this.email,
        phone: this.phone,
        id_blood_type: this.blood_type,
      };
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de registrar a este Usuario?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Registrar"
      }).then(result => {
        if (result.value) {
          this.newUser(form)
              .then(response => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "El Usuario ha sido registrado",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2)
                    this.$router.push({name: "goadmin-user-all"});
                  });
                } else {
                  let respo = response.data
                  this.addToastMessageArray({
                    array: respo,
                    type: "info",
                  })
                }

              })
              .catch(error => {
                console.log(error)
              });
        } else {
          this.loading = false;
        }

      });

    },
    onSubmitStudent() {

      this.loading = true;

      let form = {
        id_condition: this.condition,
        id_student_type: this.typeStudent,
        security_health: this.security_health,
        id_section: this.section,
        id_school: this.me_rol.id_school,
        id_user: this.id_user,
        id: this.id_student
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar"
      }).then(result => {
        if (result.value) {
          this.updateStudent(form)
              .then(response => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "El Estudiante ha sido actualizado",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2)
                    this.$router.push({
                      name: "director-students-all"
                    });
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        } else {
          this.loading = false;
        }
      });


    }
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
