<template>
  <div
    style="
      background-image: url('https://apischool.ticktap.com.ve/file/1-1-1-20203105231857.jpg');
    "
  >
    <div class="col-md-12">
      <br />
      <br />
      <div class="row">
        <img
          class="img-responsive center-block d-block mx-auto"
          style="margin-left: auto; max-width: 200px"
          src="../../../assets/img/logonew.png"
        />
      </div>
      <b-row>
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <br />
          <h3 v-if="step == 1 || step == 0" class="text-center">
            Bienvenido al Registro de Clasy
          </h3>
          <!-- <div class="row">
            <b-button type="button" variant="outline-warning" class="m-3" v-on:click="step -= 1">Atras</b-button>
            <b-button type="button" variant="outline-success" class="m-3" v-on:click="step += 1">Siguiente</b-button>
          </div>-->

          <div class="row">
            <b-col md="2"> </b-col>
            <b-card class="col-md-8">
              <b-row v-if="step == 0">
                <b-col md="12">
                  <br />
                  <h2 class="text-center"><strong>Paso 1</strong></h2>
                  <p class="text-center">
                    Seleccione el rol con el que se desea registrar en Clasy
                  </p>
                  <b-row>
                    <b-col md="5"> </b-col>
                    <b-col md="2">
                      <b-spinner
                        style="width: 3rem; height: 3rem"
                        v-if="step == 0"
                        variant="outline-primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"> </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <br />
                      <b-button
                        type="button"
                        :disabled="progress == true"
                        pill
                        block
                        size="lg"
                        v-on:click="rol = 1; checkRol()"
                        variant="outline-info"
                      >
                        <b-spinner v-if="progress"></b-spinner>
                        <span v-if="!progress"> Representante </span>

                        <v-icon v-if="!progress" name="angle-right" />
                      </b-button>
                      <br />
                      <b-button
                        type="button"
                        :disabled="progress == true"
                        pill
                        block
                        size="lg"
                        v-on:click="rol = 2; checkRol()"
                        variant="outline-warning"
                      >
                        <b-spinner v-if="progress"></b-spinner>
                        <span v-if="!progress"> Estudiante </span>

                        <v-icon v-if="!progress" name="angle-right" />
                      </b-button>
                      <br />
                      <b-button
                        type="button"
                        :disabled="progress == true"
                        pill
                        block
                        size="lg"
                        v-on:click="rol = 3; checkRol()"
                        variant="outline-success"
                      >
                        <b-spinner v-if="progress"></b-spinner>
                        <span v-if="!progress"> Profesor </span>

                        <v-icon v-if="!progress" name="angle-right" />
                      </b-button>
                      <br />
                    </b-col>
                  </b-row>

                  <br />
                </b-col>
              </b-row>
              <b-row v-if="step == 1">
                <b-col md="12">
                  <br />
                  <h2 class="text-center"><strong>Paso 2</strong></h2>
                  <p class="text-center">
                    Por favor, coloca el código de inscripción de la escuela 
                  </p>
                  <b-row>
                    <b-col md="5"> </b-col>
                    <b-col md="2">
                      <b-spinner
                        style="width: 3rem; height: 3rem"
                        v-if="step == 1"
                        variant="outline-primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"> </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <br />
                      <b-input-group size="lg" prepend="Código">
                        <b-form-input type="text" v-model="code"></b-form-input>
                      </b-input-group>
                      <small>Si no tienes el código de inscripción debes consultarlo con la administración de la escuela </small>
                      <br />
                      <br />
                      <b-button
                        type="button"
                        :disabled="progress == true"
                        pill
                        block
                        size="lg"
                        v-on:click="checkCode()"
                        variant="outline-primary"
                      >
                        <b-spinner v-if="progress"></b-spinner>
                        <span v-if="!progress"> Continuar </span>

                        <v-icon v-if="!progress" name="angle-right" />
                      </b-button>
                      <br />
                    </b-col>
                  </b-row>

                  <br />
                </b-col>
              </b-row>
              <div v-if="step == 2">
                <h2 class="text-center"><strong>Paso 3</strong></h2>
                <form class="w-100 p-2" method="post">
                  <div class="row">
                    <h3 class="col-md-12 text-center">
                     Bievenido a la Escuela: {{ school.name }}
                    </h3>
                  </div>
                  <div class="row">
                    <br>
                    <h4 class="col-md-12 text-center">
                      Indica tu correo como representante
                    </h4>
                    <br>

                    
                  </div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label-for="email" class="text-center">
                        <b-input-group size="lg" prepend="Correo">
                          <b-form-input
                            id="email"
                            v-model="email"
                            name="email"
                            placeholder="Ingrese su correo de Representante"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <br />

                      <b-button
                        type="button"
                        v-on:click="findRepresent()"
                        pill
                        block
                        :disabled="progress == true"
                        size="lg"
                        variant="outline-success"
                      >
                        <b-spinner v-if="progress"></b-spinner>
                        <v-icon name="search" v-if="!progress"></v-icon>
                        <span v-if="!progress">
                          Continuar y buscar correo de representante</span
                        >
                      </b-button>
                      <br />
                      <b-button
                        type="button"
                        v-on:click="step -= 1"
                        pill
                        block
                        size="lg"
                        variant="outline-primary"
                      >
                        <v-icon name="angle-left" />
                        Atras
                      </b-button>
                      <br />
                      <small class="col-md-12 text-center">
                      Recuerda que para iniciar con Clasy, primero debes
                      registrarte como representante. En este registro vas a poder hacerlo, y luego podrás agregar a tu representado como estudiante.
                    </small>
                      <br />
                      <br />
                      <router-link to="/login" class=""
                        ><v-icon name="home"></v-icon> Ya tengo cuenta, iniciar
                        sesión</router-link
                      >
                    </b-col>
                  </b-row>
                </form>
              </div>

              <div v-if="step == 3">
                <h2 class="text-center"><strong>Paso 4</strong></h2>
                <b-row class="center">
                  <h4 class="col-md-12 text-center">Datos de Representante</h4>

                  <b-row>
                    <br />
                    <b-col md="12">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="Nombre" label-for="first_name">
                            <b-form-input
                              id="first_name"
                              v-model="first_name"
                              name="first_name"
                              placeholder="Ingrese Nombre"
                            />
                          </b-form-group>
                          <b-form-group label="Apellido" label-for="last_name">
                            <b-form-input
                              id="last_name"
                              v-model="last_name"
                              name="last_name"
                              placeholder="Ingrese Apellido"
                            />
                          </b-form-group>
                          <b-form-group label="Cédula" label-for="dni">
                            <b-input-group>
                              <template v-slot:prepend>
                                <b-form-select
                                  value-field="id"
                                  text-field="name"
                                  v-model="dni_selected"
                                  :options="dnis"
                                ></b-form-select>
                              </template>
                              <b-form-input
                                id="dni"
                                v-model="dni"
                                name="dni"
                                type="number"

                                :formatter="formatNumber"
                                placeholder="Solo Números"
                              ></b-form-input>
                            </b-input-group>
                          </b-form-group>
                          <b-form-group label="Teléfono" label-for="phone">
                            <b-form-input
                              id="phone"
                              v-model="phone"
                              name="phone"
                              type="number"
                              placeholder="Teléfono"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="Fecha de Nacimiento"
                            label-for="birthday"
                          >
                            <date-picker
                              id="birthday"
                              name="birthday"
                              mode="single"
                              :max-date="new Date()"
                              v-model="birthday"
                              :popover="{
                                placement: 'bottom',
                                visibility: 'click',
                              }"
                            />
                          </b-form-group>
                          <b-form-group>
                            <div>
                              <div class="mb-2">Género</div>
                              <b-form-select
                                v-model="gender"
                                :options="genderList"
                                value-field="id"
                                text-field="name"
                              ></b-form-select>
                            </div>
                          </b-form-group>
                          <b-form-group>
                            <div>
                              <div class="mb-2">Tipo de Sangre</div>
                              <b-form-select
                                v-model="blood_type"
                                :options="type_blood_list"
                                value-field="id"
                                text-field="name"
                              ></b-form-select>
                              <p style="font-size: 10px">
                                El tipo de sangre es un valor informativo, que
                                le sirve a la escuela ante cualquier emergencia
                              </p>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr style="width: 100%" />
                       <b-row>
                       <b-col md="6">
                          <b-form-group
                            label="Actividad Comercial"
                            label-for="commercial_activity"
                            
                          >
                            <b-form-input
                            
                              id="commercial_activity"
                              v-model="commercial_activity"
                              name="commercial_activity"
                              placeholder="Ingrese su actividad comercial"
                            />
                          </b-form-group>
                          <b-form-group
                            label="Dirección de Hogar"
                            label-for="address_home"
                           
                          >
                            <b-form-textarea
                              id="address_home"
                              v-model="address_home"
                              name="address_home"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="Dirección de Oficina"
                            label-for="address_office"
                           
                          >
                            <b-form-textarea
                              id="address_office"
                              v-model="address_office"
                              name="address_office"
                            />
                          </b-form-group>
                          <b-form-group
                            label="Profesión"
                            label-for="email"
                           
                          >
                            <b-form-input
                              id="profession"
                              v-model="profession"
                              name="profession"
                            />
                          </b-form-group>
                        </b-col>
                       </b-row>
                    </b-col>
                  </b-row>
                  
                  <b-row>
                    <br />
                    <b-col md="12">
                     
                       
                    
                    </b-col>
                    <br />

                 
                  </b-row>
                

                  <b-row class="col-md-12">
                    <b-col md="12">
                      <br />
                      <b-button
                        type="button"
                        v-on:click="nextStepRegister()"
                        pill
                        block
                        size="lg"
                        variant="outline-success"
                      >
                        Continuar Registro
                        <v-icon name="angle-right" />
                      </b-button>
                      <br />
                      <b-button
                        type="button"
                        v-on:click="step -= 1"
                        pill
                        block
                        size="lg"
                        variant="outline-primary"
                      >
                        <v-icon name="angle-left" />
                        Atras
                      </b-button>
                      <p>
                        Recuerda que luego, podrás registrar a tus representados, desde tu acceso como representante. 
                      </p>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col md="12">
                      <br />

                      <router-link to="/login">Volver al inicio.</router-link>
                    </b-col>
                    <b-col md="12">
                      <br />
                      <router-link to="/login" class
                        >¿Ya estás registrado? Inicia sesión.</router-link
                      >
                    </b-col>
                  </b-row>
                </b-row>
              </div>

              <div v-if="step == 4">
                <h2 class="text-center"><strong>Paso 5</strong></h2>
                <b-row class="center">
                  <h4 class="col-md-12 text-center">
                    Ahora registra a tu estudiante
                  </h4>

                  <form
                    style="width: 100% !important"
                    method="post"
                    @submit.prevent="nextStepRegisterStudent"
                  >
                    <b-row>
                      <b-col md="12">
                      
                        <br />
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Nombre del estudiante"
                          label-for="first_name"
                        >
                          <b-form-input
                            id="first_name"
                            v-model="student.first_name"
                            name="first_name"
                            placeholder="Nombre completo"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Apellido del estudiante"
                          label-for="last_name"
                        >
                          <b-form-input
                            id="last_name"
                            v-model="student.last_name"
                            name="last_name"
                            placeholder="Apellidos"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Cédula del estudiante"
                          label-for="dni"
                        >
                          <b-input-group>
                            <template v-slot:prepend>
                              <b-form-select
                                value-field="id"
                                text-field="name"
                                v-model="student.dni_selected"
                                :options="dnis_student"
                                v-on:change="student.dni = dni"
                              ></b-form-select>
                            </template>
                            <b-form-input
                              id="dni"
                              v-model="student.dni"
                              name="dni"
                              type="number"
                              placeholder="Solo Números"
                              :disabled="student.dni_selected == 'R'"
                            ></b-form-input>
                            <p style="font-size: 10px">
                              Si el estudiante aún no posee cédula indique R, para utilizar la misma "cédula" del representante.
                            </p>
                          </b-input-group>
                        </b-form-group>
                        <b-form-group
                          label="Teléfono de estudiante (opcional)"
                          label-for="phone"
                        >
                          <b-form-input
                            id="phone"
                            v-model="student.phone"
                            type="number"
                            name="phone"
                            placeholder="Teléfono"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Fecha de Nacimiento"
                          label-for="birthday"
                        >
                          <date-picker
                            id="birthday"
                            name="birthday"
                            mode="single"
                            :max-date="new Date()"
                            v-model="student.birthday"
                            :popover="{
                              placement: 'bottom',
                              visibility: 'click',
                            }"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                          <b-form-group
                          v-if="check_email_student == false"
                          label="Correo del estudiante"
                          label-for="birthday"
                        >
                          <b-form-input
                            id="security_health"
                            v-model="student.email"
                            name="security_health"
                            placeholder="Correo de acceso"
                          ></b-form-input>
                        </b-form-group>
                        <p  v-if="check_email_student == false" style="font-size: 10px">
                          El correo permite al estudiante acceder a la plataforma, si no posee deje el campo en blanco
                        </p>
                        
                       
                          <b-form-checkbox v-model="check_email_student" name="check-button" size="sm" variant="outline-primary" button>
                            <b v-if="check_email_student == false">El estudiante no posee correo</b>
                            <b v-if="check_email_student == true">Cambiar a: Estudiante posee correo</b>
                          </b-form-checkbox>
                          <br>
                          <small v-if="check_email_student == true">Su correo sera: <strong>{{this.student_false_email}}</strong></small>
                          <br>
                          <br>
                        <b-form-group
                          label="Seguro de Vida HCM"
                          label-for="birthday"
                        >
                          <b-form-input
                            id="security_health"
                            v-model="student.security_health"
                            name="security_health"
                            placeholder="Código de Seguro"
                          ></b-form-input>
                        </b-form-group>
                        <p style="font-size: 10px">
                          El código de seguro HCM, permite tener información
                          ante cualquier emergencia (Opcional)
                        </p>
                        <b-form-group>
                          <div>
                            <div class="mb-2">Género</div>
                            <b-form-select
                              v-model="student.gender"
                              :options="genderList"
                              value-field="id"
                              text-field="name"
                            ></b-form-select>
                          </div>
                        </b-form-group>
                        <b-form-group>
                          <div>
                            <div class="mb-2">Tipo de Sangre</div>
                            <b-form-select
                              v-model="student.blood_type"
                              :options="type_blood_list"
                              value-field="id"
                              text-field="name"
                            ></b-form-select>
                            <p style="font-size: 10px">
                              El tipo de sangre es un valor informativo, que le
                              sirve a la escuela ante cualquier emergencia
                            </p>
                          </div>
                        </b-form-group>
                       
                      </b-col>
                    </b-row>

                    <hr
                      align="center"
                      noshade="noshade"
                      size="2"
                      width="100%"
                    />
                  <b-row>
                    <b-col md="12">
                     <b-form-group
                          label="Observación"
                          label-for="observation"
                        >
                          <b-form-textarea
                            id="observation"
                            v-model="student.observations"
                            name="observation"
                            placeholder="Datos adicionales del estudiante"
                          ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                  </b-row>
                  <hr
                      align="center"
                      noshade="noshade"
                      size="2"
                      width="100%"
                    />
                    <b-col md="12 text-right">
                   
                      <b-button type="submit" variant="outline-success" class="m-3" pill block size="lg"
                        >Continuar Registro <v-icon name="angle-right" />
                      </b-button>

                         <b-button
                        type="submit"
                        variant="outline-primary"
                        pill
                        block
                        size="lg"
                        @click="step -= 1"
                        class="m-3"
                        ><v-icon name="angle-left" /> Volver atrás
                      </b-button>
                    </b-col>
                  </form>
                </b-row>
              </div>

              <div v-if="step == 5">
                  <h2 class="text-center"><strong>Paso 6</strong></h2>
                <b-row>
                  <b-col md="12">
                    <h4 class="col-md-12 text-center">
                      Detalle de datos a registrar
                    </h4>
                    <small>Por favor lea bien todas las instrucciones para culminar su registro, y tome notas de sus credenciales de acceso</small>
                    <br />
                    <br />
                  </b-col>

                  <b-col md="6">
                    <b-list-group>
                      <b-list-group-item
                        ><strong
                          >Datos de Representante</strong
                        ></b-list-group-item
                      >
                      <b-list-group-item>{{
                        first_name + " " + last_name
                      }}</b-list-group-item>
                        <b-list-group-item>{{ dni_selected+'-'+dni }}</b-list-group-item>
                      <b-list-group-item>Accederas al sistema con este correo: <strong>{{ email }}</strong></b-list-group-item>
                      <b-list-group-item>{{ phone }}</b-list-group-item>
                    
                       <b-list-group-item v-if="gender==1">Género: Masculino</b-list-group-item>
                         <b-list-group-item v-if="gender==2">Género: Femenino</b-list-group-item>
                           <b-list-group-item>Su contraseña será su cédula de identidad: <strong>{{this.dni}}</strong></b-list-group-item>
                           <b-list-group-item><small> La clave será la cédula que has ingresado, luego desde tu cuenta puedes modificarla, y colocar una clave nueva.</small></b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col md="6">
                    <b-list-group>
                      <b-list-group-item
                        ><strong>Datos de Estudiante</strong></b-list-group-item
                      >
                      <b-list-group-item>{{
                        student.first_name + " " + student.last_name
                      }}</b-list-group-item>
                      <b-list-group-item>{{ student.dni_selected+'-'+student.dni }}</b-list-group-item>
                      <b-list-group-item v-if="student.email != ''">El estudiante podrá acceder al sistema con este correo: <strong>{{ student.email }}</strong></b-list-group-item>
                         <b-list-group-item v-if="check_email_student==true"><small>El estudiante será registrado con un correo temporal, toma nota de él, y usalo para que el estudiante pueda acceder a sus clases.</small></b-list-group-item>
                       <b-list-group-item v-if="student.gender==1">Género: Masculino</b-list-group-item>
                         <b-list-group-item v-if="student.gender==2">Género: Femenino</b-list-group-item>
                         <b-list-group-item>Su contraseña será su cédula de identidad de estudiante: <strong>{{this.student.dni}}</strong></b-list-group-item>
                           <b-list-group-item><small> La clave será la cédula que has ingresado, luego desde tu cuenta puedes modificarla, y colocar una clave nueva.</small></b-list-group-item>
                  
                    </b-list-group>
                  </b-col>
                  <b-col md="12 text-center">
                   
                    <b-button
                      type="button"
                      pill
                      block
                      size="lg"
                      variant="dark"
                      class="m-3"
                      @click="saveUser"
                      >
                      
                       <b-spinner v-if="progress"></b-spinner>
                        <v-icon name="save" v-if="!progress"></v-icon>
                        <span v-if="!progress">
                         Completar Registro</span
                        >
                     

                    </b-button>

                     <b-button
                      type="submit"
                      variant="outline-primary"
                      @click="step -= 1"
                      pill
                      size="lg"
                      block
                      class="m-3"
                      ><v-icon name="angle-left" /> Volver atrás
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card>
            <b-col md="2"> </b-col>
          </div>
        </div>
        <div class="col-md-2"></div>
      </b-row>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Moment from "moment";
//import RegisterUser from "../../pages/GEN/user/Register";
import Swal from "sweetalert2";
export default {
  name: "PreRegister",
  components: {
    // RegisterUser
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      dni: "",
      // birthday: Moment().format('DD-MM-YYYY'),
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      id_rol: 2,
      id_rol_access: 1,
      code: "",
      progress: "",
      gender: 1,
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      dni_selected: "V",
      check_email_student:false,
      student_false_email:"",
      student: {
        //STUDENT DATA
        first_name: "",
        last_name: "",
        dni: "",
        dni_selected: "V",
        birthday: new Date(),
        username: "",
        email: "",
        password: "",
        password_confirm: "",
        phone: "",
        gender: 1,
        id_blood_type: 1,
        observations: "",
        image: "",
        id_rol_access: 1,
        blood_type: 1,
        //END STUDENT DATA
      },
      dnis: [
        {
          id: "V",
          name: "V",
        },
        {
          id: "E",
          name: "E",
        },
      ],
       dnis_student: [
        {
          id: "V",
          name: "V",
        },
        {
          id: "E",
          name: "E",
        },
         {
          id: "R",
          name: "R",
        },
      ],
      genderList: [
        {
          id: 1,
          name: "Masculino",
        },
        {
          id: 2,
          name: "Femenino",
        },
      ],
      blood_type: 1,
      rols_user: [],
      accessArray: [],
      type_blood_list: [],
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: Date,
      isM: "",
      user_registered: {},
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true,
        },
      },
      school: {},
      step: 0,
      rol: null,
    };
  },
  mounted() {
    this.allRoles();
  },
  methods: {
    ...mapActions([
      "newUserPublic",
      "publicRegisterRepresentative",
      // "loadRol",
      // "loadRol_Active",
      //   "loadAccess_Active",
      "check_inscription_code",
      "loadBlood_Active",
      "findUserByEmail",
    ]),
    checkRol(){
      if (this.rol == 1){
        this.step = 1
      }else if (this.rol == 2){
        Swal.fire({
          title: 'Atención',
          text: 'Para registrarte como estudiante, primero debes tener un representante registrado. Luego que tu representante se registre, el podrá registrarte como alumno en la institución.',
          icon: 'warning'
        })
      }else if (this.rol == 3){
        Swal.fire({
          title: 'Atención',
          text: 'Para registrarte como profesor, la administración de la escuela es el único que tiene la autorización para hacerlo, enviales tu correo y contraseña para que ellos hagan el proceso de registro',
          icon: 'warning'
        })
      }
    },
    formatNumber(e) {
      return String(e).substring(0, 8);
    },
    checkCode() {
      //let id_user = this.authe.me.id;
      this.progress = true;
      this.btnLogin = true;
      if (this.code == "") {
        Swal.fire({
          title: "Por favor indica un código",
          text: "Asegúrate de escribir correctamente el código de la escuela",
          icon: "warning",
        });
        this.progress = false;
      } else {
        this.check_inscription_code({
          code: this.code,
        })
          .then((data) => {
            console.log("LOGIN 200");
            console.log(data);
            if (data.code == 200) {
              this.step = 2;
              this.progress = false;
              this.school = data.data.school;
            }
            if (data.code == 404) {
              this.progress = false;
              Swal.fire({
                title: "Código Inválido",
                text:
                  "Asegúrate de escribir correctamente el código de la escuela",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
              });
            }
          })

          .catch((data) => {
            this.btnLogin = false;
            if (data.code == 404) {
              this.addToastMessage({
                text: "Ocurrio un error al consultar",
                icon: "warning",
              });
              this.progress = false;
            }
          });
      }
    },
    nextStepRegister() {
      let dni_check = this.dni;
      let date = this.birthday;
      var years = Moment().diff(date, "years");
      console.log("Annos:" + years);
      if (years < 18) {
        console.log("years");
        Swal.fire({
          title: "Tu edad es menor a 18 años",
          text:
            "Este registro es solo para representantes no cumples los requisitos de mayoria de edad.",
          icon: "warning",
        });
      } else if (this.first_name == "") {
        Swal.fire({
          title: "Por favor indica un nombre",
          text: "Indica tus nombres completo",
          icon: "warning",
        });
      } else if (this.last_name == "") {
        Swal.fire({
          title: "Por favor indica un apellido",
          text: "Indica tus apellidos",
          icon: "warning",
        });
      } else if (this.dni == "") {
        Swal.fire({
          title: "Por favor indica una cédula",
          text: "Indica tus cédula, sólo con números",
          icon: "warning",
        });
      }  else if (isNaN(dni_check)) {
        Swal.fire({
          title: "Por favor indica una cedula válida, solo números",
          text: "Debe contener mínimo 6 caractéres",
          icon: "warning",
        });
      } else if (dni_check.length < 6) {
        Swal.fire({
          title: "Por favor indica una cedula válida, solo números",
          text: "Debe contener mínimo 6 caractéres",
          icon: "warning",
        });
      }  else {
        if (this.step == 3) {
          this.step += 1;
          let premail = this.email.substring(0, this.email.lastIndexOf("@"));
          this.student_false_email = premail+'@escuela.com';
        }
       
      }
    },
    nextStepRegisterStudent() {
      let dni_representative = this.dni_selected+this.dni;
      let dni_student = this.student.dni_selected+this.student.dni;

      if (
        this.student.first_name == "" ||
        this.student.last_name == "" ||
        this.student.birthday == "" ||
        this.student.gender == ""
      ) {
        Swal.fire({
          title: "Completa los campos",
          text:
            "Asegúrate de indicar nombres, fecha de nacimiento y género como mínimo, e intenta completar toda la información",
          icon: "warning",
        });
      }else if (this.student.dni == "") {
            Swal.fire({
              title: "Por favor indica una cédula",
              text: "Si el alumno no posee cédula, selecciona la opción R, para que sea registrado con tú cédula",
              icon: "warning",
            });
          }else if (dni_representative == dni_student) {
            Swal.fire({
              title: "Cédulas iguales",
              text: "La cédula del Representante no puede ser igual a la del estudiante, si el estudiante no posee cédula, indíque la opción R para que tome su cédula",
              icon: "warning",
            });
          }else if (this.student.email == "" && this.check_email_student == false) {
           
                Swal.fire({
                title: "Indica un correo para el estudiante",
                text: "Este correo permitirá al estudiante acceder a sus clases y tareas, si el estudiante no posee correo por favor marca la casilla, para registrarlo con un correo temporal",
                icon: "warning",
              });
            
           
          }else{
            if(this.step == 4){
               this.step += 1;
               if(this.check_email_student){
                 this.student.email = this.student_false_email;
               }
            }
           
          }
    },

    allRoles() {
      let form = {
        active: 1,
      };
      /*   this.loadRol_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.rols_user = response.data;
          }
        })
        .catch(error => {console.log(error)}); */
      this.loadBlood_Active(form)
        .then((response) => {
          if (response.code === 200) {
            this.type_blood_list = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      /*   this.loadAccess_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.accessArray = response.data;
          }
        })
        .catch(error => {console.log(error)}); */
    },
    validateEmail(email) {
      //const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //return re.test(String(email).toLowerCase());
      return email;
    },
    findRepresent() {
      //findUserByEmail
      this.progress = true;

      let validation_email = false;
      validation_email = this.validateEmail(this.email);
      console.log(validation_email);
      if (validation_email == false) {
        Swal.fire({
          title: "Por favor escribe bien el correo",
          text:
            "Asegúrate de escribir en minúsculas, sin espacios y sin carácteres especiales",
          icon: "warning",
        });
        this.progress = false;
      } else if (this.email == "") {
        Swal.fire({
          title: "Por favor indica un correo",
          text: "Asegurate de indicar tu correo",
          icon: "warning",
        });
        this.progress = false;
      } else {
        let form = {
          email: this.email,
        };
        this.findUserByEmail(form)
          .then((response) => {
            if (response.code === 200) {
              Swal.fire({
                title: "Ya este correo se encuentra registrado",
                text: "Te invitamos a iniciar sesión",
                icon: "success",
              }).then((result2) => {
                console.log(result2);
                this.$router.push({ name: "login" });
              });
              this.progress = false;
            }
            if (response.code === 404) {
              Swal.fire({
                title: "Ahora, completa tus datos",
                text:
                  "A continuación completa todos tus datos para culminar tu proceso de registro",
                icon: "success",
              }).then((result2) => {
                console.log(result2);
                this.step = 3;
              });
              this.progress = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.progress = false;
      }
    },
    saveUser() {
      this.progress = true;
      console.log("Save Represent");

      let user_representative = {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        dni: this.dni_selected + "-" + this.dni,
        birthday: Moment(this.birthday).format("YYYY-MM-DD"),
        password: this.dni,
        phone: this.phone,
        gender: this.gender,
        id_blood_type: this.blood_type,
      };
      let user_student = {
        email: this.student.email,
        first_name: this.student.first_name,
        last_name: this.student.last_name,
        dni: this.student.dni_selected + "-" + this.student.dni,
        birthday: Moment(this.student.birthday).format("YYYY-MM-DD"),
        password: this.student.dni,
        phone: this.student.phone,
        gender: this.student.gender,
        id_blood_type: this.student.blood_type,
      };
       let student = {
        security_health: this.student.security_health,
      };
      let representative = {
        commercial_activity: this.commercial_activity,
        address_home: this.address_home,
        address_office: this.address_office,
        profession: this.profession,
      };
      let form={
        user_representative:user_representative,
        representative:representative,
        user_student:user_student,
        student:student,
        school:this.school
      }
      Swal.fire({
        title: "¿Estás seguro de registrarte?",
        text: "Se enviará un registro de representante y un registro de alumno",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
      }).then((result) => {
        if (result.value) {
          this.newUserPublic(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Registro de usuario completado",
                  text: "Tu clave es la cédula de identidad que registraste como representante. Recuerda que el alumno y el representante tendrán accesos independientes a la plataforma, cada uno podrá entrar con su correo electrónico. El estudiante debe entrar con su cuenta para visualizar tareas, y evaluaciones.",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                  this.user_registered = response.data.data;
                 // this.$router.push({ name: "login" });
                 this.$router.push({ name: "first_steps" });
                });
                this.progress = false;
              }
              if (response.code == 410) {
                this.loading = false;
                Swal.fire({
                  title: "Cédula existente",
                  text:
                    "Un usuario con esta cédula ya se encuentra registrado, recuerde que en este formulario, solo debe registrarse como representante",
                  icon: "warning",
                });
                this.progress = false;
              }
              if (response.code == 409) {
                this.loading = false;
                Swal.fire({
                  title: "Correo de Representante existente",
                  text:
                    "Un representante con esta correo ya se encuentra registrado, comienza el proceso y utiliza otro correo",
                  icon: "warning",
                });
                this.progress = false;
              }
              if (response.code == 409) {
                this.loading = false;
                Swal.fire({
                  title: "Correo de Estudiante existente",
                  text:
                    "Un estudiante con esta correo ya se encuentra registrado, vuelve atrás y comprueba",
                  icon: "warning",
                });
                this.progress = false;
              }
            })
            .catch((error) => {
              console.log(error);
              this.progress = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>



<style scoped>
.content {
  margin-left: 0px !important;
}
</style>
