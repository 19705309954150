import axios from "axios";
import * as Config from "../../../config";

const state = {
  teachers: {
    current_page: 1,
    data: []
  },
  teacher: {}
};
let mod = "eval/";
const actions = {
  // TODO CON API READY
  loadTeachers({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
 
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadTeachers2({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
 
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath2 + "teacher/all/",{params:form})
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadTeachers_school({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
 
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Teacher({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)

    commit("LOAD_DATA_TEACHER", data);
  },
  loadTeacher_Stats({ commit, dispatch }, id) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "teacher/counters/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadTeacher({ commit, dispatch }, id) {
    
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadTeacherRequest({ commit, dispatch }, id) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/all/request/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadPaymentStatus({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/check_pay/" + form.id_teacher)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  findTeacherByEmail({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + mod + "teacher/find_by_email/" + form.email
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newTeacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "teacher/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  publicRegisterTeacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "teacher/public_save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  publicRequestTeacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "teacher/request", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateTeacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod + "teacher/update/" + form.id, form)
        .then(response => {
          commit("UPDATE_TEACHER_OK", {});
          resolve(response.data);
        })
        .catch(error => {
        //  commit("UPDATE_TEACHER_FAIL");
          reject(error.response.data);
        });
    });
  },
  changeActiveTeacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            mod +
            "teacher/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTeacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            mod +
            "teacher/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  loadCity({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/list/city")
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  loadTeacherPoint({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_TEACHER_POINT");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            "teacherpoint/acumfilterby/" +
            data.idtypepoint +
            "/" +
            data.idteacher
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TEACHER_POINT_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TEACHER_POINT_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TEACHER_POINT_FAIL");
          reject(error);
        });
    });
  },
  loadGender({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "teacher/list/gender")
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  async preRegister({ commit }, form) {
   
    console.log(commit)
    try {
      const response = await axios.post(
        `${Config.apiPath}gen/teacher/save`,
        form
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
};

const mutations = {
  LOAD_DATA_TEACHER(state, data) {
    state.teacher = data;
  },

  LOAD_TEACHERS_OK(state, teachers) {
    state.teachers = teachers;
  },

  LOAD_TEACHER_OK(state, teacher) {
    state.teacher = teacher;
  },

  LOAD_TEACHER_POINT_OK(state, teacher) {
    state.teacher = teacher;
  },
  
  UPDATE_TEACHER_OK(state, teacher) {
    state.teacher = teacher;
  }
};

export default {
  state,
  actions,
  mutations
};
