<template>
  <div class="Task">
    <b-row>
      <b-col md="4">
       
      </b-col>
      <b-col md="8">
        <b-form-group label="Estudiante" label-for="user">
          <!--<v-select :options="students" label="id_user" v-model="student" ></v-select>-->
          <multiselect placeholder="Selecciona un estudiante" @input="loadTask" :custom-label="getUser" v-model="student" :options="students"></multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3 px-3">
      <b-col sm="12">
        <div>
          <b-table
            id="my-table"
            show-empty
            small
            fixed
            stacked="md"
            sticky-header="true"
            hover
            :items="taskTMP"
            :fields="field"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="isBusy"
          >
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Cargando ...</strong>
              </div>
            </template>
            <template v-slot:cell(actions)="row">
              <b-button
                size="sm"
                variant="outline-primary"
                class="mr-2"
                title="Activado"
                target="_blank"
                :href="row.item.url"
              >
                <v-icon name="eye" />
              </b-button>
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col sm="12">
        <div>
          <p class="mt-3">Página actual: {{ currentPage }}</p>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            size="sm"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Multiselect from 'vue-multiselect'
export default {
  name: "Task",
  components: { Multiselect },
  data() {
    return {
      isBusy: false,
      loadTasks: [],
      evaluation: [],
      evaluations: "",
      students:[],
      student:"",
      url: "",
      taskTMP: [],
      field: [
        {
          key: "evaluation.title",
          label: "Evaluación",
          sortable: true,
        },
         {
          key: "evaluation.teacher.user.full_name",
          label: "Profesor",
          sortable: true,
        },
         {
          key: "evaluation.lapse.name",
          label: "Momento",
          sortable: true,
        },
        {
          key: "name",
          label: "Descripción",
          sortable: true,
        },
        {
          key: "student.user.full_name",
          label: "Nombre del estudiante",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Fecha de subida",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
    };
  },
  mounted() {
     this.loadStudents();
    
   
  },
  computed: {
    rows() {
      return this.taskTMP.length;
    },
     ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
  }, 
  methods: {
    ...mapActions(["loadEvaluation_Student","loadStudent_school"]),
    getUser(student){
     
      if(student.user){
         return student.user.full_name + '-'+student.user.email
      }
     
    },
    loadTask() {
      this.isBusy = true;
      let form = {      
        id_student: this.student.id,
      };
      this.loadEvaluation_Student(form)
        .then((response) => {
          if (response.code === 200) {
            this.loadTasks = response.data;
            this.taskTMP = response.data;
            this.evaluation = response.data;
            this.evaluations = this.evaluation[0];
            this.isBusy = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
        });
    },
     loadStudents() {
      this.isBusy = true;
      let form = {      
        id_school: this.me_rol.id_school,
      };
      this.loadStudent_school(form)
        .then((response) => {
          if (response.code === 200) {           
            this.students = response.data;         
            this.isBusy = false;
            this.student = this.students[0]
            this.loadTask();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
        });
    },
    changeStatus(data){

      
      console.log(data)
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
</style>