import axios from "axios";
import * as Config from "../../../config";

const state = {
  connection: ""
};

const actions = {
  loadConnection({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "log_connection/all")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newConnection({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      //http://127.0.0.1:3333/api/1.0/check_connection/save?name_device=NODE01&model=ESP8866&mac_address=123456789&datetime=2019-09-27T18:12:02.932Z
      axios
        .get(
          Config.apiPath +
            "check_connection/save?uuid=" +
            form.uuid +
            "&type=" +
            form.type
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
};
