import axios from "axios";
import * as Config from "../../../config";
import Cookies from "js-cookie";


const state = {
  token: Cookies.get("access_token") || "",
  status: "",
  me: ""
};

const actions = {
  loadCards({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "ttap/all")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadCards_Active({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "ttap/all/status/" + form.active)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newCard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "ttap/save?uuid=" + form.uuid + "&type=" + form.type
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateCard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + "ttap/" + form.id, form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveCard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + "ttap/change_status/" + form.id + "/" + form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteCard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + "ttap/change_status/" + form.id + "/" + form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  assignCard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "user_ttap", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
};
