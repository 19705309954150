<template>
  <div>
    <br />
    <div class="content">
      <h2 class="text-center">¡Bienvenido a Clasy Profesor!</h2>
      <p class="text-center">Este el panel de control que permite administrar los datos de tus materias y estudiantes</p>
      <hr>
      <div class="row">
        <div class="col-md-4">
          <b-card
          img-src="https://ik.imagekit.io/eswqag3k3o6/school2/docs/student1/2021-09-30_015007-representante-xVod0E.jpg" img-alt="Card image" img-top>
            <b-card-title class="text-center">Estudiantes</b-card-title>
            <b-card-text>Puedes, visualizar, modificar a tus estudiantes</b-card-text>
            <router-link
              to="/profesor/estudiantes"
              class="btn btn-sm"
              style="width: 100%; background-color: #28a745; color: #FFFFFF"
            >Ver estudiantes</router-link>
            <br />
            <b-card-text class="my-0">
              <b-row v-if="loadingx">
                <b-col md="12">
                  <div class="d-flex justify-content-center my-3">
                    <b-spinner
                      variant="outline-success"
                      style="width: 3rem; height: 3rem;"
                    ></b-spinner>
                  </div>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="12">
                  <p class="text-center my-0 py-0" style="font-size:46px">{{ form.student_count }}</p>
                  <p class="text-center my-0 py-0" style="font-size:14px">Estudiantes activos</p>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card 
          img-src="https://ik.imagekit.io/eswqag3k3o6/school2/docs/student1/2021-09-30_015920-newproject-2pXZM1.jpg" img-alt="Card image" img-top>
            <b-card-title class="text-center">Materias</b-card-title>
            <b-card-text>Puedes, visualizar, crear asignaciones en tus materias</b-card-text>
            <router-link
              to="/profesor/materias"
              class="btn btn-sm"
              style="width: 100%; background-color: #28a745; color: #FFFFFF"
            >Ver materias</router-link>
            <br />
            <b-card-text class="my-0">
              <b-row v-if="loadingx">
                <b-col md="12">
                  <div class="d-flex justify-content-center my-3">
                    <b-spinner
                      variant="outline-success"
                      style="width: 3rem; height: 3rem;"
                    ></b-spinner>
                  </div>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col md="12">
                  <p class="text-center my-0 py-0" style="font-size:46px">{{ form.subject_count }}</p>
                  <p class="text-center my-0 py-0" style="font-size:14px">Materias activas</p>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card
          img-src="https://ik.imagekit.io/eswqag3k3o6/school2/docs/student1/2021-09-30_020012-newproject3-ci6mGA.jpg" img-alt="Card image" img-top>
            <b-card-title class="text-center">Evaluaciones</b-card-title>
            <b-card-text>Puedes, visualizar, modificar y guardar evaluaciones</b-card-text>
            <b-button
              class="btn btn-sm"
              v-b-modal.modal-subjects
              style="width: 100%; background-color: #28a745; color: #FFFFFF"
            >Ver evaluaciones</b-button>
            <br />
            <b-row v-if="loadingx">
              <b-col md="12">
                <div class="d-flex justify-content-center my-3">
                  <b-spinner
                    variant="outline-success"
                    style="width: 3rem; height: 3rem;"
                  ></b-spinner>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
             
              <b-col md="6">
                <b-card-text>
                  <p class="text-center my-0 py-0" style="font-size:46px">{{ form.evaluation_count_task }}</p>
                  <p class="text-center my-0 py-0" style="font-size:14px">Clases / Tareas</p>
                </b-card-text>
              </b-col>
              <b-col md="6">
                <b-card-text>
                  <p class="text-center my-0 py-0" style="font-size:46px">{{ form.evaluation_count_exam }}</p>
                  <p class="text-center my-0 py-0" style="font-size:14px">Evaluaciones</p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-subjects"
      ref="modal"
      title-class="text-font-black"
      title="Selecciona la materia"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      size="md"
    >
      <b-container>
        <div class="row" v-if="loading">
          <b-col md="12">
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                variant="outline-success"
                style="width: 3rem; height: 3rem;"
                label="Large Spinner"
              ></b-spinner>
            </div>
          </b-col>
        </div>
        <div class="py-0 row" v-else>
          <div
            v-for="subject in subjects"
            :key="subject.id"
            class="col-md-12 mb-2 py-1 border-success"
            style="border-radius: 5px;"
          >
            <b-row class="py-0 my-0" align-h="center">
              <b-col class="py-0 my-0" md="10">
                <h4 class="my-0">{{ subject.subject.name }}</h4>
                <p class="my-0 py-0" style="font-size: 14px"> <strong>Sección:</strong> {{ subject.section.name }} </p>
                <p class="my-0 py-0" style="font-size: 14px"><strong>Periodo:</strong> {{ subject.period.name }}</p>
              </b-col>
              <b-col class="py-0 my-0" md="2" align-self="center">
                <b-button
                  @click="insideSubject(subject)"
                  pill
                  block
                  variant="outline-success"
                  ><v-icon name="angle-right"></v-icon
                ></b-button>
              </b-col>
            </b-row>
            <hr class="mb-0 pb-0">
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

/* eslint-disable */
import { mapState, mapActions } from "vuex"

export default {
  name: "Welcome",

  data() {
    return {
      loadingx: true,
      loading: false,
      subjects: [],
      form: {
        evaluation_count_class: 0,
        evaluation_count_exam: 0,
        evaluation_count_task: 0,
        student_count:0,
        subject_count:0
      }
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    })
  },
  mounted() {
    this.loadState()
    this.loadStatex()
  },

  methods: {
    ...mapActions([
      "get_teacher_counters",
      "loadSubjects_by_user",
      "loadData_SubjectSection",
      "loadSubjectSection",
    ]),
    insideSubject(subject) {
      this.loadData_SubjectSection(subject).then((response) => {
        this.$router.push("/profesor/materia/" + subject.subject.id);
      });
    },
    loadStatex() {
      const id_school = this.me_rol.id_school
      this.loading = true

      let form = {
        id_teacher: this.me_rol.id,
        id_school: id_school,
        active: 1,
      }
  
      this.loadSubjectSection(form)
        .then((response) => {
          if (response.code === 200) {
            this.subjects = response.data
            this.loading = false
          }
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        });
    },
    loadState() {
      const id_school = this.me_rol.id_school;
      const self = this;

      let form = {
        id_teacher: this.me_rol.id_teacher
      };
      this.get_teacher_counters(form)
        .then(response => {
          if (response.code === 200) {
            let resp = response.data;
            
            self.form.evaluation_count_class = resp.evaluation_count_class;
            self.form.evaluation_count_exam = resp.evaluation_count_exam;
            self.form.evaluation_count_task = resp.evaluation_count_task;
            self.form.student_count = resp.student_count;
            self.form.subject_count = resp.subject_count;
            this.loadingx = false
          }
        })
        .catch(error => {this.loadingx = false; console.log(error)});
    }
  }
};
</script>