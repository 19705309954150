<template>
  <div class="text-center section">
    <h2 class="h2">Calendario</h2>
    <small class="text-lg font-medium text-gray-600 mb-6">
      Puedes filtrar tus clases y tareas utilizando los filtros
    </small>
    <b-spinner variant="outline-primary" label="Spinning" v-if="loading"></b-spinner>
     <b-modal v-model="modalShowTasks" hide-footer title="Clases y Tareas en la fecha">
      <b-list-group>
        <b-list-group-item
          v-for="task in tasks_details"
          v-bind:key="task.id"
        >
          <b-row>
            <b-col md="12">
                <b-row>
                    <b-col sm="10">
                 <h3>{{task.customData.task.title}}</h3>
                
              <span v-if="task">
                 
                 Profesor: {{
                task.customData.task.teacher.user.first_name +
                  " " +
                  task.customData.task.teacher.user.last_name
              }}</span
              ><br />
               <span v-if="task">
                 
                 Materia: {{
                task.customData.task.subject.name}}</span
              >
                    </b-col>
                    <b-col sm="2">
                          <b-avatar
               
                  variant="outline-info"
                  :src="task.customData.task.teacher.user.image"
                ></b-avatar>
                    </b-col>
                </b-row>
              <span v-if="task" style="font-size:11px">
               Fecha {{ task.customData.task.deadline | format_date }}
               </span
              ><br>
               <span v-if="task" style="font-size:11px">
               Entrega de Tarea {{ task.customData.task.tasks_deadline | format_date }}
               </span
              ><br>
               <span v-if="task" style="font-size:11px">
               Clase actualizada {{ task.customData.task.updated_at | format_date }}
               </span
              ><br>
               <b-badge
                        v-if="task.customData.task.status_evaluation == 1"
                        variant="outline-primary"
                        pill
                        >Clase planificada</b-badge
                      >
                      <b-badge
                        v-if="task.customData.task.status_evaluation == 2"
                        variant="outline-success"
                        pill
                        >Clase iniciada</b-badge
                      >
                      <b-badge
                        v-if="task.customData.task.status_evaluation == 3"
                        variant="outline-warning"
                        pill
                        >Clase culminada</b-badge
                      >
                      <b-badge
                        v-if="task.customData.task.status_evaluation == 4"
                        variant="outline-danger"
                        pill
                        >Clase cerrada</b-badge
                      >
                        <b-button
                        @click="accessClass(task.customData.task)"
                        pill
                        type="submit"
                        variant="outline-success"
                        class="m-3"
                        size="sm"
                        >Acceder a esta clase</b-button
                      >
            </b-col>
           
          </b-row>
          <b-row>
              <b-col md="12">
                    
              </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <b-row>
      <b-col md="4">
        <b-form-group label="Momento" label-for="first_name">
          <b-form-select
            @change="loadState(lapse)"
            v-model="lapse"
            :options="lapseList"
            value-field="id"
            size="sm"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
       <b-button size="sm" class="mx-auto" @click="loadState(1)">Mostrar todos</b-button>
      </b-col>
      <b-col md="4">
       <b-form-group>
                <div>
                  <div class="mb-2">Materia</div>
                  <b-form-select
                    v-model="subject"
                    :options="teacherList"
                    @change="loadState()"
                    value-field="subject.id"
                    size="sm"
                    text-field="subject.name"
                  ></b-form-select>
                </div>
              </b-form-group>
      </b-col>
    </b-row>
   

    <v-calendar
    ref="calend"
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
      :locale="'es'"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div
          class="flex flex-col h-full z-10 overflow-hidden"
          style="border:1px"
        >
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <div
            style="cursor:pointer"
              class="calendar_square"
              v-on:click="showTheTasks(attributes)"
            >
              <b-row>
                <b-col md="12" class="my-2">
                  <span style="margin-bottom:10px">{{ day.day }}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <div style="width:100%">
                      <b-badge pill v-if="attributes" variant="outline-primary">{{ attributes.length }}</b-badge>
                   
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import VCalendar from "v-calendar/lib/components/calendar.umd";
import { mapState, mapActions } from "vuex";
import moment from "moment";
//import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "Calendar",
  components: {
    VCalendar,
  },
  filters: {
    from_now: function(created_at) {
      if(created_at){
           return moment(created_at).fromNow();
      }else{
          return '';
      }
     
    },
    format_date: function(date){
        if(date){
             return moment(date).format('DD-MM-YYYY')
        }else{
          return '';
      }
       
    }
  },
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      modalShow: false,
      modalShowModules: false,
      module_detail: {},
      modules_details: {},
      modules: [],
    task_detail: {},
      tasks_details: {},
      tasks: [],
      lapse: {},
      lapseList: [],
      teacher:{},
      teacherList:[],
      subject:{},
      subjectList:[],
       modalShowTasks: false,
       loading:false,
      attributes: [
        {
          key: 8,
          customData: {
            title: "",
            class: "",
          },
          dates: "",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.loadSelects();
    this.loadSubjectsComplete();
  },
  methods: {
    ...mapActions(["loadLapse_school", "loadEvaluations","loadData_Evaluation","loadSubjectSection","addToastMessage"]),
    getWith() {
      let actual_with = window.innerWidth;
      return actual_with;
    },
   accessClass(evaluation) {

      if(evaluation.status_evaluation == 1){
        this.addToastMessage({
                    text: "La clase esta planificada, pronto podrás acceder",
                    type: "success",
                  });

      }else if(evaluation.status_evaluation == 3){
         this.addToastMessage({
                    text: "La clase esta culminada, no podrás escribir mensajes, ni subir tareas",
                    type: "warning",
                  });
                 

      }else if(evaluation.status_evaluation == 4){
           this.addToastMessage({
                    text: "La clase esta cerrada, no puedes acceder",
                    type: "danger",
                  });
      }else{
         this.loadData_Evaluation(evaluation).then((response) => {
           console.log(response)
        this.$router.push("/estudiante/materia/evaluacion/detalle");
      });
      }
     
    },
    view_module_detail(module) {
      this.$router.push("/admin/phase/all?id_module=" + module.id);
    },
     showTheTasks(tasks) {
     
      this.tasks_details = tasks;
    
      this.modalShowTasks = true;
    },
    showModuleDetail(module) {
     
      this.module_detail = module;
      this.modalShow = true;
    },
    showTheModules(modules) {
     
      this.modules_details = modules;
    
      this.modalShowModules = true;
    },
    loadSelects() {
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form2)
        .then((response) => {
          if (response.code === 200) {
            this.lapseList = response.data;
            this.lapse = response.data[0].id;
            console.log("LOADED LAPSES");
            this.loadState();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSubjectsComplete(){
         let form_teacher = {
        id_section: this.me_rol.id_section,
       
      };
        this.loadSubjectSection(form_teacher)
        .then((response) => {
          if (response.code === 200) {
            this.teacherList = response.data;
            this.teacher = response.data[0].teacher.id;

         
            console.log("LOADED TEACHERS");
           
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    loadState(clean) {

      if(clean == 1){
        this.subject = '';
        this.teacher = '';
      }
        this.attributes = [];
      console.log("LOADING EVALUATIONS");
      let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.me_rol.id_section;
      //const id_actual_lapse =  this.me_rol.id_actual_lapse;
      //const self = this;

      let form = {
        id_subject: id_subject,
        id_type_evaluation: 2,
        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        active: 1,
      };
      if (this.lapse > 0) {
        form["id_lapse"] = this.lapse;
      }

       if (this.subject > 0) {
        form["id_subject"] = this.subject;
      }
       
      this.loading = true;
      this.loadEvaluations(form)
        .then((response) => {
          if (response.code === 200) {
            this.loading = false;
            let resp = response.data;
            console.log(resp);

            this.tasks = resp;

             for (let i in this.tasks) {
              let task = this.tasks[i];
             /* const servi_month = moment(task.date).month();

              const servi_year = moment(task.date).year();
              const servi_day = moment(task.date).day(); */

              var check = moment(task.deadline, 'YYYY/MM/DD');
              console.log(check);
              var servi_month = check.format('MM');
              var servi_day   = check.format('DD');
              var servi_year  = check.format('YYYY');

              if(servi_year == 2021){
              console.log('Month:'+servi_month);
              console.log('Year:'+servi_year);
              console.log('Day:'+servi_day);
              }
             
              let form_attribute = {
                key: task.id,
                customData: {
                  task: task,
                },

                dates: new Date(servi_year, parseInt(servi_month) - 1 , servi_day),
              };
               const calendar = this.$refs.calend;

            // Moves to today's date
                calendar.move(1)
                calendar.move(-1)
              this.attributes.push(form_attribute);
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #5394d6 !important;
  --weekday-border: 1px solid #a73838 !important;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px !important;
  }
}
.calendar_square {
  border: 1px solid rgb(238, 238, 238);
  margin: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font: size 14px;
  min-height: 80px;
  padding: 2px;
}
.pillmodule {
  height: 25px;
  width: 25px;
  color: black;
  font-weight: 700;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: inline-block;
}
@media only screen and (max-width: 500px) {
  .module_label {
    visibility: hidden;
  }
}
</style>
