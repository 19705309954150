import axios from "axios";
import * as Config from "../../../config";
import Cookies from "js-cookie";


const state = {
  token: Cookies.get("access_token") || "",
  status: "",
  id_rol: "",
  me: "",
  me_school: "",
  me_student: "",
  me_father: "",
  me_personal: "",
  me_teacher: "",
  me_area: "",
  permissions: []
};
const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  checkLogin({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    commit("CHECK_LOGIN");
    const accessToken = Cookies.get("access_token");
    return new Promise((resolve, reject) => {
      if (!accessToken) {
        commit("CHECK_LOGIN_FAIL");
        return reject(new Error("No access token stored"));
      }
      axios
        .get(Config.apiPath + "auth/user")
        .then(response => {
          commit("CHECK_LOGIN_OK", response.data);
        })
        .catch(error => {
          Cookies.remove("access_token");
          commit("CHECK_LOGIN_FAIL");
          reject(error.response.data);
        });
      resolve();
    });
  },
  checkLoginSpa({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    commit("CHECK_LOGIN_SPA");
    const accessToken = Cookies.get("access_token");
    return new Promise((resolve, reject) => {
      if (!accessToken) {
        commit("CHECK_LOGIN_FAIL");
        return reject(new Error("No access token stored"));
      }
      return resolve();
    });
  },
  /*async login({ commit }, form) {
    try {
      const response = await axios.post(
        `${Config.apiPath}gen/auth/login`,
        form
      );
      commit("LOGIN", response.data);
      return response.data;
    } catch (error) {
      //return error;
      return error.data;
    }
  },*/
  /*async loginAdministrator({ commit }, form) {
    try {
      const response = await axios.post(
        `${Config.apiPath}gen/auth/admin/login`,
        form
      );
      commit("ADMIN_LOGIN", response.data);
      return response.data;
    } catch (error) {
      //return error;
      return error.data;
    }
  },*/
  loginAdministrator({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/admin/login", form)
        .then(response => {
          if (response.data.code === 200) {
            const accessToken = response.data.access_token;
            Cookies.set("access_token", accessToken);
            Cookies.set("id_rol", 5);
            commit("LOGIN", response.data);
            resolve(response.data);
          } else {
            commit("LOGIN_FAIL1");
            console.log(response);
            reject(response.data);
          }
        })
        .catch(error => {
          commit("LOGIN_FAIL");

          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
  login({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/login", form)
        .then(response => {
          if (response.data.code === 200) {
            const accessToken = response.data.access_token;
            Cookies.set("access_token", accessToken);
            console.log(accessToken)
            commit("LOGIN", response.data);
            resolve(response.data);
          } else {
            commit("LOGIN_FAIL1");
            console.log(response);
            reject(response.data);
          }
        })
        .catch(error => {
          commit("LOGIN_FAIL");

          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
  login_id({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/login_id", form)
        .then(response => {
          if (response.data.code === 200) {
            const accessToken = response.data.access_token;
            Cookies.set("access_token", accessToken);
            console.log(accessToken)
            commit("LOGIN", response.data);
            resolve(response.data);
          } else {
            commit("LOGIN_FAIL1");
            console.log(response);
            reject(response.data);
          }
        })
        .catch(error => {
          commit("LOGIN_FAIL");

          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
 
  Rootlogin({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/root/login", form)
        .then(response => {
          if (response.data.code === 200) {
            const accessToken = response.data.access_token;
            Cookies.set("access_token", accessToken);
            commit("LOGIN", response.data);
            resolve(response.data);
          } else {
            commit("LOGIN_FAIL1");
            console.log(response);
            reject(response.data);
          }
        })
        .catch(error => {
          commit("LOGIN_FAIL");

          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
  recovery_request({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/password/recovery_request", form)
        .then(response => {
          if (response.data.code === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(error => {
          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
  recovery_validate({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/password/recovery_validate", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
  recovery_save({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/password/recovery_save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
 
  /*async getAllRolsByUser({ commit }, id_user) {
    try {
      const response = await axios.get(
        `${Config.apiPath2}auth/get_rols/${id_user}`
      );
      console.log(commit)
      return response.data;
    } catch (error) {
      return error;
    }
  },*/
  getAllRolsByUser({ commit, dispatch },id_user) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath2 + "auth/get_rols/"+id_user+'?show_children=1')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  sendMassive({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    //commit("LOGIN");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "gen/auth/register_massive", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log(error.response.data);
          reject(error.response.data);
        });
    });
  },
  getJson({ commit, dispatch },url) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get('http://localhost:8080/'+url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  async getAllRolsByUserAdmin({ commit }, id_user) {
    try {
      const response = await axios.get(
        `${Config.apiPath}gen/user/rols_admin/${id_user}`
      );
      console.log(commit)
      return response.data;
    } catch (error) {
      return error;
    }
  },
  verifyCode({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    commit("LOGIN");
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "auth/user/verify/code", form)
        .then(response => {
          if (response.data.code === 200) {
            const accessToken = response.data.access_token;
            Cookies.set("access_token", accessToken);
            commit("LOGIN_OK", response.data);
            resolve(response.data);
          } else {
            commit("LOGIN_FAIL");
            reject(response.data);
          }
        })
        .catch(error => {
          commit("LOGIN_FAIL");
          reject(error.data);
        });
    });
  },
  logout({ commit }) {
    
    console.log(commit)
    // remove the axios default header
    // delete axios.defaults.headers.common['Authorization']
    // resolve()

    commit("LOGOUT_OK");
    // return new Promise((resolve, reject) => {
    //   axios.get(Config.apiPath + 'auth/logout')
    //     .then(
    //       response => {
    //         commit('LOGOUT_OK');
    //         // Cookies.remove('access_token');
    //         // Object.keys(Cookies.get()).forEach(function (cookieName) {
    //         //     var neededAttributes = {
    //         //         // Here you pass the same attributes that were used when the cookie was created
    //         //         // and are required when removing the cookie
    //         //     };
    //         //     Cookies.remove(cookieName, neededAttributes);
    //         // });
    //         resolve(response.data);
    //       })
    //     .catch(error => {

    //     });
    // });
  },
  register({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    commit("REGISTER");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "auth/signup", form)
        .then(response => {
          //const accessToken = response.data.access_token
          //Cookies.set('access_token', accessToken)

          //commit('REGISTER_OK', response.data.user)
          resolve(response.data);
        })
        .catch(error => {
          commit("REGISTER_FAIL");
          reject(error.response.data);
        });
    });
  },
  verify_email({ commit, dispatch }, hash) {
    console.log(dispatch)
    console.log(commit)
    commit("REGISTER");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "auth/verify/email/" + hash)
        .then(response => {
          // commit('REGISTER_OK', response.data.user)
          commit("REGISTER_FAIL");
          resolve(response.data);
        })
        .catch(error => {
          commit("REGISTER_FAIL");
          reject(error.response.data);
        });
    });
  },
  changeImg({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    commit("UPDATE_PROFILE");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "user/change_image", form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          commit("UPDATE_PROFILE_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("UPDATE_PROFILE_FAIL");
          reject(error.response.data);
        });
    });
  },
  updateProfile({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    commit("UPDATE_PROFILE");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "user/update_profile", form)
        .then(response => {
          commit("UPDATE_PROFILE_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("UPDATE_PROFILE_FAIL");
          reject(error.response.data);
        });
    });
  },
  updatePassword({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    commit("UPDATE_PASSWORD");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "user/update_password", form)
        .then(response => {
          Object.keys(Cookies.get()).forEach(function(cookieName) {
            var neededAttributes = {};
            Cookies.remove(cookieName, neededAttributes);
          });
          commit("UPDATE_PASSWORD_OK", response.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("UPDATE_PASSWORD_FAIL");
          reject(error.response.data);
        });
    });
  },
  // loadUser({commit, dispatch}) {
  //     commit('LOAD_USER')
  //     return new Promise((resolve, reject) => {
  //         axios.get(Config.apiPath + 'auth/user')
  //             .then(
  //                 response => {
  //                     commit('LOAD_USER_OK', response.data)
  //                     resolve(response.data)
  //                 })
  //             .catch(error => {
  //                 commit('LOAD_USER_FAIL')
  //                 reject(error.response.data)
  //             })
  //     })
  // },
  imgBase64({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_USER");
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "auth/user/img/base64")
        .then(response => {
          commit("LOAD_USER_OK", response.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("LOAD_USER_FAIL");
          reject(error.response.data);
        });
    });
  },

  //TODO CAMBIAR ENTORNO
  set_homeSchool({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("SET_SCHOOL", data);
  },
  set_homeStudent({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("SET_STUDENT", data);
  },
  set_homeFather({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("SET_FATHER", data);
  },
  set_homePersonal({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("SET_PERSONAL", data);
  },
  set_homeTeacher({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("SET_TEACHER", data);
  },
  set_homeArea({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("SET_AREA", data);
  }
};

const mutations = {
  CHECK_LOGIN_OK(state, data) {
    state.me = data.user;
    state.permissions = data.permissions;
    state.status = "success";
    state.token = data.access_token;
  },
  LOGIN(state, data) {
    state.status = "loading";
    state.me = data.user;
    state.permissions = data.permissions;
    state.status = "success";
    state.token = data.access_token;
  },
  LOGIN_ID(state, data) {
    state.status = "loading";
    state.me = data;
    //state.permissions = data.permissions;
    state.status = "success";
    state.token = data.access_token;
  },
  ADMIN_LOGIN(state, data) {
    state.status = "loading";
    state.me_admin = data.user;
    state.me = data.user;
    state.permissions = data.permissions;
    state.status = "success";
    state.token = data.access_token;
  },
  LOGIN_OK(state, { data, item }) {
    state.id_rol = item.id_rol;
    state.me_rol = item;
    console.log('DATA PERMISSIONS'+data.permissions)
    state.permissions = data.permissions;
    state.token = data.token;
    state.status = "success";

    Cookies.set("access_token", data.token.token);
  },
  LOGIN_FAIL() {},

  LOGOUT_OK(state) {
    state.me = null;
    state.me_school = null;
    state.me_student = null;
    state.me_father = null;
    state.me_personal = null;
    state.me_teacher = null;
    state.me_area = null;
    state.permissions = [];
    state.token = null;
    state.status = "error";

    Cookies.remove("access_token");
  },
  CHECK_LOGIN_FAIL(state) {
    state.me = null;
    state.permissions = [];
    state.token = null;
    state.status = "error";
  },

  REGISTER_OK(state, user) {
    state.me = user;
  },

  UPDATE_PROFILE_OK(state, user) {
    state.me = user;
  },

  //TODO SETEAR ENTORNO
  SET_SCHOOL(state, user) {
    state.me_school = user;
  },
  SET_STUDENT(state, user) {
    state.me_student = user;
  },
  SET_FATHER(state, user) {
    state.me_father = user;
  },
  SET_PERSONAL(state, user) {
    state.me_personal = user;
  },
  SET_TEACHER(state, user) {
    state.me_teacher = user;
  },
  SET_AREA(state, user) {
    state.me_area = user;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
