<template>
  <div id="UpdateRepresentative">
    <br />
    <div class="content">
      <b-card title="Modificar Representante">
        <b-row>
          <b-col md="6" style="text-align: -webkit-left">
            <p>Por favor completa los campos para modificar el representante.</p>
          </b-col>
          <b-col md="3" style="text-align: -webkit-right">
             <a v-if="image" :href="image" target="a_blank"><img class="img-fluid" style="max-width:100px" :src="image"></a>
             
               </b-col>
          <b-col md="3" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-all'}"
            >Listado de representantes</router-link>
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Datos Personales" active>
                <ValidationObserver v-slot="{ handleSubmit }">
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
             
                @submit.prevent="handleSubmit(onSubmitPersonal)"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nombre" label-for="first_name">
                         <ValidationProvider name="nombre" rules="required" immediate v-slot="{ errors }">
          <b-form-input
                        id="first_name"
                        v-model="first_name"
                        name="first_name"
                        placeholder="Ingrese Nombre"
                      ></b-form-input>
        <span style="color:red">{{ errors[0] }}</span>
      </ValidationProvider>

                    
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Apellido" label-for="last_name">
                      <b-form-input
                        id="last_name"
                        v-model="last_name"
                        name="last_name"
                        placeholder="Ingrese Apellido"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                      <b-form-group label="Cédula" label-for="dni">
                        <b-input-group>
                          <template v-slot:prepend>
                            <b-form-select
                              value-field="id"
                              text-field="name"
                              v-model="dni_selected"
                              :options="dnis"
                            ></b-form-select>
                          </template>
                          <b-form-input
                            id="dni"
                            v-model="dni"
                            name="dni"
                            type="number"
                            :formatter="formatNumber"
                            placeholder="Solo Números"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                   <!-- <b-form-group label="Cedula" label-for="dni">
                      <b-form-input id="dni" v-model="dni" name="dni" placeholder="DNI"></b-form-input>
                    </b-form-group>-->
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Fecha de Nacimiento" label-for="birthday">
                       <date-picker
                        id="birthday"
                        name="birthday"
                        mode="single"
                        :max-date="new Date()"
                        
                        v-model="birthday"
                        :popover="{ placement: 'bottom', visibility: 'click' }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Teléfono" label-for="phone">
                      <b-form-input id="phone" v-model="phone" name="phone" placeholder="Teléfono"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                     <b-form-group label="Registrado" label-for="phone">
                      <label>{{moment(created_at).format('DD-MM-YYYY hh:mm a')}}</label>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Correo" label-for="email">
                      <b-form-input id="email" v-model="email" name="email" placeholder="Correo"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Tipo de Sangre</div>
                        <b-form-select
                          v-model="blood_type"
                          :options="bloodTypeArray"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                   <b-col md="4">
                     <b-form-group label="Actualizado" label-for="phone">
                      <label>{{moment(updated_at).format('DD-MM-YYYY hh:mm a')}}</label>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3">Modificar Datos Personales</b-button>
                  </b-col>
                </b-row>
              </form>
                </ValidationObserver>
            </b-tab>
            <b-tab title="Datos Representante">
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
                @submit.prevent="onSubmitRepresentative"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Actividad Comercial" label-for="commercial_activity">
                      <b-form-input
                        id="commercial_activity"
                        v-model="commercial_activity"
                        name="commercial_activity"
                        placeholder="Actividad Comercial"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Dirección de Casa" label-for="address_home">
                      <b-form-input
                        id="address_home"
                        v-model="address_home"
                        name="address_home"
                        placeholder="Dirección"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Dirección de Oficina" label-for="address_office">
                      <b-form-input
                        id="address_office"
                        v-model="address_office"
                        name="address_office"
                        placeholder="Dirección"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Profesión" label-for="profession">
                      <b-form-input
                        id="profession"
                        v-model="profession"
                        name="profession"
                        placeholder="Profesión"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="4"></b-col>
                  <b-col md="12 text-right">
                    <b-button
                      type="submit"
                      variant="outline-success"
                      class="m-3"
                    >Modificar datos de representante</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Credenciales">
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
                @submit.prevent="onSubmitPassword"
              >
                <b-row>
                  <b-col md="12">
                    <p>Desde este formulario puede actualizar la clave de acceso del representante a la plataforma.</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Contraseña" label-for="password">
                      <b-form-input id="password" v-model="password" name="password" placeholder></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Repita la contraseña" label-for="password_confirm">
                      <b-form-input
                        id="password_confirm"
                        v-model="password_confirm"
                        name="password_confirm"
                        placeholder
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="12 text-right">
                    <b-button
                      type="submit"
                      variant="outline-success"
                      class="m-3"
                    >Modificar datos de representante</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
             <b-tab title="Representados">
              <form
                v-if="!loading"
                style="width: 100%!important;"
                method="post"
                @submit.prevent="onSubmitPassword"
              >
                <b-row>
                  <b-col md="12">
                    <p>Alumnos asociados a este representante</p>
                  </b-col>
                </b-row>
                <b-row>
                   <b-col md="12">
                  <b-list-group>
                    <b-list-group-item v-for="student in studentList" :key="student.id">
                      <b-row>
                        <b-col md="4">
                          <p><strong>{{student.user.full_name}}</strong></p>
                          <p>{{student.user.dni}}</p>
                          
                        </b-col>
                        <b-col md="4">
                           <p>Email: {{student.user.email}}</p>
                           <p>Sección: {{student.section.name}}</p>
                        </b-col>
                         <b-col md="4">
                           <p>Registrado: {{student.user.created_at}}</p>
                           <p>Actualizado: {{student.user.updated_at}}</p>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                 
                  </b-list-group>
                   </b-col>

                 
                </b-row>
              </form>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Moment from "moment";
import Swal from "sweetalert2";
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import * as rules from 'vee-validate/dist/rules';


// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('es', es);

export default {
  name: "UpdateRepresentative",
  components: {
    ValidationProvider,
    ValidationObserver,
   // extend,
    //localize
  },
  data() {
      
    return {
      moment: Moment,
      created_at:'',
      updated_at:'',
      //USER DATA
      first_name: "",
      last_name: "",
      dni: "",
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      blood_type: 1,
      bloodTypeArray: [],
      //REPRESENTATIVE DATA
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      id_user: "",
      id_representative: "",
      image:"",
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: new Date(),
      isM: "",
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true
        }
      },
      studentList:[],
        dni_selected: "V",
      dnis: [
        {
          id: "V",
          name: "V",
        },
        {
          id: "E",
          name: "E",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      representative: state => state.representative.representative,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
     this.representativeLoad();
    this.allRoles();
   
  },
  methods: {
    ...mapActions([
      "updateRepresentative",
      "updateUser",
      "loadBlood_Active",
      "loadStudent_by_representative",
      "addToastMessage"
    ]),
     formatNumber(e){
     return String(e).substring(0,8);
  },
    allRoles() {
      let form = {
        active: 1
      };

      this.loadBlood_Active(form)
        .then(response => {
          if (response.code === 200) {
            this.bloodTypeArray = response.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
       let form2 = {
        id_father: this.id_representative
      };
       this.loadStudent_by_representative(form2)
        .then(response => {
          if (response.code === 200) {
            this.studentList = response.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    representativeLoad() {
      if (!this.representative.id) {
        this.$router.push("/director");
      }
      console.log(this.representative);
      this.id = this.representative.id;
      this.id_representative = this.representative.id;
      this.id_user = this.representative.user.id;

      this.first_name = this.representative.user.first_name;
      this.username = this.representative.user.username;
      this.last_name = this.representative.user.last_name;
      //this.dni = this.representative.user.dni;
      this.dni = this.representative.user.dni.substr(2);
      if (this.representative.user.birthday) {
        this.birthday = new Date(this.representative.user.birthday);
      } else {
        this.birthday = new Date();
      }

      this.email = this.representative.user.email;
      this.image = this.representative.user.image;
      this.phone = this.representative.user.phone;
      this.blood_type = this.representative.user.id_blood_type;
      //REPRESENTATIVE DATA
      this.commercial_activity = this.representative.commercial_activity;
      this.address_home = this.representative.address_home;
      this.address_office = this.representative.address_office;
      this.profession = this.representative.profession;
      this.created_at = this.representative.user.created_at;
      this.updated_at = this.representative.user.updated_at;
    },
    onSubmitPersonal() {
      this.loading = true;
 
      let form = {
        first_name: this.first_name,
        last_name: this.last_name,
        //dni: this.dni,
        
        birthday: Moment(this.birthday).format("YYYY-MM-DD"),
        email: this.email,
        password: this.password,
        phone: this.phone,
        id_blood_type: this.blood_type,
        id_user: this.id_user
      };
      if(this.dni != ''){
        form.dni = this.dni_selected + "-" + this.dni;
      }
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este representante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar"
      }).then(result => {
        if (result.value) {
          this.updateUser(form)
            .then(response => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "El representante ha sido actualizado",
                  icon: "success"
                }).then(result2 => {
                  console.log(result2);
                  this.$router.push({
                    name: "director-representative-all"
                  });
                });
              }
                if (response.code == 409) {
                   this.loading = false;
                    Swal.fire({
                      title: "Cédula existente",
                      text: "Un usuario con esta cédula ya se encuentra registrado, el usuario es: "+response.data.full_name,
                      icon: "warning"
                    })
                  }
                   if (response.code == 410) {
                      this.loading = false;
                    Swal.fire({
                      title: "Correo existente",
                      text: "Un estudiante con esta correo ya se encuentra registrado, el usuario es: "+response.data.full_name,
                      icon: "warning"
                    })
                  }
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    onSubmitRepresentative() {
      this.loading = true;

      let form = {
        commercial_activity: this.commercial_activity,
        address_home: this.address_home,
        address_office: this.address_office,
        profession: this.profession,
        id_user: this.id_user,
        id_school: this.me_rol.id_school,
        id: this.id_representative
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de actualizar a este representante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Actualizar"
      }).then(result => {
        if (result.value) {
          this.updateRepresentative(form)
            .then(response => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "El representante ha sido actualizado",
                  icon: "success"
                }).then(result2 => {
                  console.log(result2);
                  this.$router.push({
                    name: "director-representative-all"
                  });
                });
              }
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    onSubmitPassword() {
      if (this.password_confirm != this.password) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning"
        });
      } else if (this.password == "") {
        Swal.fire({
          title: "Escribe una contraseña",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning"
        });
      } else {
        this.loading = true;

        let form = {
          password: this.password,
          id_user: this.id_user
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de cambiar la contraseña de este representante?",
          icon: "question",
          reverseButtons: true,          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar"
        }).then(result => {
          if (result.value) {
            this.updateUser(form)
              .then(response => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La contraseña del representante ha sido actualizada",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2);
                    this.$router.push({
                      name: "director-representative-all"
                    });
                  });
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      }
    }
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
