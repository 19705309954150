<template>
  <div>
    <br />
    <div class="content">
      <h2>Bienvenido a Clasy Representante</h2>
      <p>Este es tu panel de control, donde podras recibir notificaciones, reportar pagos y ver a toda la información de tus representados</p>
      <br />
      
      <div class="row">
         <div class="col-md-12">
            <b-card>
                 <b-button pill variant="outline-primary" v-on:click="toggleNav()"><v-icon name="bars" style="color: white;"></v-icon>  Abrir Menu</b-button>
          </b-card>
       
         </div>
        <br/>
        <div class="col-md-4">
          <b-card title="Registra a tus Representados"
          img-src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/docs/student1/2021-09-30_015523-representative2-soWAOJ.jpg?authuser=1" img-alt="Card image" img-top>
            <b-card-text>Podrás registrar a tus representados (Estudiantes) e indicar todos sus datos.</b-card-text>
             <b-button pill variant="outline-primary"
              to="/representante/representados/nuevo"
              
            >Registrar un Representado</b-button>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Mis Representados"
          img-src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/docs/student1/2021-09-30_015439-representative1-9pSapF.jpg?authuser=1" img-alt="Card image" img-top>
            <b-card-text>Registra tus pagos, descarga las planillas, y mira el detalle de tus estudiantes</b-card-text>
            <b-button pill variant="outline-primary" to="/representante/representados">Ver Mis Representados</b-button>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Registra el pago de tus estudiantes" img-src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/docs/student1/2021-09-30_015610-payment-MFryW2.jpg?authuser=1" img-alt="Card image" img-top
          >
            <b-card-text>En este portal podrás reportar tus pagos, y ver tu saldo pendiente con la escuela</b-card-text>
          <b-button pill variant="outline-primary" to="/representante/representados">Registrar Pago</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "WelcomeIndex",
  data() {
    return {};
  },
 
  mounted: function() {
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
  },
  methods: {
   
    toggleNav() {
      $("#wrapper").toggleClass("toggled");
    },
   
  
  }
};
</script>
