import axios from "axios";
import * as Config from "../../../config";

const state = {
  enrollments: {}
};

const actions = {
  load_enrollmentsAll_school({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "enrollment/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  load_enrollmentSubjects_school({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "enrollment/subjects/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  load_enrollmentSubjects_user({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            "enrollment/subjects/user/" +
            form.id_user +
            "/" +
            form.id_school
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  save_enrollment_school({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "enrollment/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
};
