<template>
  <div style="width: 100%;" class="p-0 m-0">
    <div
      id="chat_window"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')',
      }"
      style="height: 70%">
      
      <ul class="messages container" ref="container" id="container" @scroll="HandleScroll" style="height: 100%">
        <li
          v-for="msg in messagesx"
          :key="msg.id"
          class="message appeared my-2 py-0"
          v-bind:class="{
            left: msg.id_user != me.id,
            right: msg.id_user == me.id,
          }"
        >
          <div class="text_wrapper my-0 pt-2 pb-1" style="display: inline-block;" :style="{ width: (is_mobile ? '95%' : (!msg.message ? '40%' : (msg.message.length > 70 ? '70%' : (msg.message.length <= 35 ? '35%' : msg.message.length + '%')))) }">
            <div style="display: inline-block; width: 100%">
              <!--<div class="text-left" style="display: inline-block; width: 10%">
                <b-avatar
                  variant="outline-info"
                  v-if="msg.user.image"
                  :src="msg.user.image"
                  style="height: 35px; width: 35px; aspect-ratio: 1; object-fit: cover;"
                ></b-avatar>
                <b-avatar
                  variant="outline-info"
                  v-if="!msg.user.image"
                  src="../../../../../assets/img/logochat.jpg"
                ></b-avatar>
              </div>-->
              
              <div class="text-left" style="display: inline-block; width: 80%">
                <span style="font-size: 14px" v-if="msg.user.full_name"
                  ><strong class="py-0 my-0">{{ msg.user.full_name }}</strong></span
                >
              </div>
              <div v-if="msg.id_user == me.id" class="text-right" style="display: inline-block; width: 20%">
                <b-button
                  v-if="msg.id_user == me.id"
                  @click="duplicateMsg(msg)"
                  v-b-tooltip.hover
                  title="Duplicar"
                  variant="outline-primary"
                  size="sm"
                  class="p-0 px-1"
                >
                  <font-awesome-icon icon="file-alt" style="font-size: 14px" />
                </b-button>
                <b-button
                  v-if="msg.id_user == me.id"
                  @click="deleteMsg(msg.id)"
                  v-b-tooltip.hover
                  title="Eliminar este mensaje"
                  variant="outline-danger"
                  size="sm"
                  class="ml-1 p-0 px-1"
                >
                  <font-awesome-icon icon="trash" style="font-size: 14px" />
                </b-button>
              </div>

              <div class="text-left my-0 py-0" style="display: inline-block; width: 100%">
                <div class="text mt-1 text-center" v-if="msg.url_archive">
                  <a target="a_blank" :href="msg.url_archive" style="width: 100%">
                    <img
                      class="img-fluid"
                      :src="msg.url_archive"
                      v-if="msg.type_archive == 1"
                    />
                    <font-awesome-icon
                      style="color: gray; font-size: 80px;"
                      v-if="msg.type_archive == 3"
                      icon="file-alt"
                    />
                    <div class="row">
                      <audio
                        v-if="msg.type_archive == 2"
                        :src="msg.url_archive"
                        controls
                      />
                    </div>
                    <!--  <v-icon
                      style="color: gray"
                      scale="1"
                      v-if="msg.type_archive == 2"
                      name="play-circle"
                    ></v-icon>-->

                    <!--<span style="font-size: 12px" v-if="msg.type_archive == 2"
                      >Escuchar audio en otra pestaña</span
                    >-->
                    <!--<span style="font-size: 12px" v-if="msg.type_archive == 3"
                      >Ver Documento</span
                    >-->
                    <!--<span style="font-size: 12px" v-if="msg.type_archive == 1"
                      >Ver Imagen</span
                    >--></a
                  >
                </div>

                <div class="text" v-if="msg.type_archive != 2">
                  <span style="font-size: 14px" v-if="checkLink(msg) == 0">
                    {{ msg.message }}</span
                  >
                  <a
                    style="font-size: 12px"
                    v-else-if="checkLink(msg) === 1"
                    target="a_blank"
                    :href="msg.message"
                    >{{ msg.message }}</a
                  >

                  <iframe
                    style="padding-top:20px"
                    width="100%"
                    height="215"
                    v-else
                    :src="'https://www.youtube.com/embed/' + checkLink(msg)"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div class="text-right my-0 py-0" style="display: inline-block; width: 100%">
                <p v-if="msg.delivered" class="my-0 py-0" style="font-size: 10px; color: gray">
                  {{ moment(msg.updated_at).local().format('D MMM YYYY, h:mm a') }}
                </p>

                <v-icon
                  v-else
                  class="text-right"
                  color="#000000"
                  name="clock"
                  style="font-size: 16px" />
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="px-2 clearfix" style="padding-top: 10px; background-color: #E0E0E0;">
        <b-row>
          <b-col md="12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <button
                  class="btn btn-secondary"
                  type="button"
                  :disabled="!send_msg"
                  v-b-toggle.collapse-2
                >
                  <font-awesome-icon icon="paperclip" class="m-0 p-0" style="font-size: 20px" />
                </button>
              </div>

              <b-form-textarea
                v-model="message_post"
                type="text"
                class="form-control"
                placeholder="Mensaje"
                @keyup.enter="sendWS(); message_post = null"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                :disabled="!send_msg"
                max-rows="3"
                no-resize
                autofocus
              />

              <div class="input-group-append">
                <button
                  @click="sendWS()"
                  class="btn btn-secondary"
                  :disabled="!send_msg"
                  type="button"
                >
                  <font-awesome-icon icon="paper-plane" class="m-0 p-0" style="font-size: 20px" />
                </button>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-collapse id="collapse-2">
          <b-row v-if="!send_msg" style="padding-bottom: 10px">
            <b-col sm="3" class="text-center mb-1">
              <b-button
                v-b-modal="'modal-upload' + room"
                title="Adjuntar archivo"
                variant="success"
                :disabled="!send_msg"
                size="sm"
              >
                <v-icon name="photo-video" /> Adjuntar imagen
              </b-button>
            </b-col>
            <b-col sm="3" class="text-center mb-1">
              <b-button
                v-b-modal="'modal-upload-document' + room"
                title="Adjuntar archivo"
                variant="info"
                :disabled="!send_msg"
                size="sm"
              >
                <v-icon name="file-pdf" /> Adjuntar documento
              </b-button>
            </b-col>
            <b-col sm="3" class="text-center mb-1">
              <b-button
                v-b-modal="'modal-upload-audio' + room"
                title="Adjuntar archivo"
                variant="primary"
                :disabled="!send_msg"
                size="sm"
              >
                <v-icon name="microphone" /> Adjuntar nota de voz
              </b-button>
            </b-col>
            <b-col sm="3" class="text-center mb-1">
              <b-button
                @click="modalShow = !modalShow"
                title="Insertar duplicado"
                variant="danger"
                :disabled="!send_msg"
                size="sm"
              >
                <v-icon name="file-alt" /> Insertar duplicado
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </div>
    </div>

    <!--<div class="message_template">
      <li class="message left appeared">
        <div class="avatar">Hola</div>
        <div class="text_wrapper">
          Hola
          <div class="text">Holaa</div>
        </div>
      </li>
    </div>-->

    <b-modal
      :id="'modal-upload' + room"
      title="Subir imagen"
      hide-footer
      @hide="file_description = ''; preview_image = ''"
    >
      <b-row>
        <b-col md="12">
          <b-form
            id="uploadForm"
            class="row"
            method="post"
            @submit.prevent="uploadArchive(1)"
          >
            <div class="col-md-12">
              <b-form-group label="Descripción" label-for="file_name">
                <b-form-input
                  id="file_description2"
                  v-model="file_description"
                  name="file_description2"
                  placeholder="Descripción del archivo"
                ></b-form-input>
              </b-form-group>

              <!-- Styled -->
              <b-form-group label="Imagen a subir" label-for="file_name">
                <b-form-file
                  v-model="fileToUpload"
                  accept="image/*"
                  :state="Boolean(fileToUpload)"
                  v-on:change="onFileChange"
                  placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                  drop-placeholder="Arrastre el archivo hasta aquí..."
                  browse-text="Examinar"
                >
                  <img id="blah" src="#" alt="your image"
                /></b-form-file>
              </b-form-group>

              <div v-if="preview_image" class="mt-3 mb-3 text-center">
                Imagen seleccionada:
                {{ fileToUpload ? fileToUpload.name : "" }}

                <img class="text-center img-fluid" :src="preview_image" />
              </div>
            </div>

            <div class="col-12 text-center">
              <b-button
                v-if="!progress"
                :disabled="progress != false"
                pill
                type="submit"
                variant="success"
                style="width: 100%"
                >Subir imagen</b-button
              >

              <b-spinner
                variant="outline-success"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      :id="'modal-upload-document' + room"
      title="Subir documento"
      hide-footer
      @hide="file_description = ''"
    >
      <b-row>
        <b-col md="12">
          <b-form
            id="uploadForm"
            class="row"
            method="post"
            @submit.prevent="uploadArchive(3)"
          >
            <div class="col-md-12">
              <b-form-group label="Descripción" label-for="file_name">
                <b-form-input
                  id="file_description2"
                  v-model="file_description"
                  name="file_description2"
                  placeholder="Descripción del documento"
                ></b-form-input>
              </b-form-group>

              <!-- Styled -->
              <b-form-group label="Documento a subir" label-for="file_name">
                <b-form-file
                v-model="fileToUpload"
                accept=".pdf, .docx, .xlsx, .doc, .txt"
                :state="Boolean(fileToUpload)"
                placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                drop-placeholder="Arrastre el archivo hasta aquí..."
                browse-text="Examinar"
              ></b-form-file>
              </b-form-group>
            </div>
            
            <div class="col-12 text-center">
              <b-button
                v-if="!progress"
                :disabled="progress != false"
                pill
                type="submit"
                variant="success"
                style="width: 100%"
                >Subir documento</b-button
              >

              <b-spinner
                variant="outline-success"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      :id="'modal-upload-audio' + room"
      title="Subir nota de voz"
      hide-footer
    >
      <b-form
        id="uploadForm3"
        class="row"
        method="post"
        @submit.prevent="uploadAudio(2)"
      >
        <div class="col-md-12 text-center">
          <b-form-group v-if="preview_audio" label-for="file_name">
            <!--<vue-record-audio @result="onResult" />-->
            <audio
              v-if="preview_audio && !progress"
              :src="preview_audio"
              controls
            />
            <p
              v-if="preview_audio && !progress"
              style="font-size: 10px"
              class="mt-2 mb-0 p-0"
            >
              Esta es la nota de voz que has grabado, puedes darle
              play para escucharla antes de subirla
            </p>

            <b-spinner
              variant="outline-success"
              v-show="progress"
              label="Text Centered"
            ></b-spinner>


            <!-- <audio-recorder
              
              upload-url="some url"
              filename="ninja"
              format="mp3"
              :attempts="1"
              :time="2"
              
              :before-recording="callback"
              :pause-recording="callback"
              :after-recording="callback"
              :select-record="callback"
              :before-upload="callback"
              :successful-upload="callback"
              :failed-upload="callback"
              :bit-rate="192"
            />-->
          </b-form-group>

          <!-- Styled -->
        </div>
        <div class="col-md-12 text-center">
          <div
            v-if="recording != false"
            class="spinner-grow text-danger mb-3"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="col-6 text-left">
          <b-button
            :disabled="progress != false"
            pill
            type="button"
            @click="toggleRecording"
            variant="outline-success"
            style="width: 100%"
            ><span v-if="!recording">Grabar nota de voz</span
            ><span v-if="recording"
              >Detener grabación</span
            ></b-button
          >
        </div>

        <div class="col-6 text-left">
          <b-button
            :disabled="progress != false"
            pill
            type="submit"
            variant="outline-success"
            style="width: 100%"
            >Subir nota de voz</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal
      hide-footer
      v-model="modalShow"
      size="lg"
      id="modal-clipboard"
      title="Mensajes en Portapapeles"
    >
      <p class="my-4">
        Estos mensajes los haz duplicado desde otras clases, puedes
        reutilizarlos aquí
      </p>
      <p><strong>Si tu mensaje no aparece presiona </strong></p>
      <b-button @click="getClipboard"
        ><v-icon name="sync" /> Actualizar</b-button
      >
      <br />
      <b-list-group>
        <b-list-group-item v-for="clip in clipboards" :key="clip.id">
          <b-row>
            <b-col md="5">
              <v-icon
                v-if="clip.type_archive == 2"
                scale="2"
                name="play-circle"
              ></v-icon>

              <p>{{ clip.message }}</p>
            </b-col>
            <b-col md="2">
              <p v-if="clip.type_archive == 1">Imagen</p>
              <p v-if="clip.type_archive == 3">Documento</p>
              <p v-if="clip.type_archive == 2">Audio</p>
              <p v-if="clip.type_archive == 0">Texto</p>
            </b-col>
            <b-col md="2">
              <img
                class="img-fluid"
                :src="clip.url_archive"
                v-if="clip.type_archive == 1"
              />
              <a
                v-if="clip.url_archive"
                :href="clip.url_archive"
                target="a_blank"
                ><span v-if="clip.type_archive == 1">Ver Imagen</span>
                <span v-if="clip.type_archive == 2">Escuchar Audio</span>
                <span v-if="clip.type_archive == 3">Ver Documento</span>
              </a>
            </b-col>
            <b-col md="3">
              <b-button
                @click="insert(clip)"
                v-b-tooltip.hover
                title="Insertar aquí"
                variant="outline-success"
              >
                <v-icon name="paper-plane" /> Insertar
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import $ from 'jquery'
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
import ChatWindow from "./chat";
import { integer } from "vee-validate/dist/rules";

var recorder, gumStream;

export default {
  props: {
    messages: Array,
    room: integer,
    send_msg: Boolean
  },
  //props: ["messages", "room"],
  name: "EvaluationChat",
  components: {
    ChatWindow,
  },
  data() {
    return {
      moment: moment,
      language: 'es',
      modalShow: false,
      evaluations: [],
      classes: [],
      tasks: [],
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
      progress: false,
      // moment: Moment,
      fileToUpload: [],
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      rooms: [],
      // messages: [],
      currentUserId: 1234,
      message_post: "",
      message: '',
      messagesx: [],
      saved_msg: '',
      scrolled: 'no',
      force: true,
      clipboards: [],
      preview_image: "",
      preview_audio: "",
      download_audio: "",
      recorder: "",
      gumStream: "",
      recording: false,
      is_mobile: false
    };
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluation.evaluation,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.messagesx = this.messages
    this.loadState()

    if ($(window).width() < 500) {
      this.is_mobile = true
    }

    setInterval(() => {
      try {
        let msgs_view = this.$refs.container

        if (msgs_view && this.scrolled == 'no') {
          if (this.force || msgs_view.scrollHeight >= msgs_view.scrollTop) {
            msgs_view.scrollTo({ left: 0, top: msgs_view.scrollHeight, behavior: 'smooth' })
            this.force = false
          }
        }
      } catch (error) {}
    }, 500)

    this.moment.locale(this.language)

    this.UserConnected()

    this.sockets.subscribe('user_connected', (data) => {
      console.log("USER CONNECTED: "+this.me.id)
      console.log(data)
    })

    this.sockets.subscribe('join_rooms', (data) => {
      console.log("JOINED ROOMS!")
      console.log(data)
    })

    this.sockets.subscribe('message', (data) => {
      if (data.id_room == this.room) {
        if (data.id_user == this.me.id) {
          for (let i = this.messagesx.length - 1; i >= this.messagesx.length - 10; i--) {
            this.messagesx[i].delivered = true
            break
          }
        }
        else {
          data.delivered = true
          
          this.messagesx.push(data)
        }
        
        this.force = true
      }
    })

    this.getClipboard();
    //this.scrollToEnd();
    //var objDiv = document.getElementById("MyDivmssElement");
    //objDiv.scrollTop = objDiv.scrollHeight;
  },

  sockets: {
    disconnect() {
      this.UserConnected()
    },
  },

  methods: {
    ...mapActions([
      "Post",
      "new_Message",
      "loadEvaluation_Messages",
      "delete_Chat_Message",
      "store_Clipboard",
      "load_Clipboard",
      "uploadArchiveMessageRoom",
    ]),
    HandleScroll(el) {
      if ((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight - 100) {
        this.scrolled = 'no'
      }
      else {
        this.scrolled = 'yes'
      }
    },
    UserConnected() {
      console.log("USER CONNECTED!")
      console.log("ID EVALUATION: "+this.evaluation.id)
      this.$socket.emit('user_connected', { id_user: this.me.id })
      this.$socket.emit('join_rooms', { id_user: this.me.id, id_evaluation: this.evaluation.id })
    },

    matchYoutubeUrl(url) {
      if (url) {
        console.log("Finding youtube");
        var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(p)) {
          console.log("Finded");
          return url.match(p)[1];
        }
        return false
      } else {
        return false;
      }
    },
    checkLink(msg) {
      if (!msg.message) {
        return 0;
      }
      if (msg.type_archive != 0) {
        return 0;
      }
      
      let http = msg.message.substring(0, 4);

      let youtube_result = this.matchYoutubeUrl(msg.message);
      if (youtube_result) {
        return youtube_result;
      }

      if (http == "http") {
        return 1;
      }
      if (http == "http") {
        return 1;
      } else {
        return 0;
      }
    },
    callback(msg) {
      console.debug("Event: ", msg);
    },
    toggleRecording() {
      let self = this;
      if (recorder && recorder.state == "recording") {
        this.recording = false;
        console.log("STOP RECORD");
        recorder.stop();
        console.log(gumStream.getAudioTracks());
        gumStream.getAudioTracks()[0].stop();
      } else {
        this.recording = true;
        console.log("START RECORD");
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(function(stream) {
            gumStream = stream;
            var options = { mimeType: "audio/webm" };
            recorder = new MediaRecorder(stream, options);
            recorder.ondataavailable = function(e) {
              var url = URL.createObjectURL(e.data);

              var preview = document.createElement("audio");
              preview.controls = true;
              console.log("URL: " + url);
              //this.preview_audio = url;

              console.log("The blob data:", e.data);
              //let blob = new Blob([e.data], { type: "audio/mpeg" });
              let blob = new Blob([e.data], { type: "audio/mpeg" });
              // var blob = new Blob(e.data, {type: 'video/mp4'});
              console.log("The new blob data:", blob);
              //URL.createObjectURL(blob);
              self.audio_to_upload = blob;
              self.preview_audio = URL.createObjectURL(blob);
              self.download_audio = window.URL.createObjectURL(blob);

              console.log(self.preview_audio);
              console.log(
                "Downloadable audio",
                window.URL.createObjectURL(e.data)
              );
            };
            recorder.start();
          });
      }
    },
    onResult(data) {
      console.log("The blob data:", data);
      //URL.createObjectURL(blob);
      this.audio_to_upload = data;
      this.preview_audio = URL.createObjectURL(data);
      this.download_audio = window.URL.createObjectURL(data);

      console.log(this.preview_audio);
      console.log("Downloadable audio", window.URL.createObjectURL(data));
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadArchive(type) {
      let data = new FormData();
      //let formData = new FormData(document.getElementById("uploadForm"));

      data.append("document", this.fileToUpload);

      let message = this.file_description;
      let id_school = this.me_rol.id_school;
      let id_user = this.me.id;
      let id_room = this.room;

      data.append("message", message);
      data.append("type", type);
      data.append("id_user", id_user);
      data.append("id_school", id_school);
      data.append("id_room", id_room);

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de subir "+(type == 1 ? 'esta imagen?' : 'este documento?'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Subir",
      }).then((result) => {
        if (result.value) {
          this.progress = true;

          this.uploadArchiveMessageRoom(data)
            .then((response) => {
              console.log(response)

              if (response.code === 200) {
                this.progress = false
                this.preview_image = "";
                this.$bvModal.hide("modal-upload" + this.room);
                this.$bvModal.hide("modal-upload-document" + this.room);
                //this.loadState()

                this.messagesx.push({
                  id: response.data.id,
                  id_user: this.me.id,
                  user: {
                    id: this.me.id,
                    image: this.me.image,
                    full_name: this.me.first_name + ' ' + this.me.last_name
                  },
                  message: message,
                  delivered: false,
                  url_archive: response.data.url_archive,
                  type_archive: type,
                  created_at: moment(),
                  updated_at: moment()
                })
                
                this.force = true
                this.file_description = ''
                this.fileToUpload = []
                // this.$router.push({ name: "representative_childrens" });

                let form = {
                  element: 'room_message/deploy_msg',
                  api: 1,
                  data: {
                    id_message: response.data.id
                  }
                }

                this.Post(form)

                /*Swal.fire({
                  title: (type == 1 ? 'Imagen subida' : 'Documento subido')+" correctamente",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                })*/
                  /*.then((result2) => {
                  this.scrollToEnd();
                  Swal.fire({
                    title: "Mensaje Insertado",
                    text: "El mensaje ha sido insertado en esta evaluación",
                    icon: "success",
                  });*/
                  //this.preview_image = "";
                  //this.$bvModal.hide("modal-upload" + this.room);
                  // this.$router.push({ name: "representative_childrens" });
                //});
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Ocurrio un error",
                text:
                  "Comprueba tu conexion con internet, e intentalo mas tarde",
                icon: "warning",
              });
              this.progress = false;
              console.log(error);
            });
        }
      });
    },
    uploadAudio(type) {
      if (this.preview_audio != "") {
        let data = new FormData();

        console.log("FILE FORMATS");
        console.log(this.preview_audio);
        console.log(this.download_audio);
        console.log(this.audio_to_upload);
        //let formData = new FormData(document.getElementById("uploadForm"));

        var fileOfBlob = new File(
          [this.audio_to_upload],
          "testing_Uploading.mp3"
        );
        // var file = new File([blob], "my_image.png",{type:"image/png", lastModified:new Date().getTime()})
        //let blob = new Blob(this.download_audio, {type: 'audio/mpeg-3'});
        data.append("document", fileOfBlob);

        let message = "Nota de voz🎙";
        let id_school = this.me_rol.id_school;
        let id_user = this.me.id;
        let id_room = this.room;

        data.append("message", "Nota de voz🎙");
        data.append("type", type);
        data.append("id_user", id_user);
        data.append("id_school", id_school);
        data.append("id_room", id_room);

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de subir esta nota de voz?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Subir",
        }).then((result) => {
          if (result.value) {
            this.progress = true;
            this.uploadArchiveMessageRoom(data)
              .then((response) => {
                console.log(response)
                if (response.code === 200) {
                  this.progress = false
                  this.preview_audio = "";
                  this.audio_to_upload = "";
                  this.$bvModal.hide("modal-upload-audio" + this.room);
                  //this.loadState()

                  this.messagesx.push({
                    id: response.data.id,
                    id_user: this.me.id,
                    user: {
                      id: this.me.id,
                      image: this.me.image,
                      full_name: this.me.first_name + ' ' + this.me.last_name
                    },
                    message: 'Nota de voz🎙',
                    delivered: false,
                    url_archive: response.data.url_archive,
                    type_archive: type,
                    created_at: moment(),
                    updated_at: moment()
                  })
                  
                  this.force = true

                  let form = {
                    element: 'room_message/deploy_msg',
                    api: 1,
                    data: {
                      id_message: response.data.id
                    }
                  }

                  this.Post(form)                  

                  /*Swal.fire({
                    title: "Nota de voz subida correctamente",
                    icon: "success",
                  })*/
                    /*.then((result2) => {
                      this.scrollToEnd();
                      Swal.fire({
                        title: "Mensaje Insertado",
                        text: "El mensaje ha sido insertado en esta evaluación",
                        icon: "success",
                      });
                      
                      // this.$router.push({ name: "representative_childrens" });
                    });*/
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "Ocurrio un error",
                  text:
                    "Comprueba tu conexion con internet, e intentalo mas tarde",
                  icon: "warning",
                });
                this.progress = false;
                console.log(error);
              });
          }
        });
      } else {
        Swal.fire({
          title: "Graba una nota de voz",
          text:
            "Primero debes grabar un audio para luego subirlo, recuerda darle permisos a tu navegador para que puedas grabar con tu micrófono",
          icon: "warning",
        });
      }
    },
    scrollToEnd: function() {
      var content = this.$refs.container
      console.log(content);
      console.log("scrollTop:" + content.scrollTop);
      if (content.scrollHeight > content.scrollTop) {
        content.scrollTop = content.scrollHeight;
      }
    },
    getClipboard() {
      console.log("Loading Clipboard");
      let form = {
        id_user: this.me.id,
        id_school: this.me_rol.id_school,
      };
      this.load_Clipboard(form)
        .then((response) => {
          if (response.code === 200) {
            this.clipboards = response.data;
            //  resp.data.reverse();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadState() {
      let form = {
        room: this.room,
      };
      console.log("IDROOM ACTUAL:" + this.room);
      this.loadEvaluation_Messages(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response;

            this.messagesx = [];
            for (let i in resp.data) {
              let msg = resp.data[i];
              msg.delivered = true

              this.messagesx.push(msg);
            }

            this.force = true
            //this.scrollToEnd();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //LOAD CLIPBOARD
    },
    duplicateMsg(msg) {
      console.log(msg);
      Swal.fire({
        title: "Duplicación de Mensaje",
        text:
          "Al duplicar el mensaje, podrás ir a otra clase o evaluación y reutilizarlo",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Duplicar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id_user: this.me.id,
            id_school: this.me_rol.id_school,
            message: msg.message,
            format: msg.format,
            url_archive: msg.url_archive,
            type_archive: msg.type_archive,
          };
          this.store_Clipboard(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Duplicado correctamente",
                  icon: "success",
                });
                this.getClipboard();
              }
              if (response.code === 409) {
                Swal.fire({
                  title: "Este archivo ya ha sido duplicado",
                  icon: "success",
                });
                this.getClipboard();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    deleteMsg(msg_id) {
      Swal.fire({
        title: "Eliminacion de Mensaje",
        text: "Esta seguro de eliminar este mensaje",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id_room: this.room,
            id_msg: msg_id,
          };
          this.delete_Chat_Message(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Eliminado correctamente",
                  icon: "success",
                });
                this.loadState();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    insert(mess) {
      console.log(mess);
      Swal.fire({
        title: "Duplicación de Mensaje",
        text: "Se va a copiar este mensaje, dentro del chat seleccionado",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, insertar en el chat",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        let form = {
          id_room: this.room,
          id_user: this.me.id,
          message: mess.message,
          url_archive: mess.url_archive,
          type_archive: mess.type_archive,
          format: mess.format,
        };
        this.new_Message(form)
          .then((response) => {
            console.log("RESPONSE");
            if (response.code == 200) {
              this.message_post = "";
              this.loadState();
              this.scrollToEnd();
              Swal.fire({
                title: "Mensaje Insertado",
                text: "El mensaje ha sido insertado en esta evaluación",
                icon: "success",
              });
              this.modalShow = false;
            }
            if (response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "success",
              }).then((result2) => {
                console.log(result2);
              });
            }
          })
          .catch((error) => {
            console.log("ERROR");
            if (error.response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "error",
              }).then((result2) => {
                console.log(result2);
              });
            }
          });
      });
    },
    send() {
      if (this.message_post == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Escribe un mensaje a enviar",
          icon: "warning",
        });
      } else {
        let form = {
          id_room: this.room,
          id_user: this.me.id,
          message: this.message_post,
        };
        this.new_Message(form)
          .then((response) => {
            console.log("RESPONSE");
            if (response.code == 200) {
              this.message_post = "";
              this.loadState();
              this.scrollToEnd();
            }
            if (response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "success",
              }).then((result2) => {
                console.log(result2);
              });
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "error",
              }).then((result2) => {
                console.log(result2);
              });
            }
          });
        this.$refs.typeBox.focus();
      }
    },
    sendWS() {
      if (!this.message_post || this.message_post.trim() == '')     return

      //msg in messages
      this.messagesx.push({
        id: this.messagesx.length > 0 ? this.messagesx[this.messagesx.length-1].id + 1 : 1,
        id_user: this.me.id,
        user: {
          id: this.me.id,
          image: this.me.image,
          full_name: this.me.first_name + ' ' + this.me.last_name
        },
        message: this.message_post,
        delivered: false,
        url_archive: null,
        type_archive: 0,
        created_at: moment(),
        updated_at: moment()
      })
      
      this.force = true

      this.$socket.emit('message', {
        id_user: this.me.id,
        id_room: this.room,
        message: this.message_post,
      })

      this.message_post = null
    }
  },
};
</script>

<style scoped>
body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}
.container::-webkit-scrollbar {
  width: 7px;
}
.container {
  overflow-y: scroll;
}
.chat_window {
  position: relative;
  width: calc(100%);
  max-width: 800px;
  height: 650px;
  margin-top: -20px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  overflow: hidden;
}

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 15px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}
.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}
.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.top_menu .buttons .button.close {
  background-color: #002d4a;
}
.top_menu .buttons .button.minimize {
  background-color: #d4ffe0;
}
.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}
.top_menu .title {
  text-align: center;
  color: #bcbdc0;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  /*padding: 5px 5px 200px 2px;*/
  margin: 0;
  height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.messages .message.left .text_wrapper {
  background-color: #ffffff;
  margin-left: 20px;
}
.messages .message.left .text_wrapper::after,
.messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}
.messages .message.left .text {
  color: #000000;
}
.messages .message.right .avatar {
  background-color: #012a82;
  float: right;
}
.messages .message.right .text_wrapper {
  background-color: #ffffff;
  margin-right: 20px;
  float: right;
}
.messages .message.right .text_wrapper::after,
.messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #ffffff;
}
.messages .message.right .text {
  color: #000000;
}
.messages .message.appeared {
  opacity: 1;
}
.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}
.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 28px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}
.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}

.bottom_wrapper {
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  margin-top: 60px;
  position: fixed;
  bottom: 0;
}
.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}
.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
}
.bottom_wrapper .send_message {
  width: 70px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
}
.bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #fff;
}
.bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}

.message_template {
  display: none;
}
</style>
