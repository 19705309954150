<template>
  <div>
    <br />
    <div class="content">
      <b-card title="Consultas">
        <b-row>
          <b-col md="10">
            <p>Tu opinión vale, y en esta sección puedes decidir, y votar sobre consultas generadas desde la dirección del colegio.</p>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
        <br />
        <b-row>
          <b-col md="12">
            <div v-for="cons in consults" :key="cons.id">
              <div class="card">
                <div class="card-body">
                  <b-row>
                    <b-col md="7">
                      <h5 class="card-title" style="font-size:25px;">{{cons.title}}</h5>

                      <p class="card-text">{{cons.description}}</p>
                      <p style="font-size:11px">Fecha: {{cons.created_at}}</p>
                    </b-col>
                    <b-col md="5">
                      <b-row>
                        <p style="font-size:12px;text-align:center" v-if="cons.vote == 1">
                          <strong>Su voto fue "Sí, estoy de acuerdo"</strong>
                        </p>
                        <p style="font-size:12px;text-align:center" v-if="cons.vote == 2">
                          <strong>Aun no ha votado</strong>
                        </p>
                        <p style="font-size:12px;text-align:center" v-if="cons.vote == 0">
                          <strong>Su voto fue "No estoy de acuerdo"</strong>
                        </p>
                      </b-row>
                      <b-row>
                        <br />
                        <b-col md="6">
                          <b-button variant="outline-success" size="sm" v-on:click="sendVote(cons,1)"><v-icon name="thumbs-up"/> Sí, Estoy de acuerdo</b-button>
                        </b-col>
                        <b-col md="6">
                          <b-button variant="outline-danger" size="sm" v-on:click="sendVote(cons,0)"><v-icon name="thumbs-down"/> No estoy de acuerdo</b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <br />
            </div>
            <br />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "AllConsult",
  data() {
    return {
      consults: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [5, 10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_user: {},
      loading: false,
      error: "",
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.consults.length;
    },
  },
  mounted() {
    this.allConsult();
  },
  methods: {
    ...mapActions([
      "load_Consult_Representative",
      "changeActiveConsult",
      "loadData_Consult",
      "delete_Consult",
      "store_Vote"
    ]),
    allConsult() {
      let form = {
        id_school: this.me_rol.id_school,
        id_user: this.me.id,
      };
      this.load_Consult_Representative(form)
        .then((response) => {
          if (response.code === 200) {
            this.consults = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendVote(consult,vote) {
        Swal.fire({
        title: "Confirmación de Voto",
        text: "¿Estás seguro de enviar este voto?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Enviar Voto",
        cancelButtonText: "Cancelar",
      }).then((result) => {
          console.log(result)
      let form = {
        id_consult:consult.id,
        id_user: this.me.id,
        vote:vote
      };
      this.store_Vote(form)
        .then((response) => {
          if (response.code === 200) {
            Swal.fire({
                  title: "Voto Enviado",
                  icon: "success",
                });
                this.allConsult();
          }
        })
        .catch((error) => {
          console.log(error);
        });

        });
    },
    editConsult(data) {
      this.loadData_Consult(data).then((response) => {
        console.log(response);
        this.$router.push("/notificacion/editar/" + data.id);
      });
    },
    changeStatus_Consult(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 = data.active === 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de Desactivarlo Ahora?"
          : "¿Estás seguro de Activarlo Ahora?";
      Swal.fire({
        title: title,
        text: text,
        type: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActiveConsult(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  type: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    deleteConsult(row) {
      Swal.fire({
        title: "Eliminar esta Notificacion",
        text: "¿Estás seguro de eliminar esta Notificacion?",
        type: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.delete_Consult(form)
            .then((response) => {
              if (response.code === 200) {
                this.consults.splice(row.index, 1);
                Swal.fire({
                  title: "Notificacion Eliminada",
                  type: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
