<template>
  <div id="AllLapses">
    <br />
    <div class="content">
      <b-row>
        <b-col md="6" style="text-align: -webkit-left">
          <h3>Lista de los momentos creados</h3>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button
            v-b-modal.modal-3
            @click="
              titleModal = 'Crear nuevo Momento';
              addmodal = true;
            "
            >Crear nuevo Momento</b-button
          >
        </b-col>
        <b-col md="3  " style="text-align: -webkit-right">
          <h5>Momentos Registrados: {{ lapses_count }}</h5>
          <b-modal
            id="modal-3"
            hide-footer
            @show="load"
            @hidden="resetModal"
            :title="titleModal"
          >
            <form
              v-if="!loading"
              style="width: 100% !important"
              method="post"
              @submit.prevent="onSubmitLapse"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group label="Momento" label-for="description">
                    <b-form-input
                      id="title"
                      v-model="title"
                      name="title"
                      placeholder
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Periodo" label-for="title">
                    <b-form-select
                      v-model="period"
                      :options="Periods"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-button
                    class="mt-3"
                    variant="outline-primary"
                    type="submit"
                    pill
                    block
                    >Guardar</b-button
                  >
                </b-col>
              </b-row>
            </form>
          </b-modal>
          <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar profesores</router-link>-->
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtrar por"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                v-on:keyup="searchInput"
                type="text"
                placeholder="Escribe para buscar"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="clearFilter"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Por página"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3 px-3">
        <b-col sm="12">
          <div>
            <b-table
              id="my-table"
              show-empty
              small
              fixed
              stacked="md"
              sticky-header="true"
              hover
              :primary-key="id"
              :items="lapsesTMP"
              :fields="field"
              :current-page="currentPage"
              :per-page="perPage"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :busy="isBusy"
            >
              <!--                :filter="filter"-->
              <thead>
                <tr>
                  <th>Cedula</th>
                  <th>Nombre y Apellido</th>
                  <th>asd</th>
                  <th>asdasd</th>
                  <th>asdas</th>
                  <th>asdasdsa</th>
                </tr>
              </thead>
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando ...</strong>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button
                  v-if="row.item.active === 1"
                  size="sm"
                  variant="outline-success"
                  class="mr-2"
                  title="Activado"
                  @click="changeStatus(row.item)"
                >
                  <v-icon name="check-square" />
                </b-button>
                <b-button
                  v-if="row.item.active === 0"
                  size="sm"
                  variant="outline-warning"
                  class="mr-2"
                  title="Desactivado"
                  @click="changeStatus(row.item)"
                >
                  <v-icon name="minus-square" />
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-info"
                  class="mr-2"
                  @click="
                    titleModal = 'Editar Momento';
                    edit_Lapse(row.item);
                    addmodal = false;
                  "
                  v-b-modal.modal-3
                >
                  <v-icon name="edit" />
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="mr-2"
                  @click="delete_Lapse(row)"
                >
                  <v-icon name="trash" />
                </b-button>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col sm="12">
          <div>
            <p class="mt-3">Página actual: {{ currentPage }}</p>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function (e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllLapses",
  data() {
    return {
      addmodal: null,
      titleModal: "",
      title: "",
      period: "",
      lapses_count: 0,
      isBusy: false,
      id: "",
      id_lapse: null,
      items: [],
      item_table: null,
      ttap_selected: null,
      field: [
        {
          key: "name",
          label: "Momento",
          sortable: true,
        },
        {
          key: "period.name",
          label: "Periodo",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      Periods: [],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 30],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      lapses: [],
      lapsesTMP: [],
      loading: false,
      error: "",
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.lapsesTMP.length;
    },
  },
  mounted() {
    this.allLapses();
  },
  methods: {
    ...mapActions([
      "loadLapse_school",
      "loadPeriod_school",
      "changeActiveLapse",
      "deleteLapse",
      "newLapse",
      "updateLapse",
    ]),
    load() {
      this.allPeriods();
    },
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.lapsesTMP = this.lapses;
      this.filter = "";
      return true;
    },
    searchInput() {
      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.lapses.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            studentsTmp.push(el);
          }
        });
        this.lapsesTMP = studentsTmp;
      } else {
        this.lapsesTMP = this.lapses;
      }
      return true;
    },
    allPeriods() {
      let form = {
        id_school: this.me_rol.id_school,
        active: "?active=1"
      };
      this.loadPeriod_school(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.Periods = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allLapses() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.lapses = response.data;
            this.lapsesTMP = response.data;
            console.log(this.lapses.length);
            console.log(this.lapsesTMP);
            this.lapses_count = this.lapses.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit_Lapse(data) {
      console.log(data);
      this.title = data.name;
      this.period = data.id_period;
      this.id_lapse = data.id;
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de desactivarlo ahora?"
          : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActiveLapse(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    delete_Lapse(row) {
      console.log(row.item.id);
      Swal.fire({
        title: "Eliminar Momento",
        text: "¿Estás seguro de eliminar este Momento?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.deleteLapse(form)
            .then((response) => {
              if (response.code === 200) {
                this.lapses.splice(row.index, 1);
                Swal.fire({
                  title: "Momento Eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onSubmitLapse() {
      if (this.title == "") {
        Swal.fire({
          title: "Campo vacio",
          text: "Asegurate de escribir un nombre para el Momento",
          icon: "warning",
        });
      } else {
        this.loading = true;

        let form = {
          id: this.id_lapse,
          id_school: this.me_rol.id_school,
          id_period: this.period,
          name: this.title,
        };
        console.log(form);
        if (this.addmodal) {
          Swal.fire({
            title: "Confirmación",
            text: "¿Estás seguro de agregar este Momento?",
            icon: "question",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            if (result.value) {
              this.newLapse(form)
                .then((response) => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "El Momento ha sido creada correctamente",
                      icon: "success",
                    }).then((result2) => {
                      console.log(result2);
                      this.$nextTick(() => {
                        this.$bvModal.hide("modal-3");
                      });
                      this.allLapses();
                      this.loading = false;
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.loading = false;
                });
            } else {
              this.loading = false;
            }
          });
        } else {
          Swal.fire({
            title: "Confirmación",
            text: "¿Estás seguro de editar este Momento?",
            icon: "question",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Editar",
          }).then((result) => {
            if (result.value) {
              this.updateLapse(form)
                .then((response) => {
                  if (response.code == 200) {
                    Swal.fire({
                      title: "Listo",
                      text: "El Momento ha sido editado correctamente",
                      icon: "success",
                    }).then((result2) => {
                      console.log(result2);
                      this.$nextTick(() => {
                        this.$bvModal.hide("modal-3");
                      });
                      this.allLapses();
                      this.loading = false;
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.$nextTick(() => {
                    this.$bvModal.hide("modal-3");
                    this.loading = false;
                  });
                });
            } else {
              this.loading = false;
            }
          });
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetModal() {
      console.log("resetmodal");
      this.title = "";
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
