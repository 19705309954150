<template>
  <div
    :style="{
      backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')',
    }"
    style="overflow-x: hidden; background-size: contain"
  >
    <b-container>
      <br />
      <b-row>
        <b-col md="3"> </b-col>
        <b-col md="6">
          <b-form center @submit="onSubmit" style="background: transparent">
            <b-card
            img-src="https://storage.googleapis.com/ticktaps-school/header.jpeg" img-alt="Card image" img-top>
             <!-- <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                  <img
                    class="img-responsive center-block d-block mx-auto"
                    style="margin-left: auto; max-width: 200px"
                    src="../../assets/img/logonew.png"
                  />
                </div>
                <div class="col-sm-4"></div>
              </div>-->
              
              <h2 class="mt-2" style="text-align: center">¡Bienvenido a Clasy!</h2>

              <p class="py-0" style="text-align:center">
                <span>Ahora Ticktaps School se llama Clasy y puedes acceder desde:</span><br>
                <span><a href="https://clasy.app" class="px-2 pb-1" style="font-size: 20px; border: 1px solid green; border-radius: 20px">clasy.app</a></span>
              </p>
              <!-- Using props -->
              <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>
              
              <b-input-group class="mt-2" size="lg" prepend="Clave">
                <b-form-input type="password" v-model="password"></b-form-input>
              </b-input-group>

              <div class="row mt-3">
                <div class="col-md-12 text-center">
                  <b-button block pill class="py-2" variant="primary" type="submit">
                    <span v-if="!progress">Acceder a la plataforma</span>
                    <span v-else>
                      <b-spinner
                        style="width: 22px; height: 22px"
                        variant="outline-primary"
                        v-show="progress"
                      ></b-spinner>
                    </span>
                  </b-button>

                  <br />
                  <p class="m-0 p-0">
                    <span>
                      <router-link
                        :to="{ name: 'register' }"
                        class="mr-2"
                        style="text-align: center"
                        >¿No tienes cuenta? Regístrate aquí</router-link
                      >
                    </span>
                    <span> | </span>
                    <span>
                      <router-link :to="{ name: 'recovery' }" class="ml-2"
                        >¿Olvidaste tu contraseña?</router-link>
                    </span>
                  </p>
                  <router-link
                    class="mt-2"
                    :to="{ name: 'first_steps' }"
                    style="color: #5cb85c; text-align: center"
                    >¡Aprende más sobre Clasy!</router-link
                  >
                  
                </div>
              </div>
            </b-card>
          </b-form>
        </b-col>
        <b-col md="3"> </b-col>
      </b-row>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </b-container>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import platform from "platform";
//import Permission from "../../permission";
import { validate } from "vee-validate";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      btnLogin: false,
      email: "",
      password: "",
      listRoles: [],
      auth: {},
    };
  },

  computed: {
    ...mapState({}),
    listRolesHasData() {
      return this.listRoles.length ? true : false;
    },
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  mounted() {
    let device =
      platform.name +
      " - " +
      platform.version +
      " - " +
      platform.os +
      " - " +
      platform.product;
    console.log(device);
  },
  methods: {
    ...mapActions(["login", "getAllRolsByUser", "addToastMessage"]),
    onSubmit(evt) {
      evt.preventDefault();
      console.log("Submit");
      if (this.email == "" || this.password == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Por favor ingresa un correo y una clave",
          icon: "warning",
        });
      } else {
        validate().then((result) => {
          if (result) {
            this.progress = true;
            this.btnLogin = true;
            //GET DEVICE INFO
            let device = null;
            device =
              platform.name +
              " - " +
              platform.version +
              " - " +
              platform.os +
              " - " +
              platform.product;

            this.login({
              email: this.email,
              password: this.password,
              from: 2,
              device: device,
            })
              .then((data) => {
                console.log("LOGIN 200");
                console.log(data);
                if (data.code == 200) {
                  this.auth = data;

                  this.btnLogin = false;
                   console.log(data)
                  this.$router.push("/check");
                  this.progress = false;
                }
                if (data.code == 404) {
                  Swal.fire({
                    title: "Correo no registrado",
                    text: "Por favor comprueba los datos ingresados",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              })

              .catch((data) => {
                this.btnLogin = false;
                if (data.code == 404) {
                  this.addToastMessage({
                    text: "Credenciales incorrectas - Comprueba el correo",
                    icon: "warning",
                  });
                  this.progress = false;
                }
                if (data.code == 401) {
                  this.addToastMessage({
                    text: "Contraseña Incorrecta",
                    icon: "warning",
                  });
                  this.progress = false;
                }
              });
          }
        });
      }
    },
  },
};
</script>