<template>
  <div>
    <br />
    <div class="content">
      <b-row>
        <b-col md="8" v-if="evaluation">
          <h2>Clase: {{ evaluation.title }}</h2>
          <!--<p v-if="!chat_active">Descripción: {{ evaluation.description }}</p>-->
          <p v-if="!chat_active">Profesor: {{ me.first_name + ' ' + me.last_name }}</p>
          <p v-else>¡Bienvenido al salón de clase!</p>
        </b-col>
        <b-col md="4" class="text-right" align-self="center">
          <b-button
            v-if="!chat_active"
            style="display: inline-block"
            class="btn btn-info"
            @click="$router.go(-1)">
            <v-icon name="angle-left" />
            Volver a {{ label == 'Clase' ? 'clases' : 'evaluaciones' }}
          </b-button>
          <b-button
            v-if="chat_active"
            style="display: inline-block"
            class="btn btn-info"
            @click="chat_active = false">
            <v-icon name="angle-left" />
            Volver
          </b-button>
        </b-col>

        <b-col v-if="!chat_active" md="12">
          <b-row>
            <b-col md="9"> 
              <b-button
                size="sm"
                variant="primary"
                class="ml-2 mb-2"
                pill
                @click="modalDuplicateShow = !modalDuplicateShow"
              >
                <span class="mr-1"> Duplicar </span>
                <v-icon name="clipboard-list" />
              </b-button>

              <b-modal
                v-model="modalDuplicateShow"
                hide-footer
                :title="'Duplicación de clase desde: ' + evaluation.section.name"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Duplicar en sección</div>
                        <b-form-select
                          v-model="section"
                          :options="sectionList"
                          value-field="section"
                          size="sm"
                          @input="section_detail = section"
                          text-field="section.name"
                        ></b-form-select>
                        <div class="mb-2">En la materia</div>
                        <b-form-select
                          v-model="subject"
                          :options="subjectList"
                          value-field="subject"
                          size="sm"
                          @input="subject_detail = subject"
                          text-field="subject.name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                    <p v-if="evaluation">
                      Esta clase será duplicada en la materia:
                      <strong
                        ><span v-if="subject_detail">{{
                          subject_detail.name
                        }}</span></strong
                      >
                      por el profesor
                      <strong>{{ evaluation.teacher.user.full_name }}</strong>
                      en la sección
                      <strong
                        ><span v-if="section_detail">{{
                          section_detail.name
                        }}</span></strong
                      >
                    </p>
                    <p>
                      La clase será duplicada, incluyendo toda la información
                      escrita en el <strong>canal</strong>, los mensajes del grupo y
                      tareas no serán duplicados
                    </p>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      class="ml-2 p-1"
                      pill
                      block
                      @click="duplicate_Evaluation(evaluation)"
                      >Duplicar Clase</b-button
                    >
                  </b-col>
                </b-row>
              </b-modal>
          
              <b-button
                size="sm"
                variant="danger"
                class="ml-2 mb-2"
                pill
                @click="delete_Evaluation(evaluation)"
              >
                <span class="mr-1"> Borrar {{ label.toLowerCase() }}</span>
                <v-icon name="trash" />
              </b-button>

              <b-button
                size="sm"
                variant="success"
                class="ml-2 mb-2"
                pill
                :disabled="evaluation.teacher_assisted == 1"
                @click="checkAssistanceTeacher"
                data-toggle="tooltip"
                data-placement="top"
                :title="
                  ('Asistencia marcada el:' +
                    evaluation.teacher_assisted_datetime)
                    | format_date
                "
              >
                <span class="mr-1" v-if="evaluation.teacher_assisted == 0">
                  Marcar mi asistencia
                </span>
                <span class="mr-1" v-if="evaluation.teacher_assisted == 1"
                  >Asistencia Marcada
                </span>
                <v-icon name="clipboard-list" />
              </b-button>
              <br>
              <span v-if="evaluation.teacher_assisted == 1" class="ml-2 pb-2 mb-2 pt-0 mt-0" style="font-size: 16px;">Asistencia marcada el: {{ evaluation.teacher_assisted_datetime | format_date}}</span>
            </b-col>

            <b-col md="3" class="text-right" align-self="center">
              <b-button
                style="display: inline-block;"
                class="btn btn-info py-2"
                @click="loadState(); chat_active = true"
              >
                <v-icon name="comments" />
                <span class="mx-1"> Ir al salón de clase</span>
                <v-icon name="angle-right" />
              </b-button>
            </b-col>
          </b-row>
          <hr />

          <div class="row scroll">
            <b-col md="12">
              <b-row class="mx-auto">
                <b-badge v-if="evaluation.duplicated == 2" variant="warning" style="font-size: 20px; width: 100%">
                  <b-col md="12" class="text-center">
                    Esta clase es duplicada
                  </b-col>
                </b-badge>
              </b-row>
            </b-col>

            <b-col md="6" class="mr-0 pr-0 text-center">
              <b-list-group-item style="height: 100%">
                <img
                  v-if="evaluation_image"
                  :src="evaluation_image"
                  height="150px"
                  width="auto"
                  alt=""
                />
                <div v-else style="height: 150px;">
                  <br><br>
                  <p class="mt-3">No hay imagen para esta {{ label.toLowerCase() }}</p>
                </div>
              </b-list-group-item>
            </b-col>

            <b-col md="6" class="ml-0 pl-0 text-center">
              <b-list-group-item>
                <iframe
                  v-if="
                    matchYoutubeUrl(evaluation.url_video) &&
                      evaluation.url_video
                  "
                  class="my-0 py-0"
                  width="100%"
                  height="150px"
                  :src="
                    'https://www.youtube.com/embed/' +
                      matchYoutubeUrl(evaluation.url_video)
                  "
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div v-else style="height: 150px;">
                  <br><br>
                  <p class="mt-3">No hay video para esta {{ label.toLowerCase() }}</p>
                </div>
              </b-list-group-item>
            </b-col>

            <b-col md="6" class="mr-0 pr-0">
              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="bell" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Estatus de la {{ label }}</strong>
                    </p>-->
                    <b-form-select
                      v-if="status_evaluation_edit"
                      v-model="evaluation.status_evaluation"
                      class="my-0 py-0"
                      :options="status_evaluations"
                    ></b-form-select>
                    <p v-if="!status_evaluation_edit" class="my-0 py-0">
                      <span v-if="evaluation.status_evaluation == 1">Clase planificada</span>
                      <span v-else-if="evaluation.status_evaluation == 2">Clase iniciada</span>
                      <span v-else-if="evaluation.status_evaluation == 3">Clase culminada</span>
                      <span v-else>Clase cerrada</span>
                    </p>
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="status_evaluation_edit = !status_evaluation_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>

                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="status_evaluation_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="chalkboard-teacher" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Título de la {{ label }} </strong>
                    </p>-->
                    <b-form-input
                      v-if="title_edit"
                      id="first_name"
                      v-model="evaluation.title"
                      @keyup.enter="onSubmitUpdateEvaluaton"
                      name="first_name"
                    />
                    <p v-if="!title_edit" class="mb-0 py-0">{{ evaluation.title }}</p>
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="title_edit = !title_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="title_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="info-circle" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Descripción de la {{ label }}</strong>
                    </p>-->
                    <b-form-textarea
                      v-if="description_edit"
                      id="first_name"
                      v-model="evaluation.description"
                      name="first_name"
                    />
                    <p v-if="!description_edit" class="mb-0 py-0">{{ evaluation.description }}</p>
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="description_edit = !description_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="description_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="calendar-alt" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Fecha de la {{ label }}</strong>
                    </p>-->

                    <b-row>
                      <b-col md="6">
                        <b-form-datepicker
                          id="birthday"
                          v-if="deadline_date_edit"
                          v-model="deadline_to_update"
                          :hide-header="true"
                          placeholder="Fecha"
                          label-no-date-selected="Indica una fecha"
                          label-help="Usa las flechas para navegar entre las fechas"
                          locale="es"
                        />
                      </b-col>
                      <b-col md="6">
                        <b-form-timepicker
                          v-if="deadline_date_edit"
                          v-model="time_to_update"
                          label-close-button="Cerrar"
                          label-no-time-selected="Indica una hora"
                          placeholder="Hora"
                          locale="es"
                        ></b-form-timepicker>
                      </b-col>
                    </b-row>
                    
                    <p v-if="evaluation.deadline" class="mb-0 py-0">
                      <span v-if="deadline_date_edit">Fecha actual:</span>
                      <span v-else>Fecha de la {{ label.toLowerCase() }}:</span>
                      {{ evaluation.deadline | format_date }}
                    </p>
                  </b-col>

                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="deadline_date_edit = !deadline_date_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateDatesEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="deadline_date_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="calendar-check" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Fecha de entrega de tarea</strong>
                    </p>-->

                    <b-row>
                      <b-col md="6">
                        <b-form-datepicker
                          id="birthday"
                          v-if="deadlinetask_date_edit"
                          v-model="deadlinetask_to_update"
                          :hide-header="true"
                          placeholder="Fecha"
                          label-no-date-selected="Indica una fecha"
                          label-help="Usa las flechas para navegar entre las fechas"
                          locale="es"
                        />
                      </b-col>
                      <b-col md="6">
                        <b-form-timepicker
                          v-if="deadlinetask_date_edit"
                          v-model="timetask_to_update"
                          placeholder="Hora"
                          label-close-button="Cerrar"
                          label-no-time-selected="Indica una hora"
                          locale="es"
                        ></b-form-timepicker>
                      </b-col>
                    </b-row>

                    <p class="mb-0 py-0">
                      <span v-if="deadlinetask_date_edit">Fecha actual: </span>
                      <span v-if="!deadlinetask_date_edit">Fecha de entrega: </span>
                      <span v-if="evaluation.tasks_deadline">{{ evaluation.tasks_deadline | format_date}}</span>
                      <span v-else>No definida</span>
                    </p>
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="deadlinetask_date_edit = !deadlinetask_date_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateDatesTaskEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="deadlinetask_date_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
              
            </b-col>
            
            <b-col md="6" class="ml-0 pl-0">
              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="camera" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Imagen de la {{ label }} </strong>
                    </p>-->
                    <b-form-file
                      v-if="image_edit"
                      v-model="evaluation.url_image"
                      accept="image/*"
                      :state="Boolean(evaluation.url_image)"
                      placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                      drop-placeholder="Arrastre el archivo hasta aquí..."
                      browse-text="Examinar"
                    >
                      <img id="blah" src="#" alt="your image"
                    /></b-form-file>

                    <!-- <b-form-input
                        v-if="image_edit"
                        id="url_link"
                        v-model="evaluation.url_document"
                        name="url_link"
                        placeholder="https://ejemplo.com"
                      /> -->

                    <p v-if="!evaluation_image && !image_edit" class="mb-0 py-0" style="color: gray">
                      No hay imagen para esta {{ label.toLowerCase() }}
                    </p>
                    <a
                      class="mb-0 py-0"
                      target="a_blank"
                      v-if="evaluation_image && !image_edit"
                      :href="evaluation_image"
                      >Ver imagen de la {{ label.toLowerCase() }}</a
                    >
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="image_edit = !image_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="image_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="video" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>URL de video de la {{ label }} </strong>
                    </p>-->
                    <b-form-input
                      v-if="video_edit"
                      id="url_link"
                      v-model="evaluation.url_video"
                      name="url_link"
                      placeholder="URL de Youtube"
                    />

                    <p v-if="!evaluation.url_video && !video_edit" class="mb-0 py-0" style="color: gray">
                      No hay video para esta {{ label }}
                    </p>

                    <a
                      class="mb-0 py-0"
                      target="a_blank"
                      v-if="evaluation.url_video && !video_edit"
                      :href="evaluation.url_video.indexOf('http') >= 0 ? evaluation.url_video : 'https://'+evaluation.url_video"
                      >Ver video de la {{ label.toLowerCase() }}</a
                    >
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="video_edit = !video_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="video_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="file-pdf" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Documento de la {{ label }} </strong>
                    </p>-->
                    <b-form-file
                      v-if="url_edit2"
                      v-model="evaluation.url_document"
                      accept=""
                      :state="Boolean(evaluation.url_document)"
                      placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                      drop-placeholder="Arrastre el archivo hasta aquí..."
                      browse-text="Examinar"
                    >
                    /></b-form-file>

                    <!-- <b-form-input
                      v-if="url_edit2"
                      id="url_link"
                      v-model="evaluation.url_document"
                      name="url_link"
                      placeholder="https://ejemplo.com"
                    /> -->

                    <p v-if="!evaluation_doc && !url_edit2" class="mb-0 py-0" style="color: gray">
                      No hay documento para esta {{ label.toLowerCase() }}
                    </p>
                    <a
                      v-if="evaluation_doc && !url_edit2"
                      class="mb-0 py-0"
                      target="a_blank"
                      :href="evaluation.url_document"
                      >Ver documento de la {{ label.toLowerCase() }}</a
                    >
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="url_edit2 = !url_edit2"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="url_edit2"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="globe" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>URL de la {{ label }} </strong>
                    </p>-->
                    <b-form-input
                      v-if="url_edit"
                      id="url_link"
                      v-model="evaluation.url_link"
                      name="url_link"
                      @keyup.enter="onSubmitUpdateEvaluaton"
                      placeholder="https://ejemplo.com"
                    />

                    <p v-if="!evaluation.url_link && !url_edit" class="mb-0 py-0" style="color: gray">
                      No hay URL para esta {{ label.toLowerCase() }}
                    </p>

                    <a
                      class="mb-0 py-0"
                      target="a_blank"
                      v-if="evaluation.url_link && !url_edit"
                      :href="evaluation.url_link.indexOf('http') >= 0 ? evaluation.url_link : 'https://'+evaluation.url_link"
                      >Ver URL de la {{ label.toLowerCase() }}</a
                    >
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="url_edit = !url_edit"
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="url_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-list-group-item>
                <b-row>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <font-awesome-icon style="color: #003e79; font-size: 25px" icon="microphone" />
                  </b-col>
                  <b-col md="10" class="mx-0 px-0 pl-2" align-self="center">
                    <!--<p class="my-0 py-0">
                      <strong>Audio de la {{ label }}</strong>
                    </p>-->
                    <!--<av-line
                      :cors-anonym="true"
                      :audio-src="evaluation.url_voice"
                    >
                    </av-line
                  >-->
                    <p v-if="!evaluation.url_voice" class="mb-0 py-0" style="color: gray">
                      No hay audio para esta {{ label.toLowerCase() }}
                    </p>

                    <div v-else class="row">
                      <!--<div class="col-md-12">
                        <audio-player
                          style="width:100%"
                          :src="evaluation.url_voice"
                        />
                      </div>-->
                      <audio controls v-if="evaluation.url_voice">
                        <source :src="evaluation.url_voice" type="audio/ogg" />
                        Tu navegador no soporta este elemento de audio
                      </audio>
                    </div>
                  </b-col>
                  <b-col md="1" class="mx-0 px-0 text-center" align-self="center">
                    <!--<b-button
                      class="mx-auto px-auto"
                      style="width: 32px"
                      @click="url_edit = !url_edit"
                      disabled
                      variant="outline-primary"
                      size="sm"
                    >
                      <v-icon name="edit" />
                    </b-button>
                    <br>
                    <b-button
                      @click="onSubmitUpdateEvaluaton"
                      class="mt-2 mx-auto px-auto"
                      style="width: 32px"
                      v-if="url_edit"
                      variant="outline-success"
                      size="sm"
                    >
                      <v-icon name="save" />
                    </b-button>-->
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-col>
          </div>

          <br>
          <br>
          <br>
        </b-col>
        
        <b-col v-else md="12">
          <b-row>
            <!--<b-col md="7">
              <h4>Mensajes de la {{ label }}</h4>
            </b-col>-->
            <b-col md="5">
              <b-button
                v-if="false"
                type="button"
                @click="loadState"
                variant="outline-success"
                size="sm"
                pill
                >Actualizar Mensajes <v-icon name="sync" />
              </b-button>
            </b-col>
          </b-row>

          <b-tabs card>
            <b-tab title="Canal" class="m-0 p-0" style="width: 100%">
              <chat-window
                style="width: 100%; height: 70vh"
                :messages="messages_channel"
                :room="room_channel"
                :id_room_channel="room_channel"
              />
            </b-tab>
            <b-tab title="Grupal" active class="m-0 p-0" style="width: 100%">
              <chat-window
                style="width: 100%; height: 70vh"
                :messages="messages_group"
                :room="room_group"
                :id_room_group="room_channel"
              />
            </b-tab>

            <b-tab
              title="Tareas enviadas"
              v-if="evaluation.type_evaluation.id == 2"
            >
              <tasks></tasks>
            </b-tab>
            <b-tab title="Asistencia">
              <assistances></assistances>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
import ChatWindow from "./chat";
import Tasks from "./tasks";
import Assistances from "./assistances";

export default {
  name: "EvaluationDetail",
  components: {
    ChatWindow,
    Tasks,
    Assistances,
  },
  filters: {
    format_date: function(created_at) {
      if (created_at) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")+' - '+moment(created_at).format("h:mm a");
      }
      else {
        return 'No definida'
      }
    },
  },
  data() {
    return {
      moment: moment,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      status_evaluations: [
        //{ value: null, text: "Selecciona un estatus de clase" },
        { value: 1, text: "Clase planificada" },
        { value: 2, text: "Clase iniciada" },
        { value: 3, text: "Clase culminada" },
        { value: 4, text: "Clase cerrada" },
      ],
      //preview_audio: "",
      section: 0,
      sectionList: [],
      subject: 0,
      modalDuplicateShow: false,
      url_edit: false,
      url_edit2: false,
      image_edit: false,
      video_edit: false,
      title_edit: false,
      status_evaluation_edit: false,
      description_edit: false,
      deadline_date_edit: false,
      time_edit: false,
      deadline_to_update: "",
      time_to_update: "",
      evaluation_image: null,
      evaluation_doc: null,

      deadlinetask_to_update: "",
      timetask_to_update: "",
      deadlinetask_date_edit: false,

      evaluations: [],
      classes: [],
      tasks: [],
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
      section_detail: {
        section: {
          id: "",
          name: "",
          description: "",
        },
      },
      subjectList: [],
      label: "",
      room_channel: "",
      room_group: "",
      rooms: [],
      chat_active: false,
      messages_channel: [],
      messages_group: [],
      currentUserId: 1234,
    };
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluation.evaluation,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    console.log("Will it play here?? lol");
    console.log("Play outside of");
    var audio = new Audio();
    // "https://apischool.myticktap.com/file/1-1-1-20201707165750.m4a" // path to file
    // audio.play();
    this.evaluation_image = this.evaluation.url_image
    this.evaluation_doc = this.evaluation.url_document
    this.subjectLoad();
    this.loadState();

    if (this.evaluation.id_type_evaluation == 1) {
      this.label = "Clase";
    } else if (this.evaluation.id_type_evaluation == 2) {
      this.label = "Clase";
    } else if (this.evaluation.id_type_evaluation == 3) {
      this.label = "Evaluación";
    }
    this.loadSubjectsByTeacher();
  },

  methods: {
    ...mapActions([
      "GetAll",
      "loadEvaluation_Messages",
      "load_Evaluation_by_subject",
      "loadData_SubjectSection",
      "update_Evaluation",
      "deleteEvaluation",
      "loadSubjectSection",
      "duplicateEvaluation",
    ]),

    callback(data) {
      console.debug(data);
    },
    matchYoutubeUrl(url) {
      if (url) {
        console.log("Finding youtube");
        var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(p)) {
          console.log("Finded");
          return url.match(p)[1];
        }
      } else {
        return false;
      }
    },
    createClass(type) {
      this.loadData_SubjectSection(this.subject_section).then((response) => {
        this.$router.push("/profesor/materia/evaluacion/nuevo/" + type);
      });
    },
    subjectLoad() {
      if (!this.evaluation.id) {
        this.$router.push("/profesor/materias");
      }
    },
    loadSubjectsByTeacher() {
      let form_teacher = {
        id_teacher: this.evaluation.id_teacher,
        id_period: this.me_rol.id_period,
        id_school: this.me_rol.id_school,
      };
      this.loadSubjectSection(form_teacher)
        .then((response) => {
          if (response.code === 200) {
            this.sectionList = response.data;
            this.subjectList = response.data;
            //this.section = response.data[0].subject.id;

            console.log("LOADED TEACHERS");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadState() {
      this.messages_channel = [];
      this.messages_group = [];
      this.room_channel = this.evaluation.rooms[0].id;
      console.log("ROOM CHANNEL:" + this.room_channel);

      this.room_group = this.evaluation.rooms[1].id;
      console.log("ROOM GROUP:" + this.room_group);
      /*let form = {
        room: this.room_channel,
      };
      this.loadEvaluation_Messages(form)
        .then((response) => {
          console.log(response)
          if (response.code === 200) {
            for (let item of response.data) {
              item.delivered = true
              this.messages_channel.push(item)
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //GROUP CHATS
      let form2 = {
        room: this.room_group,
      };
      this.loadEvaluation_Messages(form2)
        .then((response) => {
          if (response.code === 200) {
            for (let item of response.data) {
              item.delivered = true
              this.messages_group.push(item)
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });*/
    },
    checkAssistanceTeacher() {
      this.loading = true;

      let data = new FormData();

      data.append("id", this.evaluation.id);
      data.append("teacher_assisted", 1);

      let form = {
        id: this.evaluation.id,
        params: data,
      };

      Swal.fire({
        title: "Asistencia del Profesor",
        text: "¿Estás seguro de marcar su asistencia como profesor en esta clase?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Marcar asistencia",
      }).then((result) => {
        if (result.value) {
          this.update_Evaluation(form)
            .then((response) => {
              console.log("RESPONSE");
              console.log(response);
              if (response.code == 200) {
                Swal.fire({
                  title: "Listo",
                  text: "Su asistencia fue actualizada",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                   this.evaluation.teacher_assisted = response.data.teacher_assisted;
                   this.evaluation.teacher_assisted_datetime = response.data.teacher_assisted_datetime;
                });
              }
              if (response.code == 500) {
                Swal.fire({
                  title: "Ocurrio un error",
                  text: "Intenta de nuevo, completa todos los campos",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            })
            .catch((error) => {
              console.log("ERROR");
              if (error.response.code == 500) {
                Swal.fire({
                  title: "Ocurrio un error",
                  text: "Intenta de nuevo, completa todos los campos",
                  icon: "error",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            });
        }
      });
    },
    onSubmitUpdateEvaluaton() {
      if (this.title == "" || this.description == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar todos los datos de la clase",
          icon: "warning",
        });
      } else {
        this.loading = true;
        console.log(this.me);
        let dead = Moment(this.deadline).format("YYYY-MM-DD");
        console.log(dead + " " + this.time);

        let data = new FormData();

        data.append("title", this.evaluation.title);
        data.append("description", this.evaluation.description);
        data.append("url_link", this.evaluation.url_link);
        data.append("url_video", this.evaluation.url_video);
        data.append("url_document", this.evaluation.url_document);
        data.append("url_image", this.evaluation.url_image);
        data.append("status_evaluation", this.evaluation.status_evaluation);

        let form = {
          id: this.evaluation.id,
          params: data,
        };
        /* let form = {
          id: this.evaluation.id,
          title: this.evaluation.title,
          description: this.evaluation.description,
          url_link: this.evaluation.url_link,
          url_video: this.evaluation.url_video,
          url_document: this.evaluation.url_document,
        }; */

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de actualizar esta clase?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.update_Evaluation(form)
              .then((response) => {
                console.log("RESPONSE");
                console.log(response);
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La clase ha sido actualizada",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  })

                  this.title_edit = false;
                  this.url_edit2 = false;
                  this.description_edit = false;
                  this.url_edit = false;
                  this.video_edit = false;
                  this.deadline_to_update = ''
                  this.deadlinetask_to_update = ''
                  this.time_to_update = ''
                  this.timetask_to_update = ''
                  this.evaluation.url_document = response.data.url_document;
                  this.evaluation.url_image = response.data.url_image;
                  this.evaluation_image = response.data.url_image
                  this.evaluation_doc = response.data.url_document
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "error",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },

    duplicate_Evaluation(row) {
      if (!this.section > 0) {
        Swal.fire({
          title: "Debes seleccionar una sección para poder duplicar la clase",
          icon: "warning",
        });
      } else {
        Swal.fire({
          title: "Duplicar " + this.label.toLowerCase(),
          text:
            "¿Estás seguro de duplicar esta " +
            this.label.toLowerCase() +
            " en la sección:" +
            this.section_detail.name +
            "?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          confirmButtonText: "Duplicar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            let form = {
              id_evaluation: row.id,
              id_section: this.section_detail.id,
              id_subject: this.subject_detail.id,
            };
            this.duplicateEvaluation(form)
              .then((response) => {
                if (response.code === 200) {
                  Swal.fire({
                    title: this.label + " duplicada",
                    icon: "success",
                  });
                  this.$router.go(-1);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    delete_Evaluation(row) {
      console.log(row);
      Swal.fire({
        title: "Eliminar " + this.label.toLowerCase(),
        text:
          "¿Estás seguro de eliminar esta " + this.label.toLowerCase() + " ?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009900",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.id,
          };
          this.deleteEvaluation(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: this.label + " eliminada",
                  icon: "success",
                });
                this.$router.go(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onSubmitUpdateDatesEvaluaton() {
      if (this.deadline_to_update == "" || this.time_to_update == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar todos los datos de la clase",
          icon: "warning",
        });
      } else {
        this.loading = true;
        console.log(this.me);
        let dead = Moment(this.deadline_to_update).format("YYYY-MM-DD");
        let data = new FormData();
        data.append("id", this.evaluation.id);
        data.append("deadline", dead + " " + this.time_to_update);

        let form = {
          id: this.evaluation.id,
          params: data,
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de actualizar la fecha de esta clase?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar",
        }).then((result) => {
          if (result.value) {
            this.update_Evaluation(form)
              .then((response) => {
                console.log("RESPONSE");
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La clase ha sido actualizada",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.title_edit = false;
                    this.description_edit = false;
                    this.deadline_date_edit = false;

                    this.evaluation.deadline = dead + " " + this.time_to_update;
                  });
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "error",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },
    onSubmitUpdateDatesTaskEvaluaton() {
      if (this.deadlinetask_to_update == "" || this.timetask_to_update == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar todos los datos de la clase",
          icon: "warning",
        });
      } else {
        this.loading = true;
        console.log(this.me);
        let dead = Moment(this.deadlinetask_to_update).format("YYYY-MM-DD");
        let data = new FormData();
        data.append("id", this.evaluation.id);
        data.append("tasks_deadline", dead + " " + this.timetask_to_update);

        let form = {
          id: this.evaluation.id,
          params: data,
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de actualizar la fecha de esta clase?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar",
        }).then((result) => {
          if (result.value) {
            this.update_Evaluation(form)
              .then((response) => {
                console.log("RESPONSE");
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La clase ha sido actualizada",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.title_edit = false;
                    this.description_edit = false;
                    this.deadlinetask_date_edit = false;

                    this.evaluation.tasks_deadline =
                      dead + " " + this.timetask_to_update;
                  });
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "error",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },
  },
};
</script>
<style lang="css" scoped>
audio {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  object-fit: contain;
}

.scroll::-webkit-scrollbar {
  width: 7px;
}
</style>
