import Vue from "vue";
import Vuex from "vuex";
// import Vuex, {Payload, Store} from 'vuex'
import VuexPersist from "vuex-persist";
import Cookies from "js-cookie";
import createLogger from "vuex/dist/logger";
import toast from "./modules/toast";

//=====MODULES =====

//ASIS
import assistance from "./modules/ASIS/assistance";
import rolAccess from "./modules/ASIS/rolAccess";
import shift from "./modules/ASIS/shift";
import access from "./modules/ASIS/access";
import incidence from "./modules/ASIS/incidence";
//EVAL
import typeEvaluation from "./modules/EVAL/typeEvaluation";
import evaluation from "./modules/EVAL/evaluation";
import period from "./modules/EVAL/period";
import subject from "./modules/EVAL/subject";
import subjectSection from "./modules/EVAL/subjectSection";
import student from "./modules/EVAL/student";
import section from "./modules/EVAL/section";
import lapse from "./modules/EVAL/lapse"
import levels from "./modules/EVAL/levels";
import enrollmentsUser from "./modules/EVAL/enrollmentsUser";
import studentEnrollment from "./modules/EVAL/studentEnrollment";
import teacher from "./modules/EVAL/teacher";
import conditionStudent from "./modules/EVAL/conditionStudent";
import bulletinStudent from "./modules/EVAL/bulletinStudent";
import typeStudent from "./modules/EVAL/typeStudent";
import clipboard from "./modules/EVAL/clipboard";
//GEN
import main from "./modules/main";
import auth from "./modules/GEN/auth";
import users from "./modules/GEN/users";
import representative from "./modules/GEN/representative";
import typeSchool from "./modules/GEN/typeSchool";
import typeDevice from "./modules/GEN/typeDevice";
import device from "./modules/GEN/device";
import headMasters from "./modules/GEN/headMasters";
//import father from "./modules/GEN/father";
import personal from "./modules/GEN/personal";
import typeBlood from "./modules/GEN/typeBlood";
import logConnection from "./modules/GEN/logConnection";
import school from "./modules/GEN/school";
import general from "./modules/GEN/general";
import card from "./modules/GEN/card";
//MSG
import communication from "./modules/MSG/communication";
import notification from "./modules/MSG/notification";
import consult from "./modules/MSG/consult";
import messages from "./modules/MSG/messages";
import feeds from "./modules/MSG/feeds";
import private_messages from "./modules/MSG/private_messages";
//PAY
import typeProduct from "./modules/PAY/typeProduct";
import areaSchool from "./modules/PAY/areaSchool";
import product from "./modules/PAY/product";
import studentPay from "./modules/PAY/student";

import money from "v-money";

Vue.use(money, { precision: 4 });
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
var inHalfADay = 0.5;

const vuexCookie = new VuexPersist({
  key: "store",
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) =>

    Cookies.set(key, state, {
      expires: inHalfADay
    }),
  //modules: ["auth", "route"] //only save user module
  modules: ["auth"]
  // filter: (mutation) => (mutation.type == 'logIn' || mutation.type == 'logOut')
});

export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger(), vuexCookie.plugin] : [vuexCookie.plugin],
  modules: {
    auth,
    main,
    toast,
    users,
    representative,
    assistance,
    card,
    typeSchool,
    typeProduct,
    areaSchool,
    product,
    typeEvaluation,
    evaluation,
    typeDevice,
    device,
    headMasters,
    period,
    subject,
    subjectSection,
    typeStudent,
    student,
   // father,
    teacher,
    personal,
    enrollmentsUser,
    studentEnrollment,
    typeBlood,
    conditionStudent,
    bulletinStudent,
    school,
    rolAccess,
    shift,
    access,
    notification,
    consult,
    logConnection,
    communication,
    messages,
    incidence,
    general,
    studentPay,
    section,
    levels,
    lapse,
    private_messages,
    clipboard,
    feeds
  }
});
