<template>
  <div
    id="check"
    :style="{
      backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')',
    }"
  >
    <br />
    <br />
    <b-row
      cols="12"
      class="justify-content-center"
      align-h="center"
      align-v="center"
      align-content="center"
    >
      <b-col md="5"> </b-col>
      <b-col md="2">
        <img
          class="img-responsive center-block d-block mx-auto"
          style="margin-left: auto; max-width: 200px"
          src="../../assets/img/logonew.png"
        />
      </b-col>
      <b-col md="5"> </b-col>
    </b-row>
    <b-row v-if="step == 1">
      <b-col md="3"></b-col>
      <b-col md="6">
        <br />
        <h2 class="text-center"><strong>Registro en Escuela</strong></h2>
        <p class="text-center">
          Por favor, coloca el código de la escuela, si no lo tienes, debes solicitarlo a la administración de la escuela. 
        </p>
        <b-row>
          <b-col md="5"> </b-col>
          <b-col md="2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              v-if="step == 1"
              variant="outline-primary"
              v-show="progress"
              label="Text Centered"
            ></b-spinner>
          </b-col>
          <b-col md="5"> </b-col>
        </b-row>
        <b-row>
          <b-col md="2"> </b-col>
          <b-col md="8">
            <br />
            <b-input-group size="lg" prepend="Código">
              <b-form-input type="text" v-model="code"></b-form-input>
            </b-input-group>
            <br />
            <br />
            <button
              type="button"
              v-on:click="checkCode(3)"
              class="btn btn-outline-primary btn-block btn-lg"
            >
              Registrarme como Representante
            </button>
            <br />
          <!--  <button
              type="button"
              v-on:click="checkCode(2)"
              class="btn btn-outline-success btn-block btn-lg"
            >
              Entrar como Profesor
            </button>-->
          </b-col>
          <b-col md="2"> </b-col>
        </b-row>

        <br />
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
    <b-row v-if="step == 2">
      <b-col md="3"></b-col>
      <b-col md="6">
        <br />
        <h2 class="text-center">{{ school.name }}</h2>
        <p class="text-center">
          Ahora por favor completa tus datos como representante, para finalizar
          el registro
        </p>
        <b-row>
          <b-col md="5"> </b-col>
          <b-col md="2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              v-if="step == 1"
              variant="outline-primary"
              v-show="progress"
              label="Text Centered"
            ></b-spinner>
          </b-col>
          <b-col md="5"> </b-col>
        </b-row>
        <b-row>
          <b-col md="2"> </b-col>
          <b-col md="8">
            <br />
            <b-card>
              <b-form-group
                label="Actividad Comercial"
                label-for="email"
                class="text-center"
              >
                <b-form-input
                  id="commercial_activity"
                  v-model="commercial_activity"
                  name="commercial_activity"
                />
              </b-form-group>
              <b-form-group
                label="Dirección de Hogar"
                label-for="email"
                class="text-center"
              >
                <b-form-textarea
                  id="address_home"
                  v-model="address_home"
                  name="address_home"
                />
              </b-form-group>
              <b-form-group
                label="Dirección de Oficina"
                label-for="email"
                class="text-center"
              >
                <b-form-textarea
                  id="address_office"
                  v-model="address_office"
                  name="address_office"
                />
              </b-form-group>
              <b-form-group
                label="Profesión"
                label-for="email"
                class="text-center"
              >
                <b-form-input
                  id="profession"
                  v-model="profession"
                  name="profession"
                />
              </b-form-group>
              <button
                type="button"
                v-on:click="saveRepresentative()"
                class="btn btn-outline-primary btn-block btn-lg"
              >
                Completar registro como Representante
              </button>
            </b-card>
            <br />
            <br />

            <button
              type="button"
              v-if="step == 1"
              v-on:click="checkCode(3)"
              class="btn btn-outline-primary btn-block btn-lg"
            >
              Registrarme como Representante
            </button>
            <br />
           <!-- <button
              type="button"
              v-if="step == 1"
              v-on:click="checkCode(2)"
              class="btn btn-outline-success btn-block btn-lg"
            >
              Registrarme como Profesor
            </button>-->
          </b-col>
          <b-col md="2"> </b-col>
        </b-row>

        <br />
      </b-col>
      <b-col md="3"></b-col>
     
    </b-row>
  <b-row class="justify-content-md-center">
    <b-col></b-col>
    <b-col align-self="center"> <router-link class="text-centered" :to="{ name: 'check' }"
        ><v-icon name="angle-left"/>  Volver al inicio.</router-link
      ></b-col>
           <b-col></b-col>
     
  </b-row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import Cookies from "js-cookie";
export default {
  name: "check",
  props: ["items", "auth"],
  data() {
    return {
      progress: false,
      code: "",
      fields: [
        {
          key: "name_school",
          label: "Escuela",
          sortable: false,
        },
        {
          key: "action",
          label: "Acceder Como",
          sortable: false,
        },
      ],
      listRoles: [],
      school: {},
      commercial_activity: "",
      address_home: "",
      address_office: "",
      profession: "",
      id_school: "",
      step: 1,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.me,
      authe: (state) => state.auth,
    }),
  },
  mounted() {},
  methods: {
    ...mapActions([
      "addToastMessage",
      "newRepresentative",
      "check_inscription_code",
    ]),

    checkCode(id_rol) {
      //let id_user = this.authe.me.id;
      this.progress = true;

      console.log(id_rol);

      this.progress = true;
      this.btnLogin = true;
      this.check_inscription_code({
        code: this.code,
      })
        .then((data) => {
          console.log("LOGIN 200");
          console.log(data);
          if (data.code == 200) {
            this.step = 2;
            this.progress = false;
            this.school = data.data.school;
          }
          if (data.code == 404) {
            this.progress = false;
            Swal.fire({
              title: "Código Inválido",
              text:
                "Asegúrate de escribir correctamente el código de la escuela",
              icon: "warning",
            }).then((result2) => {
              console.log(result2);
            });
          }
        })

        .catch((data) => {
          this.btnLogin = false;
          if (data.code == 404) {
            this.addToastMessage({
              text: "Ocurrio un error al consultar",
              icon: "warning",
            });
            this.progress = false;
          }
        });
    },
    saveRepresentative() {
      if (
        this.commercial_activity == " " ||
        this.address_office == " " ||
        this.address_home == " "
      ) {
        this.addToastMessage({
          text: "No puedes dejar campos vacios",
          type: "warning",
        });
        return false;
      }

      let form = {
        address_home: this.address_home,
        address_office: this.address_office,
        id_school: this.school.id,
        id_user: this.user.id,
        profession: this.profession,
        commercial_activity: this.commercial_activity,
      };
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de completar tu registro?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Registrar",
      }).then((result) => {
        if (result.value) {
          this.newRepresentative(form)
            .then((response) => {
              if (response.code == 200) {
                Swal.fire({
                  title: "Bienvenido",
                  text: "Tu registro fue completado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                  this.$router.push({ name: "check" });
                });
              } else {
                let respo = response.data;
                this.addToastMessageArray({
                  array: respo,
                  type: "info",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    goEnvironment(data) {
      console.log(data);
      const { id_rol } = data;
      Cookies.set("id_rol", id_rol);
      this.$store.commit("LOGIN_OK", { data: this.authe, item: data });

      if (id_rol === 1) {
        this.$router.push("/estudiante");
      } else if (id_rol === 2) {
        this.$router.push("/profesor");
      } else if (id_rol === 3) {
        this.$router.push("/representante");
      } else if (id_rol === 4) {
        this.$router.push("/director");
      } else if (id_rol === 5) {
        this.$router.push("/admin");
      }

      this.addToastMessage({
        text: "Bienvenido",
        icon: "success",
      });
    },
  },
};
</script>
