<template>
  <div>
    <br />
    <div class="content">
      <div>
        <h2>Perfil de usuario</h2>
        <p>Este el panel de control que permite administrar tus datos</p>
      </div>

      <div class="tab-content">
        <div
          class="tab-pane tabs-animation fade show active"
          id="tab-content-0"
          role="tabpanel"
        >
          <div class="main-card card">
            <div class="card-body">
              <b-container>
                <b-row align-v="center">
                  <b-col lg="6" md="12" sm="12" cols="12">
                    <div >
                      <img
                      :src="me.image"
                      class="mt-3 img-size mb-2"
                      height="250px"
                      width="250px"
                      style="border-radius: 200px; display: block; margin-left: auto; margin-right: auto;"
                      alt
                    />
                    <p>Para actualizar tu imagen de perfil, accede por la aplicacion movil y actualiza tu foto</p>
                    </div>
                    
                  </b-col>
                  <b-col lg="6" md="12" sm="12" cols="12">
                    <div class="my-3 ml-3 mr-3">
                      <div class="h3 text-center">Datos Personales</div>
                      <div class="class-flex flex-row bd-highlight p-1">
                        <h5>
                          Nombre:
                          <span
                            ><strong>{{ me.full_name }}</strong></span
                          >
                        </h5>
                      </div>
                      <div class="class-flex flex-row bd-highlight p-1">
                        <h5>
                          Correo:
                          <span
                            ><strong>{{ me.email }}</strong></span
                          >
                        </h5>
                      </div>
                      <div class="class-flex flex-row bd-highlight p-1">
                        <h5>
                          Rol:
                          <span
                            ><strong>{{ me_rol.id_rol == 1 ? 'Estudiante' : me_rol.id_rol == 2 ? 'Profesor' : me_rol.id_rol == 3 ? 'Representante' : me_rol.id_rol == 4 ? 'Director' : me_rol.id_rol}}</strong></span
                          >
                        </h5>
                      </div>

                      <!-- <b-button
                        class="mt-3 mr-2 button-color"
                        v-show="!progress"
                        @click="changePicture"
                      >
                        <strong>Cambiar imagen</strong>
                      </b-button> -->
                      <b-button
                        class="mt-3 mr-2 button-color"
                        v-show="progress"
                        disabled
                      >
                        <b-spinner small type="grow"></b-spinner>
                        <strong>Cargando...</strong>
                      </b-button>
                      <!-- <b-button
                        class="mt-3 mr-2 button-color"
                        v-b-modal.modal-pass
                        @click="
                          title2 = 'Cambiar datos del usuario';
                          showModal = true;
                        "
                      >
                        <strong>Cambiar datos</strong>
                      </b-button> -->
                      <b-button
                        class="mt-3 "
                        variant="outline-success"
                        v-b-modal.modal-pass
                        @click="
                          title2 = 'Cambiar contraseña';
                          showModal = false;
                        "
                      >
                        <strong>Cambiar contraseña</strong>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </div>

      <!--Modal Add User-->
      <b-modal
        id="modal-pass"
        ref="modal"
        :title="title2"
        ok-title="Realizar cambio"
        ok-variant="outline-success"
        cancel-title="Cancelar"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form1"
          @submit.stop.prevent="onSubmitData"
          v-show="showModal"
        >
          <b-form-group
            :state="nameState"
            label="Nombre completo"
            label-for="name"
            invalid-feedback
          >
            <b-form-input
              id="name"
              type="text"
              v-model="name"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :state="passState"
            label="Correo nuevo"
            label-for="email"
            invalid-feedback="Correo invalido"
          >
            <b-form-input
              id="email"
              type="email"
              v-model="email"
              :state="emailState"
              required
            ></b-form-input>
          </b-form-group>

          <div v-show="progress1" class="text-center text-primary">
            <b-spinner small class="align-middle"></b-spinner>
            <strong class="ml-2">Cargando...</strong>
          </div>
        </form>
        <form
          ref="form"
          @submit.stop.prevent="onSubmitPass"
          v-show="!showModal"
        >
          <b-form-group
            :state="passState"
            label="Contraseña nueva"
            label-for="pass"
          
          >
            <b-form-input
              id="pass"
              type="password"
              v-model="pass"
              :state="passState"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :state="passState"
            label="Repita la nueva contraseña"
            label-for="pass1"
            
          >
            <b-form-input
              id="pass1"
              type="password"
              v-model="pass1"
              :state="passState1"
              required
            ></b-form-input>
          </b-form-group>

          <div v-show="progress1" class="text-center text-primary">
            <b-spinner small class="align-middle"></b-spinner>
            <strong class="ml-2">Cargando...</strong>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
const Swal = require("sweetalert2");

export default {
  name: "UserProfile",
  data() {
    return {
      showModal: null,
      title2: "",
      progress: false,
      progress1: false,
      pass: "",
      passState: null,
      pass1: "",
      passState1: null,
      img: "",
      name: null,
      nameState: null,
      email: null,
      emailState: null,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  methods: {
    ...mapActions(["updateUser"]),
    checkFormValidity() {
      console.log("checkForm");

      if (this.showModal) {
        const valid = this.$refs.form1.checkValidity();
        this.nameState = valid;
        this.emailState = valid;
        return valid;
      } else {
        const valid = this.$refs.form.checkValidity();
        this.passState = valid;
        this.passState1 = valid;

        return valid;
      }
    },
    resetModal() {
      console.log("resetmodal");
      this.pass = "";
      this.passState = null;
      this.pass1 = "";
      this.passState1 = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      if (this.showModal) {
        this.onSubmitData();
      } else {
        this.onSubmitPass();
        console.log('PAssword')
      }
    },
    onSubmitPass() {
      // Exit when the form isn't valid

      if (!this.checkFormValidity() || this.pass != this.pass1) {
        this.passState = false;
        this.passState1 = false;
        Swal.fire({
          title: 'Atención',
          text: 'Las contraseñas ingresadas no coincides',
          icon: 'warning'
        })
        return;
      }
      // Push the name to submitted names
      let form = {
        id_user: this.me.id,
        password: this.pass
      }
      this.progress1 = true;
      this.updateUser(form)
        .then((response) => {
          console.log(response);
          this.progress1 = false;
          if (response.code === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Contraseña reestablecida",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$nextTick(() => {
              this.$bvModal.hide("modal-pass");
            });
          }
        })
        .catch((error) => {
          this.progress = false;
          console.log(error);
          alert("Ocurrió un error, intente más tarde");
          
        });
    },
    /* async changePicture() {
      const { value: file } = await Swal.fire({
        title: "Seleccionar una imagen",
        input: "file",
        inputAttributes: {
          accept: "image/*",
          "aria-label": "Upload your profile picture",
        },
      });

      if (file) {
        this.progress = true;
        let datos = new FormData();
        datos.append("profile_picture", file);
        let form = {
          data: datos,
          urldata: "change_picture",
          use: 1,
        };
        const reader = new FileReader();
        reader.onload = (e) => {
          this.updateUser(form)
            .then((data) => {
              this.progress = false;
              if (data.code == 200) {
                this.img = data.data.profile_picture;
                //this.userPicture = data.data.profile_picture;
                Swal.fire({
                  title: "Imagen subida",
                  imageUrl: e.target.result,
                  imageAlt: "The uploaded picture",
                });
                Cookies.set("picture", this.img);
                this.$router.go();
              } else if (data.code == 400) {
                Swal.fire({
                  title: "Error al subir la imagen",
                  icon: "error",
                  imageAlt: "The uploaded picture",
                });
              }
            })
            .catch((data) => {
              console.log("catch");
              console.log(data);
            });
        };
        reader.readAsDataURL(file);
      }
    }, */
    onSubmitData() {
      if (!this.checkFormValidity()) {
        console.log("1");
        return;
      }
      this.progress1 = true;
      let datos = new FormData();
      datos.append("full_name", this.name);
      datos.append("email", this.email);
      let form = {
        data: datos,
        urldata: "update",
        use: 0,
      };
      this.updateUser(form)
        .then((response) => {
          this.progress1 = false;
          console.log(response);
          this.progress2 = false;
          if (response.code === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Datos actualizados",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$nextTick(() => {
              this.$bvModal.hide("modal-pass");
            });
            this.$router.go();
          }
        })
        .catch((error) => {
          this.progress2 = false;
          console.log(error);
          alert("Ocurrió un error, intente más tarde");
        });
    },
  },
  mounted() {},
};

/*Hacer la función para cambiar la imagine y el password en una sola(updateUser), ya que tienen un enlace muy parecido
hacer la funcion onSubmitPass*/
</script>

<style scoped>
</style>