import axios from "axios";
import * as Config from "../../../config";

const state = {
  consult: {}
};
let mod = "eval/";
const actions = {
  load_Clipboard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"clipboard/all?id_user="+form.id_user)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  store_Clipboard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"clipboard/save",form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Clipboard({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_NOTIFICATION", data);
  },
  new_Clipboard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"clipboard/consult/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  update_Clipboard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod+"clipboard/consult/update/" + form.id, form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveClipboard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +mod+
            "clipboard/consult/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  delete_Clipboard({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +mod+
            "clipboard/consult/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_NOTIFICATION(state, data) {
    state.consult = data;
  }
};

export default {
  state,
  actions,
  mutations
};
