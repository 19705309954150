<template>
  <div :style="{ backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')' }">
    <b-container>
      <br />
      <br />
      <b-row>
        <b-col md="3">
        </b-col>
         <b-col md="6">
            <b-form center @submit="onSubmit" style="background:transparent">
         
                <b-card>
                  <div class="row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4">
                        <img
          class="img-responsive center-block d-block mx-auto"
          style="margin-left: auto; max-width: 200px"
          src="../../assets/img/logonew.png"
        /> </div>
                    <div class="col-sm-4"></div>
                  </div>
                  <br />

                  <h3 style="text-align:center">Acceso a Root Clasy</h3>

                  <br />
                  <!-- Using props -->
                  <b-input-group size="lg" prepend="Correo">
                    <b-form-input type="email" v-model="email"></b-form-input>
                  </b-input-group>
                  <br />

                  <b-input-group size="lg" prepend="Clave">
                    <b-form-input type="password" v-model="password"></b-form-input>
                  </b-input-group>

                  <br />

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                      <b-button block pill variant="outline-primary" type="submit">Acceder a la plataforma</b-button>
                      <br />
                      <b-row>
                        <b-col md="5"></b-col>
                        <b-col md="2">
                          <b-spinner variant="outline-primary" v-show="progress" label="Text Centered"></b-spinner>
                        </b-col>
                        <b-col md="5"></b-col>
                      </b-row>

                      <br />
                      <br />
                      <router-link
                        :to="{name: 'register'}"
                        style="text-align:center"
                      >¿No tienes cuenta? - Regístrate</router-link>
                      <br />
                      <br />
                      <router-link :to="{name: 'recovery'}" class>Recuperar Contraseña</router-link>
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                </b-card>
             
          </b-form>
        </b-col>
         <b-col md="3">
        </b-col>
      </b-row>
          
        

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </b-container>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import platform from "platform";
//import Permission from "../../permission";
import { validate } from "vee-validate";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      btnLogin: false,
      email: "",
      password: "JzdWIiOiIxMjM",
      listRoles: [],
      auth: {},
    };
  },

  computed: {
    ...mapState({}),
    listRolesHasData() {
      return this.listRoles.length ? true : false;
    },
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  mounted(){
    let device =platform.name+' - '+platform.version+' - '+platform.os+' - '+platform.product;
            console.log(device)
          
  },
  methods: {
    ...mapActions(["Rootlogin", "getAllRolsByUser", "addToastMessage"]),
    onSubmit(evt) {
      evt.preventDefault();
      console.log("Submit");
      if (this.email == "" || this.password == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Por favor ingresa un correo y una clave",
          icon: "warning",
        });
      } else {
        validate().then((result) => {
          if (result) {
            this.progress = true;
            this.btnLogin = true;
            //GET DEVICE INFO
            let device = null;
            device =platform.name+' - '+platform.version+' - '+platform.os+' - '+platform.product;
          
            this.Rootlogin({
              email: this.email,
              password: this.password,
              from:2,
              device:device
            })
              .then((data) => {
                console.log("LOGIN 200");
                console.log(data);
                if (data.code == 200) {
                  this.auth = data;

                  this.btnLogin = false;

                  this.$router.push("/check");
                  this.progress = false;
                }
                if (data.code == 404) {
                  Swal.fire({
                    title: "Correo no registrado",
                    text: "Por favor comprueba los datos ingresados",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                if (data.code == 401) {
                  Swal.fire({
                    title: "Contrasena incorrecta",
                    text: "Por favor comprueba los datos ingresados",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              })

              .catch((data) => {
                this.btnLogin = false;
                if (data.code == 404) {
                  this.addToastMessage({
                    text: "Credenciales incorrectas - Comprueba el correo",
                    icon: "warning",
                  });
                  this.progress = false;
                }
                if (data.code == 401) {
                  this.addToastMessage({
                    text: "Contraseña Incorrecta",
                    icon: "warning",
                  });
                  this.progress = false;
                }
              });
          }
        });
      }
    },
  },
};
</script>