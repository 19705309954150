<template>
  <div>
    <br />
    <div class="content">
      <b-card title="Noticias">
        <b-row>
          <b-col md="10">
            <p>En esta sección puedes visualizar todos los comunicados enviados por el equipo administrativo del colegio, incluyendo al director, mantente atento a revisar esta sección periódicamente para estar al día con todas las novedades en el colegio</p>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
        <br />
        <b-row>
          <b-col md="12">
            <div v-for="not in notifications" :key="not.id">
              <div class="card">
                
                <div class="card-body">
                  <h5 class="card-title">{{not.title}}</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{not.date}}</h6>
                  <p class="card-text">{{not.body}}</p>
                    <p style="font-size:11px">Fecha: {{not.created_at}}</p>
                </div>
              </div>
              <br />
            </div>
            <br />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
 import {mapState, mapActions} from "vuex";
//import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "AllNews",
  data() {
    return {
      notifications: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [5, 10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_user: {},
      loading: false,
      error: ""
    };
  },
  computed: {
     ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.notifications.length;
    }
  },
  mounted() {
    this.allNotification();
  },

  methods: {
    
    ...mapActions([
      "load_Notification", 
    ]),
    
    allNotification() {
      let form = {
        id_school: this.me_rol.id_school,
        rol: 2
      };
      console.log('ID_SCHOOL:'+this.me_rol.id_school)
      this.load_Notification(form)
        .then(response => {
          if (response.code === 200) {
            this.notifications = response.data;
          }
        })
        .catch(error => {console.log(error)});
    },
   /* editNotification(data) {
      this.loadData_Notification(data).then(response => {
          console.log(response)
        this.$router.push("/notificacion/editar/" + data.id);
      });
    }, */
  
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
