<template>
  <div id="NewSchool">
    <br/>
    <div class="content">
      <b-card title="Registrar Nueva Escuela">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>Por favor completa los campos para registrar un nueva escuela</p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <router-link
                class="btn btn-outline-primary"
                :to="{name:'goadmin-school-all'}"
            >Listado de Escuelas
            </router-link>
          </b-col>
        </b-row>
        <br/>
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Datos de la escuela" active>
              <form
                  v-if="!loading"
                  style="width: 100%!important;"
                  method="post"
                  @submit.prevent="onSubmitSchool"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nombre de la escuela" label-for="name">
                      <b-form-input
                          id="name"
                          v-model="name"
                          required
                          name="name"
                          placeholder="Ingrese el nombre de la escuela"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Código" label-for="name">
                      <b-form-input
                          id="code"
                          v-model="code"
                          required
                          name="code"
                          placeholder="Ingrese el código de la escuela"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <div>
                        <div class="mb-2">Tipo de Escuela</div>
                        <b-form-select
                            v-model="type_school"
                            :options="typeSchoolArray"
                            value-field="id"
                            text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Capacidad de la escuela" label-for="capacity">
                      <b-form-input
                          id="capacity"
                          v-model="capacity"
                          required
                          type="number"
                          name="capacity"
                          placeholder="Ingrese una cantidad"
                      ></b-form-input>

                    </b-form-group>
                  </b-col>
                 <b-col md="4">
                    <label for="dni">Dni de escuela</label>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-select
                            v-model="prefix_dni"
                              :options="[{id:'G-',name:'G'},{id:'J-',name:'J'}]"
                              value-field="id"
                              text-field="name"
                          ></b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                            id="dni"
                            v-model="dni"
                            required
                            type="number"
                            name="dni"
                            placeholder="DNI"
                        ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Teléfono de la escuela" label-for="phone">
                      <b-form-input
                          id="phone"
                          v-model="phone"
                          required
                          type="number"
                          name="phone"
                          placeholder="Ingrese un número teléfonico"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Dirección de la escuela" label-for="address">
                      <b-form-input
                          id="address"
                          v-model="address"
                          required
                          name="address"
                          placeholder="Ingrese una dirección"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12 text-right">
                    <b-button type="submit" variant="outline-success" class="m-3">Registrar</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab title="Datos de Configuración" disabled>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "NewSchool",
  data() {
    return {
      name: "",
      code: "",
      dni: "",
      prefix_dni:"G-",
      phone: "",
      capacity: "",
      address: "",
      type_school: 1,
      typeSchoolArray: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  mounted() {
    this.allSelects()
  },
  methods: {
    ...mapActions([
      "newSchool",
      "addToastMessage",
      "addToastMessageArray",
      "loadTypeSchool_Active",
    ]),
    allSelects() {
      let form = {
        active: 1
      };
      this.loadTypeSchool_Active(form)
          .then(response => {
            if (response.code === 200) {
              this.typeSchoolArray = response.data;
              this.type_school = this.typeSchoolArray[0].id
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    onSubmitSchool() {

      if (this.name == ' ' || this.code == ' ' || this.address == ' ' || this.dni == ' ' || this.capacity == ' ' || this.phone == ' ') {
        this.addToastMessage({
          text: "No puedes dejar campos vacios",
          type: "warning",
        })
        return false
      }
      //let arrayNameTmp = this.name.split(' ');

      // let initName = '';
      // arrayNameTmp.map(function(v){
      //   let vNameTmp = v.split('.');
      //   if(vNameTmp.length > 0){
      //     vNameTmp.map(function(b){
      //       initName = initName+''+b.charAt(0)
      //     });
      //   }else{
      //     initName = initName+''+v.charAt(0)
      //   }
      //
      // });

      let form = {
        name: this.name,
        code:this.code.toUpperCase(),
        // code:initName.toUpperCase(),
        address: this.address,
         dni: this.prefix_dni+''+this.dni,
        phone: this.phone,
        capacity: this.capacity,
        id_type_school: this.type_school,
      };
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de registrar a esta escuela?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Registrar"
      }).then(result => {
        if (result.value) {
          this.newSchool(form)
              .then(response => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "La escuela ha sido registrada",
                    icon: "success"
                  }).then(result2 => {
                    console.log(result2)
                    this.$router.push({name: "goadmin-school-all"});
                  });
                } else {
                  let respo = response.data
                  this.addToastMessageArray({
                    array: respo,
                    type: "info",
                  })
                }

              })
              .catch(error => {
                console.log(error)
              });
        } else {
          this.loading = false;
        }

      });
    },
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
