import axios from "axios";
import * as Config from "../../../config";

const state = {
  message: {}
};
let mod = "msg/";
const actions = {
  load_PrivateRooms({ commit, dispatch },form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"private_rooms/find_by_user/"+form.id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  load_PrivateRooms_Messages({ commit, dispatch },form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"messages/find_by_private_room/"+form.id_room+"/all?page="+form.page)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  send_Message({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"private_rooms/send", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  create_PrivateRoom({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"schools/"+form.id_school+"/private_rooms/create", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Message({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_MESSAGE", data);
  },
  
};

const mutations = {
  LOAD_DATA_MESSAGE(state, data) {
    state.message = data;
  }
};

export default {
  state,
  actions,
  mutations
};
