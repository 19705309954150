<template>
  <div>
    <br />
    <div class="content">
      <h2>Ticktap School Admin (Root)</h2>
      <p>Este el panel de control que permite administrar el sistema ticktap school</p>
      <br />
      <br />
      <div class="row">
        <div class="col-md-4">
          <b-card title="Datos de Mensajeria">
         
            <br />
            
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-card-text>
              Notificaciones creadas
            
            </b-card-text>
                <p style="font-size:55px">{{ form.notification_count }}</p>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Mensajes escritos en chats
                <b-badge variant="outline-primary" pill>{{form.room_msg_count}}</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Mensajes Directos enviados
                <b-badge variant="outline-primary" pill>{{form.message_count}}</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Salas de chats
                <b-badge variant="outline-primary" pill>{{form.room_count}}</b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Datos de Evaluaciones">
         
            <br />
            
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-card-text>
              Cantidad de Estudiantes
            
            </b-card-text>
                <p style="font-size:55px">{{ form.student_count }}</p>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Secciones creadas
                <b-badge variant="outline-primary" pill>{{form.section_count}}</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Materias creadas
                <b-badge variant="outline-primary" pill>{{form.subject_count}}</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Profesores activos
                <b-badge variant="outline-primary" pill>{{form.teacher_count}}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
               Directores de Escuelas
                <b-badge variant="outline-primary" pill>{{form.headmaster_count}}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Representantes activos
                <b-badge variant="outline-primary" pill>{{form.representatives_count}}</b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Datos de Usuarios">
         
            <br />
            
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-card-text>
              Cantidad de Usuarios
            
            </b-card-text>
                <p style="font-size:55px">{{ form.user_count }}</p>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Cantidad de escuelas
                <b-badge variant="outline-primary" pill>{{form.school_count}}</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Consultas realizadas
                <b-badge variant="outline-primary" pill>{{form.consult_count}}</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Votos emitidos
                <b-badge variant="outline-primary" pill>{{form.vote_count}}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
               Pagos Reportados
                <b-badge variant="outline-primary" pill>{{form.student_payment_reported_count}}</b-badge>
              </b-list-group-item>
             
            </b-list-group>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "Welcome",

  data() {
    return {
      form: {
        consult_count: 0,
        headmaster_count: 0,
        message_count: 0,
        notification_count: 0,
        representatives_count: 0,
        room_count: 0,
        room_msg_count: 0,
        school_count: 0,
        section_count: 0,
        student_count: 0,
        student_payment_reported_count: 0,
        subject_count: 0,
        teacher_count: 0,
        user_count: 0,
        vote_count: 0
      }
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    })
  },
  mounted() {
    this.loadState();
  },

  methods: {
    ...mapActions(["get_root_counters"]),
    loadState() {
    
      const self = this;

      this.get_root_counters()
        .then(response => {
          if (response.code === 200) {
            let resp = response.data;
          
       
        self.form.consult_count= resp.consult_count;
        self.form.headmaster_count= resp.headmaster_count;
        self.form.message_count= resp.message_count;
        self.form.notification_count= resp.notification_count;
        self.form.representatives_count= resp.representatives_count;
        self.form.room_count= resp.room_count;
        self.form.room_msg_count= resp.room_msg_count;
        self.form.school_count= resp.school_count;
        self.form.section_count= resp.section_count;
        self.form.student_count= resp.student_count;
        self.form.student_payment_reported_count= resp.student_payment_reported_count;
        self.form.subject_count= resp.subject_count;
        self.form.teacher_count= resp.teacher_count;
        self.form.user_count= resp.user_count;
        self.form.vote_count= resp.vote_count

          }
        })
        .catch(error => {console.log(error)});
    }
  }
};
</script>
