import axios from "axios";
import * as Config from "../../../config";

const state = {
  student: {}
};
let mod = "eval/";
const actions = {
  loadStudent({ commit, dispatch }, params) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student/student/all", { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudent_by_representative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath +  mod +"student/all_by_parent/" + form.id_father)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudent_school({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudent_teacher({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student/all/teacher/" + form.id_teacher)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadStudent_teacher2({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath2 + "student/all", {params:form})
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadAllStudents({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath2 + "student/all", {params:form})
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  loadStudent_Active({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "student/student/all/status/" + form.active)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Student({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_STUDENT", data);
  },
  loadStudent_Stats({ commit, dispatch }, id) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "student/counters/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "student/student/save", form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  checkStudentAssistance({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath2 + "student_assistance/check", form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getStudentAssistance({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath2 + "student_assistance/all", {params:form})
        .then(response => {
         
            resolve(response.data);
          
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
 
  newStudentFromRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "student/children/save", form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  student_full({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "student/save/full", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  
  updateStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod + "student/update/" + form.id, form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateStudentPay({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod + "student/update_status_pay/" + form.id, form)
        .then(response => {
          if (response.data.data.id) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateStudentPayGeneral({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath2 + "student/change_pay_status/" + form.id+'/'+form.status)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + mod +
            "student/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + mod +
            "student/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteTotalStudent({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
      .post(Config.apiPath + mod + "student/delete", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  // Adonis 5

  LoadDataStudent({ commit, dispatch }, id) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath2 + "student/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

const mutations = {
  LOAD_DATA_STUDENT(state, data) {
    state.student = data;
  }
};

export default {
  state,
  actions,
  mutations
};
