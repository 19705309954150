<template>
  <div>
    <div
      id="chat_window"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')',
      }"
      class="chat_window"
    >
      <ul style="background-color:white;height:80px;padding-top:10px">
        <li>
          <p>
            <strong>Nota: </strong><span v-if="restt">{{ restt.result }}</span>
          </p>
        </li>
        <li>
          <p>
            <strong>Feedback del Profesor: </strong
            ><span v-if="restt">{{ restt.feedback }}</span>
          </p>
        </li>
      </ul>

      <ul class="messages" ref="container" id="container">
        <li
          v-for="msg in task_sended"
          :key="msg.id"
          class="message appeared right"
        >
          <div class="text_wrapper">
            <div class="row">
              <b-col md="6"> </b-col>
              <b-col md="6">
                <label style="font-size: 10px; color: gray">{{
                  msg.updated_at
                }}</label>
              </b-col>
            </div>
            <div class="text" v-if="msg.url">
              <img
                v-if="msg.format == 'jpg' || msg.format == 'jpeg' || msg.format == 'png'"
                :src="msg.url"
                class="img-fluid"
              />
                 <audio
                          v-if="msg.format == 'mp3'"
                          :src="msg.url"
                          controls
                        />
              <a v-if="msg.format == 'jpg' || msg.format == 'jpeg' || msg.format == 'png'" target="a_blank" :href="msg.url">
                <small>Ver Imagen en otra pestaña</small>
              </a>
               <a v-else-if="msg.format == 'mp3' || msg.format == 'mp4' || msg.format == 'ogg'" target="a_blank" :href="msg.url">
                <small>Escuchar audio en otra pestaña</small>
              </a>
               <a v-else-if="msg.format == 'pdf' || msg.format == 'docx' || msg.format == 'pptx'" target="a_blank" :href="msg.url">
                <small>Ver Documento en otra pestaña</small>
              </a>
               <a v-else>
                <small>Ver Archivo en otra pestaña</small>
              </a>
            </div>
            <div class="text">
              <span style="font-size: 12px"> {{ msg.name }}</span>
            </div>
            <div class="row">
              <b-col md="8"> </b-col>
              <b-col md="2"> </b-col>
              <b-col md="2">
                <b-button
                  @click="deleteMsg(msg.id)"
                  v-b-tooltip.hover
                  title="Eliminar este mensaje"
                  variant="outline-danger"
                  size="sm"
                >
                  <v-icon name="trash" />
                </b-button>
              </b-col>
            </div>
          </div>
        </li>
      </ul>

      <div class="bottom_wrapper clearfix">
        <b-row>
          <b-col md="6"> </b-col>
          <b-col md="6">
            <b-modal id="modal-upload" title="Subir tarea">
              <b-form
                id="uploadForm"
                class="row"
                method="post"
                @submit.prevent="uploadTask"
              >
                <div class="col-md-12">
                  <b-form-group
                    label="Nombre del documento que va a subir"
                    label-for="file_name"
                  >
                    <b-form-input
                      id="file_name"
                      v-model="file_name"
                      name="file_name"
                      placeholder="Ej: Profesor acá esta mi tarea"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Styled -->
                  <b-form-file
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Seleccione un archivo o arrastrelo hasta aquí..."
                    drop-placeholder="Arrastre el archivo hasta aquí..."
                  ></b-form-file>
                  <div class="mt-3">
                    Archivo Seleccionado: {{ file ? file.name : "" }}
                  </div>
                </div>

                <div class="col-md-4"></div>

                <div class="col-12 text-left">
                  <b-button pill type="submit" variant="outline-success" class="m-3"
                    >Subir Archivo</b-button
                  >

                  <b-spinner
                    variant="outline-success"
                    v-show="progress"
                    label="Text Centered"
                  ></b-spinner>
                </div>
              </b-form>
            </b-modal>

           <!-- <div
              v-if="evaluation.status_evaluation == 2"
              v-b-modal.modal-upload
              class="send_message"
            >
              Adjuntar Tarea
              <div class="text"><v-icon name="paperclip"></v-icon></div>
            </div>-->
            <div v-if="evaluation.status_evaluation != 2">
              <p v-if="evaluation.status_evaluation == 1">
                La clase se encuentra planificada
              </p>
              <p v-if="evaluation.status_evaluation == 3">
                La clase se encuentra culminada
              </p>
              <p v-if="evaluation.status_evaluation == 4">
                La clase se encuentra cerrada
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <hr />
          <b-col md="3">
            <b-button
              v-b-modal="'modal-upload-1'"
              title="Adjuntar archivo"
              variant="outline-success"
              size="sm"
            >
              <v-icon name="paperclip" /> Adjuntar una imagen
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button
              v-b-modal="'modal-upload-document'"
              title="Adjuntar archivo"
              variant="outline-warning"
              size="sm"
            >
              <v-icon name="paperclip" /> Adjuntar documento
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button
              v-b-modal="'modal-upload-audio'"
              title="Adjuntar archivo"
              variant="outline-primary"
              size="sm"
            >
              <v-icon name="paperclip" /> Adjuntar nota de voz
            </b-button>
          </b-col>

            <b-col md="3">
            <b-button
              @click="alert()"

              title="Adjuntar archivo"
              variant="outline-danger"
              size="sm"
            >
              <v-icon name="paperclip" /> Link Youtube
            </b-button>
          </b-col>
         
      
        </b-row>
        <b-row>
          <b-col md="12">
            <b-modal
              :id="'modal-upload-1'"
              title="Subir archivo"
              hide-footer
            >
              <b-tabs content-class="mt-3">
                <b-tab title="Subir una imagen" active>
                  <b-form
                    id="uploadForm"
                    class="row"
                    method="post"
                    @submit.prevent="uploadTask()"
                  >
                    <div class="col-md-12">
                      <b-form-group label-for="file_name">
                        <br />
                        <b-form-input
                          id="file_description2"
                          v-model="file_description"
                          name="file_description2"
                          placeholder="Descripción del archivo"
                        ></b-form-input>
                      </b-form-group>

                      <!-- Styled -->
                      <b-form-file
                        v-model="fileToUpload"
                        accept="image/*"
                        :state="Boolean(fileToUpload)"
                        v-on:change="onFileChange"
                        placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                        drop-placeholder="Arrastre el archivo hasta aquí..."
                      >
                        <img id="blah" src="#" alt="your image"
                      /></b-form-file>
                      <div class="mt-3">
                        Imagen seleccionada:
                        {{ fileToUpload ? fileToUpload.name : "" }}

                        <img class="img-fluid" :src="preview_image" />
                      </div>
                    </div>

                    <div class="col-md-4"></div>

                    <div class="col-12 text-left">
                      <b-button
                        :disabled="progress != false"
                        pill
                        type="submit"
                        variant="outline-success"
                        class="m-3"
                        >Subir imagen</b-button
                      >

                      <b-spinner
                        variant="outline-success"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </div> </b-form
                ></b-tab>
              </b-tabs>
            </b-modal>

            <b-modal
              :id="'modal-upload-document'"
              title="Subir archivo"
              hide-footer
            >
              <b-tabs content-class="mt-3">
                <b-tab title="Subir un documento">
                  <b-form
                    id="uploadForm2"
                    class="row"
                    method="post"
                    @submit.prevent="uploadTask()"
                  >
                    <div class="col-md-12">
                      <b-form-group label-for="file_name">
                        <br />
                        <b-form-input
                          id="file_description"
                          v-model="file_description"
                          name="file_description"
                          placeholder="Descripción del archivo"
                        ></b-form-input>
                      </b-form-group>

                      <!-- Styled -->
                      <b-form-file
                        v-model="fileToUpload"
                        accept=".pdf, .docx, .xlsx, .doc, .txt"
                        :state="Boolean(fileToUpload)"
                        placeholder="Seleccione un archivo o arrástrelo hasta aquí..."
                        drop-placeholder="Arrastre el archivo hasta aquí..."
                      ></b-form-file>
                      <div class="mt-3">
                        Archivo seleccionado:
                        {{ fileToUpload ? fileToUpload.name : "" }}
                      </div>
                    </div>

                    <div class="col-md-4"></div>

                    <div class="col-12 text-left">
                      <b-button
                        :disabled="progress != false"
                        pill
                        type="submit"
                        variant="outline-success"
                        class="m-3"
                        >Subir archivo</b-button
                      >

                      <b-spinner
                        variant="outline-success"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </div>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-modal>
             <b-modal
              :id="'modal-upload-youtube'"
              title="Subir archivo"
              hide-footer
            >
              <b-tabs content-class="mt-3">
                <b-tab title="Subir un link de youtube">
                  <b-form
                    id="uploadForm2"
                    class="row"
                    method="post"
                    @submit.prevent="uploadTask()"
                  >
                    <div class="col-md-12">
                      <b-form-group label-for="file_name">
                        <br />
                        <b-form-input
                          id="file_description"
                          v-model="file_description"
                          name="file_description"
                          placeholder="Descripción del archivo"
                        ></b-form-input>
                      </b-form-group>

                      <!-- Styled -->
                     
                    </div>

                    <div class="col-md-4"></div>

                    <div class="col-12 text-left">
                      <b-button
                        :disabled="progress != false"
                        pill
                        type="submit"
                        variant="outline-success"
                        class="m-3"
                        >Subir archivo</b-button
                      >

                      <b-spinner
                        variant="outline-success"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </div>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-modal>
            <b-modal
              :id="'modal-upload-audio'"
              title="Subir archivo"
              hide-footer
            >
              <b-tabs content-class="mt-3">
                <b-tab title="Subir una nota de voz">
                  <b-form
                    id="uploadForm3"
                    class="row"
                    method="post"
                    @submit.prevent="uploadAudio(2)"
                  >
                    <div class="col-md-12">
                      <b-form-group label-for="file_name">
                        <br />
                        <!--<vue-record-audio @result="onResult" />-->
                        <audio
                          v-if="preview_audio"
                          :src="preview_audio"
                          controls
                        />
                        <p
                          v-if="preview_audio"
                          style="font-size: 10px"
                          class="my-4"
                        >
                          Esta es la nota de voz que haz grabado, puedes darle
                          Play para escucharla antes de subirla
                        </p>

                        <!-- <audio-recorder
                          
                          upload-url="some url"
                          filename="ninja"
                          format="mp3"
                          :attempts="1"
                          :time="2"
                          
                          :before-recording="callback"
                          :pause-recording="callback"
                          :after-recording="callback"
                          :select-record="callback"
                          :before-upload="callback"
                          :successful-upload="callback"
                          :failed-upload="callback"
                          :bit-rate="192"
                        />-->
                      </b-form-group>

                      <!-- Styled -->
                    </div>

                    <div class="col-md-4"></div>

                    <div class="col-12 text-left">
                      <b-button
                        :disabled="progress != false"
                        pill
                        type="button"
                        @click="toggleRecording"
                        variant="outline-success"
                        class="m-3"
                        ><span v-if="!recording">Grabar nota de voz</span
                        ><span v-if="recording"
                          >Detener grabación</span
                        ></b-button
                      >
                      <div
                        v-if="recording != false"
                        class="spinner-grow text-danger"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>

                      <b-spinner
                        variant="outline-success"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>

                      <b-button
                        :disabled="progress != false"
                        pill
                        type="submit"
                        variant="outline-success"
                        class="m-3"
                        >Subir nota de voz</b-button
                      >
                    </div>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-modal>
          </b-col>
        </b-row>
        <hr />
      </div>
    </div>
    <div class="message_template">
      <li class="message left appeared">
        <!-- <div class="avatar">Hola</div>
        <div class="text_wrapper">
          Hola
          <div class="text">Holaa</div>
        </div>-->
      </li>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
var recorder, gumStream;
import { integer } from "vee-validate/dist/rules";
export default {
  //props: ["messages", "room"],
  name: "Welcome",

  data() {
    return {
      modalShow: false,
      file: null,
      file_name: null,
      progress: false,
      evaluations: [],
      classes: [],
      tasks: [],
      task_sended: [
        {
          active: "",
          created_at: "",
          feedback: "",
          format: "",
          id: "",
          id_evaluation: "",
          id_student: "",
          name: "",
          student: {},
          updated_at: "",
          url: "",
        },
      ],
      restt: {
        result: 0,
        feedback: "",
      },
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
        fileToUpload: [],
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      rooms: [],
      // messages: [],
      currentUserId: 1234,
      message_post: "",
      clipboards: [],
       id_room_channel: "",
      id_room_group: "",
      preview_image: "",
      preview_audio: "",
      download_audio: "",
      recorder: "",
      gumStream: "",
      recording: false,
    };
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluation.evaluation,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    // this.getClipboard();
    // this.scrollToEnd();
    //var objDiv = document.getElementById("MyDivmssElement");
    //objDiv.scrollTop = objDiv.scrollHeight;
    this.loadState();
    this.loadResults();
  },
  methods: {
    ...mapActions([
      "new_Message",
      "loadEvaluation_docs_by_student",
      "loadEvaluation_result_by_student",
      "delete_Chat_Message",
      "store_Clipboard",
      "uploadArchiveTask",
      "changeActive_docs_by_student"
    ]),
    alert(){
      alert('Próximamente podrás subir tus links de youtube');
    },
    scrollToEnd: function() {
      var content = this.$refs.container;
      console.log(content);
      console.log("scrollTop:" + content.scrollTop);
      if (content.scrollHeight > content.scrollTop) {
        content.scrollTop = content.scrollHeight;
      }
    },
     onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
      createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    loadResults() {
      console.log("Loading Tasks");
      let form3 = {
        id_student: this.me_rol.id_student,
        id_evaluation: this.evaluation.id,
      };
      this.loadEvaluation_result_by_student(form3)
        .then((response) => {
          if (response.code === 200) {
            this.restt = response.data[0];
            console.log(this.restt);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //LOAD CLIPBOARD
    },
    toggleRecording() {
      let self = this;
      if (recorder && recorder.state == "recording") {
        this.recording = false;
        console.log("STOP RECORD");
        recorder.stop();
        console.log(gumStream.getAudioTracks());
        gumStream.getAudioTracks()[0].stop();
      } else {
        this.recording = true;
        console.log("START RECORD");
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(function(stream) {
            gumStream = stream;
            var options = { mimeType: "audio/webm" };
            recorder = new MediaRecorder(stream, options);
            recorder.ondataavailable = function(e) {
              var url = URL.createObjectURL(e.data);

              var preview = document.createElement("audio");
              preview.controls = true;
              console.log("URL: " + url);
              //this.preview_audio = url;

              console.log("The blob data:", e.data);
              //let blob = new Blob([e.data], { type: "audio/mpeg" });
              let blob = new Blob([e.data], { type: "audio/mpeg" });
              // var blob = new Blob(e.data, {type: 'video/mp4'});
              console.log("The new blob data:", blob);
              //URL.createObjectURL(blob);
              self.audio_to_upload = blob;
              self.preview_audio = URL.createObjectURL(blob);
              self.download_audio = window.URL.createObjectURL(blob);

              console.log(self.preview_audio);
              console.log(
                "Downloadable audio",
                window.URL.createObjectURL(e.data)
              );
            };
            recorder.start();
          });
      }
    },
    onResult(data) {
      console.log("The blob data:", data);
      //URL.createObjectURL(blob);
      this.audio_to_upload = data;
      this.preview_audio = URL.createObjectURL(data);
      this.download_audio = window.URL.createObjectURL(data);

      console.log(this.preview_audio);
      console.log("Downloadable audio", window.URL.createObjectURL(data));
    },
    loadState() {
      console.log("Loading Tasks");
      let form3 = {
        id_student: this.me_rol.id_student,
        id_evaluation: this.evaluation.id,
      };
      this.loadEvaluation_docs_by_student(form3)
        .then((response) => {
          if (response.code === 200) {
            this.task_sended = response.data;
            console.log(this.task_sended);
            /* resp.data.reverse();

            for (let i in resp.data) {
              let msg = resp.data[i];

              this.task_sended.push(msg);
            }*/
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //LOAD CLIPBOARD
    },

    deleteMsg(msg_id) {
      Swal.fire({
        title: "Eliminacion de Mensaje",
        text: "Esta seguro de eliminar este mensaje",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: msg_id,
            active: 2,
          };
          this.changeActive_docs_by_student(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Eliminado correctamente",
                  icon: "success",
                });
                this.loadState();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    
    send() {
      if (this.message_post == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Escribe un mensaje a enviar",
          icon: "warning",
        });
      } else {
        let form = {
          id_room: this.room,
          id_user: this.me.id,
          message: this.message_post,
        };
        this.new_Message(form)
          .then((response) => {
            console.log("RESPONSE");
            if (response.code == 200) {
              this.message_post = "";
              this.loadState();
              this.scrollToEnd();
            }
            if (response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "success",
              }).then((result2) => {
                console.log(result2);
              });
            }
          })
          .catch((error) => {
            console.log("ERROR");
            if (error.response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "error",
              }).then((result2) => {
                console.log(result2);
              });
            }
          });
      }
    },
      uploadAudio(type) {
      if (this.preview_audio != "") {
        let data = new FormData();

        console.log("FILE FORMATS");
        console.log(this.preview_audio);
        console.log(this.download_audio);
        console.log(this.audio_to_upload);
        //let formData = new FormData(document.getElementById("uploadForm"));

        var fileOfBlob = new File(
          [this.audio_to_upload],
          "testing_Uploading.mp3"
        );
        // var file = new File([blob], "my_image.png",{type:"image/png", lastModified:new Date().getTime()})
        //let blob = new Blob(this.download_audio, {type: 'audio/mpeg-3'});
        data.append("file", fileOfBlob);

     // let name = this.file_description;
      let id_evaluation = this.evaluation.id;
      let id_student = this.me_rol.id_student;
      let id_school = this.me_rol.id_school;

    
      data.append("id_evaluation", id_evaluation);
      data.append("id_student", id_student);
      data.append("id_school", id_school);

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de subir este Archivo?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Subir",
        }).then((result) => {
          if (result.value) {
            this.progress = true;
            this.uploadArchiveTask(data)
              .then((response) => {
                if (response.code === 200) {
                  (this.progress = false),
                    Swal.fire({
                      title: "Subida de Archivo Completada",
                      icon: "success",
                    }).then((result2) => {
                      this.loadState();
                      this.scrollToEnd();
                      Swal.fire({
                        title: "Mensaje Insertado",
                        text: "El mensaje ha sido insertado en esta evaluación",
                        icon: "success",
                      });
                      this.preview_image = "";
                      this.$bvModal.hide("modal-upload" + this.room);
                      this.preview_audio = "";
                      this.audio_to_upload = "";
                      // this.$router.push({ name: "representative_childrens" });
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "Ocurrio un error",
                  text:
                    "Comprueba tu conexion con internet, e intentalo mas tarde",
                  icon: "warning",
                });
                this.progress = false;
                console.log(error);
              });
          }
        });
      } else {
        Swal.fire({
          title: "Graba una nota de voz",
          text:
            "Primero debes grabar un audio para luego subirlo, recuerda darle permisos a tu navegador para que puedas grabar con tu micrófono",
          icon: "warning",
        });
      }
    },
    uploadTask() {
      let data = new FormData();
      //let formData = new FormData(document.getElementById("uploadForm"));

      data.append("file", this.fileToUpload);
      let name = this.file_description;
      let id_evaluation = this.evaluation.id;
      let id_student = this.me_rol.id_student;
      let id_school = this.me_rol.id_school;

      data.append("name", name);
      data.append("id_evaluation", id_evaluation);
      data.append("id_student", id_student);
      data.append("id_school", id_school);

      console.log(data);
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de subir este Archivo como tarea?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Subir",
      }).then((result) => {
        if (result.value) {
          this.progress = true;
          this.uploadArchiveTask(data)
            .then((response) => {
              if (response.code === 200) {
                (this.progress = false),
                  Swal.fire({
                    title: "Subida de Archivo Completada",
                    text:
                      "Su archivo de tarea ha sido almacenado correctamente",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.progress=false;
                    this.loadState();
                    this.file_description = "";
                    this.fileToUpload = "";
                    this.$bvModal.hide("modal-upload-1");
                    this.$bvModal.hide("modal-upload-document");
                    this.$bvModal.hide("modal-upload-audio");
                    this.$bvModal.hide("modal-upload-youtube");
                   
                    // this.$router.push({ name: "representative_childrens" });
                  });
              }
            })
            .catch((error) => {
              this.progress =false;
              Swal.fire({
                    title: "Ocurrio un error al subir el archivo",
                    text:
                      "Comprueba el formato del archivo,verifica tu conexión e intenta usar otro formato",
                    icon: "warning",
                  })
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}
.container {
  overflow-y: scroll;
}
.chat_window {
  position: relative;
  width: calc(100% - 20px);
  max-width: 800px;
  height: 650px;
  margin-top: -30px;
  border-radius: 10px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  overflow: hidden;
}

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 15px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}
.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}
.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.top_menu .buttons .button.close {
  background-color: #002d4a;
}
.top_menu .buttons .button.minimize {
  background-color: #d4ffe0;
}
.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}
.top_menu .title {
  text-align: center;
  color: #bcbdc0;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  padding: 10px 10px 200px 10px;
  margin: 0;

  height: 600px;
  overflow: scroll;
}
.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.messages .message.left .text_wrapper {
  background-color: #ffffff;
  margin-left: 20px;
}
.messages .message.left .text_wrapper::after,
.messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}
.messages .message.left .text {
  color: #000000;
}
.messages .message.right .avatar {
  background-color: #012a82;
  float: right;
}
.messages .message.right .text_wrapper {
  background-color: #ffffff;
  margin-right: 20px;
  float: right;
}
.messages .message.right .text_wrapper::after,
.messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #ffffff;
}
.messages .message.right .text {
  color: #000000;
}
.messages .message.appeared {
  opacity: 1;
}
.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}
.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 28px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}
.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}

.bottom_wrapper {
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  margin-top: 60px;
  position: fixed;
  bottom: 0;
}
.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}
.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
}
.bottom_wrapper .send_message {
  width: 250px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
}
.bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #fff;
}
.bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}

.message_template {
  display: none;
}
</style>
