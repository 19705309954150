<template>
  <div class="accordion" role="tablist">
    <b-row v-if="loading">
      <b-col md="12" class="text-center" align-self="center">
        <b-spinner variant="success" class="text-center" large ></b-spinner>
      </b-col>
    </b-row>

    <b-row v-else class="mb-1" v-for="asis in assistances" :key="asis.id">
      <b-col md="1" class="text-center" align-self="center">
        <img v-if="asis.user.image" :src="asis.user.image" alt="" height="50px" style="border-radius: 50%; aspect-ratio: 1; object-fit: cover">
        <img v-else src="https://storage.googleapis.com/ticktaps-school/clasy2logo.png" alt="" height="50px" style="border-radius: 50%; aspect-ratio: 1; object-fit: cover">
      </b-col>
      <b-col md="7" class="text-left" align-self="center">
        <p
          class="my-0 py-0"
          v-if="asis.user">
          {{ asis.user.full_name }}
        </p>
      </b-col>
      <b-col md="2" class="text-center">
        <p class="my-0 py-0" style="font-size: 14px">Profesor</p>
        <b-button
          variant="outline-success"
          v-if="asis.assisted_teacher == 1"
          @click="setAssistance(asis)"
          size="md">
          <v-icon v-if="!progress" name="check"></v-icon>
          <b-spinner v-else variant="success" class="text-center" small ></b-spinner>
        </b-button>
        <b-button
          variant="outline-danger"
          v-if="asis.assisted_teacher == 0"
          @click="setAssistance(asis)"
          size="md">
          <v-icon v-if="!progress" name="times"></v-icon>
          <b-spinner v-else variant="danger" class="text-center" small ></b-spinner>
        </b-button>
        <p v-if="asis.assisted_teacher == 1" class="my-0 py-0" style="font-size: 11px">{{ moment(asis.assisted_teacher_datetime).local().format('D MMM YYYY, h:mm a') }}</p>
      </b-col>
      <b-col md="2" class="text-center" v-b-tooltip.hover title="Asistencia marcada por el alumno">
        <p class="my-0 py-0" style="font-size: 14px">Alumno</p>
        <b-button
          variant="outline-success"
          v-if="asis.assisted_student == 1"
          disabled
          @click="setAssistance(asis)"
          size="md"
          ><v-icon name="check"></v-icon
        ></b-button>

        <b-button
          variant="outline-danger"
          v-if="asis.assisted_student == 0"
          disabled
          size="md"
          ><v-icon name="times"></v-icon
        ></b-button>
        <p v-if="asis.assisted_student == 1" class="my-0 py-0" style="font-size: 11px">{{ moment(asis.assisted_student_datetime).local().format('D MMM YYYY, h:mm a') }}</p>
      </b-col>

      <b-col md="12"><hr></b-col>
    </b-row>

    <!--<b-card no-body class="mb-1" v-for="asis in assistances" :key="asis.id">
      <b-card-body class="p-0" role="tab">
        <b-row>
          <b-col sm="6">
            <p
              v-b-toggle="'collapse-' + asis.id"
              variant="outline-info"
              style="padding: 5px"
              v-if="asis.user"
            >
              {{ asis.user.full_name }}
            </p>
          </b-col>

          <b-col sm="6">
               <div class="row">
                       <div class="col-md-12">
            <p style="font-size: 10px">Marcaje de Asistencia</p>
                       </div>
                 </div>
            <div class="row">
              <div class="col-md-6">
                <b-button
                  variant="outline-success"
                  v-if="asis.assisted_teacher == 1"
                  @click="setAssistance(asis)"
                  size="sm"
                  ><v-icon name="check"></v-icon
                ></b-button>
                <b-button
                  variant="outline-secondary"
                  v-if="asis.assisted_teacher == 0"
                  @click="setAssistance(asis)"
                  size="sm"
                  ><v-icon name="times"></v-icon
                ></b-button>

                <p style="font-size: 9px">Profesor</p>
              </div>
              <div
                class="col-md-6"
                v-b-tooltip.hover
                title="Asistencia marcada por el alumno"
              >
                <b-button
                  variant="outline-success"
                  v-if="asis.assisted_student == 1"
                  disabled
                  @click="setAssistance"
                  size="sm"
                  ><v-icon name="check"></v-icon
                ></b-button>

                <b-button
                  variant="outline-secondary"
                  v-if="asis.assisted_student == 0"
                  disabled
                  size="sm"
                  ><v-icon name="times"></v-icon
                ></b-button>
                <p style="font-size: 9px">Alumno</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
        <b-collapse :id="'collapse-'+asis.id" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
           
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>-->

    <!--<b-modal v-model="modalShow" size="lg" ref="my-modal" hide-footer>
      <div class="d-block text-center">
        <b-row>
          <b-col md="12">
            <b-list-group>
              <b-list-group-item>
                <h4 v-if="task_detail.user">
                  Alumno: {{ task_detail.user.full_name }}
                </h4>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="3">
                    <p>Nota de Alumno en esta evaluación</p>
                  </b-col>
                  <b-col md="2">
                    <b-form>
                      <b-form-input
                        min="0"
                        max="100"
                        id="inline-form-input-name"
                        v-model="task_detail.grade_result"
                        name="first_name"
                      />
                    </b-form>
                  </b-col>
                  <b-col md="4">
                    <b-textarea
                      id="inline-form-input-name"
                      type="number"
                      placeholder="Feedback General"
                      v-model="task_detail.grade_feedback"
                      name="first_name"
                    />
                  </b-col>
                  <b-col md="3">
                    <b-button
                      @click="saveResultEvaluation(task_detail)"
                      variant="outline-primary"
                      >Guardar Nota <v-icon name="save"></v-icon
                    ></b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>

            <br />
            <br />
            <b-list-group>
              <b-list-group-item>
                <p>
                  <strong>{{ archive_label }}</strong>
                </p>
              </b-list-group-item>
              <b-list-group-item
                v-for="task in task_detail.documents"
                :key="task.id"
              >
                <b-row>
                  <b-col md="8">
                    <p><strong>Título:</strong> {{ task.name }}</p>
                    <a :href="task.url" target="a_blank">Ver Archivo</a>
                    <p style="font-size: 9px; color: gray">
                      <strong>Enviado:</strong> {{ task.created_at }}
                    </p>
                  </b-col>
                  <b-col md="4">
                    <b-textarea v-model="task.feedback"></b-textarea>
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="setFeedbackToEvaluationDoc(task)"
                      >Dar retroalimentación</b-button
                    >
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>-->
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
import { integer } from "vee-validate/dist/rules";
export default {
  //props: ["messages", "room"],
  name: "TasksEvaluations",
  data() {
    return {
      loading: true,
      progress: false,
      moment: moment,
      evaluations: [],
      modalShow: false,
      archive_label: "",
      task_detail: {
        user: {
          full_name: "",
        },
      },
      assistances: [],
    };
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluation.evaluation,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.moment.locale('es')

    this.getAssistances();
    console.log("MOUNTING");
  },
  methods: {
    ...mapActions([
      "new_Message",
      "loadEvaluation_Messages",
      "delete_Chat_Message",
      "loadEvaluation_Tasks_teacher",
      "setFeedback_evaluation_doc",
      "saveEvaluation_result",
      "loadEvaluation_Student_list",
      "checkStudentAssistance",
    ]),
    setAssistance(assistance) {
      console.log("GETTIN TASKS");
      let assis = 1;
      let text_assis =
        "¿Estás seguro de marcar la asistencia de " +
        assistance.user.full_name +
        "? La misma se registrará con la fecha y hora actual ("+moment().local().format('D MMM YYYY, h:mm a')+")";
      if (assistance.assisted_teacher == 1) {
        assis = 0;
        text_assis =
          "¿Estás seguro de desmarcar la asistencia de " +
          assistance.user.full_name +
          "?";
      }
      //GET TASKS BY EVALUATION
      let form = {
        id_student: assistance.student.id,
        id_evaluation: this.evaluation.id,
        assisted: assis,
        user_checker: 1,
      };

      Swal.fire({
        title: "Marcaje de Asistencia",
        text: text_assis,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.value) {
          this.progress = true

          this.checkStudentAssistance(form)
            .then((response) => {
              console.log(response)
              this.progress = false
              console.log("RESPONSE");

              if (response.code == 200) {
                assistance.assisted_teacher = response.data.assisted_teacher
                assistance.assisted_teacher_datetime = response.data.assisted_teacher_datetime
                
                Swal.fire({
                  title: "Asistencia actualizada",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500
                })
              }
              else if (response.code == 500) {
                Swal.fire({
                  title: "Ocurrio un error",
                  text: "Intenta de nuevo, completa todos los campos",
                  icon: "error",
                })
              }
            })
            .catch((error) => {
              this.progress = false
              console.log(error)

              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo más tarde",
                icon: "error",
              })
            });
        }
      });
    },
    getAssistances() {
      //GET TASKS BY EVALUATION
      let form = {
        id_evaluation: this.evaluation.id,
      };
      this.loadEvaluation_Student_list(form)
        .then((response) => {
          this.loading = false

          if (response.code === 200) {
            let resp = response.data;
            this.assistances = resp;
          }
        })
        .catch((error) => {
          this.loading = false
          console.log(error);
        });
    },
    setFeedbackToEvaluationDoc(task) {
      console.log("GETTIN TASKS");
      //GET TASKS BY EVALUATION
      let form = {
        id_evaluation_doc: task.id,
        feedback: task.feedback,
      };
      this.setFeedback_evaluation_doc(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            this.assistances = resp;

            this.$bvToast.toast("Retroalimentación enviada", {
              title: "Guardado correctamente",
              type: "success",
              autoHideDelay: 5000,
              // appendToast: append
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveResultEvaluation(task_detail) {
      console.log("SAVING TASKS RESULT");
      //GET TASKS BY EVALUATION
      let form = {
        id_evaluation: this.evaluation.id,
        id_student: task_detail.id,
        result: task_detail.grade_result,
        feedback: task_detail.grade_feedback,
      };
      this.saveEvaluation_result(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;

            this.getAssistances();

            this.$bvToast.toast("Nota asignada al estudiante", {
              title: "Guardado correctamente",
              variant: "success",
              autoHideDelay: 5000,
              // appendToast: append
            });
            this.modalShow = false;
          }
          if (response.code === 201) {
            let resp = response.data;

            this.getAssistances();

            this.$bvToast.toast("Nota asignada al estudiante", {
              title: "Actualizado correctamente",
              variant: "success",
              autoHideDelay: 5000,
              // appendToast: append
            });
            this.modalShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showDetail(task) {
      this.modalShow = true;
      this.task_detail = task;

      console.log("TASK LENGHT:" + Object.keys(task.documents).length);
      if (Object.keys(task.documents).length > 0) {
        this.archive_label = "Archivos de este estudiante";
      } else {
        this.archive_label = "No hay documentos asociados a este estudiante";
      }
    },
    scrollToEnd: function() {
      var content = this.$refs.container;
      console.log(content);
      console.log("scrollTop:" + content.scrollTop);
      if (content.scrollHeight > content.scrollTop) {
        content.scrollTop = content.scrollHeight;
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}
.container {
  overflow-y: scroll;
}
.chat_window {
  position: relative;
  width: calc(100% - 20px);
  max-width: 800px;
  height: 650px;
  margin-top: -30px;
  border-radius: 10px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  overflow: hidden;
}

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 15px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}
.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}
.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.top_menu .buttons .button.close {
  background-color: #002d4a;
}
.top_menu .buttons .button.minimize {
  background-color: #d4ffe0;
}
.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}
.top_menu .title {
  text-align: center;
  color: #bcbdc0;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  padding: 20px 10px 0 10px;
  margin: 0;
  height: 570px;
  overflow: scroll;
}
.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.messages .message.left .text_wrapper {
  background-color: #ffffff;
  margin-left: 20px;
}
.messages .message.left .text_wrapper::after,
.messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}
.messages .message.left .text {
  color: #000000;
}
.messages .message.right .avatar {
  background-color: #012a82;
  float: right;
}
.messages .message.right .text_wrapper {
  background-color: #ffffff;
  margin-right: 20px;
  float: right;
}
.messages .message.right .text_wrapper::after,
.messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #ffffff;
}
.messages .message.right .text {
  color: #000000;
}
.messages .message.appeared {
  opacity: 1;
}
.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}
.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}
.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}

.bottom_wrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  position: absolute;
  bottom: 0;
}
.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}
.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
}
.bottom_wrapper .send_message {
  width: 70px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
}
.bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #fff;
}
.bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}

.message_template {
  display: none;
}
</style>
