import axios from "axios";
import * as Config from "../../../config";

const state = {
    schools: {
        current_page: 1,
        data: []
    },
    school: {}
};
let mod = "gen/";
const actions = {
    loadSchool({commit, dispatch}) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath +mod+ "school/all")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadSchool2({commit, dispatch}) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath2+ "school/all")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadSchool_byId({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath +mod+ "school/" + form.id + "")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    check_inscription_code({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath+mod+"school/configs/check_inscription_code", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadSchools_Configs({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+"configs/all/" + form.id_school)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadSchools_Active({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod+"school/all/?active=" + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadData_School({commit, dispatch}, data) {
        console.log(dispatch)
        console.log(commit)
        commit("LOAD_DATA_SCHOOL", data);
    },
    newSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + mod+"school/save", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    changeActiveSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath +mod+ "school/change_status/" + form.id + "/" + form.status
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    updateSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + mod+"school/update/" + form.id, form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    deleteSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "school/change_status/" + form.id + "/" + form.status
                )
                .then(response => {
                    // commit('DELETE_SCHOOL_OK', id)
                    resolve(response.data);
                })
                .catch(error => {
                    //commit('DELETE_SCHOOL_FAIL')
                    reject(error.response.data);
                });
        });
    },
    configSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "config/school/save/update", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};

const mutations = {
    LOAD_DATA_SCHOOL(state, data) {
        state.school = data;
    },

    LOAD_School_OK(state, School) {
        state.school = School;
    },

    LOAD_SCHOOL_OK(state, SCHOOL) {
        state.school = SCHOOL;
    },

    LOAD_SCHOOL_POINT_OK(state, SCHOOL) {
        state.school = SCHOOL;
    },

    UPDATE_SCHOOL_OK(state, SCHOOL) {
        state.school = SCHOOL;
    }
};

export default {
    state,
    actions,
    mutations
};
