<template>
  <div
    id="check"
    :style="{
      backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')',
    }"
    style="overflow-x: hidden; background-size: contain"
  >
    <br />
    <br />
    <b-row
      cols="12"
      class="justify-content-center"
      align-h="center"
      align-v="center"
      align-content="center"
    >
      <b-col md="3"> </b-col>
      <b-col md="6" class="text-center">
        <img class="img-responsive center-block d-block mx-auto" style="max-width:200px" src="https://storage.googleapis.com/ticktaps-school/clasy2logo.png" />
      </b-col>
      <b-col md="3"> </b-col>
    </b-row>

    <b-row v-if="step==1">
      <b-col md="3"></b-col>
      <b-col md="6">
        <br />
        <p v-if="progress" class="text-center">
          Obteniendo información, por favor espere...
        </p>
         <p v-if="listRoles.length > 0 && !progress" class="px-2 text-center">
          Por favor selecciona tu perfil en la escuela para acceder:
        </p>
         <p v-if="listRoles.length === 0 && !progress" class="text-center">
          No estas agregado a ninguna escuela, por favor presiona el botón para registrarte en una.
        </p>
        <b-row v-if="progress">
         
          <b-col md="5"> </b-col>
          <b-col md="2" class="text-center">
            <b-spinner
              style="width: 3rem; height: 3rem"
              v-if="step==1"
              variant="outline-primary"
            ></b-spinner>
            
          </b-col>
          <b-col md="5"> </b-col>
        </b-row>

        <!--<div v-for="rol in listRoles" :key="rol.id">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <b-col class="col-md-6">
                  <label class="card-title">{{ rol.name_school }}</label><br>
                  <small v-if="rol.id_rol ==1 && rol.first_name">Estudiante:  {{rol.first_name+' '+rol.last_name}}</small>
                   <small v-if="rol.id_rol ==1 && !rol.first_name">Estudiante:  {{user.first_name+' '+user.last_name}}</small>
                </b-col>
                <b-col class="col-md-6">
                  <b-button
                    size="lg"
                    pill
                    variant="outline-primary"
                    class="mr-2"
                    v-if="rol.id_rol == 1"
                    @click="goEnvironment(rol)"
                  >
                   
                    <span  style="font-size:14px">Acceder como Estudiante  </span>
                   
                     <v-icon name="angle-right" />
                  </b-button>

                   <b-button
                    size="lg"
                    pill
                    variant="outline-success"
                    class="mr-2"
                    v-if="rol.id_rol == 2"
                    @click="goEnvironment(rol)"
                  >
                   
                    <span  style="font-size:14px">Acceder como Profesor  </span>
                   
                     <v-icon name="angle-right" />
                  </b-button>

                    <b-button
                    size="lg"
                    pill
                    variant="outline-info"
                    class="mr-2"
                    v-if="rol.id_rol == 3"
                    @click="goEnvironment(rol)"
                  >
                   
                    <span  style="font-size:14px">Acceder como Representante  </span>
                   
                     <v-icon name="angle-right" />
                  </b-button>

                       <b-button
                    size="lg"
                    pill
                    variant="outline-secondary"
                    class="mr-2"
                    v-if="rol.id_rol == 4"
                    @click="goEnvironment(rol)"
                  >
                   
                    <span  style="font-size:14px">Acceder como Director  </span>
                   
                     <v-icon name="angle-right" />
                  </b-button>
                  
                  


                </b-col>
              </div>
            </div>
          </div>
          <br />
        </div>-->

        <div v-for="elem in listSchools" :key="elem.id_school" class="mx-1">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <b-col md="12" class="text-center">
                  <img v-if="elem.school.img_logo" :src="elem.school.img_logo" style="background-color: #FFFFFF; border-radius: 50%; aspect-ratio: 1; object-fit: cover; max-height: 100px; max-width: 100px" alt="">
                  <img v-else src="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/image_data/file/111994/s960_EmptyClassroom.jpg" style="background-color: #FFFFFF; border-radius: 50%; aspect-ratio: 1; object-fit: cover; max-height: 100px; max-width: 100px" alt="">
                </b-col>
                <b-col md="12" class="mt-1 mb-1 text-center">
                  <p class="my-0 py-0 card-title"><strong>{{ elem.school.name }}</strong></p>
                </b-col>
                <b-col v-for="rol of elem.roles" :key="'R-'+rol.id" md="12" class="mt-1">
                  <b-button
                    size="lg"
                    pill
                    variant="primary"
                    class="mr-2 my-0 pt-0 pb-1 px-3"
                    style="width: 100%"
                    v-if="rol.id_rol == 1"
                    @click="goEnvironment(rol)">
                    <span class="my-0 py-0" style="font-size: 14px">Acceder como Estudiante{{ rol.first_name ? ' ('+rol.first_name+' '+rol.last_name+')' : ' ('+user.first_name+' '+user.last_name+')' }}</span>
                    <v-icon class="my-0 py-0 ml-2" name="angle-right" />
                  </b-button>

                   <b-button
                    size="lg"
                    pill
                    variant="success"
                    class="mr-2 my-0 pt-0 pb-1 px-3"
                    style="width: 100%"
                    v-if="rol.id_rol == 2"
                    @click="goEnvironment(rol)">
                    <span class="my-0 py-0" style="font-size: 14px">Acceder como Profesor</span>
                    <v-icon class="my-0 py-0 ml-2" name="angle-right" />
                  </b-button>

                  <b-button
                    size="lg"
                    pill
                    variant="info"
                    class="mr-2 my-0 pt-0 pb-1 px-3"
                    style="width: 100%"
                    v-if="rol.id_rol == 3"
                    @click="goEnvironment(rol)">
                    <span class="my-0 py-0" style="font-size:14px">Acceder como Representante</span>
                    <v-icon class="my-0 py-0 ml-2" name="angle-right" />
                  </b-button>

                  <b-button
                    size="lg"
                    pill
                    variant="secondary"
                    class="mr-2 my-0 pt-0 pb-1 px-3"
                    style="width: 100%"
                    v-if="rol.id_rol == 4"
                    @click="goEnvironment(rol)">
                    <span class="my-0 py-0" style="font-size:14px">Acceder como Director</span>
                    <v-icon class="my-0 py-0 ml-2" name="angle-right" />
                  </b-button>
                </b-col>
              </div>
            </div>
          </div>
          <br />
        </div>
        
        <button
          v-if="!progress"
          type="button"
          v-on:click="RegisterOnSchool()"
          class="btn btn-success btn-block btn-lg"
          >Registrarme como representante en una nueva escuela</button
        >
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
    
    <br />
    <br />
    <br />
  </div>
</template>

<script>
//import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
export default {
  name: "check",
  props: ["items", "auth"],
  data() {
    return {
      progress: false,
      fields: [
        {
          key: "name_school",
          label: "Escuela",
          sortable: false,
        },
        {
          key: "action",
          label: "Acceder Como",
          sortable: false,
        },
      ],
      listRoles: [],
      listSchools: [],
      step:1
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.me,
      authe: (state) => state.auth,
    }),
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    ...mapActions(["addToastMessage", "getAllRolsByUser"]),
    getRoles() {
      //let id_user = this.authe.me.id;
      this.progress = true;
      this.getAllRolsByUser(this.user.id).then((response) => {
        if (response.data.roles.length === 0) {
         this.progress = false;
         
        } else if (response.code === 200) {
          let schools = []

          for (let item of response.data.roles) {
            let key = 0

            for (let sch of schools) {
              if (item.id_school == sch) {
                key = 1
                break
              }
            }

            if (key == 0)   schools.push(item.id_school)
          }

          this.listSchools = []

          for (let sch of schools) {
            let school = {}
            let roles = []

            for (let rol of response.data.roles) {
              if (rol.id_school == sch) {
                school = rol.school
                roles.push(rol)
              }
            }

            roles.sort((a,b) => {
              return b.id_rol - a.id_rol
            })

            this.listSchools.push({ school: school, roles: roles })
          }

          console.log(this.listSchools)
          this.listRoles = response.data.roles;
          this.progress = false;
        }
      });
    },
    RegisterOnSchool(){
       this.$router.push("/registro/escuela");
    },
    goEnvironment(data) {
      
      const id_rol = data.id_rol;
      const block_unsolvent = data.block_unsolvent;
      const block_reason = data.block_reason;
      const status_pay = data.status_pay;
      Cookies.set("id_rol", id_rol);
    
    
      this.$store.commit("LOGIN_OK", { data: this.authe, item: data });
      console.log('DATA')
      console.log(data)
      if (id_rol === 1) {
        if(block_unsolvent == 1 && status_pay == 2){
          console.log('School is blocking')
           Swal.fire({
          title: "Atención",
          text: block_reason,
          icon: "warning",
        });

           this.addToastMessage({
            text: "Aún no puedes acceder",
            icon: "warning",
          });
        }else{
          this.$router.push("/estudiante");
          console.log('ENTRANDO A ESTUDIANTE')
           this.addToastMessage({
              text: "Bienvenido estudiante",
              icon: "success",
            });
        }
        
      } else if (id_rol === 2) {
        this.$router.push("/profesor");
         this.addToastMessage({
        text: "Bienvenido profesor",
        icon: "success",
      });
      } else if (id_rol === 3) {
        this.$router.push("/representante");
         this.addToastMessage({
        text: "Bienvenido representate",
        icon: "success",
      });
      } else if (id_rol === 4) {
        this.$router.push("/director");
         this.addToastMessage({
        text: "Bienvenido director",
        icon: "success",
      });
      } else if (id_rol === 5) {
        this.$router.push("/admin");
         this.addToastMessage({
        text: "Bienvenido administrador",
        icon: "success",
      });
      }

     
    },
  },
};
</script>