<template>
  <div>
    <br />
    <div class="content">
      <h2>Materias</h2>
      <p>Acá puedes visalizar todas las materias según la sección a la que perteneces</p>
      <hr>

      <b-row class="row" v-if="loading">
        <b-col md="12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="outline-success"
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="px-2">
        <b-col
          md="4"
          v-for="subject in subjects"
          :key="subject.id"
          class="mb-4 px-2"
          style="height: 200px"
        > 
          <div v-if="subject.subject.url_img" :style="`background-image: url(${subject.subject.url_img}); background-size: 100%; border-radius: 5px 5px 0px 0px; max-height: 180px`">
            <b-row align-v="center" align-h="center">
              <b-col md="12" class="text-center pt-2">
                <img :src="subject.teacher.user.image" alt="" height="70rem" style="box-shadow: 2px 2px 5px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
              </b-col>
              <b-col md="12" class="pb-2">
                <h3 class="text-center ml-0 mb-1" style="font-size: 2rem; color: #FFFFFF; text-shadow: 2px 2px 5px #000000;">{{ subject.subject.name }}</h3>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 1rem; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Sección:</strong> {{ subject.section.name }} </p>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 16px; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Periodo:</strong> {{ subject.period.name }} </p>
              </b-col>
                <!--<img src="https://storage.googleapis.com/ticktaps-school/school-banner1.jpeg" alt="" width="100%" height="100px" style="border-radius: 5px 5px 0px 0px; object-fit: cover;">-->
            </b-row>
          </div>

          <div v-else style="background-image: url(https://storage.googleapis.com/ticktaps-school/school-banner1.jpeg); background-size: 100%; border-radius: 5px 5px 0px 0px; max-height: 180px">
            <b-row align-v="center" align-h="center">
              <b-col md="12" class="text-center pt-2">
                <img v-if="subject.teacher.user.image" :src="subject.teacher.user.image" alt="" height="70rem" style="box-shadow: 2px 2px 5px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                <b-avatar v-else variant="outline-info" size="4rem"></b-avatar>
              </b-col>
              <b-col md="12" class="pb-2">
                <h3 class="text-center ml-0 mb-1" style="font-size: 2rem; color: #FFFFFF; text-shadow: 2px 2px 5px #000000;">{{ subject.subject.name }}</h3>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 1rem; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Sección:</strong> {{ subject.section.name }} </p>
                <p class="text-center my-0 py-0 ml-0" style="color: #000000; font-size: 16px; text-shadow: 2px 2px 5px #FFFFFF;"> <strong>Periodo:</strong> {{ subject.period.name }} </p>
              </b-col>
                <!--<img src="https://ik.imagekit.io/eswqag3k3o6/school2/docs/student1/2021-09-30_015920-newproject-2pXZM1.jpg" alt="" width="100%" height="100px" style="border-radius: 5px 5px 0px 0px; object-fit: cover;">-->
            </b-row>
          </div>

          <b-row align-h="center" class="mb-2">
            <b-col md="12" align-self="center">
              <b-button
                class="px-0 mx-0"
                @click="insideSubject(subject)"
                style="border-radius: 0px 0px 5px 5px; width: 100%"
                variant="primary"
                >Entrar a la materia<v-icon class="ml-2" name="angle-right"></v-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "Welcome",

  data() {
    return {
      subjects: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    this.loadState();
  },

  methods: {
    ...mapActions([
      "loadSubjects_by_user",
      "loadData_SubjectSection",
      "loadSubjectSection",
    ]),
    insideSubject(subject) {
      this.loadData_SubjectSection(subject).then((response) => {
        console.log(response);
        this.$router.push("/estudiante/materia/" + subject.subject.id);
      });

      //this.$router.push("/profesor/materia/"+subject.subject.id);
    },
    loadState() {
      this.loading = true;
      const id_school = this.me_rol.id_school;
      const self = this;

      let form = {
        id_student: this.me_rol.id_student,
        id_section: this.me_rol.id_section,
        active: 1,
        id_school: id_school,
      };
      this.loader = true;
      this.loadSubjectSection(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            this.subjects = resp;
            this.loader = false;
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
        this.loading = false;
    },
  },
};
</script>
