<template>
  <div id="AllDirectorEvaluations">
    <br />
    <div class="content">
      <b-card>
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              En este listado se muestran las clases, evaluaciones y tareas,
              creadas por los profesores.<strong
                >Debes seleccionar un profesor para ver sus clases</strong
              >
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <h5>Registros: {{ fathers_count }}</h5>
            <!-- <router-link
              class="btn btn-outline-primary"
              :to="{name:'director-representative-new'}"
            >Registrar profesores</router-link>-->
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="3" class="my-1">
            <label>Buscar</label>
            <b-form-group
              label=""
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  v-on:keyup="searchInput"
                  type="text"
                  placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter"
                    >Limpiar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <label>Profesor</label>
            <b-form-select
              id="perPageSelect"
              value-field="id"
              text-field="user.full_name"
              @input="loadSubjectsByTeacher"
              v-model="teacher"
              size="sm"
              :options="teachers"
            />
          </b-col>
          <b-col lg="3">
            <label>Sección</label>

            <select
              v-model="subject_section"
              @change="allEvaluations()"
              class="form-control form-control-sm"
            >
              <option
                v-for="sect in sections"
                v-bind:key="sect.id"
                v-bind:value="sect"
                >{{ sect.subject.name + " - " + sect.section.name }}</option
              >
            </select>
          </b-col>

          <b-col lg="3">
            <label>Mostrar</label>

            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                hover
                :primary-key="id"
                :items="fathersTMP"
                :fields="field"
                :current-page="currentPage"
                :per-page="perPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <thead></thead>
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(type)="row">
                  <p v-if="row.item.id_type_evaluation == 1">Antigua Clase</p>
                  <p v-if="row.item.id_type_evaluation == 2">Clase</p>
                  <p v-if="row.item.id_type_evaluation == 3">Evaluación</p>
                </template>
                <template v-slot:cell(status_evaluation)="row">
                  <b-badge
                    pill
                    variant="outline-primary"
                    v-if="row.item.status_evaluation == 1"
                    >Planificada</b-badge
                  >
                  <b-badge
                    pill
                    variant="outline-success"
                    v-if="row.item.status_evaluation == 2"
                    >Iniciada</b-badge
                  >
                  <b-badge
                    pill
                    variant="outline-warning"
                    v-if="row.item.status_evaluation == 3"
                    >Culminada</b-badge
                  >
                  <b-badge
                    pill
                    variant="outline-danger"
                    v-if="row.item.status_evaluation == 4"
                    >Cerrada</b-badge
                  >
                  <br>
                   <b-badge
                    pill
                    variant="primary"
                    v-if="row.item.teacher_assisted == 1"
                    ><span style="font-size:8px">Asistencia marcada</span></b-badge
                  >
                    <b-badge
                    pill
                    variant="warning"
                    v-if="row.item.teacher_assisted == 0"
                    ><span style="font-size:8px">Asistencia no marcada</span></b-badge
                  >
                </template>
                <template v-slot:cell(actions)="row">
                  <!-- <b-button
                      v-if="row.item.active === 1"
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      title="Activado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="check-square"/>
                  </b-button>
                  <b-button
                      v-if="row.item.active === 0"
                      size="sm"
                      variant="outline-warning"
                      class="mr-2"
                      title="Desactivado"
                      @click="changeStatus(row.item)"
                  >

                    <v-icon name="minus-square"/>

                  </b-button>-->

                  <b-button
                    size="sm"
                    variant="outline-info"
                    class="mr-2"
                    @click="accessClass(row.item)"
                  >
                    <v-icon name="eye" />
                  </b-button>

                  <!--  <b-button
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      @click="delete_Teacher(row)"
                  >

                    <v-icon name="trash"/>
                  </b-button>-->
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllDirectorEvaluations",
  data() {
    return {
      fathers_count: 0,
      isBusy: false,
      id: "",
      items: [],
      item_table: null,
      ttap_selected: null,
      field: [
        {
          key: "teacher.user.full_name",
          label: "Profesor",
          sortable: true,
        },
        {
          key: "section.name",
          label: "Sección",
          sortable: true,
        },

        {
          key: "subject.name",
          label: "Materia",
          sortable: true,
        },
        {
          key: "title",
          label: "Título",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "lapse.name",
          label: "Momento",
          sortable: true,
        },
        {
          key: "status_evaluation",
          label: "Estatus",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Creado",
          sortable: false,
        },
        {
          key: "actions",
          label: "Detalles",
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      fathers: [],
      teachers: [],
      teacher: "",
      fathersTMP: [],
      loading: false,
      error: "",
      subject_section: "",
      sections: [],
      sectionsTMP: [],
      subject: "",
      subjects: [],
      subjectsTMP: [],
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathersTMP.length;
    },
  },
  mounted() {
    this.allTeachers();
    //  / this.allSections();
  },
  methods: {
    ...mapActions([
      "load_Evaluation2",
      "changeActiveTeacher",
      "loadData_Teacher",
      "deleteTeacher",
      "loadData_Evaluation",
      "loadTeachers2",
      "loadSection_school",
      "loadSubjects_school",
      "loadSubjectSection",
    ]),

    allTeachers() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        active: 1,
      };
      this.loadTeachers2(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.teachers = response.data;
            this.teacher = this.teachers[0].id;
            this.loadSubjectsByTeacher();
            // this.allEvaluations();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allSections() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
      };
      this.loadSection_school(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.sections = response.data;
            this.sectionsTMP = response.data;
            console.log(this.sections.length);
            console.log(this.sectionsTMP);
            this.sections_count = this.sections.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSubjectsByTeacher() {
      console.log("Obtaining sections");
      let form_teacher = {
        id_teacher: this.teacher,
        id_period: this.me_rol.id_period,
        id_school: this.me_rol.id_school,
        active: 1,
      };
      this.loadSubjectSection(form_teacher)
        .then((response) => {
          if (response.code === 200) {
            this.sections = response.data;
            this.sectionsTMP = response.data;
            this.subject_section = response.data[0];
            console.log(this.sections.length);
            console.log(this.sectionsTMP);
            this.sections_count = this.sections.length;
            this.allEvaluations(this.subject_section);
            console.log("LOADED TEACHERS");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.fathersTMP = this.fathers;
      this.filter = "";
      return true;
    },
    accessClass(evaluation) {
      this.loadData_Evaluation(evaluation).then((response) => {
        console.log(response);
        this.$router.push("/director/evaluacion/detalle");
      });
    },
    searchInput() {
      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.fathersTMP.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          cadena = cadena.toLowerCase();
          cadena = clearAccents(cadena);

          if (cadena.indexOf(filterTMP.toLowerCase(), i) > -1) {
            studentsTmp.push(el);
          }
        });
        this.fathersTMP = studentsTmp;
      } else {
        this.fathersTMP = this.fathers;
      }

      return true;
    },
    allEvaluations(subject_section) {
      if (!subject_section) {
        subject_section = this.subject_section;
      }
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
        active: 1,
      };
      if (this.teacher) {
        form["id_teacher"] = this.teacher;
        console.log("Select Teacher:" + this.teacher);
      } else {
        console.log("Select Teacher 2");
        form["id_teacher"] = this.teachers[0].id;
      }
      if (subject_section) {
        console.log("Filter by Section");
        form["id_section"] = subject_section.section.id;
      }
      if (subject_section) {
        console.log("Filter by Subject");
        form["id_subject"] = subject_section.subject.id;
      }
      form["order_key"] = "id";
      form["order_value"] = "desc";

      this.load_Evaluation2(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.fathers = response.data;
            this.fathersTMP = response.data;

            this.fathers_count = this.fathers.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit_Teacher(data) {
      this.loadData_Teacher(data).then((response) => {
        console.log(response);
        this.$router.push("/director/evaluaciones/profesor/editar/" + data.id);
        //  this.$router.push({ name: "uecamag" });
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de Desactivarlo Ahora?"
          : "¿Estás seguro de Activarlo Ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActiveTeacher(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    delete_Teacher(row) {
      Swal.fire({
        title: "Eliminar Profesor",
        text:
          "¿Estás seguro de eliminar este Profesor?, esta eliminación, hará que el profesor pierda acceso a sus materias",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.deleteTeacher(form)
            .then((response) => {
              if (response.code === 200) {
                this.fathers.splice(row.index, 1);
                Swal.fire({
                  title: "Profesor Eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
