<template>
  <div id="DetailsSolution">
    <br />
    <div class="content">
      <b-card title="Respuestas a los exámenes">
        <b-row>
          <b-col md="10" style="text-align: -webkit-left">
            <b-row>
              <b-col md="6">
                <span class="my-0 py-1"
                  ><strong>Entrenador: </strong
                  ><a @click="GoToProfile" class="user-link">{{
                    element.full_name
                  }}</a></span
                >
              </b-col>
              <b-col md="6">
                <span class="my-0 py-1" v-if="element.checked == 0"
                  ><strong>Resultado (parcial): </strong
                  >{{ element.result + "/" + element.over + " pts" }}</span
                >
                <span class="my-0 py-1" v-else
                  ><strong>Resultado (definitivo): </strong
                  >{{ element.result + "/" + element.over + " pts" }}</span
                >
              </b-col>
              <b-col md="6">
                <span class="my-0 py-1" v-if="element.websocket_closed == 1"
                  ><strong>Finalización: </strong>Por desconexión</span
                >
                <span
                  class="my-0 py-1"
                  v-else-if="element.websocket_closed == 2"
                  ><strong>Finalización: </strong>Por captura o grabación de
                  pantalla</span
                >
                <span
                  class="my-0 py-1"
                  v-else-if="element.websocket_closed == 3"
                  ><strong>Finalización: </strong>Se salió de la aplicación por
                  mucho tiempo</span
                >
                <span class="my-0 py-1" v-else
                  ><strong>Finalización: </strong>Entrega normal</span
                >
              </b-col>
              <b-col md="6">
                <span class="my-0 py-1"
                  ><strong>Duración: </strong
                  >{{ element.duration + " minutos" }}</span
                >
              </b-col>
              <b-col md="6" v-if="element.id_admin_checked > 0">
                <span class="my-0 py-1"
                  ><strong>Corregido por: </strong
                  >{{ element.admin_checked.full_name }}</span
                >
              </b-col>
              <b-col md="6" v-if="element.id_admin_checked > 0">
                <span class="my-0 py-1"
                  ><strong>Fecha y hora de corregido: </strong
                  >{{ element.checked_datetime }}</span
                >
              </b-col>
            </b-row>
          </b-col>

          <b-col md="2" style="text-align: -webkit-right">
            <button
              class="btn-wine py-1 px-3"
              style="border-radius: 10px; border: none; color: white"
              @click="$router.push('../../../../../../soluciones' + changes)"
            >
              Volver
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="10" style="text-align: -webkit-left">
            <b-row>
              <b-col md="6" class="mt-2">
                <button
                  v-if="element.checked == 1"
                  class="text-center mr-2 mb-1 py-1 px-3"
                  style="background-color: #9C243C; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                  @click="DeleteElement(element)"
                >
                  <v-icon name="book-open" color="white"></v-icon>
                  <span class="ml-2">Habilitar de nuevo</span>
                </button>
                <button
                  v-if="element.review == 1"
                  class="text-center py-1 px-3"
                  style="background-color: #9C243C; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                  @click="MarkAsReview"
                >
                  <v-icon name="pen" color="white"></v-icon>
                  <span class="ml-2">Revisión habilitada</span>
                </button>
                <button
                  v-else
                  class="ml-2 text-center py-1 px-3"
                  style="background-color: #FFFFFF; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #9C243C"
                  @click="MarkAsReview"
                >
                  <v-icon name="pen" color="#9C243C"></v-icon>
                  <span class="ml-2">Habilitar revision</span>
                </button>
              </b-col>
              <b-col md="6" class="mt-2">
                <button
                  v-if="approval_status"
                  class="text-center py-1 px-3"
                  style="background-color: #9C243C; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                  disabled
                >
                  <v-icon name="check-circle" color="white"></v-icon>
                  <span class="ml-2"
                    >{{ element.quiz_poll.title }} - Aprobado</span
                  >
                </button>
                <button
                  v-else
                  class="text-center py-1 px-3"
                  style="background-color: #FFFFFF; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #9C243C"
                  disabled
                >
                  <v-icon name="times-circle" color="#9C243C"></v-icon>
                  <span class="ml-2"
                    >{{ element.quiz_poll.title }} - Reprobado</span
                  >
                </button>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2" class="mt-2">
            <button
              v-if="element.checked == 1"
              class="text-center py-1 px-2"
              style="width: 100%; background-color: #9C243C; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
              disabled
            >
              <v-icon name="clipboard-check" color="white"></v-icon>
              <span class="ml-2">Corregido</span>
            </button>
            <button
              v-else
              class="text-center py-1 px-2"
              style="width: 100%; background-color: #FFFFFF; border-radius: 10px; border: 1px solid; border-color: #9C243C; color: #9C243C"
              @click="MarkAsChecked"
            >
              <v-icon name="clipboard-check" color="#9C243C"></v-icon>
              <span class="ml-2">Corregir</span>
            </button>
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>
        <b-card v-if="!loading" no-body>
          <b-tabs card>
            <b-tab
              title="Selección simple"
              v-if="element.quiz_poll.questions_quantity1 > 0"
              :title-link-class="'tab-title-class'"
              active
            >
              <div style="width: 100% !important">
                <b-col md="12" class="text-right mb-2"
                  ><p>
                    <strong style="color: #000000"
                      >Puntos en esta sección: </strong
                    >{{ obtained_points[0] + "/" + total_points[0] + " pts" }}
                  </p></b-col
                >
                <b-row
                  v-for="(data, index) of element.section_1"
                  v-bind:key="'A' + index"
                  class="mb-3"
                >
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Pregunta #{{
                          String(index + 1) +
                            " (" +
                            String(data.question.value) +
                            " pts)"
                        }}</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12">
                    <strong>
                      <b-form-group :label="data.question.question">
                        <b-form-radio
                          v-for="(datax, index) of data.options"
                          v-bind:key="'B' + index"
                          v-model="datax.is_selected"
                          disabled
                          style="font-weight: normal;"
                          :value="1"
                        >
                          <span>{{ datax.answer }}</span>
                          <span class="ml-2" v-if="datax.valid == 1"
                            ><v-icon name="check-circle" color="green"
                          /></span>
                          <span
                            class="ml-2"
                            v-else-if="
                              datax.is_selected == 1 && datax.valid == 0
                            "
                            ><v-icon name="times-circle" color="red"
                          /></span>
                        </b-form-radio>
                      </b-form-group>
                    </strong>
                  </b-col>
                </b-row>
              </div>
            </b-tab>

            <b-tab
              title="Verdadero/Falso"
              v-if="element.quiz_poll.questions_quantity2 > 0"
              :title-link-class="'tab-title-class'"
            >
              <div style="width: 100% !important">
                <b-col md="12" class="text-right mb-2"
                  ><p>
                    <strong style="color: #000000"
                      >Puntos en esta sección: </strong
                    >{{ obtained_points[1] + "/" + total_points[1] + " pts" }}
                  </p></b-col
                >
                <b-row
                  v-for="(data, index) of element.section_2"
                  v-bind:key="'C' + index"
                  class="mb-3"
                >
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Pregunta #{{
                          String(index + 1) +
                            " (" +
                            String(data.question.value) +
                            " pts)"
                        }}</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12">
                    <strong>
                      <b-form-group :label="data.question.question">
                        <b-form-radio
                          v-for="(datax, index) of data.options"
                          v-bind:key="'D' + index"
                          v-model="datax.is_selected"
                          disabled
                          style="font-weight: normal;"
                          :value="1"
                        >
                          <span>{{ datax.answer }}</span>
                          <span class="ml-2" v-if="datax.valid == 1"
                            ><v-icon name="check-circle" color="green"
                          /></span>
                          <span
                            class="ml-2"
                            v-else-if="
                              datax.is_selected == 1 && datax.valid == 0
                            "
                            ><v-icon name="times-circle" color="red"
                          /></span>
                        </b-form-radio>
                      </b-form-group>
                    </strong>
                  </b-col>
                </b-row>
              </div>
            </b-tab>

            <b-tab
              title="Pareamiento"
              v-if="element.quiz_poll.questions_quantity4 > 0"
              :title-link-class="'tab-title-class'"
            >
              <div style="width: 100% !important">
                <b-col md="12" class="text-right mb-2"
                  ><p>
                    <strong style="color: #000000"
                      >Puntos en esta sección: </strong
                    >{{ obtained_points[3] + "/" + total_points[3] + " pts" }}
                  </p></b-col
                >
                <b-row
                  v-for="(data, index) of element.section_4"
                  v-bind:key="'E' + index"
                  class="mb-3"
                >
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Pregunta #{{
                          String(index + 1) +
                            " (" +
                            String(data.question.value) +
                            " pts)"
                        }}</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12">
                    <strong>
                      <b-form-group
                        :label="data.question.question"
                        style="color: #9C243C"
                      >
                        <div
                          v-for="(datax, index) of data.concepts"
                          v-bind:key="'F' + index"
                          style="font-weight: normal; color: #000000"
                        >
                          <span>{{
                            String(index + 1) +
                              ". " +
                              datax.concept.answer +
                              " - "
                          }}</span>
                          <span v-if="datax.answer && datax.answer.match_answer"
                            ><strong>{{
                              datax.answer.match_answer.answer
                            }}</strong></span
                          >
                          <span v-else><strong>N/A</strong></span>
                          <span
                            class="ml-2"
                            v-if="datax.answer && datax.answer.value > 0"
                            ><v-icon name="check-circle" color="green"
                          /></span>
                          <span class="ml-2" v-else
                            ><v-icon name="times-circle" color="red"
                          /></span>
                        </div>
                      </b-form-group>
                    </strong>
                  </b-col>
                </b-row>
              </div>
            </b-tab>

            <b-tab
              title="Completación"
              v-if="element.quiz_poll.questions_quantity3 > 0"
              :title-link-class="'tab-title-class'"
            >
              <div style="width: 100% !important">
                <b-col md="12" class="text-right mb-2"
                  ><p>
                    <strong style="color: #000000"
                      >Puntos en esta sección: </strong
                    >{{ obtained_points[2] + "/" + total_points[2] + " pts" }}
                  </p></b-col
                >
                <b-row
                  v-for="(data, index) of element.section_3"
                  v-bind:key="'G' + index"
                  class="mb-3"
                >
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Pregunta #{{
                          String(index + 1) +
                            " (" +
                            String(data.question.value) +
                            " pts)"
                        }}</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12">
                    <strong>
                      <p class="mb-1">{{ data.final_question }}</p>
                    </strong>
                    <b-row>
                      <b-col md="6">
                        <strong>
                          <b-form-group
                            label="Respuestas correctas:"
                            style="color: #9C243C"
                          >
                            <div
                              v-for="(datax, index) of data.right_answers"
                              v-bind:key="'H' + index"
                              class="mb-1"
                              style="font-weight: normal; color: #000000"
                            >
                              <span>{{ String(index + 1) + ". " }}</span>
                              <span>{{ datax.answer }}</span>
                            </div>
                          </b-form-group>
                        </strong>
                      </b-col>
                      <b-col md="6">
                        <strong>
                          <b-form-group
                            label="Respuestas dadas por el usuario:"
                            style="color: #9C243C"
                          >
                            <div
                              v-for="(datax, index) of data.answers"
                              v-bind:key="'I' + index"
                              class="mb-1"
                              style="font-weight: normal; color: #000000"
                            >
                              <span>{{ String(index + 1) + ". " }}</span>
                              <span v-if="datax.answer">{{
                                datax.answer
                              }}</span>
                              <span v-else>N/A</span>
                              <span class="ml-2" v-if="datax.value > 0"
                                ><v-icon name="check-circle" color="green"
                              /></span>
                              <span class="ml-2" v-else
                                ><v-icon name="times-circle" color="red"
                              /></span>
                              <span
                                class="ml-2"
                                v-if="
                                  datax.answer &&
                                    datax.value == 0 &&
                                    element.review
                                "
                              >
                                <button
                                  class="text-center px-3"
                                  style="background-color: #9C243C; border-radius: 5px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                                  @click="ChangeCorrect(datax)"
                                >
                                  <v-icon name="check" color="#FFFFFF"></v-icon>
                                  <span class="ml-2">Marcar como correcta</span>
                                </button>
                              </span>
                              <span
                                class="ml-2"
                                v-if="
                                  datax.answer &&
                                    datax.value > 0 &&
                                    element.review
                                "
                              >
                                <button
                                  class="text-center px-3"
                                  style="background-color: #9C243C; border-radius: 5px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                                  @click="ChangeCorrect(datax)"
                                >
                                  <v-icon name="times" color="#FFFFFF"></v-icon>
                                  <span class="ml-2"
                                    >Marcar como incorrecta</span
                                  >
                                </button>
                              </span>
                            </div>
                          </b-form-group>
                        </strong>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-tab>

            <b-tab
              title="Desarrollo"
              v-if="
                element.quiz_poll.questions_quantity5 > 0 ||
                  element.quiz_poll.questions_quantity6 > 0
              "
              :title-link-class="'tab-title-class'"
            >
              <div style="width: 100% !important">
                <h4 v-if="element.quiz_poll.questions_quantity5 > 0">
                  <strong style="color: #000000">Preguntas abiertas</strong>
                </h4>

                <b-col md="12" class="text-right mb-2"
                  ><p>
                    <strong style="color: #000000"
                      >Puntos en esta sección: </strong
                    >{{ obtained_points[4] + "/" + total_points[4] + " pts" }}
                  </p></b-col
                >

                <b-row
                  v-for="(data, index) of element.section_5"
                  v-bind:key="'J' + index"
                  class="mb-3"
                >
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Pregunta #{{
                          String(index + 1) +
                            " (" +
                            String(data.question.value) +
                            " pts)"
                        }}</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="8">
                    <strong>
                      <b-form-group :label="data.question.question">
                        <label
                          v-if="data.answer.answer"
                          style="font-weight: normal;"
                          >{{ "R = " + data.answer.answer }}</label
                        >
                        <label v-else style="font-weight: normal;"
                          >No indicó ninguna respuesta a esta pregunta</label
                        >
                      </b-form-group>
                    </strong>
                  </b-col>

                  <b-col md="4">
                    <strong>
                      <b-form-group label="Puntaje:">
                        <b-row>
                          <b-col md="8">
                            <b-form-input
                              type="number"
                              id="value"
                              v-model="values[String(data.question.id)]"
                              name="value"
                              @keyup.enter="SetAnswerPoints(data.answer)"
                              :disabled="
                                data.answer.answer == null ||
                                  element.review == 0
                              "
                            >
                            </b-form-input>
                          </b-col>
                          <b-col md="4">
                            <button
                              class="text-center"
                              style="height: 100%; width: 100%; background-color: #9C243C; border-radius: 5px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                              :disabled="
                                data.answer.answer == null ||
                                  element.review == 0
                              "
                              @click="SetAnswerPoints(data.answer)"
                            >
                              <span>Calificar</span>
                            </button>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </strong>
                  </b-col>
                </b-row>

                <hr v-if="element.quiz_poll.questions_quantity5 > 0" />

                <h4 v-if="element.quiz_poll.questions_quantity6 > 0">
                  <strong style="color: #000000">Preguntas de análisis</strong>
                </h4>

                <b-col md="12" class="text-right mb-2"
                  ><p>
                    <strong style="color: #000000"
                      >Puntos en esta sección: </strong
                    >{{ obtained_points[5] + "/" + total_points[5] + " pts" }}
                  </p></b-col
                >

                <b-row
                  v-for="(data, index) of element.section_6"
                  v-bind:key="'K' + index"
                  class="mb-3"
                >
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Pregunta #{{
                          String(index + 1) +
                            " (" +
                            String(data.question.value) +
                            " pts)"
                        }}</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="8">
                    <strong>
                      <b-form-group :label="data.question.question">
                        <label
                          v-if="data.answer.answer"
                          style="font-weight: normal;"
                          >{{ "R = " + data.answer.answer }}</label
                        >
                        <label v-else style="font-weight: normal;"
                          >No indicó ninguna respuesta a esta pregunta</label
                        >
                      </b-form-group>
                    </strong>
                  </b-col>

                  <b-col md="4">
                    <strong>
                      <b-form-group label="Puntaje:">
                        <b-row>
                          <b-col md="8">
                            <b-form-input
                              type="number"
                              id="value"
                              v-model="values[String(data.question.id)]"
                              name="value"
                              @keyup.enter="SetAnswerPoints(data.answer)"
                              :disabled="
                                data.answer.answer == null ||
                                  element.review == 0
                              "
                            >
                            </b-form-input>
                          </b-col>
                          <b-col md="4">
                            <button
                              class="text-center"
                              style="height: 100%; width: 100%; background-color: #9C243C; border-radius: 5px; border: 1px solid; border-color: #9C243C; color: #FFFFFF;"
                              :disabled="
                                data.answer.answer == null ||
                                  element.review == 0
                              "
                              @click="SetAnswerPoints(data.answer)"
                            >
                              <span>Calificar</span>
                            </button>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </strong>
                  </b-col>
                </b-row>
              </div>
            </b-tab>

            <b-tab title="Archivos" :title-link-class="'tab-title-class'">
              <div style="width: 100% !important">
                <b-row>
                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Foto inicial de verificación</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12" class="text-center">
                    <a
                      v-if="element.initial_photo"
                      :href="element.initial_photo"
                      target="_blank"
                      ><img
                        :src="element.initial_photo"
                        style="max-height: 300px; max-width: 100%;"
                        alt=""
                    /></a>
                    <span v-else
                      >No subió su foto inicial con la cédula de identidad</span
                    >
                  </b-col>

                  <b-col md="12"> <hr /> </b-col>

                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Foto durante el examen #1</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12" class="text-center">
                    <a
                      v-if="element.random_photo1"
                      :href="element.random_photo1"
                      target="_blank"
                      ><img
                        :src="element.random_photo1"
                        style="max-height: 300px; max-width: 100%;"
                        alt=""
                    /></a>
                    <span v-else>No se subió ninguna foto</span>
                  </b-col>

                  <b-col md="12"> <hr /> </b-col>

                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Foto durante el examen #2</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12" class="text-center">
                    <a
                      v-if="element.random_photo2"
                      :href="element.random_photo2"
                      target="_blank"
                      ><img
                        :src="element.random_photo2"
                        style="max-height: 300px; max-width: 100%;"
                        alt=""
                    /></a>
                    <span v-else>No se subió ninguna foto</span>
                  </b-col>

                  <b-col md="12"> <hr /> </b-col>

                  <b-col md="12" class="mb-2"
                    ><h5>
                      <strong style="color: #9C243C"
                        >Foto durante el examen #3</strong
                      >
                    </h5></b-col
                  >

                  <b-col md="12" class="text-center">
                    <a
                      v-if="element.random_photo3"
                      :href="element.random_photo3"
                      target="_blank"
                      ><img
                        :src="element.random_photo3"
                        style="max-height: 300px; max-width: 100%;"
                        alt=""
                    /></a>
                    <span v-else>No se subió ninguna foto</span>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

export default {
  name: "SolutionDetails",

  data() {
    return {
      loading: true,
      values: {},
      changes: "",
      element: {
        quiz_poll: {
          title: "",
        },
        section_1: [],
        section_2: [],
        section_3: [],
        section_4: [],
        section_5: [],
        section_6: [],
      },
    };
  },

  computed: {
    ...mapState({
      me: (state) => state.auth.me,
    }),
    obtained_points() {
      let points = [0, 0, 0, 0, 0, 0];

      for (let item of this.element.section_1) {
        points[0] =
          points[0] + Math.round(parseFloat(item.answer.value) * 1000) / 1000;
      }
      for (let item of this.element.section_2) {
        points[1] =
          points[1] + Math.round(parseFloat(item.answer.value) * 1000) / 1000;
      }
      for (let item of this.element.section_3) {
        for (let itemx of item.answers) {
          points[2] =
            points[2] + Math.round(parseFloat(itemx.value) * 1000) / 1000;
        }

        points[2] = Math.round(points[2] * 1000) / 1000;

        if (points[2] > 2.5) points[2] = 2.5;
      }
      for (let item of this.element.section_4) {
        for (let itemx of item.concepts) {
          points[3] =
            points[3] +
            Math.round(
              parseFloat(itemx.answer != null ? itemx.answer.value : 0) * 1000
            ) /
              1000;
        }
      }
      for (let item of this.element.section_5) {
        points[4] =
          points[4] + Math.round(parseFloat(item.answer.value) * 1000) / 1000;
      }
      for (let item of this.element.section_6) {
        points[5] =
          points[5] + Math.round(parseFloat(item.answer.value) * 1000) / 1000;
      }

      return points;
    },
    total_points() {
      let points = [0, 0, 0, 0, 0, 0];

      for (let item of this.element.section_1) {
        points[0] =
          points[0] + Math.round(parseFloat(item.question.value) * 1000) / 1000;
      }
      for (let item of this.element.section_2) {
        points[1] =
          points[1] + Math.round(parseFloat(item.question.value) * 1000) / 1000;
      }
      for (let item of this.element.section_3) {
        points[2] =
          points[2] + Math.round(parseFloat(item.question.value) * 1000) / 1000;
      }
      for (let item of this.element.section_4) {
        points[3] =
          points[3] + Math.round(parseFloat(item.question.value) * 1000) / 1000;
      }
      for (let item of this.element.section_5) {
        points[4] =
          points[4] + Math.round(parseFloat(item.question.value) * 1000) / 1000;
      }
      for (let item of this.element.section_6) {
        points[5] =
          points[5] + Math.round(parseFloat(item.question.value) * 1000) / 1000;
      }

      return points;
    },
    approval_status() {
      if (this.element.result >= this.element.quiz_poll.min_approval_points) {
        return 1;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    this.FindExam();
  },

  methods: {
    ...mapActions(["GetByID", "Update", "ChangeStatus"]),
    GoToProfile() {
      this.$router.push(
        "../../../../../../../../../usuarios/detalles/" + this.element.id_user
      );
    },
    FindExam() {
      this.loading = true;
      let id_element = this.$route.params.id;

      let form = {
        element: `quiz_poll_solution`,
        id: id_element,
      };

      this.GetByID(form)
        .then((response) => {
          this.loading = false;

          if (response.code === 200) {
            this.element = response.data;

            for (let item of response.data.section_5) {
              this.values[String(item.question.id)] = item.answer.value;
            }
            for (let item of response.data.section_6) {
              this.values[String(item.question.id)] = item.answer.value;
            }
          } else if (response.code == 420 || response.code == 421) {
            Cookies.remove("access_token");
            Cookies.remove("email");
            Cookies.remove("id_role");

            Swal.fire({
              title: "¡Atención!",
              text: "Debe iniciar sesión nuevamente para continuar",
              icon: "warning",
              confirmButtonColor: "#9C243D",
            });

            this.$router.push({ name: "login" });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    SetAnswerPoints(data) {
      console.log("VALUE: " + this.values[String(data.id_question)]);

      let form = {
        element: `solution_answer`,
        update: "update",
        id: data.id,
        data: {
          value: this.values[String(data.id_question)],
        },
      };

      this.Update(form)
        .then((response) => {
          if (response.code == 200) {
            //this.element.result = (this.element.result - data.value) + parseFloat(this.values[String(data.id_question)])
            this.element.result =
              Math.round(response.data.solution.result * 1000) / 1000;
            data.value = parseFloat(this.values[String(data.id_question)]);

            const today = new Date().getMilliseconds();
            this.changes = "?reload=" + today;

            Swal.fire({
              title: "Pregunta corregida",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (response.code == 420 || response.code == 421) {
            Cookies.remove("access_token");
            Cookies.remove("email");
            Cookies.remove("id_role");

            Swal.fire({
              title: "¡Atención!",
              text: "Debe iniciar sesión nuevamente para continuar",
              icon: "warning",
              confirmButtonColor: "#9C243D",
            });

            this.$router.push({ name: "login" });
          } else {
            Swal.fire({
              title: "Puntaje incorrecto",
              text:
                "El puntaje establecido para esta respuesta no puede ser mayor al puntaje de la pregunta",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#9C243C",
              confirmButtonText: "Aceptar",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ChangeCorrect(data) {
      let form = {
        element: `solution_answer`,
        update: "update",
        id: data.id,
        data: {
          change_correct: 1,
        },
      };

      this.Update(form)
        .then((response) => {
          if (response.code == 200) {
            if (data.value == 0) {
              Swal.fire({
                title: "Respuesta marcada como correcta",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                title: "Respuesta marcada como incorrecta",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            }

            const today = new Date().getMilliseconds();
            this.changes = "?reload=" + today;

            //this.element.result = (this.element.result - data.value) + response.data.value
            this.element.result =
              Math.round(response.data.solution.result * 1000) / 1000;
            data.value = response.data.value;
          } else if (response.code == 420 || response.code == 421) {
            Cookies.remove("access_token");
            Cookies.remove("email");
            Cookies.remove("id_role");

            Swal.fire({
              title: "¡Atención!",
              text: "Debe iniciar sesión nuevamente para continuar",
              icon: "warning",
              confirmButtonColor: "#9C243D",
            });

            this.$router.push({ name: "login" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    MarkAsReview() {
      let review = 1;

      if (this.element.review == 1) review = 0;

      let form = {
        element: `quiz_poll_solution`,
        update: "update",
        id: this.element.id,
        data: {
          review: String(review),
        },
      };

      Swal.fire({
        title: "Confirmación",
        text:
          "¿Estás seguro de " +
          (review ? "habilitar" : "deshabilitar") +
          " el modo revisión?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#9C243C",
        cancelButtonColor: "#C4C4C4",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Cambiar",
      }).then((result) => {
        if (result.value) {
          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                this.element.review = review;
                this.element.checked = 0;

                let solution = {
                  result: this.element.result,
                  checked: this.element.checked
                }
                this.$store.commit('change_solution', solution)

                const today = new Date().getMilliseconds();
                this.changes = "?reload=" + today;

                if (review) {
                  Swal.fire({
                    title: "Modo revisión habilitado",
                    text: "Ahora puedes hacer modificaciones en los resultados",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    title: "Modo revisión deshabilitado",
                    text: "Ya no puedes hacer modificaciones en los resultados",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } else if (response.code == 420 || response.code == 421) {
                Cookies.remove("access_token");
                Cookies.remove("email");
                Cookies.remove("id_role");

                Swal.fire({
                  title: "¡Atención!",
                  text: "Debe iniciar sesión nuevamente para continuar",
                  icon: "warning",
                  confirmButtonColor: "#9C243D",
                });

                this.$router.push({ name: "login" });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    MarkAsChecked() {
      let checked = 1;

      if (this.element.checked == 1) checked = 0;

      let form = {
        element: `quiz_poll_solution`,
        update: "update",
        id: this.element.id,
        data: {
          checked: String(checked),
        },
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de marcar como corregido este examen?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#9C243C",
        cancelButtonColor: "#C4C4C4",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.value) {
          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                this.element.checked = checked;
                this.element.review = 0;
                this.element.approved =
                  this.element.result >=
                  this.element.quiz_poll.min_approval_points
                    ? 1
                    : 0;
                this.element.id_admin_checked = response.data.id_admin_checked;
                this.element.admin_checked = response.data.admin_checked;
                this.element.checked_datetime = response.data.checked_datetime;

                let solution = {
                  result: this.element.result,
                  checked: this.element.checked
                }
                this.$store.commit('change_solution', solution)

                const today = new Date().getMilliseconds();
                this.changes = "?reload=" + today;

                if (this.element.approved == 1) {
                  Swal.fire({
                    title: "Examen marcado como corregido",
                    text:
                      "El entrenador será notificado mediante un correo electrónico sobre su resultado",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#9C243C",
                    confirmButtonText: "Aceptar",
                  }).then((result) => {
                    if (result.value) {
                      Swal.fire({
                        title: "¡Examen aprobado!",
                        text:
                          "El examen ha sido aprobado, ¿deseas emitir la licencia para el entrenador?",
                        icon: "question",
                        reverseButtons: true,
                        showCancelButton: true,
                        confirmButtonColor: "#9C243C",
                        cancelButtonColor: "#C4C4C4",
                        cancelButtonText: "No",
                        confirmButtonText: "Si",
                      }).then((result) => {
                        if (result.value) {
                          this.$router.push(
                            "../../../../../../../../../../licencias/emitir/" +
                              response.data.coach_license.id
                          );
                        }
                      });
                    }
                  });
                } else {
                  Swal.fire({
                    title: "Examen marcado como corregido",
                    text:
                      "El entrenador será notificado mediante un correo electrónico sobre su resultado",
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  return;
                }
              } else if (response.code == 420 || response.code == 421) {
                Cookies.remove("access_token");
                Cookies.remove("email");
                Cookies.remove("id_role");

                Swal.fire({
                  title: "¡Atención!",
                  text: "Debe iniciar sesión nuevamente para continuar",
                  icon: "warning",
                  confirmButtonColor: "#9C243D",
                });

                this.$router.push({ name: "login" });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    DeleteElement(data) {
      Swal.fire({
        title: "Confirmación",
        text:
          "¿Estás seguro que deseas habilitar de nuevo este examen para el entrenador? Esta respuesta será eliminada y el entrenador podrá tener acceso nuevamente a presentar el examen",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#9C243D",
        cancelButtonColor: "gray",
        confirmButtonText: "Habilitar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            element: `quiz_poll_solution`,
            id: data.id,
            status: 2,
          };
          this.ChangeStatus(form)
            .then((response) => {
              if (response.code === 200) {
                const today = new Date().getMilliseconds();
                this.changes = "?reload=" + today;

                Swal.fire({
                  title: "Examen habilitado nuevamente",
                  confirmButtonColor: "#9C243C",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
                this.$router.push(
                  "../../../../../../../../soluciones" + this.changes
                );
              } else if (response.code == 420 || response.code == 421) {
                Cookies.remove("access_token");
                Cookies.remove("email");
                Cookies.remove("id_role");

                Swal.fire({
                  title: "¡Atención!",
                  text: "Debe iniciar sesión nuevamente para continuar",
                  icon: "warning",
                  confirmButtonColor: "#9C243D",
                });

                this.$router.push({ name: "login" });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style>
.user-link {
  cursor: pointer;
  color: #9c243d;
}
.user-link:hover {
  text-decoration: underline;
  color: #9c243d;
}
</style>
