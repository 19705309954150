import axios from "axios";
import * as Config from "../../../config";

const state = {
  users: {
    current_page: 1,
    data: []
  },
  user: {}
};
let mod = "gen/";
const actions = {
  // TODO CON API READY
  allUsers({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + mod+"user/all")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  allUsersToChat({ commit, dispatch },form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + mod+"user/all_to_chat?id_school="+form.id_school+"&type="+form.type)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  allUsersAdmin({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + mod+"user/all_by_admin")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadUsers({ commit, dispatch }, params) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user/all", { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadUsers_Active({commit, dispatch}, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
          .get(Config.apiPath + mod+"user/all/status/" + form.active)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
    });
  },
  loadData({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_USER", data);
  },
  loadUser({ commit, dispatch }, id) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  findUserByEmail({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user/find_by_email/" + form.email)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newUser({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"user/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newUserPublic({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"user/save_public", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateUser({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    commit("UPDATE_USER");

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + mod+"user/update/" + form.id_user, form)
        .then(response => {
          commit("UPDATE_USER_OK", {});
          resolve(response.data);
        })
        .catch(error => {
          commit("UPDATE_USER_FAIL");
          reject(error.response.data);
        });
    });
  },
  changeActiveUser({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + mod+"user/change_status/" + form.id + "/" + form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
   deleteUserFile({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + mod+"student_file/delete/" + form.id + "/" + form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteUser({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath + mod+"user/change_status/" + form.id + "/" + form.status
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTotalUser({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
      .post(Config.apiPath + mod + "user/delete", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  loadCity({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user/list/city")
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  loadUserPoint({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_USER_POINT");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            "userpoint/acumfilterby/" +
            data.idtypepoint +
            "/" +
            data.iduser
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_USER_POINT_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_USER_POINT_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_USER_POINT_FAIL");
          reject(error);
        });
    });
  },
  loadUserFiles({ commit, dispatch },form) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user/files",{params:form})
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  uploadArchiveUser({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios(
      {
        method: 'post',
        url: Config.apiPath + "upload/student_file",
        data: form,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadGender({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user/list/gender")
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  async preRegister({ commit }, form) {

    console.log(commit)
    try {
      const response = await axios.post(`${Config.apiPath}gen/user/save`, form);
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  loadLogAccess({commit, dispatch}, id){
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user_log_access/user/"+id)
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  loadUserLocation({commit, dispatch}, ip){
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"user_log_access/ubication_ip/"+ip)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_USER(state, data) {
    state.user = data;
  },

  LOAD_USERS_OK(state, users) {
    state.users = users;
  },

  LOAD_USER_OK(state, user) {
    state.user = user;
  },

  LOAD_USER_POINT_OK(state, user) {
    state.user = user;
  },

  UPDATE_USER_OK(state, user) {
    state.user = user;
  }
};

export default {
  state,
  actions,
  mutations
};
