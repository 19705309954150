<template>
  <div>
    <br />
    <div class="content">
      <h2>Bienvenido a Clasy Director</h2>
      <p>Este el panel de control que permite administrar los datos en la escuela</p>
      <br />
      <br />
      <div class="row">
        <div class="col-md-4">
          <b-card title="Representantes"
           img-src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/docs/student1/2021-09-30_015007-representante-xVod0E.jpg?authuser=1" img-alt="Card image" img-top>
            <b-card-text>Puedes, visualizar, modificar y guardar a nuevos representantes</b-card-text>
            <router-link
              to="/director/representantes"
              class="btn btn-outline-warning btn-sm"
            >Ver representantes</router-link>
            <br />
            <br />
            <b-card-text>
              Representantes activos:
              <p style="font-size:55px">{{ form.representatives_count }}</p>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Estudiantes"  img-src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/docs/student1/2021-09-30_015147-students-uYykNK.jpg?authuser=1" img-top>
            <b-card-text>Puedes, visualizar, modificar y guardar a nuevos estudiantes.</b-card-text>
            <router-link to="/director/estudiantes" class="btn btn-outline-warning btn-sm">Ver estudiantes</router-link>

            <br />
            <br />
            <b-card-text>
              Estudiantes activos:
              <p style="font-size:55px">{{ form.student_count }}</p>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card title="Profesores"  img-src="https://storage.googleapis.com/dev-schools-ttaps-school/school2/docs/student1/2021-09-30_015229-teachers-OMJ5Cq.jpg?authuser=1" img-alt="Card image" img-top>
            <b-card-text>Puedes, visualizar, modificar y guardar a nuevos profesores</b-card-text>
            <router-link v-if="me_rol.head_rol == 1 || me_rol.head_rol == 2" to="/director/evaluaciones" class="btn btn-outline-warning btn-sm">Ver profesores</router-link>
            <br />
            <br />
            <b-card-text>
              Profesores activos:
              <p style="font-size:55px">{{ form.teacher_count }}</p>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <br>
      <div class="row">
         <div class="col-md-4">
          <b-card title="Movimiento del Periodo">
           <b-row>
             <b-col md="12">
               <p><strong>Clases:</strong> {{ form.classes_count }} creadas.</p>
              
                <p><strong>Tareas:</strong> {{ form.tasks_count }} creadas.</p>
              
                 <p><strong>Evaluaciones: </strong>{{ form.evaluations_count }} creadas.</p>
             
             </b-col>
             
           </b-row>
           
            <router-link to="/director/evaluaciones" class="btn btn-outline-warning btn-sm" v-if="me_rol.head_rol == 1 || me_rol.head_rol == 2">Ver Evaluaciones</router-link>
            <br />
            <br />
           
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "Welcome",

  data() {
    return {
      form: {
        student_count: 0,
        representatives_count: 0,
        teacher_count: 0,
        classes_count:0,
        tasks_count:0,
        evaluations_count:0
      
      }
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    })
  },
  mounted() {
    this.loadState();
  },

  methods: {
    ...mapActions(["get_counters"]),
    loadState() {
      const id_school = this.me_rol.id_school;
      const self = this;

      let form = {
        id_school: this.me_rol.id_school
      };
      this.get_counters(form)
        .then(response => {
          if (response.code === 200) {
            let resp = response.data;
            self.form.student_count = resp.student_count[0].total;
            self.form.representatives_count =
              resp.representatives_count[0].total;
            self.form.teacher_count = resp.teacher_count[0].total;

            self.form.classes_count = resp.classes_count[0].total;
            self.form.tasks_count = resp.tasks_count[0].total;
            self.form.evaluations_count = resp.evaluations_count[0].total;
          }
        })
        .catch(error => {console.log(error)});
    }
  }
};
</script>