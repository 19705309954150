<template>
  <div class="content">
    <br>
    <br>
    <br>
     <b-row>
           <b-col md="3"></b-col>
          <b-col md="6">
      <b-card title="403 - Acceso no permitido">
       
            <p>Estas intentando acceder a una zona restringida o en la que no tienes los roles suficientes para acceder.</p>

            <p> Asegurate que si eres representante, acceder con tus credenciales de representante</p>
            <p> Si eres estudiante, debes asegurarte de entrar con tu acceso de estudiante</p>

            <p>No te preocupes, puedes volver a iniciar sesión y acceder nuevamente con tus credenciales</p>
              <b-col md="12 text-right">
              <b-button pill variant="outline-success" block :to="{ name: 'login' }">Volver a iniciar</b-button>


            </b-col>
            <p>
             <p style="font-size:10px"> Asegúrate de empezar de nuevo, y si el problema persiste comunícate con la administración del colegio, para verificar por que te aparece este error.</p>
        <br />
        <b-row>
        
        </b-row>
      </b-card>
         </b-col>
          <b-col md="3"></b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: 'NotPermission'
    };
</script>
