<template>
  <div id="AllMessages">
    <div class="content scrollable" ref="msgContainer">
      <b-card title="Mensajería">
        <b-row>
          <b-col md="10">
            <p>
              En esta sección puedes comunicarte con los miembros de tu escuela
            </p>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
        <br />
        <b-row>
          <b-col md="12">
            <div class="row">
              <!-- Users box-->
              <div class="col-md-4">
                <div class="bg-gray px-4 py-2 bg-light">
                  <b-row>
                    <b-col md="10">
                      <p class="h5 mb-0 py-1">Conversaciones</p>
                    </b-col>
                    <b-col md="2">
                      <v-icon
                        @click="modalShow = !modalShow"
                        name="plus"
                      ></v-icon>
                      <b-modal v-model="modalShow" title="Iniciar conversación">
                        <b-row>
                          <b-col md="12">
                            <b-list-group
                              style="overflow: scroll; height: 600px"
                            >
                              <input
                                class="form-control"
                                type="text"
                                v-model="searchQuery"
                                placeholder="Busqueda ..."
                              />
                              <div v-for="user in user_to_chats" :key="user.id">
                                <b-list-group-item v-if="filter(user)">
                                  <b-row
                                    @click="startChat(user)"
                                    href="#"
                                    style="cursor: pointer"
                                  >
                                    <b-col md="6">
                                      <span>{{
                                        user.first_name + " " + user.last_name
                                      }}</span>
                                    </b-col>
                                    <b-col md="6">
                                      <span>{{ user.rol_title }}</span>
                                    </b-col>
                                  </b-row>
                                </b-list-group-item>
                              </div>
                            </b-list-group>
                          </b-col>
                        </b-row>
                      </b-modal>
                    </b-col>
                  </b-row>
                </div>

                <div class="messages-box">
                  <div class="list-group rounded-0">
                    <a
                      v-for="room in private_rooms"
                      :key="room.id"
                      @click="viewDetailMessages(room.id, 1)"
                      href="#"
                      v-bind:class="selected(room.id)"
                      class="list-group-item list-group-item-action list-group-item-light rounded-0"
                    >
                      <div class="media" v-if="room.id_user_a != me.id">
                        <img
                          height="50"
                          width="50"
                          :src="room.user_a.image"
                          alt="user"
                          class="rounded-circle"
                        />

                        <div class="media-body ml-4">
                          <div
                            class="d-flex align-items-center justify-content-between mb-1"
                          >
                            <h5 class="mb-0">
                              {{
                                room.user_a.first_name +
                                " " +
                                room.user_a.last_name
                              }}
                            </h5>
                            <small class="small font-weight-bold"
                              ><span v-if="room.last_message">{{
                                room.last_message.created_at
                              }}</span></small
                            >
                          </div>
                          <p class="font-italic text-muted mb-0 text-small">
                            <span v-if="room.last_message">{{
                              room.last_message.message
                            }}</span
                            ><span v-else>Iniciar conversación ...</span>
                          </p>
                        </div>
                      </div>
                      <div class="media" v-else>
                        <img
                          height="50"
                          width="50"
                          :src="room.user_b.image"
                          alt="user"
                          class="rounded-circle"
                        />

                        <div class="media-body ml-4">
                          <div
                            class="d-flex align-items-center justify-content-between mb-1"
                          >
                            <h5 class="mb-0">
                              {{
                                room.user_b.first_name +
                                " " +
                                room.user_b.last_name
                              }}
                            </h5>
                            <small class="small font-weight-bold"
                              ><span v-if="room.last_message">{{
                                room.last_message.created_at
                              }}</span></small
                            >
                          </div>
                          <p class="font-italic text-muted mb-0 text-small">
                            <span v-if="room.last_message">{{
                              room.last_message.message
                            }}</span
                            ><span v-else>Iniciar conversación ...</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <!-- Chat Box-->
              <div class="col-md-8" v-if="actual_room == 0">
                <br />
                <br />
                <b-row>
                  <b-col md="12">
                    <p>
                      Haz clic en un chat de la izquierda para comenzar a
                      escribir
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div class="col-md-8" v-if="actual_room != 0">
                <div class="bg-gray px-4 py-2 bg-light">
                  <b-row>
                    <b-col md="3" @click="ScrollUp">
                      <v-icon name="angle-left" scale="2"></v-icon>
                    </b-col>
                    <b-col md="6" offset-md="3">
                      <span
                        @click="GetMessages(actual_room, 1)"
                        style="padding: 10px"
                        ><v-icon name="sync"></v-icon> Actualizar mensajes</span
                      >
                    </b-col>
                  </b-row>
                </div>
                <div class="px-4 py-5 chat-box-mobile bg-white">
                  <div v-for="msg in proom_messages" :key="msg.id">
                    <!-- Sender Message-->
                    <div v-if="msg.id_user != me.id" class="media w-75 mb-3">
                      <img
                        v-if="msg.user.image"
                        :src="msg.user.image"
                        alt="user"
                        height="50"
                        width="50"
                        class="rounded-circle"
                      />

                      <div class="media-body ml-3">
                        <h6>
                          {{ msg.user.first_name + " " + msg.user.last_name }}
                        </h6>
                        <div class="bg-light rounded py-2 px-3 mb-2">
                          <p class="text-small mb-0 text-muted">
                            {{ msg.message }}
                          </p>
                        </div>
                        <p class="small text-muted" style="font-size: 10px">
                          {{ msg.created_at }}
                        </p>
                      </div>
                    </div>

                    <!-- Reciever Message-->
                    <div
                      class="media w-75 ml-auto mb-3"
                      v-if="msg.id_user == me.id"
                    >
                      <div class="media-body">
                        <div class="bg-primary rounded py-2 px-3 mb-2">
                          <p class="text-small mb-0 text-white">
                            {{ msg.message }}
                          </p>
                        </div>
                        <p class="small text-muted" style="font-size: 10px">
                          {{ msg.created_at }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Typing area -->
                <b-row>
                  <b-col md="1"></b-col>
                  <b-col md="10">
                    <div class="input-group">
                      <input
                        type="text"
                         id="typeBox" ref="typeBox"
                        v-model="message_post"
                        placeholder="Escribe un mensaje y haz clic en el botón para enviar"
                        aria-describedby="button-addon2"
                        class="form-control rounded-0 border-0 py-4 bg-light"
                      />
                      <div class="input-group-append paddingmobile">
                        <button
                          id="button-addon2"
                          type="button"
                          @click="send"
                          :disabled="progress"
                          class="btn btn-button btn-outline-primary"
                        >
                          <b-spinner v-if="progress" small></b-spinner>

                          <v-icon
                            v-if="!progress"
                            name="paper-plane"
                            size="xl"
                          ></v-icon>
                        </button>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="1"></b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "AllMessages",
  data() {
    return {
      items: [],
      progress: false,
      private_rooms: [],
      proom_messages: [],
      loading: false,
      error: "",
      page: 1,
      actual_room: 0,
      message_post: "",
      modalShow: false,
      user_to_chats: {},
      searchQuery: "",
      actual_chat: 1,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.students.length;
    },
  },
  mounted() {
    this.LoadRooms();
    this.AllUsers();
    this.$nextTick(function () {
      window.setInterval(() => {
        this.GetMessages(this.actual_room, 1);
      }, 25000);
    });
  },
  methods: {
    ...mapActions([
      "load_PrivateRooms",
      "load_PrivateRooms_Messages",
      "create_PrivateRoom",
      "loadData_Student",
      "allUsersToChat",
      "send_Message",
    ]),
    test() {
      console.log("yaea");
    },
    selected(id_room) {
      if (id_room == this.actual_room) {
        return "selected";
      }
    },
    startChat(user) {
      Swal.fire({
        title: "Confirmación",
        text:
          "¿Estás seguro de iniciar una conversación con " +
          user.first_name +
          "?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Iniciar conversación",
      }).then((result) => {
        if (result.value) {
          let formchat = {
            id_school: this.actual_room,
            id_user_a: user.id,
            id_user_b: this.me.id,
          };
          this.create_PrivateRoom(formchat)
            .then((response) => {
              console.log("RESPONSE");
              if (response.code == 200) {
                this.message_post = "";
                this.LoadRooms();
                this.modalShow = false;
              }
              if (response.code == 409) {
                this.GetMessages(response.data.id);
                this.modalShow = false;
              }
              if (response.code == 500) {
                Swal.fire({
                  title: "Ocurrio un error",
                  text: "Intenta de nuevo, completa todos los campos",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            })
            .catch((error) => {
              console.log("ERROR");
              if (error.response.code == 500) {
                Swal.fire({
                  title: "Ocurrio un error",
                  text: "Intenta de nuevo, completa todos los campos",
                  icon: "error",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            });
        }
      });
    },
    filter: function (user) {
      var first_name = user.first_name.toLowerCase();
      var last_name = user.last_name.toLowerCase();
      var email = user.email.toLowerCase();

      var search = this.searchQuery.toLowerCase();
      if (search === "") {
        return user;
      } else if (
        first_name.includes(search) ||
        last_name.includes(search) ||
        email.includes(search)
      ) {
        return user;
      }
    },
    ScrollUp() {
      var container = this.$refs.msgContainer;
      this.scrollTo(container, 0, 1250);
    },
    scrollTo(element, to, duration) {
      var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      var animateScroll = function () {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    },
    viewDetailMessages(id_room) {
      this.GetMessages(id_room);
      var container = this.$refs.msgContainer;
      this.scrollTo(container, container.scrollHeight + 130, 1250);
    },
    GetMessages(id_room) {
      if (id_room != 0) {
        // container.scrollTop = container.scrollHeight + 120;

        let form = {
          id_room: id_room,
          page: this.page,
        };
        this.actual_room = id_room;
        this.progress = true;
        this.load_PrivateRooms_Messages(form)
          .then((response) => {
            if (response.code === 200) {
              let rev_msges = response.data.data.reverse();
              // resp.data.reverse();

              this.proom_messages = [];
              for (let i in rev_msges) {
                let msg = rev_msges[i];

                this.proom_messages.push(msg);
              }

              if (this.private_rooms.length == 0) {
                console.log("No hay chats");
                this.label = "No hay conversaciones creadas";
              }
              this.progress = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    AllUsers() {
      let form = {
        id_school: this.me_rol.id_school,
        type: this.me_rol.id_rol,
      };

      this.progress = true;
      this.allUsersToChat(form)
        .then((response) => {
          if (response.code === 200) {
            this.user_to_chats = response.data;
            // resp.data.reverse();
            this.progress = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    send() {
      if (this.message_post == "") {
        Swal.fire({
          title: "Completa los campos",
          text: "Escribe un mensaje a enviar",
          icon: "warning",
        });
      } else {
        let form = {
          id_private_room: this.actual_room,
          id_user: this.me.id,
          message: this.message_post,
        };
        this.send_Message(form)
          .then((response) => {
            console.log("RESPONSE");
            if (response.code == 200) {
              this.message_post = "";
              this.GetMessages(this.actual_room);
              //this.scrollToEnd();
            }
            if (response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "success",
              }).then((result2) => {
                console.log(result2);
              });
            }
          })
          .catch((error) => {
            console.log("ERROR");
            if (error.response.code == 500) {
              Swal.fire({
                title: "Ocurrio un error",
                text: "Intenta de nuevo, completa todos los campos",
                icon: "error",
              }).then((result2) => {
                console.log(result2);
              });
            }
          });
      }
    },
    LoadRooms() {
      let form = {
        id: this.me.id,
      };
      this.progress = true;
      this.load_PrivateRooms(form)
        .then((response) => {
          if (response.code === 200) {
            this.private_rooms = response.data;
            if (this.private_rooms.length == 0) {
              console.log("No hay chats");
              this.label = "No hay conversaciones creadas";
            }
            this.progress = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
</script>

<style>
.scrollable {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 10px);
}
.selected {
  background-color: #ececf6;
}
/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  width: 2px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  outline: 1px solid slategrey;
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}
@media only screen and (max-width: 600px) {
  .paddingmobile {
    padding-bottom: 200px;
  }
}
.messages-box {
  height: 510px;
  overflow-y: scroll;
}

.chat-box-mobile {
  height: 510px;
  width: 100%;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}
</style>