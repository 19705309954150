<template>
  <div>
    <br />
    <div class="content">
      <b-row>
        <b-col md="8">
          <h2 style="display: inline-block">Materias > {{ subject_detail.subject.name }} - <span v-if="subject_detail.section">{{subject_detail.section.name}}</span></h2>
          <p class="my-0 py-0" v-if="subject_detail.teacher">
            <strong>Periodo:</strong> {{subject_detail.period.name}} - 
            <span v-if="subject_detail.id_teacher2 == 0"><strong>Profesor:</strong> {{subject_detail.teacher.user.first_name + ' ' + subject_detail.teacher.user.last_name}}</span>
            <span v-else><strong>Profesores:</strong> {{subject_detail.teacher.user.first_name + ' ' + subject_detail.teacher.user.last_name}} y {{subject_detail.teacher2.user.first_name + ' ' + subject_detail.teacher2.user.last_name}}</span>
          </p>
        </b-col>
        <b-col md="4" class="text-right" align-self="center">
          <router-link
            style="display: inline-block"
            class="btn btn-info"
            :to="{ name: 'teacher_subjects' }">
            <v-icon name="angle-left" />
            Volver a materias
          </router-link>
        </b-col>
      </b-row>

      <hr />

      <b-tabs card>
        <b-tab title="Clases" @click="$route.query.tab != 'clases' ? $router.replace({ query: { tab: 'clases', lapse: lapse } }) : ''" :active="$route.query.tab == 'clases'">
          <b-row class="mb-3" align-v="center">
            <b-col md="3">
              <b-button
                variant="success"
                pill
                size="sm"
                class="px-3"
                @click="createClass(2)">
                <v-icon name="plus" class="mr-2" />
                <span style="font-size: 16px">Crear clase</span>
              </b-button>
            </b-col>
            <b-col md="6"></b-col>
            <b-col md="3">
              <p class="mx-1 my-auto ml-auto small">Seleccione un momento</p>
              <b-form-group>
                <b-form-select
                  @change="loadState(lapse)"
                  v-model="lapse"
                  :options="lapseList"
                  value-field="id"
                  text-field="name"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row v-if="loading" align-h="center">
            <b-col md="12" class="text-center">
              <b-spinner
                variant="outline-success"
                style="width: 3rem; height: 3rem" />
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col md="4" v-for="(task, index) in tasks" :key="task.id" class="mb-3 px-2">
              <b-list-group>
                <b-popover
                  :target="'popover-target-' + task.created_at"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>
                    <h4 v-if="task.status_evaluation == 1" class="text-center my-0">
                      Clase planificada
                    </h4>
                    <h4 v-if="task.status_evaluation == 2" class="text-center my-0">
                      Clase iniciada
                    </h4>
                    <h4 v-if="task.status_evaluation == 3" class="text-center my-0">
                      Clase culminada
                    </h4>
                    <h4 v-if="task.status_evaluation == 4" class="text-center my-0">
                      Clase cerrada
                    </h4>
                  </template>

                  <p v-if="task.status_evaluation == 1" class="my-0">
                    Las clases planificadas, sólo estarán disponibles cuando
                    el profesor decida iniciar la clase, los alumnos aún no
                    pueden acceder a esta clase
                  </p>
                  <p v-if="task.status_evaluation == 2" class="my-0">
                    Puedes acceder en cualquier momento a esta clase
                    mientras esté iniciada
                  </p>
                  <p v-if="task.status_evaluation == 3" class="my-0">
                    La clase fue culminada por el profesor, los alumnos
                    pueden acceder a los contenidos pero no podrán
                    interactuar en los chats
                  </p>
                  <p v-if="task.status_evaluation == 4" class="my-0">
                    La clase se encuentra cerrada, los alumnos no tienen
                    acceso a esta clase
                  </p>
                </b-popover>

                <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                   <b-col :id="'popover-target-' + task.created_at" md="10" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-if="task.status_evaluation == 1"
                      class="py-1"
                      variant="primary"
                      style="cursor: default; border-radius: 5px 0px 0px 0px; height: 100%; width: 100%;"
                      >Clase planificada</b-button
                    >
                    <b-button
                      v-if="task.status_evaluation == 2"
                      class="py-1"
                      variant="success"
                      style="cursor: default; border-radius: 5px 0px 0px 0px; height: 100%; width: 100%;"
                      >Clase iniciada</b-button
                    >
                    <b-button
                      v-if="task.status_evaluation == 3"
                      class="py-1"
                      variant="warning"
                      style="cursor: default; border-radius: 5px 0px 0px 0px; height: 100%; width: 100%;"
                      >Clase culminada</b-button
                    >
                    <b-button
                      v-if="task.status_evaluation == 4"
                      class="py-1"
                      variant="danger"
                      style="cursor: default; border-radius: 5px 0px 0px 0px; height: 100%; width: 100%;"
                      >Clase cerrada</b-button
                    >
                  </b-col>
                  <b-col md="2" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      variant="danger"
                      class="py-1"
                      style="border-radius: 0px 5px 0px 0px; height: 100%; width: 100%"
                      @click="delete_Evaluation(task, 'Clase', 'clase', index)">
                      <v-icon name="trash" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                 
                </b-row>

                <div v-if="task.url_image" class="card-img-top obj" :style="`background-image: url(${task.url_image}); background-size: 100%; border-radius: 0px`">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
                <div v-else class="card-img-top obj" style="background-image: url(https://storage.googleapis.com/ticktaps-school/school-banner2.jpeg); background-position: 0px -20px; border-radius: 0px; background-size: 100%;">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>

                <b-badge
                  variant="info"
                  class="py-2"
                  style="border-radius: 0px; height: 100%; width: 100%">
                  {{ task.lapse.name }}
                </b-badge>

                <b-list-group-item>
                  <h4 class="text-center">{{ task.title }}</h4>
                  <hr>

                  <p class="py-auto my-auto">
                    {{ task.description.length > 80 ? task.description.substr(0, 80) + '...' : task.description }}
                  </p>
                  <hr>

                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Fecha:</strong> {{ task.deadline | format_date }}
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Fecha tope: </strong>
                    <span>{{ task.tasks_deadline | format_date }}</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Creada el:</strong> {{ task.created_at | format_date }}
                  </p>
                  <p class="py-auto mt-0 mb-auto" style="font-size: 12px">
                    <strong>Últ. modificación el:</strong> {{ task.updated_at | format_date }}
                  </p>
                  <p>
                    <b-badge v-if="task.duplicated == 2" variant="warning">Duplicada</b-badge>
                  </p>
                  
                </b-list-group-item>

                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="dark"
                      class="px-0 mx-0"
                      style="border-radius: 0px 0px 5px 5px; width: 100%"
                      @click="accessClass(task)">
                      Acceder a clase
                      <v-icon name="angle-right" class="ml-2" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group>

            </b-col>
          </b-row>

          <b-row v-if="!loading && tasks.length == 0" align-h="center">
            <b-col md="12" class="text-center">
              <p>No hay ningúna clase registrada... </p>
            </b-col>
          </b-row>
        </b-tab>

        <!--<b-tab title="Evaluaciones" @click="$route.query.tab != 'evaluaciones' ? $router.replace({ query: { tab: 'evaluaciones', lapse: lapse } }) : ''" :active="$route.query.tab == 'evaluaciones'">
          <b-row class="mb-3" align-v="center">
            <b-col md="3">
              <b-button
                variant="success"
                pill
                size="sm"
                class="px-3"
                @click="createClass(3)">
                <v-icon name="plus" class="mr-2" />
                <span style="font-size: 16px">Crear evaluación</span>
              </b-button>
            </b-col>
            <b-col md="6"></b-col>
            <b-col md="3">
              <p class="mx-1 my-auto ml-auto small">Seleccione un momento</p>
              <b-form-group>
                <b-form-select
                  @change="loadState(lapse)"
                  v-model="lapse"
                  :options="lapseList"
                  value-field="id"
                  text-field="name"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row v-if="loading" align-h="center">
            <b-col md="12" class="text-center">
              <b-spinner
                variant="outline-success"
                style="width: 3rem; height: 3rem" />
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col md="4" v-for="(evaluation, index) in evaluations" :key="evaluation.id" class="mb-3 px-2">
              <b-list-group>
                <b-popover
                  :target="'popover-target-' + evaluation.created_at"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>
                    <h4 v-if="evaluation.status_evaluation == 1" class="text-center my-0">
                      Evaluación planificada
                    </h4>
                    <h4 v-if="evaluation.status_evaluation == 2" class="text-center my-0">
                      Evaluación iniciada
                    </h4>
                    <h4 v-if="evaluation.status_evaluation == 3" class="text-center my-0">
                      Evaluación culminada
                    </h4>
                    <h4 v-if="evaluation.status_evaluation == 4" class="text-center my-0">
                      Evaluación cerrada
                    </h4>
                  </template>

                  <p v-if="evaluation.status_evaluation == 1" class="my-0">
                    Las evaluaciones planificadas, sólo estarán disponibles cuando
                    el profesor decida iniciar la evaluación, los alumnos aún no
                    pueden acceder a esta evaluación
                  </p>
                  <p v-if="evaluation.status_evaluation == 2" class="my-0">
                    Puedes acceder en cualquier momento a esta evaluación
                    mientras esté iniciada
                  </p>
                  <p v-if="evaluation.status_evaluation == 3" class="my-0">
                    La evaluación fue culminada por el profesor, los alumnos
                    pueden acceder a los contenidos pero no podrán
                    interactuar en los chats
                  </p>
                  <p v-if="evaluation.status_evaluation == 4" class="my-0">
                    La evalucaión se encuentra cerrada, los alumnos no tienen
                    acceso a esta evalucaión
                  </p>
                </b-popover>

                <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                  <b-col md="3" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      variant="danger"
                      class="py-1"
                      style="border-radius: 5px 0px 0px 0px; height: 100%; width: 100%"
                      @click="delete_Evaluation(evaluation, 'Evaluación', 'evaluación', index)">
                      <v-icon name="trash" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                  <b-col :id="'popover-target-' + evaluation.created_at" md="9" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-if="evaluation.status_evaluation == 1"
                      class="py-2"
                      variant="primary"
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Evaluación planificada</b-button
                    >
                    <b-button
                      v-if="evaluation.status_evaluation == 2"
                      class="py-1"
                      variant="success"
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Evaluación iniciada</b-button
                    >
                    <b-button
                      v-if="evaluation.status_evaluation == 3"
                      class="py-1"
                      variant="warning"
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Evaluación culminada</b-button
                    >
                    <b-button
                      v-if="evaluation.status_evaluation == 4"
                      class="py-1"
                      variant="danger"
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Evaluación cerrada</b-button
                    >
                  </b-col>
                </b-row>

                <div v-if="evaluation.url_image" class="card-img-top obj" :style="`background-image: url(${evaluation.url_image}); background-size: 100%; border-radius: 0px`">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
                <div v-else class="card-img-top obj" style="background-image: url(https://ik.imagekit.io/eswqag3k3o6/school-banner3.jpg); background-position: 0px -50px; border-radius: 0px; background-size: 100%;">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>

                <b-badge
                  variant="info"
                  class="py-2"
                  style="border-radius: 0px; height: 100%; width: 100%">
                  {{ evaluation.lapse.name }}
                </b-badge>

                <b-list-group-item>
                  <h4 class="text-center">{{ evaluation.title }}</h4>
                  <hr>

                  <p class="py-auto my-auto">
                    {{ evaluation.description.length > 80 ? evaluation.description.substr(0, 80) + '...' : evaluation.description }}
                  </p>
                  <hr>

                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Fecha:</strong> {{ evaluation.deadline | format_date }}
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Fecha tope: </strong>
                    <span>{{ evaluation.tasks_deadline | format_date}}</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Creada el:</strong> {{ evaluation.created_at | format_date }}
                  </p>
                  <p class="py-auto mt-0 mb-auto" style="font-size: 12px">
                    <strong>Últ. modificación el:</strong> {{ evaluation.updated_at | format_date }}
                  </p>
                  
                </b-list-group-item>

                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="dark"
                      class="px-0 mx-0"
                      style="border-radius: 0px 0px 5px 5px; width: 100%"
                      @click="accessClass(evaluation)">
                      Acceder a evaluación
                      <v-icon name="angle-right" class="ml-2" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group>

            </b-col>
          </b-row>

          <b-row v-if="!loading && evaluations.length == 0" align-h="center">
            <b-col md="12" class="text-center">
              <p>No hay ningúna evaluación registrada... </p>
            </b-col>
          </b-row>
        </b-tab>-->

        <b-tab title="Exámenes" @click="$route.query.tab != 'examenes' ? $router.replace({ query: { tab: 'examenes', lapse: lapse } }) : ''" :active="$route.query.tab == 'examenes'">
          <b-row class="mb-3" align-v="center">
            <b-col md="3">
              <b-button
                variant="success"
                pill
                size="sm"
                class="px-3"
                @click="createExam()">
                <v-icon name="plus" class="mr-2" />
                <span style="font-size: 16px">Nuevo examen</span>
              </b-button>
            </b-col>
            <b-col md="6"></b-col>

            <b-col md="3">
              <p class="mx-1 my-auto ml-auto small">Seleccione un momento</p>
              <b-form-group>
                <b-form-select
                  @change="loadState(lapse)"
                  v-model="lapse"
                  :options="lapseList"
                  value-field="id"
                  text-field="name"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row v-if="loading" align-h="center">
            <b-col md="12" class="text-center">
              <b-spinner
                variant="outline-success"
                style="width: 3rem; height: 3rem" />
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col md="4" v-for="exam in exams" :key="exam.id" class="mb-3 px-2">
              <b-list-group>
                <b-popover
                  :target="'popover-target-' + exam.created_at"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>
                    <h4 v-if="exam.active == 0" class="text-center my-0">
                      Examen inhabilitado
                    </h4>
                    <h4 v-if="exam.active == 1 && exam.time_passed == 0" class="text-center my-0">
                      Examen habilitado
                    </h4>
                    <h4 v-if="exam.active == 1 && exam.time_passed == 1" class="text-center my-0">
                      Examen concluido
                    </h4>
                  </template>

                  <p v-if="exam.active == 0" class="my-0">
                    Los exámenes inhabilitados no están aún disponibles
                    para ser contestados por los estudiantes
                  </p>
                  <p v-if="exam.active == 1 && exam.time_passed == 0" class="my-0">
                    El examen está disponible para que los estudiantes puedan
                    responderlo, siempre que sea antes de la fecha máxima indicada
                  </p>
                  <p v-if="exam.active == 1 && exam.time_passed == 1" class="my-0">
                    El examen ya ha concluido, por lo cual ya pasó la fecha máxima
                    de aceptar respuestas de los estudiantes
                  </p>
                </b-popover>

                <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                  <b-col v-if="exam.active == 0" md="2" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      variant="danger"
                      class="py-1"
                      style="border-radius: 5px 0px 0px 0px; height: 100%; width: 100%"
                      @click="DeleteExam(exam)">
                      <v-icon name="trash" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                  <b-col v-if="exam.active == 0" :id="'popover-target-' + exam.created_at" md="8" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-if="exam.active == 0"
                      class="py-1"
                      variant="dark"
                      style="cursor: default; border-radius: 0px 0px 0px 0px; height: 100%; width: 100%;"
                      >Examen inhabilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 0"
                      class="py-1"
                      variant="success"
                      style="cursor: default; border-radius: 0px 0px 0px 0px; height: 100%; width: 100%;"
                      >Examen habilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 1"
                      class="py-1"
                      variant="danger"
                      style="cursor: default; border-radius: 0px 0px 0px 0px; height: 100%; width: 100%;"
                      >Examen concluido</b-button
                    >
                  </b-col>
                  <b-col v-if="exam.active == 0" md="2" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-b-modal.modal-activate
                      variant="success"
                      class="py-1"
                      style="border-radius: 0px 5px 0px 0px; height: 100%; width: 100%"
                      @click="activeTMP(exam)">
                      <font-awesome-icon icon="check-circle" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                  
                  <b-col v-if="exam.active == 1" :id="'popover-target-' + exam.created_at" md="12" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      v-if="exam.active == 0"
                      class="py-2"
                      variant="primary"
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Examen inhabilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 0"
                      class="py-1"
                      variant="success"
                      style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                      >Examen habilitado</b-button
                    >
                    <b-button
                      v-if="exam.active == 1 && exam.time_passed == 1"
                      class="py-1"
                      variant="warning"
                      style="cursor: default; border-radius: 5px 5px 0px 0px; height: 100%; width: 100%;"
                      >Examen concluido</b-button
                    >
                  </b-col>
                </b-row>

                <b-badge
                  variant="info"
                  class="py-2"
                  style="border-radius: 0px; height: 100%; width: 100%">
                  {{ exam.lapse.name }}
                </b-badge>

                <b-list-group-item>
                  <h4 class="text-center">{{ exam.title }}</h4>
                  <hr>

                  <p class="py-auto my-auto">
                    {{ exam.description.length > 80 ? exam.description.substr(0, 80) + '...' : exam.description }}
                  </p>
                  <hr>

                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Total preguntas: </strong>
                    <span>{{ exam.questions_quantity }}</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Duración: </strong>
                    <span>{{ exam.duration }} minutos</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Puntaje: </strong>
                    <span>{{ exam.points }} puntos</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Puntaje mínimo de aprobación: </strong>
                    <span>{{ exam.min_approval_points }} puntos</span>
                  </p>
                  <p v-if="exam.active == 1" class="py-auto my-auto" style="font-size: 12px">
                    <strong>Disponible desde:</strong> {{ exam.available_from | format_date }}
                  </p>
                  <p v-if="exam.active == 1" class="py-auto my-auto" style="font-size: 12px">
                    <strong>Disponible hasta: </strong>
                    <span>{{ exam.available_until | format_date}}</span>
                  </p>
                  <p class="py-auto my-auto" style="font-size: 12px">
                    <strong>Creado el:</strong> {{ exam.created_at | format_date }}
                  </p>
                </b-list-group-item>

                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="dark"
                      class="px-0 mx-0"
                      style="border-radius: 0px 0px 5px 5px; width: 100%"
                      @click="accessExam(exam)">
                      Acceder a examen
                      <v-icon name="angle-right" class="ml-2" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group>

            </b-col>
          </b-row>

          <b-row v-if="!loading && exams.length == 0" align-h="center">
            <b-col md="12" class="text-center">
              <p>No hay ningún examen registrado... </p>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Cartelera" @click="$route.query.tab != 'cartelera' ? $router.replace({ query: { tab: 'cartelera', lapse: lapse } }) : ''" :active="$route.query.tab == 'cartelera'">
          <b-row class="mb-3" align-v="center">
            <b-col md="4">
              <b-button
                variant="success"
                pill
                size="sm"
                class="px-3"
                @click="$bvModal.show('bv-modal-example')">
                <v-icon name="plus" class="mr-2" />
                <span style="font-size: 16px">Crear noticia en cartelera</span>
              </b-button>
            </b-col>
            <b-col md="8"></b-col>
          </b-row>
          
          <b-row v-if="loading" align-h="center">
            <b-col md="12" class="text-center">
              <b-spinner
                variant="outline-success"
                style="width: 3rem; height: 3rem" />
            </b-col>
          </b-row>

          <b-row v-else align-h="center">
            <b-col
              v-for="(notice, index) in notices" :key="notice.id"
              md="6"
              class="mb-3 px-2"
            >
              <b-list-group>
                <b-row class="m-0 p-0" style="font-size: 20px; width: 100%">
                  <b-col md="3" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      variant="danger"
                      class="py-1"
                      style="border-radius: 5px 0px 0px 0px; height: 100%; width: 100%"
                      @click="deleteFeed(notice, index)">
                      <v-icon name="trash" color="white" style="font-size: 18px" />
                    </b-button>
                  </b-col>
                  <b-col md="9" class="m-0 p-0 text-center" style="width: 100%">
                    <b-button
                      class="py-2"
                      variant="dark"
                      disabled
                      style="cursor: default; border-radius: 0px 5px 0px 0px; height: 100%; width: 100%;"
                      >Publicada: {{ moment(notice.created_at).local().format('D MMM YYYY, h:mm a') }}</b-button
                    >
                  </b-col>
                </b-row>

                <b-list-group-item>
                  <h4>{{ notice.title }}</h4>
                  <p class="my-auto py-auto">{{ notice.description }}</p>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>

          <b-row v-if="!loading && notices.length == 0" align-h="center">
            <b-col md="12" class="text-center">
              <p>No hay ningúna publicación registrada... </p>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>

    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>
        Agregar un nuevo anuncio en la cartelera
      </template>
      <div class="d-block">
        <b-form-group
          id="input-group-1"
          label="Título:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="feed.title"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Descripción:"
          label-for="input-1"
          description="Los anuncios en cartelera permiten informar a los estudiantes sobre temas en general que no dependen de una clase o evaluación específica"
        >
          <b-form-textarea
            id="input-1"
            v-model="feed.description"
            rows="2"
            max-rows="4"
            type="text"
            required
          ></b-form-textarea>
        </b-form-group>
      </div>
      <b-row>
        <b-col md="6">
          <b-button
            class="mt-3"
            block
            @click="$bvModal.hide('bv-modal-example')"
            >Cancelar</b-button
          >
        </b-col>
        <b-col md="6">
          <b-button
            class="mt-3"
            variant="outline-success"
            block
            @click="new_Feed_notice()"
            >
            <span v-if="!progress">Registrar</span>
            <span v-else class="text-center">
              <b-spinner
                variant="outline-success"
                style="width: 20px; height: 20px" />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-help" title="Ayuda" size="lg" hide-footer>
      <b-row>
        <b-col md="12">
          <p>
            <strong>- Momento: </strong>
            Se refiere al lapso al cual pertenecerá el examen. Entendiendo que el mismo se creará para la materia y sección dentro de la cual se está: {{ subject_detail.subject.name }} - {{ subject_detail.section.name }}
          </p>
          <p>
            <strong>- Puntaje mínimo de aprobación: </strong>
            Puntaje a partir del cual el examen es considerado como aprobado. Por ejemplo, si se trata de un examen de 20 puntos, pudiera indicarse como puntaje mínimo de aprobación 10 puntos
          </p>
          <p>
            <strong>- Duración del examen: </strong>
            Tiempo del cual dispondrá el estudiante para responder el examen. En caso de cumplirse este tiempo, el examen le sería cerrado automáticamente dejándolo hasta el punto que alcanzó el estudiante. Debe ser indicado en minutos y en números enteros
          </p>
          <p>
            <strong>- Título: </strong>
            Título del examen
          </p>
          <p>
            <strong>- Descripción: </strong>
            Descripción del examen. En este campo se pueden incluir los temas que lo componen y la estructura del mismo
          </p>
          <p>
            <strong>- Cantidad de preguntas por sección: </strong>
            Para cada una de las secciones se debe indicar la cantidad de preguntas que aparecerán al estudiante al momento de presentar su examen. En caso de que no se requieran preguntas de determinada sección, se deja la cantidad en 0. En cuanto al puntaje, se refiere al puntaje que tendrá cada una de las preguntas que correspondan a dicha sección. Por ejemplo, un examen de 20 puntos, tendrá 20 preguntas de selección simple y 10 de verdadero/falso, las de selección simple tendrán un valor de 0,5 puntos cada una (para un total de 10 puntos en esa sección) mientras que las de verdadero/falso tendrán un puntaje de 1 punto cada una (para un total de 10 puntos en esa sección) y así se obtiene el total de 20 puntos del examen
          </p>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-activate" title="Habilitar examen" @hide="available_until_date = ''; available_until_hour = ''" size="md" hide-footer>
      <b-row>
        <b-col md="12">
          <p>Está a punto de habilitar el examen. Sin embargo, es necesario indicar antes hasta qué fecha y hora estará disponible para recibir respuestas por parte de los estudiantes. Por lo tanto, debe ingresar esa información a continuación:</p>
        </b-col>

        <b-col md="6">
          <b-form-group label="Fecha">
            <b-form-datepicker
              id="birthday"
              v-model="available_until_date"
              :hide-header="true"
              placeholder="Fecha"
              :min="new Date()"
              label-no-date-selected="Indica una fecha"
              label-help="Usa las flechas para navegar entre las fechas"
              locale="es" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Hora">
            <b-form-timepicker
              v-model="available_until_hour"
              label-close-button="Cerrar"
              label-no-time-selected="Indica una hora"
              placeholder="Hora (24 hrs)"
              locale="es" />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <p><strong>RECORDATORIO</strong>: La última hora de un día son las 23:59</p>
        </b-col>

        <b-col md="12" class="text-center">
          <b-button class="btn btn-success" style="width: 100%" @click="activeExam(exam)">
            Habilitar examen
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2"
import axios from "axios";
import moment from "moment";

export default {
  name: "Welcome",

  data() {
    return {
      progress: false,
      moment: moment,
      available_until_date: '',
      available_until_hour: '',
      evaluations: [],
      exam: {},
      exams: [],
      classes: [],
      tasks: [],
      notices: [],
      feed: {
        title: "",
        description: "",
      },
      //   notice:{},
      loading: false,
      label: "",
      lapse: {},
      lapseList: [],
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },
    };
  },
  computed: {
    ...mapState({
      subject_section: (state) => state.subjectSection.subject_section,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    total_points() {
      let total = 0
      let questions_quantity = []
      let value_questions = []

      if (!this.questions_quantity1)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity1)
      if (!this.questions_quantity2)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity2)
      if (!this.questions_quantity3)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity3)
      if (!this.questions_quantity4)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity4)
      if (!this.questions_quantity5)    questions_quantity.push(0)
      else                              questions_quantity.push(this.questions_quantity5)

      if (!this.value_questions1)    value_questions.push(0)
      else                           value_questions.push(this.value_questions1)
      if (!this.value_questions2)    value_questions.push(0)
      else                           value_questions.push(this.value_questions2)
      if (!this.value_questions3)    value_questions.push(0)
      else                           value_questions.push(this.value_questions3)
      if (!this.value_questions4)    value_questions.push(0)
      else                           value_questions.push(this.value_questions4)
      if (!this.value_questions5)    value_questions.push(0)
      else                           value_questions.push(this.value_questions5)

      for (let i = 0; i < 5; i++) {
        total += questions_quantity[i] * value_questions[i] 
      }

      return total
    }
  },
  mounted() {
    //this.loadState();
    this.lapse = this.$route.query.lapse ? this.$route.query.lapse : this.me_rol.id_actual_lapse
    this.loadSelects();
    this.subjectLoad();
  },
  filters: {
    from_now: function(created_at) {
      return moment(created_at).fromNow();
    },
    format_date: function(created_at) {
      if (created_at && created_at.length > 10) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")+' - '+moment(created_at).format("h:mm a");
      }
      if (created_at && created_at.length <= 10) {
        return moment(created_at).format("D")+' de '+moment(created_at).format("MMMM")+' de '+moment(created_at).format("YYYY")
      }
      else {
        return 'No definida'
      }
    },
  },
  methods: {
    ...mapActions([
      "GetAll",
      "loadData_Evaluation",
      "loadData_Exam",
      "load_Evaluation_by_subject",
      "loadData_SubjectSection",
      "loadLapse_school",
      "loadEvaluations",
      "deleteEvaluation",
      "load_Feed",
      "new_Feed",
      "delete_Feed",
      "Save",
      "ChangeStatus"
    ]),
    loadSelects() {
      let form2 = {
        id_school: this.me_rol.id_school,
      };
      this.loadLapse_school(form2)
        .then((response) => {
          if (response.code === 200) {
            this.lapseList = response.data.reverse();
            if (this.lapse == 0)  this.lapse = response.data[0].id;
            this.loadState(this.lapse);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createClass(type) {
      this.loadData_SubjectSection(this.subject_section).then((response) => {
        console.log(response);
        this.$router.push("/profesor/materia/evaluacion/nuevo/" + type);
      });
    },
    createExam() {
      this.loadData_SubjectSection(this.subject_section).then((response) => {
        this.$router.push("/profesor/materia/"+this.subject_section.id_subject+"/examen/nuevo");
      });
    },
    accessClass(evaluation) {
      console.log(evaluation);
      this.loadData_Evaluation(evaluation).then((response) => {
        console.log(response);
        this.$router.push("/profesor/materia/evaluacion/detalle");
      });
    },
    accessExam(exam) {
      this.loadData_Exam(exam).then((response) => {
        this.$router.push("/profesor/materia/examen/"+exam.id+"/detalle");
      });
    },
    subjectLoad() {
      if (!this.subject_section.id) {
        this.$router.push("/profesor/materias");
      }

      this.subject_detail = this.subject_section;

      //
    },
    loadState(lapse) {
      console.log(lapse);
      this.$router.replace({ query: { tab: this.$route.query.tab, lapse: lapse }})

      let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.subject_detail.id_section;
      const self = this;

      let form = {
        id_subject: id_subject,
        id_type_evaluation: 2,
        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        active: 1,
      };
      if (lapse > 0) {
        form["id_lapse"] = lapse;
      }
      this.loading = true;
      console.log("LAPSE:" + form.id_lapse);
      //LOAD CLASSESS AND TASKS
      this.loadEvaluations(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            // this.evaluations = resp.evaluations;
            // this.classes = resp.classes;
            this.tasks = resp;

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      let form2 = {
        id_subject: id_subject,
        id_type_evaluation: 3,
        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        active: 1,
      };
      if (lapse > 0) {
        form2["id_lapse"] = lapse;
      }
      this.loading = true;
      //LOAD EVALUATIONS
      this.loadEvaluations(form2)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            this.evaluations = resp;
            // this.classes = resp.classes;
            // this.tasks = resp.tasks;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //FORM 3

      let form3 = {
        id_subject: id_subject,

        id_school: id_school,
        id_period: id_period,
        id_section: id_section,
        order_key: "id",
        order_value: "desc",
        active: 1,
      };
      this.loading = true;
      //LOAD EVALUATIONS
      this.load_Feed(form3)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            console.log(resp);
            this.notices = resp;
            // this.classes = resp.classes;
            // this.tasks = resp.tasks;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let form4 = {
        api: 1,
        element: 'quiz_poll',
        query: {
          order_key: 'id',
          order_value: 'desc',
        },
        columns: ['id_subject','id_section','id_lapse'],
        params: {
          id_subject: id_subject,
          id_section: id_section,
          id_lapse: lapse
        }
      }

      console.log(form4)

      this.loading = true;
      //LOAD EVALUATIONS
      this.GetAll(form4)
        .then((response) => {
          console.log(response)
          if (response.code === 200) {
            this.exams = response.data
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    new_Feed_notice(row, label, label2) {
      let id_subject = this.$route.params.id;
      const id_school = this.me_rol.id_school;
      const id_period = this.me_rol.id_period;
      const id_section = this.subject_detail.id_section;

      if (!this.feed.title || !this.feed.description) {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar todos los datos del anuncio",
          icon: "warning",
          confirmButtonColor: 'primary',
          confirmButtonText: "OK",
        })
        return
      }

      Swal.fire({
        title: "Crear nuevo anuncio",
        text: "¿Estás seguro de crear este anuncio en la cartelera",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Registrar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.progress = true

          let form = {
            id_subject: id_subject,

            id_school: id_school,
            id_period: id_period,
            id_section: id_section,
            id_user: this.me.id,
            title: this.feed.title,
            description: this.feed.description,
          };
          this.new_Feed(form)
            .then((response) => {
              this.progress = false

              if (response.code === 200) {
                this.$bvModal.hide('bv-modal-example')

                Swal.fire({
                  title: "Anuncio registrado",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                
                this.feed.title = "";
                this.feed.description = "";

                this.loadState(this.lapse);
              }
            })
            .catch((error) => {
              this.progress = false
              console.log(error);
            });
        }
      });
    },
    activeTMP(exam) {
      this.exam = exam
    },
    activeExam(exam) {
      const validation = !this.available_until_date || !this.available_until_hour

      if (validation) {
        Swal.fire({
          title: "Completa los campos",
          text: "Debe indicar la fecha y hora hasta la cual estará disponible el examen",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        })
        return
      }

      Swal.fire({
        title: "Habilitar examen",
        text: "¿Estás seguro de habilitar este examen? El mismo estará disponible para recibir respuestas de parte de los estudiantes hasta la fecha y hora indicadas",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Habilitar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            api: 1,
            element: 'quiz_poll',
            id: exam.id,
            status: 1,
            data: {
              available_until: this.available_until_date + ' ' + this.available_until_hour
            }
          };
          this.ChangeStatus(form)
            .then((response) => {
              if (response.code === 200) {
                exam.active = 1
                exam.available_from = response.data.available_from
                exam.available_until = response.data.available_until

                this.$nextTick(() => {
                  this.$bvModal.show("bv-modal-activate");
                });

                Swal.fire({
                  title: "Examen habilitado correctamente",
                  icon: "success",
                  showConfirmButton: true,
                  confirmButtonText: 'Aceptar',
                }).then((result1) => {
                  Swal.fire({
                    title: "Publicar en cartelera",
                    text: "¿Desea publicar que el examen ha sido habilitado? De esta forma sus estudiantes serán notificados",
                    icon: "question",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar"
                  }).then((result2) => {
                    if (result2) {
                      this.$nextTick(() => {
                        this.$bvModal.show("bv-modal-example");
                      });
                    }
                  })
                })
              }
              else {
                Swal.fire({
                  title: "¡Atención!",
                  text: "Verifique que la cantidad de preguntas por sección sea correcta y que todas las preguntas estén verificadas",
                  icon: "warning",
                  confirmButtonText: 'Aceptar',
                })
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    delete_Evaluation(row, label, label2, index) {
      Swal.fire({
        title: "Eliminar " + label2,
        text: "¿Estás seguro de eliminar esta " + label2 + "?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009900",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.id,
          };
          this.deleteEvaluation(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: label + " eliminada",
                  icon: "success",
                });

                if (label2 == 'clase')  this.tasks.splice(index,1)
                else                    this.evaluations.splice(index,1)
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    DeleteExam(data, index) {
      Swal.fire({
        title: "Eliminar examen",
        text: "¿Estás seguro de eliminar este examen?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009900",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            api: 1,
            element: 'quiz_poll',
            id: data.id,
            status: 2,
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Examen eliminado",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });

                this.exams.splice(index, 1)
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    deleteFeed(row, index) {
      Swal.fire({
        title: "Eliminar Anuncio",
        text: "¿Estás seguro de eliminar este anuncio?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009900",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.id,
            status: 2,
          };
          this.delete_Feed(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Anuncio eliminado",
                  icon: "success",
                });
                this.notices.splice(index,1)
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.obj {
  overflow: hidden;
}

.obj img {
  transition: all 1.5s ease;
  max-width: 15rem;
  max-height: 8rem;
  object-fit: cover;
}

.obj:hover img {
  transform: scale(1.5);
}
</style>
