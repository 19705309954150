<template>
  <div id="SchoolContainer">
    <div class="d-flex" id="wrapper">
      <!-- Sidebar -->
      <div class="bg-dark border-right" id="sidebar-wrapper">
        <b-row>
          <b-col md="1">
          </b-col>
          <b-col md="10">
            <img
                src="../../assets/img/logosmall.png"
                width="150px"
                style="padding:10px"
                alt="Ticktap Logo"
            />
          </b-col>
          <b-col md="1">
          </b-col>
        </b-row>

        <div class="list-group list-group-flush">

          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin_welcome' }" exact>
            <v-icon name="home"/>
            Inicio
          </router-link>

          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-school-all' }"
              exact>
            <v-icon name="school"/>
            Escuelas
          </router-link>
          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-user-all' }"
              exact>
            <v-icon name="users"/>
            Usuarios
          </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-students-all' }"
              exact>
            <v-icon name="users"/>
            Estudiantes
          </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-representatives-all' }"
              exact>
            <v-icon name="users"/>
            Representantes
          </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-teachers-all' }"
              exact>
            <v-icon name="users"/>
            Profesores
          </router-link>

          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item"
              :to="{ name: 'goadmin-table-teacher-all' }" exact>
            <v-icon name="cog"/>
            Maestros / Tipos
          </router-link>

          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item"
              :to="{ name: 'goadmin-evaluations-all' }" exact>
            <v-icon name="cog"/>
            Evaluaciones
          </router-link>

          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-headmasters' }"
              exact>
            <v-icon name="user-tie"/>
            Directores
          </router-link>

          <router-link
              class="list-group-item list-group-item-action bg-dark menu-item" :to="{ name: 'goadmin-massive-import' }"
              exact>
            <v-icon name="file-alt"/>
            Importar
          </router-link>
        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper">
        <b-navbar
            toggleable="lg"
            type="dark"
            variant="dark"
            :class="{ 'd-none': $route.path ==='/login' }"
        >
          <b-navbar-brand href="#" @click="toggleNav">
            <b-icon-list></b-icon-list>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"/>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

              <b-nav-item-dropdown right>
                <!-- Using 'button-content' slot -->
                <template v-slot:button-content>
                  <em>{{ me.email }}</em>
                </template>
                <b-dropdown-item href="#">Perfil</b-dropdown-item>
                <b-dropdown-item @click.native="logoutMenu">Cerrar Sesión</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {mapState, mapActions} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "DirectorContainer",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    })
  },
  mounted: function () {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
  },
  methods: {
    ...mapActions(["logout", "set_homeSchool", "addToastMessage"]),
    toggleNav() {
      $("#wrapper").toggleClass("toggled");
    },
    logoutMenu() {
      Swal.fire({
        title: "¿Desea cerrar la sesión?",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Cerrar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.logout().then(data => {
            console.log(data);
            this.$router.push("/login");
          });
        }
      });
    },
    backAdmin() {
      Swal.fire({
        title: "Atención!",
        text: "¿Esta seguro de volver al entorno administrativo?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#37bd25",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, Volver"
      }).then(result => {
        if (result.value) {
          this.set_homeSchool(null).then(response => {
            console.log(response);
            this.$router.push("/admin");
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.menu-item {
  color: white;
}

.menu-item:hover {
  background-color: #00873d;
  background: #00873d;
}

#wrapper {
  overflow-x: hidden;
}

.router-link-active {
  /* background-color: #606b75;*/
  background-color: #28a745 !important;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  color: white;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: white;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
