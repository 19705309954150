import axios from "axios";
import * as Config from "../../../config";

const state = {
  notification: {}
};
let mod = "msg/";
const actions = {
  load_Notification({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"notification/all/school/"+form.id_school+"/"+form.rol)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  load_push_Notification({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod+"push_notification/"+form.id_school+"/"+form.id_user)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Notification({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_DATA_NOTIFICATION", data);
  },
  new_Notification({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod+"notification/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  update_Notification({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod+"notification/update/" + form.id, form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  changeActiveNotification({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +mod+
            "notification/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  delete_Notification({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +mod+
            "notification/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

const mutations = {
  LOAD_DATA_NOTIFICATION(state, data) {
    state.notification = data;
  }
};

export default {
  state,
  actions,
  mutations
};
