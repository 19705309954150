<template>
  <div id="Configuration">
    <br />
    <div class="content">
      <b-card title="Configuraciones:">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>A continuación, podrá ver todo el detalle del estudiante, y modificar su información.</p>
          </b-col>
        </b-row>
        <br />
        <div v-if="loading">Cargando...</div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Periodos" active>
              <periods/>
            </b-tab>
            <b-tab title="Momentos">
              <lapses/>
            </b-tab>
            <b-tab title="Niveles">
              <levels/>
            </b-tab>
            <b-tab title="Secciones">
              <sections/>
            </b-tab>
            <b-tab title="Materias">
              <subjects/>
            </b-tab>
              <b-tab title="Datos de la Escuela">
              <school/>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import sections from "./sections/all"
import periods from "./periods/all"
import lapses from "./lapses/all"
import levels from "./levels/all"
import subjects from "./subjects/all"
import school from "./school/all"
import { mapState} from "vuex";
//import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "Configuration",
  components:{
      sections,
      periods,
      lapses,
      levels,
      subjects,
      school
  },
  data() {
    return {
      loading:false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    
        
      
    
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
