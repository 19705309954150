<template>
  <div>
    <br />
    <div class="content">
      <h2>Ayuda de Ticktap School</h2>
      <p>Al trabajar con una nueva plataforma, es normal tener dudas, en este panel, tendrás videos y documentación que te ayudarán a usar correctamente la plataforma</p>
      <br />
      
      <div class="row">
        
        <div class="col-md-4" v-show="me_rol.id_rol == 1">
          <b-card title="Acceso de Estudiantes">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/876xZtb6pXc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <b-card-text>Como usar Ticktap School como Estudiante</b-card-text>
            
          </b-card>
        </div>
        <div class="col-md-4" v-show="me_rol.id_rol == 1">
          <b-card title="Manejo de la APP">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/HnznLMZCqic" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <b-card-text>Rol estudiante - Explicación paso a paso de como usar la aplicación móvil de Clasy</b-card-text>
            
          </b-card>
        </div>
        <div class="col-md-4" v-show="me_rol.id_rol == 2">
          <b-card title="Acceso de Profesor">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/w0GfWqRdU8c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <b-card-text>Como usar Ticktap School como profesor</b-card-text>
            </b-card>
        </div>
        <div class="col-md-4" v-show="me_rol.id_rol == 2">
          <b-card title="Manejo de la APP">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/NwnRZMcKMvs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <b-card-text>Rol profesor - Explicación paso a paso de como usar la aplicación móvil de Clasy</b-card-text>
            
          </b-card>
        </div>
        
       
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from "jquery";
export default {
  name: "WelcomeIndex",
  data() {
    return {};
  },
 
  mounted: function() {
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
  },
  methods: {
   
    toggleNav() {
      $("#wrapper").toggleClass("toggled");
    },
   
  
  },
  computed: {
    ...mapState({
      me_rol: (state) => state.auth.me_rol,
    }),
  },
};
</script>
