// export const apiPath_temp = 'http://bananaback.co/api/1.0/';
//export const apiPath_temp = 'http://127.0.0.1:3333/';
//export const apiPath = 'http://apischool.myticktap.com/api/1.0/';

var url_domain = window.location.host;
var config_url = '';
var config_url_2 = '';
if (url_domain == 'localhost:8080') {
 config_url = 'http://127.0.0.1:3333/api/1.0/';
 config_url_2 = 'http://127.0.0.1:3334/api/1.0/';

 //config_url = 'https://api.clasy.app/api/1.0/';
 //config_url_2 = 'https://api2.clasy.app/api/1.0/'
// config_url = 'https://apitest.clasy.app/api/1.0/';
 //config_url_2 = 'https://apitest2.clasy.app/api/1.0/'

} else if (url_domain == 'escuela.ticktaps.com') {
    config_url = 'https://apischool.myticktap.com/api/1.0/';
    config_url_2 = 'https://apischool2.myticktap.com/api/1.0/'
} 
else if (url_domain == 'clasy.app') {
    config_url = 'https://api.clasy.app/api/1.0/';
    config_url_2 = 'https://api2.clasy.app/api/1.0/'
} 
else if (url_domain == 'test.clasy.app') {
    config_url = 'https://apitest.clasy.app/api/1.0/';
    config_url_2 = 'https://apitest2.clasy.app/api/1.0/'
} 
else if (url_domain == 'escuela2.ticktaps.com') {
    config_url = 'https://apischooltest.myticktap.com/api/1.0/';
    config_url_2 = 'https://apischooltest2.myticktap.com/api/1.0/'
}
else if (url_domain == 'escuela.ticktap.com.ve') {
    config_url = 'https://apischooltest.myticktap.com/api/1.0/';
}
//config_url = 'https://apischool.myticktap.com/api/1.0/';
console.log('Backend Conected: ' + config_url + " Also " + config_url_2)
export const apiPath = config_url;
export const apiPath2 = config_url_2;
export const apiPathx = [config_url, config_url_2]

//TODO name: ['admin','commercial','teacher','personal','student','father','school'][i],
