<template>
  <div id="AllRepresentatives">
    <br />
    <div class="content">
      <b-card title="Lista de Representados">
        <b-row>
          <b-col md="8">
            <p>
              A continuación podrás visualizar una lista de tus representados en
              la escuela
            </p>
          </b-col>
          <b-col md="4">
            <b-button
              pill
              variant="outline-primary"
              :to="{ name: 'representative_children-new' }"
            >
              <v-icon name="plus"></v-icon> Registrar nuevo estudiante</b-button
            >
          </b-col>
        </b-row>
        <br />
        <b-row>
          <div class="col-md-5"></div>
          <div class="col-md-2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              v-show="progress"
              variant="outline-primary"
              label="Text Centered"
            ></b-spinner>
          </div>
          <div class="col-md-5"></div>

          <div class="col-md-12">
            <p>{{ label }}</p>
            <div v-for="student in students" :key="student.id">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <b-col class="col-md-5">
                      <h4 class="card-title" v-if="student.user">
                        {{
                          student.user.first_name + " " + student.user.last_name
                        }}
                      </h4>
                      <p class="card-subtitle">
                        Condición: {{ student.condition.name }} - Sección:
                        {{ student.section.name }}
                      </p>
                      <br />
                      <p style="font-size:11px" v-if="student.section.id == 1">
                        El alumno recien registrado, tendrá condición y sección
                        "No definido", debe esperar que la administración de la
                        escuela le asigne su respectiva sección. De esta forma
                        podrá acceder a sus clases.
                      </p>
                    </b-col>
                    <b-col class="col-md-7">
                      <!-- <b-button size="sm" pill variant="outline-primary" class="mr-2">
                  Ver Detalles
                  </b-button> -->
                      <b-button
                        pill
                        v-on:click="report_pay_Student(student)"
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        style="margin:10px;"
                      >
                        <v-icon name="money-bill"></v-icon> Registrar Pagos
                      </b-button>
                      <b-button
                        pill
                        v-on:click="view_archives_Student(student)"
                        size="sm"
                        variant="outline-primary"
                        class="mr-2"
                        style="margin:10px;"
                      >
                        <v-icon name="file-alt"></v-icon> Gestionar Archivos
                      </b-button>

                      <b-button
                        size="sm"
                        pill
                        style="margin:10px;"
                        @click="loadStudentCredential(student)"
                        ><v-icon name="key"></v-icon> Gestionar
                        credenciales</b-button
                      >

                      <b-button
                        size="sm"
                        pill
                        variant="outline-warning"
                        style="margin:10px;"
                        @click="loginAsStudent(student)"
                        ><v-icon name="sign-in-alt"></v-icon> Acceder como
                        Estudiante</b-button
                      >
                    </b-col>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <p>
              Recuerda que para visualizar
              <strong>Tareas, Clases y Evaluaciones</strong>, debes acceder con
              tus credenciales de estudiante al sistema
            </p>
            <p>
              Si aun no tienes tus credenciales de acceso, presiona en el botón
              gris de <strong>"Gestionar Credenciales"</strong>
            </p>
          </div>
        </b-row>
      </b-card>
      <b-modal
        hide-footer
        id="modal-1"
        v-model="modalShow"
        title="Gestionar credenciales de acceso"
      >
        <form
          style="width: 100% !important"
          method="post"
          @submit.prevent="onSubmitPassword"
        >
          <h2>{{ student_detail.full_name }}</h2>
          <p class="my-4">
            Las credenciales permiten al estudiante acceder a la aplicación
            móvil, y disfrutar de sus contenidos.
          </p>
          <b-form-group
            description="Si el estudiante no posee correo, debe crearlo desde una plataforma externa, le recomendamos que use un correo real, para poder recibir notificaciones"
            label="Correo para acceder"
            label-for="first_name"
          >
            <b-form-input
              id="student_detail_email"
              v-model="student_detail.email"
              name="student_detail_email"
              placeholder="Correo de acceso"
              type="email"
            />
          </b-form-group>
          <br />
          <b-form-group label="Contraseña de acceso" label-for="first_name">
            <b-form-input
              id="student_detail_email"
              v-model="student_detail.password"
              type="password"
              name="student_detail_email"
              placeholder="Indica una contraseña"
            />
          </b-form-group>

          <b-form-group
            description="Al indicar esta contraseña, cualquier contraseña antigua será reemplazada por esta nueva"
            label="Repetir contraseña"
            label-for="first_name"
          >
            <b-form-input
              id="student_detail_email"
              v-model="student_detail.password2"
              type="password"
              name="student_detail_email"
              placeholder="Repite la contraseña"
            />
          </b-form-group>
          <br />
          <b-button block variant="outline-success" type="submit" pill v-b-modal.modal-1
            >Guardar credenciales</b-button
          >
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "AllRepresentatives",
  data() {
    return {
      items: [],
      progress: false,
      item_table: null,
      ttap_selected: null,
      student_detail: {
        id_user: "",
        full_name: "",
        email: "",
        password: "",
        password2: "",
      },
      field: [
        {
          key: "user.namecompleted",
          label: "Nombre y Apellido",
          sortable: true,
        },
        {
          key: "user.dni",
          label: "DNI",
          sortable: true,
        },
        {
          key: "user.username",
          label: "Nombre de Usuario",
          sortable: true,
        },
        {
          key: "user.phone",
          label: "Teléfono",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      label: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      students: [],
      loading: false,
      error: "",
      modalShow: false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.students.length;
    },
  },
  mounted() {
    this.AllSons();
  },
  methods: {
    ...mapActions([
      "updateUser",
      "loadStudent_by_representative",
      "set_homeStudent",
      "changeActiveStudent",
      "loadData_Student",
      "deleteStudent",
      "login_id"
    ]),

    loginAsStudent(student) {
     
      console.log("Submit");

      this.progress = true;
      this.btnLogin = true;
      //GET DEVICE INFO
    /*  let device = null;
      device =
        platform.name +
        " - " +
        platform.version +
        " - " +
        platform.os +
        " - " +
        platform.product; */

      this.login_id({
        id: student.user.id,
  
      })
        .then((data) => {
          console.log("LOGIN 200");
          console.log(data);
          if (data.code == 200) {
            this.auth = data;
            this.btnLogin = false;       
            this.$router.push('/check').catch(() => {});
            this.progress = false;
          }
          if (data.code == 404) {
            Swal.fire({
              title: "Correo no registrado",
              text: "Por favor comprueba los datos ingresados",
              icon: "warning",
            }).then((result2) => {
              console.log(result2);
            });
          }
        })

        .catch((data) => {
          this.btnLogin = false;
          if (data.code == 404) {
            this.addToastMessage({
              text: "Credenciales incorrectas - Comprueba el correo",
              icon: "warning",
            });
            this.progress = false;
          }
          if (data.code == 401) {
            this.addToastMessage({
              text: "Contraseña Incorrecta",
              icon: "warning",
            });
            this.progress = false;
          }
        });
    },
    onSubmitPassword() {
      if (this.student_detail.password != this.student_detail.password2) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning",
        });
      } else if (this.student_detail.password == "") {
        Swal.fire({
          title: "Escribe una contraseña",
          text:
            "Asegurate de escribir una contraseña válida y que ambas coincidan",
          icon: "warning",
        });
      } else if (this.student_detail.email == "") {
        Swal.fire({
          title: "Debes indicar un correo real",
          text:
            "El correo permitirá al estudiante, recibir notificaciones, y gestionar todos sus datos.",
          icon: "warning",
        });
      } else {
        this.loading = true;

        let form = {
          email: this.student_detail.email,
          password: this.student_detail.password,
          id_user: this.student_detail.id_user,
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de guardar las credenciales de tu representado?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Actualizar",
        }).then((result) => {
          if (result.value) {
            this.updateUser(form)
              .then((response) => {
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text:
                      "Las credenciales de acceso de tu representado ha sido actualizada",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.modalShow = false;
                  });
                  this.AllSons();
                }
                if (response.code == 409) {
                  this.loading = false;
                  Swal.fire({
                    title: "Cédula existente",
                    text:
                      "Un usuario con esta cédula ya se encuentra registrado",
                    icon: "warning",
                  });
                }
                if (response.code == 410) {
                  this.loading = false;
                  Swal.fire({
                    title: "Correo existente",
                    text:
                      "Un estudiante con esta correo ya se encuentra registrado",
                    icon: "warning",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      }
    },

    loadStudentCredential(data) {
      console.log(data);
      //CREANING DATA
      this.student_detail.id_user = "";
      this.student_detail.full_name = "";
      this.student_detail.email = "";
      //SET THE DATA
      this.student_detail.id_user = data.user.id;
      this.student_detail.full_name = data.user.full_name;
      this.student_detail.email = data.user.email;

      this.modalShow = !this.modalShow;
    },
    report_pay_Student(data) {
      this.loadData_Student(data).then((response) => {
        console.log(response);
        this.$router.push("/representante/estudiante/pago/" + data.id);
      });
    },
    view_archives_Student(data) {
      this.loadData_Student(data).then((response) => {
        console.log(response);
        this.$router.push("/representante/estudiante/archivos/" + data.id);
      });
    },

    AllSons() {
      let form = {
        id_father: this.me_rol.id,
      };
      this.progress = true;
      this.loadStudent_by_representative(form)
        .then((response) => {
          if (response.code === 200) {
            this.students = response.data;
            if (this.students.length == 0) {
              console.log("No hay estudiantes");
              this.label = "No hay estudiantes registrados";
            }
            this.progress = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    homeStudent(data) {
      Swal.fire({
        title: "Atención!",
        text: "¿Esta seguro de cambiar al entorno a estudiante?",
        type: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#37bd25",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, Cambiar.",
      }).then((result) => {
        if (result.value) {
          this.set_homeStudent(data).then((response) => {
            console.log(response);
            this.$router.push("/estudiante");
          });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
