<template>
  <div id="AllUsers">
    <br />
    <div class="content">
      <b-card title="Respuestas a los exámenes">
        <b-row>
          <b-col md="7" style="text-align: -webkit-left">
            <button class="btn-wine py-1 px-3" style="border-radius: 10px; border: none; color: white" @click="GetAllElements">
              <v-icon name="sync-alt" color="white"></v-icon>
              <span class="ml-2">Actualizar</span>
            </button>
          </b-col>
          <b-col md="5" style="text-align: -webkit-right">
            <h5>Elementos registrados: {{ elements_count }}</h5>
          </b-col>
        </b-row>
        <br />
        <b-row class="mb-2">
          <b-col lg="4" class="my-1">
            <b-form-group
              label="Examen"
              label-cols-sm="6"
              label-cols-md="5"
              label-cols-lg="4"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="exams"
                v-model="id_quiz_poll"
                size="sm"
                :options="quiz_polls"
                @change="ElementChanged()"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4" class="my-1">
            <b-form-group
              label="Aprobado"
              label-cols-sm="6"
              label-cols-md="5"
              label-cols-lg="4"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="approved"
                v-model="approved"
                size="sm"
                :options="approveds"
                @change="ElementChanged()"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4" class="my-1">
            <b-form-group
              label="Corregidos"
              label-cols-sm="6"
              label-cols-md="5"
              label-cols-lg="4"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="checked"
                v-model="checked"
                size="sm"
                :options="checkeds"
                @change="ElementChanged()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="5" class="my-1">
            <b-form-group
              label="Filtrar por"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  @keyup.enter="SearchInput"
                  type="text"
                  placeholder="Buscar"
                />
                <b-input-group-append>
                  <b-button style="background-color: #9C243D" :disabled="!filter" @click="SearchInput"
                    >Buscar</b-button
                  >
                  <b-button :disabled="!filter" @click="ClearFilter"
                    >Limpiar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="2" class="my-1">
            <b-form-group
              label="Por página"
              label-cols-sm="9"
              label-cols-md="7"
              label-cols-lg="6"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                @input="PageChangedx"
              />
            </b-form-group>
          </b-col>
          <b-col lg="5" class="my-1">
            <b-form-group
              label="Página actual"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label-page="Página"
              label-for="perPageSelect"
              class="mb-0"
            >
              <!--<p class="mt-3">Página actual {{ currentPage }}</p>-->
              <b-pagination
                v-if="elements_count > 0"                
                v-model="currentPage"
                :total-rows="elements_count"
                :per-page="perPage"
                aria-controls="my-table"
                @change="PageChanged"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                hover
                :primary-key="id"
                :items="elementsTMP"
                :fields="field"
                :current-page="currentPage"
                :per-page="0"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :empty-html="emptyHTML"
                :empty-filtered-html="emptyFilteredHTML"
                @filtered="OnFiltered"
                :busy="is_busy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(checked)="row">
                  <span v-if="row.item.checked == 1">Corregido</span>
                  <span v-else>Pendiente</span>
                </template>
                <template v-slot:cell(created_at)="row">
                  <span>
                    {{ row.value.substr(0, 10).split('-').reverse().join('-') + ' - ' + row.value.substr(11, 5) }}
                  </span>
                </template>
                <template v-slot:cell(actions)="row">
                   <!--<b-button
                    v-if="row.item.active === 1"
                    size="sm"
                    variant="white"
                    class="mr-2"
                    title="Activado"
                    @click="ChangeActive(row.item)"
                  >
                    <v-icon name="check-square" color="#9C243D" />
                  </b-button>
                  <b-button
                    v-if="row.item.active === 0"
                    size="sm"
                    variant="white"
                    class="mr-2"
                    title="Desactivado"
                    @click="ChangeActive(row.item)"
                  >
                    <v-icon name="minus-square" />
                  </b-button>-->

                  <b-button
                    v-if="row.item.active === 0"
                    size="sm"
                    class="mr-2"
                    variant="white"
                    @click="DetailElement(row.item)"
                  >
                    <v-icon name="eye" />
                  </b-button>
                  <b-button
                    v-if="row.item.active === 1"
                    size="sm"
                    class="mr-2"
                    variant="white"
                    @click="DetailElement(row.item)"
                  >
                    <v-icon name="eye" color="#9C243D" />
                  </b-button>

                  <!--<b-button
                    v-if="row.item.active === 1"
                    size="sm"
                    variant="white"
                    class="mr-2"
                    title="Eliminar"
                    @click="DeleteElement(row.item)"
                  >
                    <v-icon name="trash" color="#9C243D" />
                  </b-button>
                  <b-button
                    v-if="row.item.active === 0"
                    size="sm"
                    variant="white"
                    class="mr-2"
                    title="Eliminar"
                    @click="DeleteElement(row.item)"
                  >
                    <v-icon name="trash" />
                  </b-button>-->
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex"
import Swal from "sweetalert2"
import "sweetalert2/src/sweetalert2.scss"
import Cookies from 'js-cookie'

export default {
  name: "AllExams",

  data() {
    return {
      title: "Resultados",
      elements_count: 0,
      id_quiz_poll: Cookies.get("id_quiz_pollx") ? Cookies.get("id_quiz_pollx") : 0,
      approved: Cookies.get("approvedx") ? Cookies.get("approvedx") : -1,
      checked: Cookies.get("checkedx") ? Cookies.get("checkedx") : 0,
      is_busy: false,
      id: "",
      emptyHTML: `<div class="mt-2 text-center"><h6>No existen elementos registrados</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>No existen elementos registrados que coincidan con tu búsqueda</h6></div>`,
      field: [
        {
          key: "user.full_name",
          label: "Nombre y Apellido",
          sortable: false,
        },
        {
          key: "quiz_poll.title",
          label: "Examen",
          sortable: false,
        },
        {
          key: "result",
          label: "Resultado",
          sortable: false,
        },
        {
          key: "checked",
          label: "Corregido",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Registrado",
          sortable: false,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      //perPage: 50,
      //currentPage: 1,
      perPage: Cookies.get("per_page2") ? Cookies.get("per_page2") : 50,
      currentPage: Cookies.get("current_page2")
        ? Cookies.get("current_page2")
        : 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      quiz_polls: [{ value: 0, text: 'Todos' }],
      approveds: [{ value: -1, text: 'Todos' },{ value: 0, text: 'Reprobados'},{ value: 1, text: 'Aprobados'}],
      checkeds: [{ value: -1, text: 'Todos' },{ value: 0, text: 'Pendientes'},{ value: 1, text: 'Corregidos'}],
      elements: [],
      elementsTMP: [],
      currentPagex: Cookies.get("current_page2x")
        ? Cookies.get("current_page2x")
        : 1,
      refresher: false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
      solutions: (state) => state.main.solutions,
      solution_index: (state) => state.main.solution_index
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    if (Cookies.get('total_solutions'))       this.elements_count = Cookies.get('total_solutions')

    this.GetAllQuizPolls()

    if (!Cookies.get("per_page2"))          Cookies.set("per_page2", this.perPage);
    if (!Cookies.get("current_page2"))      Cookies.set("current_page2", this.currentPage);
    if (!Cookies.get("current_page2x"))     Cookies.set("current_page2x", this.currentPagex);
    
    if (this.solutions.length > 0) {
      this.is_busy = false
      this.elements = this.solutions
      this.elementsTMP = this.solutions
    }
    else {
      this.GetAllElements()
    }
  },
  methods: {
    ...mapActions(["GetAll","ChangeStatus"]),
    ClearFilter() {
      this.elementsTMP = this.elements
      this.filter = ""
      this.GetAllElements()
      return true
    },
    PageChangedx() {
      if (this.elements_count > 0) {
        this.currentPage = 1;
        Cookies.set("per_page2", this.perPage);
        Cookies.set("current_page2", this.currentPage);
        Cookies.set("current_page2x", this.currentPage);
        this.GetAllElements();
      }
    },
    /*PageChanged() {
      this.GetAllElements()
    },*/
    PageChanged(page) {
      if (this.elements_count > 0) {
        Cookies.set("current_page2", page);
        Cookies.set("current_page2x", page);
        this.GetAllElements();
      }
    },
    SearchInput() {
      let filterTMP = this.filter
      this.currentPage = 1
      Cookies.set("current_page2", 1);
      Cookies.set("current_page2x", 1);
      
      if (filterTMP) {
        //Ask for Users by Query (first 20)
        this.is_busy = true

        let form = {
          element: `quiz_poll_solution`,
          query: {
            query: filterTMP,
            order_key: 'full_name',
            order_value: 'asc',
            limit: 10,
          },
          columns: [],
          params: {}
        }

        if (this.id_quiz_poll > 0) {
          Cookies.set('id_quiz_pollx', this.id_quiz_poll)
          form.columns.push('id_quiz_poll')
          form.params["id_quiz_poll"] = this.id_quiz_poll
        }
        if (this.approved >= 0) {
          Cookies.set('approvedx', this.approved)
          form.columns.push('approved')
          form.params["approved"] = String(this.approved)
        }
        if (this.checked >= 0) {
          Cookies.set('checkedx', this.checked)
          form.columns.push('checked')
          form.params["checked"] = String(this.checked)
        }
        
        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.is_busy = false
              this.elements = response.data
              this.elementsTMP = response.data
              this.elements_count = this.elements.length
              this.$store.commit('save_solutions',this.elementsTMP)
            }
            else if (response.code == 420 || response.code == 421) {              
              Cookies.remove('access_token')
              Cookies.remove('email')
              Cookies.remove('id_role')

              Swal.fire({
                title: '¡Atención!',
                text: 'Debe iniciar sesión nuevamente para continuar',
                icon: "warning",
                confirmButtonColor: '#9C243D',
              })

              this.$router.push({ name: 'login' })
            }
          })
          .catch((error) => {
            this.is_busy = false
            console.log(error)
          })
      }
      else {
        this.GetAllElements()
      }
      
      return true;
    },
    OnFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.elements_count = filteredItems.length
      this.currentPage = 1
    },
    ElementChanged() {
      Cookies.set("current_page2", 1);
      Cookies.set("current_page2x", 1);

      this.GetAllElements()
    },
    GetAllQuizPolls() {
      let form = {
        element: `quiz_poll`,
        query: {
          //active: 1,
          order_key: 'id',
          order_value: 'asc',
        }
      }

      this.GetAll(form)
        .then((response) => {

          if (response.code === 200) {
            for (let item of response.data) {
              this.quiz_polls.push({ value: item.id, text: item.title })            
            }
          }
          else if (response.code == 420 || response.code == 421) {              
            Cookies.remove('access_token')
            Cookies.remove('email')
            Cookies.remove('id_role')

            Swal.fire({
              title: '¡Atención!',
              text: 'Debe iniciar sesión nuevamente para continuar',
              icon: "warning",
              confirmButtonColor: '#9C243D',
            })

            this.$router.push({ name: 'login' })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    GetAllElements() {
      this.is_busy = true

      let form = {
        element: `quiz_poll_solution`,
        query: {
          order_key: 'id',
          order_value: 'desc',
          //page: this.currentPage,
          page: Cookies.get("current_page2"),
          limit: this.perPage
        },
        columns: [],
        params: {}
      }

      Cookies.set('id_quiz_pollx', 0)
      Cookies.set('approvedx', -1)
      Cookies.set('checkedx', -1)

      if (this.id_quiz_poll > 0) {
        Cookies.set('id_quiz_pollx', this.id_quiz_poll)
        form.columns.push('id_quiz_poll')
        form.params["id_quiz_poll"] = this.id_quiz_poll
      }
      if (this.approved >= 0) {
        Cookies.set('approvedx', this.approved)
        form.columns.push('approved')
        form.params["approved"] = String(this.approved)
      }
      if (this.checked >= 0) {
        Cookies.set('checkedx', this.checked)
        form.columns.push('checked')
        form.params["checked"] = String(this.checked)
      }

      this.GetAll(form)
        .then((response) => {

          if (response.code === 200) {
            if (Cookies.get('current_page2') == response.data.meta.current_page) {
              this.is_busy = false;
              this.elements = response.data.data;
              this.elementsTMP = response.data.data;
              this.$store.commit('save_solutions',this.elementsTMP)
              
              if (Cookies.get('total_solutions') >= 0 && Cookies.get('total_solutions') != response.data.meta.total) {
                this.elements_count = response.data.meta.total
                Cookies.set('total_solutions', this.elements_count)
              }
              if (!Cookies.get('total_solutions')) {
                this.elements_count = response.data.meta.total
                Cookies.set('total_solutions', this.elements_count)
              }
            }
          }
          else if (response.code == 420 || response.code == 421) {
            this.is_busy = false;
            Cookies.remove('access_token')
            Cookies.remove('email')
            Cookies.remove('id_role')

            Swal.fire({
              title: '¡Atención!',
              text: 'Debe iniciar sesión nuevamente para continuar',
              icon: "warning",
              confirmButtonColor: '#9C243D',
            })

            this.$router.push({ name: 'login' })
          }
        })
        .catch((error) => {
          this.is_busy = false
          console.log(error)
        })
    },
    DetailElement(data) {
      let index = 0
      
      for (let i = 0; i < this.elementsTMP.length; i++) {
        if (this.elementsTMP[i].id == data.id) {
          index = i
          break
        }
      }

      this.$store.commit('save_solution_index', index)

      this.$router.push('../../../../../../../../../soluciones/detalles/' + data.id)
    },
    ChangeActive(data) {
      console.log(data.active)
      let active = data.active == 1 ? 0 : 1
      let title = data.active == 1 ? "Desactivar" : "Activar"
      let title2 = data.active == 1 ? "Desactivado" : "Activado"
      let text =
        data.active === 1
          ? "¿Estás seguro de inactivar este elemento"
          : "¿Estás seguro de activar este elemento?"
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#9C243D",
        cancelButtonColor: "gray",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            id: data.id,
            status: active,
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active
                Swal.fire({
                  title: title2,
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
              else if (response.code == 420 || response.code == 421) {              
                Cookies.remove('access_token')
                Cookies.remove('email')
                Cookies.remove('id_role')

                Swal.fire({
                  title: '¡Atención!',
                  text: 'Debe iniciar sesión nuevamente para continuar',
                  icon: "warning",
                  confirmButtonColor: '#9C243D',
                })

                this.$router.push({ name: 'login' })
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
    DeleteElement(data) {
      Swal.fire({
        title: "Eliminar",
        text: "¿Estás seguro que deseas eliminar este elemento?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#9C243D",
        cancelButtonColor: "gray",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            element: `user`,
            id: data.id,
            status: 2,
          }
          this.ChangeStatus(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Eliminado",
                  confirmButtonColor: "#9C243C",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                })
                this.GetAllElements()
              }
              else if (response.code == 420 || response.code == 421) {              
                Cookies.remove('access_token')
                Cookies.remove('email')
                Cookies.remove('id_role')

                Swal.fire({
                  title: '¡Atención!',
                  text: 'Debe iniciar sesión nuevamente para continuar',
                  icon: "warning",
                  confirmButtonColor: '#9C243D',
                })

                this.$router.push({ name: 'login' })
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
  },
}

</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
