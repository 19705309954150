<template>
  <div>
    <div
      id="chat_window"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/wall1.jpg') + ')',
      }"
      class="chat_window"
    >
      <ul class="messages" ref="container" id="container">
        <li
          v-for="msg in messages"
          :key="msg.id"
          class="message appeared"
          v-bind:class="{
            left: msg.users.id != me.id,
            right: msg.users.id == me.id,
          }"
        >
          
           <b-avatar
                  variant="outline-info"
                  v-if="msg.users.image"
                  :src="msg.users.image"
                ></b-avatar>
                <b-avatar
                  variant="outline-info"
                  v-if="!msg.users.image"
                  src="../../../../../assets/img/logochat.jpg"
                ></b-avatar>

          <div class="text_wrapper">
            <div class="row">
              <b-col md="6">
                <span style="font-size: 13px" v-if="msg.users.full_name"
                  ><strong>{{ msg.users.full_name }}</strong></span
                >
              </b-col>
              <b-col md="6">
                <label style="font-size: 10px; color: gray">{{
                  msg.updated_at
                }}</label>
              </b-col>
            </div>
            <div class="text" v-if="msg.url_archive">
              <a target="a_blank" :href="msg.url_archive">
                <img
                  style="max-width: 300px"
                  class="img-fluid"
                  :src="msg.url_archive"
                  v-if="msg.type_archive == 1"
                />
                <br />
                <v-icon
                  style="color: gray"
                  scale="3"
                  v-if="msg.type_archive == 3"
                  name="file-alt"
                ></v-icon>
               <!-- <v-icon
                  style="color: gray"
                  scale="3"
                  v-if="msg.type_archive == 2"
                  name="play-circle"
                ></v-icon>-->
                <div class="row">
                  <audio
                    v-if="msg.type_archive == 2"
                    :src="msg.url_archive"
                    controls
                  />
                </div>

                <br />

                <span style="font-size: 12px" v-if="msg.type_archive == 2"
                  >Escuchar Audio</span
                >
                <span style="font-size: 12px" v-if="msg.type_archive == 3"
                  >Ver Documento</span
                >
                <span style="font-size: 12px" v-if="msg.type_archive == 1"
                  >Ver Imagen</span
                ></a
              >
            </div>
            <div class="text">
               <span style="font-size: 12px" v-if="checkLink(msg.message) == 0"> {{ msg.message }}</span>
              <a style="font-size: 12px" v-else-if="checkLink(msg.message) == 1" target="a_blank" :href="msg.message">{{msg.message}}</a>
              
              <iframe style="padding-top:20px" width="100%" height="215" v-else :src="'https://www.youtube.com/embed/'+checkLink(msg.message)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           
              
            </div>
          
          </div>
        </li>
      </ul>

     
    </div>
    <div class="message_template">
      <li class="message left appeared">
        <div class="avatar">Hola</div>
        <div class="text_wrapper">
          Hola
          <div class="text">Holaa</div>
        </div>
      </li>
    </div>
  
  </div>
</template>
      <script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
import ChatWindow from "./chat";
import { integer } from "vee-validate/dist/rules";
export default {
  props: {
    messages: Array,
    room: integer,
  },
  //props: ["messages", "room"],
  name: "Welcome",
  components: {
    ChatWindow,
  },
  data() {
    return {
      modalShow: false,
      evaluations: [],
      classes: [],
      tasks: [],
      subject_detail: {
        subject: {
          id: "",
          name: "",
          description: "",
        },
      },

      rooms: [],
      // messages: [],
      currentUserId: 1234,
      message_post: "",
      clipboards: [],
    };
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluation.evaluation,
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
   // this.getClipboard();
    // this.scrollToEnd();
    //var objDiv = document.getElementById("MyDivmssElement");
    //objDiv.scrollTop = objDiv.scrollHeight;
  },
  methods: {
    ...mapActions([
      "new_Message",
      "loadEvaluation_Messages",
      "delete_Chat_Message",    
    ]),
     matchYoutubeUrl(url) {
      if (url) {
        console.log("Finding youtube");
        var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(p)) {
          console.log("Finded");
          return url.match(p)[1];
        }
      } else {
        return false;
      }
    },
    checkLink(msg){
       if (!msg) {
        return 0;
      }
     let http = msg.substring(0, 4);
     
     let youtube_result = this.matchYoutubeUrl(msg);
     if(youtube_result){
       
       return youtube_result;
     }
   
     if(http == 'http'){
       return 1;
     }
     if(http == 'http'){
       return 1;
     }else{
       return 0;
     }
    },
    scrollToEnd: function () {
      var content = this.$refs.container;
      console.log(content);
      console.log("scrollTop:" + content.scrollTop);
      if (content.scrollHeight > content.scrollTop) {
        content.scrollTop = content.scrollHeight;
      }
    },
 
    loadState() {
      console.log("Test");
      let form = {
        room: this.room,
      };
      this.loadEvaluation_Messages(form)
        .then((response) => {
          if (response.code === 200) {
            let resp = response.data;
            resp.data.reverse();

            this.messages = [];
            for (let i in resp.data) {
              let msg = resp.data[i];

              this.messages.push(msg);
            }
            this.scrollToEnd();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //LOAD CLIPBOARD
    },
  
  },
};
</script>

      
  <style scoped>
body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}
.container {
  overflow-y: scroll;
}
.chat_window {
  position: relative;
  width: calc(100% - 20px);
  max-width: 800px;
  height: 650px;
  margin-top: -30px;
  border-radius: 10px;
  background-color: #fff;
  margin-left:-10px;
  margin-right:-30px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  overflow: hidden;
}

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 15px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}
.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}
.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.top_menu .buttons .button.close {
  background-color: #002d4a;
}
.top_menu .buttons .button.minimize {
  background-color: #d4ffe0;
}
.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}
.top_menu .title {
  text-align: center;
  color: #bcbdc0;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  padding: 2px 1px 200px 4px;
  margin: 0;
 
  height: 600px;
  overflow: scroll;
}
.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.messages .message.left .text_wrapper {
  background-color: #ffffff;
  margin-left: 20px;
}
.messages .message.left .text_wrapper::after,
.messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffffff;
}
.messages .message.left .text {
  color: #000000;
}
.messages .message.right .avatar {
  background-color: #012a82;
  float: right;
}
.messages .message.right .text_wrapper {
  background-color: #ffffff;
  margin-right: 20px;
  float: right;
}
.messages .message.right .text_wrapper::after,
.messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #ffffff;
}
.messages .message.right .text {
  color: #000000;
}
.messages .message.appeared {
  opacity: 1;
}
.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}
.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 28px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}
.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}

.bottom_wrapper {
 
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  margin-top:60px;
  position: fixed;
  bottom: 0;
}
.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}
.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
}
.bottom_wrapper .send_message {
  width: 70px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
}
.bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #fff;
}
.bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}

.message_template {
  display: none;
}
</style>