<template>
  <div id="check">
    <b-row
      cols="12"
      class="justify-content-center"
      align-h="center"
      align-v="center"
      align-content="center"
    >
      <b-col md="5"> </b-col>

      <b-col md="5"> </b-col>
    </b-row>
    <b-row v-if="step == 1">
      <b-col md="1"></b-col>
      <b-col md="10">
        <br />
        <p v-if="listRoles.length > 0" class="text-center">
          Perfiles que posee este usuario
        </p>
        <p style="color:red" v-if="listRoles.length === 0" class="text-center">
          <strong>Este usuario no tiene rol en ninguna escuela</strong>
        </p>
        <b-row v-if="listRoles.length > 0" class="mt-5">
          <b-col md="6">
            <p><strong>Escuelas</strong></p>
          </b-col>
          <b-col md="6">
            <p><strong>Rol</strong></p>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="5"> </b-col>
          <b-col md="2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              v-if="step == 1"
              variant="outline-primary"
              v-show="progress"
              label="Text Centered"
            ></b-spinner>
          </b-col>
          <b-col md="5"> </b-col>
        </b-row>
        <div v-for="rol in listRoles" :key="rol.id">
          
          <div class="card" >
            <div class="card-body" v-bind:class="{ blue: rol.id_rol == 1,green: rol.id_rol == 3}">
              <div class="row">
                <b-col class="col-md-4">
                  <label class="card-title"><strong>{{ rol.name_school }}</strong></label>
                </b-col>
                <b-col class="col-md-4">
                  <p v-if="rol.id_rol == 1">Estudiante</p>
                  <p v-if="rol.id_rol == 2">Profesor</p>
                  <p v-if="rol.id_rol == 3">Representante</p>
                  <p v-if="rol.id_rol == 4">Director</p>
                </b-col>
                <b-col class="col-md-4">
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="id_element = rol.id_representative"
                    v-b-modal.modal-1
                    v-if="rol.id_rol == 3"
                    >Eliminar Representante</b-button
                  >
                    <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="id_student = rol.id_student"
                    v-b-modal.modal-2
                    v-if="rol.id_rol == 1"
                    >Eliminar Estudiante</b-button
                  >
                </b-col>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-list-group v-if="rol.id_rol == 1">
                    <b-list-group-item><strong>Representantes Asociados</strong></b-list-group-item>
                    <b-list-group-item v-for="rep in rol.representatives" :key="rep.id">
                      {{rep.user.full_name+' - Email:  '+ rep.user.email+' - '+ rep.user.created_at}}
                    </b-list-group-item>
                    
                  </b-list-group>

                   <b-list-group v-if="rol.id_rol == 3">
                    <b-list-group-item><strong>Estudiantes Asociados</strong></b-list-group-item>
                    <b-list-group-item v-for="stud in rol.students" :key="stud.id">
                      {{stud.user.full_name+' - Email:  '+ stud.user.email+' - '+ stud.user.created_at}}
                    </b-list-group-item>
                    
                  </b-list-group>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        <b-modal id="modal-1" title="Eliminacion de Representante">
          <p class="my-4">
            Por favor indica un password, esta eliminacion no puede ser
            recuperada
          </p>
          <b-form-group label="Contrasena" label-for="first_name">
            <b-form-input
              id="password"
              v-model="password"
              name="password"
              placeholder="Contrasena de Eliminacion"
              required
            ></b-form-input>
          </b-form-group>
          <b-button variant="outline-danger" @click="deleteRepresentative" size="sm"
            >Eliminar Representante</b-button
          >
        </b-modal>


          <b-modal id="modal-2" title="Eliminacion de Estudiante">
          <p class="my-4">
            Por favor indica un password, esta eliminacion no puede ser
            recuperada
          </p>
          <b-form-group label="Contrasena" label-for="first_name">
            <b-form-input
              id="password"
              v-model="password"
              name="password"
              placeholder="Contrasena de Eliminacion"
              required
            ></b-form-input>
          </b-form-group>
          <b-button variant="outline-danger" @click="deleteStudent" size="sm"
            >Eliminar Estudiante</b-button
          >
        </b-modal>


        <br />
        <br />
        <br />
      </b-col>
      <b-col md="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
export default {
  name: "check",
  props: ["items", "auth"],
  data() {
    return {
      id_element:'',
      id_student:'',
      password: "",
      progress: false,
      fields: [
        {
          key: "name_school",
          label: "Escuela",
          sortable: false,
        },
        {
          key: "action",
          label: "Acceder Como",
          sortable: false,
        },
      ],
      listRoles: [],
      step: 1,
    };
  },
  computed: {
    ...mapState({
      userEdit: (state) => state.users.user,
      authe: (state) => state.auth,
    }),
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    ...mapActions([
      "addToastMessage",
      "getAllRolsByUserAdmin",
      "deleteTotalRepresentative",
      "deleteTotalStudent"
    ]),
    deleteRepresentative() {
      Swal.fire({
        title: "Eliminacion de Representante",
        text: "Realice esta accion solo si esta totalmente seguro",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: this.id_element,
            password: this.password,
          };
          this.deleteTotalRepresentative(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Eliminado correctamente",
                  icon: "success",
                });
                this.id_element = '';
                this.password = '';
               this.getRoles();
               this.$bvModal.hide('modal-1')
              }
               if (response.code === 403) {
                Swal.fire({
                  title: "Password incorrecto",
                  icon: "warning",
                });
               
                this.password = '';
              }
               if (response.code === 404) {
                Swal.fire({
                  title: "Ocurrio un error, seleccione nuevamente al elemento a eliminar",
                  icon: "warning",
                });
                  this.getRoles();
               this.$bvModal.hide('modal-1')
               
                this.password = '';
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
     deleteStudent() {
      Swal.fire({
        title: "Eliminacion de Estudiante",
        text: "Realice esta accion solo si esta totalmente seguro",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: this.id_student,
            password: this.password,
          };
          this.deleteTotalStudent(form)
            .then((response) => {
              if (response.code === 200) {
                Swal.fire({
                  title: "Eliminado correctamente",
                  icon: "success",
                });
                this.id_student = '';
                this.password = '';
               this.getRoles();
               this.$bvModal.hide('modal-1')
              }
               if (response.code === 403) {
                Swal.fire({
                  title: "Password incorrecto",
                  icon: "warning",
                });
               
                this.password = '';
              }
               if (response.code === 404) {
                Swal.fire({
                  title: "Ocurrio un error, seleccione nuevamente al elemento a eliminar",
                  icon: "warning",
                });
                  this.getRoles();
               this.$bvModal.hide('modal-1')
               
                this.password = '';
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    getRoles() {
      //let id_user = this.authe.me.id;
      this.progress = true;

      console.log(this.userEdit);
      this.getAllRolsByUserAdmin(this.userEdit.id).then((response) => {
        if (response.data.roles.length === 0) {
          console.log(response.data.roles);
          this.progress = false;
        } else if (response.code === 200) {
          this.listRoles = response.data.roles;
          this.progress = false;
        }
      });
    },
  },
};
</script>
<style>
.blue{
  background-color:rgb(205, 255, 255);
}
.green{
  background-color:rgb(208, 252, 189);
}
</style>