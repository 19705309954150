<template>
  <div id="AllStudent">
    <br />
    <div class="content">
      <b-card title="Lista de Estudiantes">
        <b-row>
          <b-col md="6" style="text-align: -webkit-left">
            <p>
              A continuación, podrás visualizar una lista de los estudiantes
              registrados.
            </p>
          </b-col>
          <b-col md="6" style="text-align: -webkit-right">
            <div class="table-responsive">
              <p><strong>Estado de Estudiantes</strong></p>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col" style="background-color:#EEEFEF">
                      Sin verificar
                    </th>
                    <th scope="col" style="background-color:#FFECCD">
                      Insolventes
                    </th>
                    <th scope="col" style="background-color:#E8FFCD">
                      Solventes
                    </th>
                    <th scope="col" style="background-color:#EEEFEF">
                      Sin sección
                    </th>
                    <th scope="col" style="background-color:#CDFCFF">
                      Sin correo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{ count_pay_1 }}</th>
                    <td>{{ count_pay_2 }}</td>
                    <td>{{ count_pay_3 }}</td>
                    <td>{{ count_pay_4 }}</td>
                    <td>{{ count_pay_5 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="4" class="lmy-1">
            <b-form-group
              label="Filtrar por"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  v-on:keyup="searchInput"
                  type="text"
                  placeholder="Escribe para buscar"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="clearFilter"
                    >Limpiar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="lmy-1">
            <b-form-group
              label="Por página"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-button
              size="sm"
              variant="outline-danger"
              class="mr-2"
              title="Cambiar Estatus"
              @click="modalStatusPaymentShow = !modalStatusPaymentShow"
            >
              <v-icon name="money-bill" />
              Cambiar estatus de pago
            </b-button>

            <b-modal v-model="modalStatusPaymentShow" title="Cambio de estatus de pago general">
              <b-row>
                <b-col md="12">
                   <p>
                    Indica el estatus que quieres colocar para todos los alumnos, este cambio afectara el estatus de pago de todos los alumnos de la institución
                  </p>
                </b-col>
                <b-col md="12">
                  <br>
                  <b-form-select
                    v-model="status_pay_selected"
                    :options="status_payments"
                  ></b-form-select>
                   <b-button class="mt-3" block @click="checkPayGeneral"
                    >Marcar Solvencia</b-button
                  >
                </b-col>
               
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-1">
          <b-col sm="12">
            <div>
              <b-table
                style="font-size:14px"
                id="my-table"
                show-empty
                small
                hover
                fixed
                stacked="md"
                :filter-debounce="400"
                primary-key="id"
                :items="studentsTmp"
                :fields="field"
                :current-page="currentPage"
                :per-page="perPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.status_pay == 1"
                    size="sm"
                    variant="outline-primary"
                    class="mr-2"
                    title=""
                    @click="checkShow(row.item)"
                  >
                    <v-icon name="money-bill" />
                  </b-button>
                  <b-button
                    v-if="row.item.status_pay == 2"
                    size="sm"
                    variant="outline-danger"
                    class="mr-2"
                    title=""
                    @click="checkShow(row.item)"
                  >
                    <v-icon name="money-bill" />
                  </b-button>
                  <b-button
                    v-if="row.item.status_pay == 3"
                    size="sm"
                    variant="outline-success"
                    class="mr-2"
                    title=""
                    @click="checkShow(row.item)"
                  >
                    <v-icon name="money-bill" />
                  </b-button>

                  <b-button
                    v-if="row.item.active === 1"
                    size="sm"
                    variant="outline-success"
                    class="mr-2"
                    title=""
                    v-b-tooltip.hover
                    @click="changeStatus(row.item)"
                  >
                    <v-icon name="check-square" />
                  </b-button>
                  <b-button
                    v-if="row.item.active === 0"
                    size="sm"
                    variant="outline-warning"
                    class="mr-2"
                    title=""
                    v-b-tooltip.hover
                    @click="changeStatus(row.item)"
                  >
                    <v-icon name="minus-square" />
                  </b-button>
                  <!--
                  <b-button size="sm" variant="outline-info" class="mr-2" @click="alert(row.item)">
                    <i class="fa fa-edit" v-b-tooltip.hover title="Actualizar Estudiante"/>
                  </b-button>-->
                  <b-button size="sm" variant="outline-danger" class="mr-2" @click="delete_Student(row)">
                    <v-icon name="trash" />
                  </b-button>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    class="mr-2"
                    @click="show_Student(row.item)"
                  >
                    <v-icon name="eye" />
                  </b-button>
                </template>
              </b-table>
              <b-modal hide-footer id="modal-pay" title="Indicar Solvencia">
                <p class="my-4">
                  Por favor indica el estado de pago de este estudiante
                  <strong>{{ student.user.full_name }}</strong>
                </p>
                <br />
                <b-form-select
                  v-model="status_pay_selected"
                  :options="status_payments"
                ></b-form-select>
                <b-button class="mt-3" block @click="checkPay"
                  >Marcar Solvencia</b-button
                >
              </b-modal>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              <p class="mt-3">Página actual: {{ currentPage }}</p>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllStudent",
  data() {
    return {
      modalStatusPaymentShow: false,
      isBusy: true,
      items: [],
      item_table: null,
      ttap_selected: null,
      student: {
        user: {
          name: "",
        },
      },
      status_pay_selected: 1,
      status_payments: [
        { value: 1, text: "No Revisado" },
        { value: 2, text: "Insolvente" },
        { value: 3, text: "Solvente" },
      ],

      field: [
        {
          key: "user.full_name",
          label: "Nombre y Apellido",
          sortable: true,
        },
        {
          key: "user.email",
          label: "Correo Electrónico",
          sortable: true,
        },
        {
          key: "user.dni",
          label: "Cédula",
          sortable: true,
        },
        {
          key: "section.name",
          label: "Sección",
          sortable: true,
        },
        {
          key: "parent1.user.full_name",
          label: "Representante",
          sortable: true,
        },
        {
          key: "parent1.user.phone",
          label: "Teléfono Representante",
          sortable: true,
        },
        {
          key: "status_pay_label",
          label: "Estado de Pago",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
          class: 'no'
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      students: [],
      studentsTmp: [],
      loading: false,
      error: "",
      count_pay_1: 0,
      count_pay_2: 0,
      count_pay_3: 0,
      count_pay_4: 0,
      count_pay_5: 0,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return [];
      // this.field ?
      //     this.students.filter(item => item.user.toLowerCase().indexOf('josé') > -1)
      //     :[];
      // return this.field
      //   .filter(f => f.sortable)
      //   .map(f => {
      //     return { text: f.label, value: f.key };
      //   });
      // return this.field
      //     ? this.students.filter(function(el,i) {
      //       console.log(el,'hola')
      //       return el.user.toLowerCase().indexOf(this.field.toLowerCase(),i) > -1;
      //     })
      //     : this.students
      // ? this.students.filter(item => item.user.full_name.includes(this.field) || item.user.email.includes(this.field))
      // function filterItems(query) {
      //   return fruits.filter(function(el) {
      //     return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
      //   })
      // }
    },
    rows() {
      return this.studentsTmp.length;
    },
  },
  mounted() {
    this.allStudent();
  },
  methods: {
    ...mapActions([
      "loadStudent_school",
      "updateStudent",
      "updateStudentPay",
      "set_homeStudent",
      "changeActiveStudent",
      "loadData_Student",
      "deleteStudent",
      "updateStudentPayGeneral"
    ]),
    clearFilter() {
      this.studentsTmp = this.students;
      this.filter = "";
      return true;
    },
    searchInput() {
      let filterTMP = this.filter;
      let studentsTmp = [];
      if (filterTMP) {
        this.students.filter(function(el, i) {
          let cadena = JSON.stringify(el.user);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            studentsTmp.push(el);
          }
        });
        this.studentsTmp = studentsTmp;
      } else {
        this.studentsTmp = this.students;
      }

      return true;
    },
    allStudent() {
      this.isBusy = true;
      let form = {
        id_school: this.me_rol.id_school,
      };
      this.loadStudent_school(form)
        .then((response) => {
          this.isBusy = false;
          if (response.code === 200) {
            this.students = response.data;
            this.studentsTmp = this.students;
            this.count_pay_1 = 0;
            this.count_pay_2 = 0;
            this.count_pay_3 = 0;
            this.count_pay_4 = 0;
            this.count_pay_5 = 0;
            for (let i in this.studentsTmp) {
              var std = this.studentsTmp[i];

              if (std.status_pay == 1) {
                this.count_pay_1 += 1;
              }
              if (std.status_pay == 2) {
                this.count_pay_2 += 1;
              }
              if (std.status_pay == 3) {
                this.count_pay_3 += 1;
              }
              if (std.id_section == 1) {
                this.count_pay_4 += 1;
              }
              if (!std.user.email) {
                this.count_pay_5 += 1;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    alert() {
      alert("Proximamente esta funcionalidad estara lista");
    },
    edit_Student(data) {
      this.loadData_Student(data).then((response) => {
        console.log(response);
        this.$router.push("../director/estudiante/editar/" + data.id);
      });
    },
    show_Student(data) {
      this.loadData_Student(data).then((response) => {
        console.log(response);
        this.$router.push("../director/estudiante/detalle/" + data.id);
      });
    },
    homeStudent(data) {
      Swal.fire({
        title: "Atención!",
        text: "¿Esta seguro de cambiar al entorno a estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#37bd25",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, Cambiar.",
      }).then((result) => {
        if (result.value) {
          this.set_homeStudent(data).then((response) => {
            console.log(response);
            this.$router.push("/estudiante");
          });
        }
      });
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 = data.active === 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1
          ? "¿Estás seguro de desactivarlo ahora?"
          : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeActiveStudent(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    checkPay(data) {
      this.loading = true;

      let form = {
        id: this.student.id,
        status_pay: this.status_pay_selected,
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de editar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
        showLoaderOnConfirm: true,
        timerProgressBar: true,
      }).then((result) => {
        if (result.value) {
          this.updateStudentPay(form)
            .then((response) => {
              if (response.code === 200) {
                data.status_pay = this.status_pay_selected;
                this.allStudent();
                this.$bvModal.hide("modal-pay");
                Swal.fire({
                  title: "Listo",
                  text: "El estudiante ha sido modificado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
      checkPayGeneral(data) {
      this.loading = true;

      let form = {
        id: this.me_rol.id_school,
        status: this.status_pay_selected,
      };

      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de editar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
        showLoaderOnConfirm: true,
        timerProgressBar: true,
      }).then((result) => {
        if (result.value) {
          this.updateStudentPayGeneral(form)
            .then((response) => {
              if (response.code === 200) {
                console.log('READY')
                console.log(data)
                this.allStudent();
                this.modalStatusPaymentShow = false;
                Swal.fire({
                  title: "Listo",
                  text: "Los estudiantes han sido modificado",
                  icon: "success",
                }).then((result2) => {
                  console.log(result2);
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    checkShow(data) {
      this.$bvModal.show("modal-pay");

      this.student = data;
      this.status_pay_selected = data.status_pay;
    },
    delete_Student(row) {
      Swal.fire({
        title: "Eliminar Estudiante",
        text: "¿Estás seguro de eliminar este estudiante?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009900",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.deleteStudent(form)
            .then((response) => {
              if (response.code === 200) {
                this.allStudent();
                Swal.fire({
                  title: "Estudiante Eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
.no {
  width : 180px !important;
}
</style>
