<template>
  <div id="table-type-evaluation">
    <div>
      <b-row>
        <b-col md="8" style="text-align: -webkit-left">
          <h3>Listado de tipos de evaluaciones</h3>
        </b-col>
        <b-col md="4" style="text-align: -webkit-right">
          <b-button
              size="xl"
              variant="outline-primary"
              class="mr-2"
              title=""
              v-b-tooltip.hover
              @click="showModalEval({})"
          >Registrar tipo de evaluación
          </b-button>
        </b-col>
      </b-row>
      <br/>
      <b-row>
        <b-col lg="6" class="lmy-1">
          <b-form-group
              label="Filtrar por"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="filterInput"
                  v-model="filter"
                  v-on:keyup="searchInputEval"
                  type="text"
                  placeholder="Escribe para buscar"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="clearFilterEval">Limpiar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="lmy-1">
          <b-form-group
              label="Por página"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
          >
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3 px-1">
        <b-col sm="12">
          <div>
            <b-table style="font-size:14px"
                     id="my-table-Eval"
                     show-empty
                     small
                     hover
                     fixed
                     stacked="md"
                     :filter-debounce="400"
                     primary-key="id"
                     :items="typeEvaluationTmp"
                     :fields="field"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter-included-fields="filterOn"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection"
                     @filtered="onFiltered"
                     :busy="isBusy"
            >
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Cargando ...</strong>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button
                    v-if="row.item.active === 1"
                    size="sm"
                    variant="outline-success"
                    class="mr-2"
                    title=""
                    v-b-tooltip.hover
                    @click="changeActiveEval(row.item)"
                >
                  <v-icon name="check-square"/>
                </b-button>
                <b-button
                    v-if="row.item.active === 0"
                    size="sm"
                    variant="outline-warning"
                    class="mr-2"
                    title=""
                    v-b-tooltip.hover
                    @click="changeActiveEval(row.item)"
                >
                  <v-icon name="minus-square" color="#fff"/>
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-primary"
                    class="mr-2"
                    title=""
                    v-b-tooltip.hover
                    @click="showModalEval(row.item)"
                >
                  <v-icon name="edit" color="#fff"/>
                </b-button>
              </template>
            </b-table>
            <b-modal hide-footer id="modalAddEditEval" :title="editTypeEvaluation.id?'Modificar Registro':'Guardar Registro'">
              <form style="width: 100%!important;" method="post" @submit.prevent="onSubmitEval">
                <b-form-group label="Nombre del tipo de evaluación" label-for="nameTypeEvaluation">
                  <b-form-input
                      id="nameTypeEvaluation"
                      v-model="nameTypeEvaluation"
                      name="nameTypeEvaluation"
                  />
                </b-form-group>
                <b-button class="mt-3" type="submit" block size="xl" variant="outline-primary">
                  {{ editTypeEvaluation.id ? 'Modificar' : 'Guardar' }}
                </b-button>
              </form>
            </b-modal>
          </div>
        </b-col>
        <b-col sm="12">
          <div>
            <p class="mt-3">Página actual: {{ currentPage }}</p>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table-Eval"
                size="sm"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "table-type-evaluation",
  data() {
    return {
      isBusy: false,
      items: [],
      item_table: null,
      ttap_selected: null,
      student: {
        user: {
          name: ""
        }
      },
      field: [
        {
          key: "name",
          label: "Nombre",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false
        }
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      typeEvaluation: [],
      typeEvaluationTmp: [],
      loading: false,
      error: "",
      editTypeEvaluation: {},
      nameTypeEvaluation: ''
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return [];
    },
    rows() {
      return this.typeEvaluationTmp.length;
    }
  },
  mounted() {
    this.loadTypeEval();
  },
  methods: {
    ...mapActions([
      "load_TypeEvaluation",
      "new_TypeEvaluation",
      "update_TypeEvaluation",
      "changeActive_TypeEvaluation",
    ]),
    clearFilterEval() {
      this.typeEvaluationTmp = this.typeEvaluation;
      this.filter = ''
      return true;
    },
    searchInputEval() {
      let filterTMP = this.filter;
      let typeSchoolTmp = [];
      if (filterTMP) {
        this.typeEvaluation.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            typeSchoolTmp.push(el)
          }
        })
        this.typeEvaluationTmp = typeSchoolTmp;
      } else {
        this.typeEvaluationTmp = this.typeEvaluation;
      }

      return true;

    },
    loadTypeEval() {
      this.isBusy = true
      this.load_TypeEvaluation()
          .then(response => {
            this.isBusy = false
            if (response.code === 200) {
              this.typeEvaluation = response.data;
              this.typeEvaluationTmp = this.typeEvaluation;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    hideModalEval() {
      this.editTypeEvaluation = {}
      this.$bvModal.hide("modalAddEditEval");
    },
    showModalEval(data) {
      this.editTypeEvaluation = data
      this.nameTypeEvaluation = ''
      if (data.id) {
        this.nameTypeEvaluation = this.editTypeEvaluation.name
      }
      this.$bvModal.show("modalAddEditEval");
    },
    onSubmitEval() {

      if (this.nameTypeEvaluation.trim() === '') {
        Swal.fire({
          title: "Completa el campo",
          text: "Asegúrate de indicar un nombre para el tipo de evaluación e intenta de nuevo",
          icon: "warning",
        })
      } else {
        this.loading = true;
        let form = {
          id: this.editTypeEvaluation.id ? this.editTypeEvaluation.id : null,
          name: this.nameTypeEvaluation
        };
        Swal.fire({
          title: "Confirmación",
          text: this.editTypeEvaluation.id ? '¿Estás seguro de modificar este registro?' : '¿Estás seguro de guardar este registro?',
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: this.editTypeEvaluation.id ? 'Modificar' : 'Guardar',
        }).then(result => {
          if (result.value) {
            this.loading = false;
            if (form.id) {
              this.update_TypeEvaluation(form)
                  .then(response => {
                    if (response.code == 200) {
                      Swal.fire({
                        title: "Listo",
                        text: "Información Modificada",
                        icon: "success"
                      }).then(() => {
                        this.hideModalEval()
                        this.loadTypeEval()
                      });
                    }
                    if (response.code !== 200) {
                      Swal.fire({
                        title: "Ocurrio un error",
                        text: response.message,
                        icon: "warning"
                      }).then(result2 => {
                        console.log(result2)
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      title: "Ocurrio un error",
                      text: "Intenta de nuevo, completa todos los campos",
                      icon: "warning"
                    }).then(result2 => {
                      console.log(result2)
                    });
                  });

            } else {
              this.new_TypeEvaluation(form)
                  .then(response => {
                    if (response.code == 200) {
                      Swal.fire({
                        title: "Listo",
                        text: "Información Guardada",
                        icon: "success"
                      }).then(() => {
                        this.hideModalEval()
                        this.loadTypeEval()
                      });
                    }
                    if (response.code !== 200) {
                      Swal.fire({
                        title: "Ocurrio un error",
                        text: response.message,
                        icon: "warning"
                      }).then(result2 => {
                        console.log(result2)
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      title: "Ocurrio un error",
                      text: "Intenta de nuevo, completa todos los campos",
                      icon: "warning"
                    }).then(result2 => {
                      console.log(result2)
                    });
                  });

            }

          }
        });
      }
    },
    changeActiveEval(data) {
      let active = data.active === 1 ? 0 : 1;
      let title = data.active === 1 ? "Desactivar" : "Activar";
      let title2 = data.active === 1 ? "Desactivado correctamente" : "Activado correctamente";
      let text = data.active === 1 ? "¿Estás seguro de desactivarlo ahora?" : "¿Estás seguro de activarlo ahora?";
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#009900",
        cancelButtonColor: "#d33",
        confirmButtonText: title,
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeActive_TypeEvaluation(form)
              .then(response => {
                if (response.code == 200) {
                  data.active = active;
                  Swal.fire({
                    title: title2,
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}

</style>
