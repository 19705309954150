<template>
  <div class="Conection">
    <b-list-group v-if="userlog.length > 0">
      <b-list-group-item v-for="info of userlog" :key="info.id">
        <b-row align-v="center">
         <b-col md="10">
            <h5><strong>IP: {{ info.ip }}</strong></h5>
            <p>Desde: {{ info.from == 1 ? "Web" : "Celular" }}</p>
            <p>Dispositivo o navegador: {{ info.device === null ? "No detectado" : info.device }} </p>
            <p>Fecha de conexión: {{ info.updated_at }}</p>
          </b-col>
          <b-col>
            <b-button
              variant="white"
              v-b-modal.modal-10
              @click="searchlocation(info)"
            >
              <v-icon name="eye" scale="2"></v-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-if="userlog.length === 0">
      <h3>No hay conexiones recientes</h3>
    </b-list-group>

    <b-modal
      id="modal-10"
      title="Localización de la conexión"
      ok-only
      ok-title="Aceptar"
    >
      <div class="my-4" v-show="!loading">
       <h5>
          {{ location.country }}, {{ location.city }},
          {{ location.countryCode }}
        </h5>
        <p>{{location.as}}</p>
        <p>{{location.isp}}</p>
      </div>
      <div class="text-center" v-show="loading">
        <b-spinner label="Text-centered"></b-spinner>
        <strong class="ml-2">Cargando...</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Conection",
  data() {
    return {
      userlog: [],
      ip: null,
      location: {
        country: "",
        city: "",
        countryCode: "",
        as:'',
        isp:''
      },
      loading: false,
    };
  },
  mounted() {
    this.allConections();
  },
  methods: {
    ...mapActions(["loadLogAccess", "loadUserLocation"]),

    allConections() {
      this.loadLogAccess(this.userEdit.id)
        .then((response) => {
          if (response.code === 200) {
            this.userlog = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
     searchlocation(info) {
      this.loading = true;
     this.loadUserLocation(info.ip)
        .then((response) => {
          if (response.code === 200) {
            console.log('IP OK')
            console.log(response.data)
            this.location = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState({
      userEdit: (state) => state.users.user,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
};
</script>

<style>
</style>