<template>
  <div id="NewStudent">
    <br />
    <div class="content">
      <b-card title="Crear una nueva asignación">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              En este panel podrás asignar a un profesor, dentro de una matería,
              asi como indicar en que sección imparte la misma.
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{ name: 'director-evaluations-all' }"
            >
              <v-icon name="angle-left" /> Volver a Asignaciones</router-link
            >
          </b-col>
          <br />
          <hr align="center" noshade="noshade" size="2" width="100%" />
        </b-row>

        <form
          style="width: 100% !important"
          method="post"
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col md="5">
              <b-form-group>               
                  <div>
                    <div class="mb-2">Sección</div>
                    <b-form-select
                      v-model="section"
                      :options="sectionList"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </div>
             
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <div>
                  <div class="mb-2">Materia</div>
                  <b-form-select
                    v-model="subject"
                    :options="subjectList"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2"> </b-col>
            <b-col md="5">
              <b-form-group>
                <div>
                  <div class="mb-2">Profesor</div>
                  <b-form-select
                    v-model="teacher"
                    :options="teacherList"
                    value-field="id"
                    text-field="user.full_name"
                  ></b-form-select>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <div>
                  <div class="mb-2">Profesor 2 (opcional)</div>
                  <b-form-select
                    v-model="teacher2"
                    :options="teacherList"
                    value-field="id"
                    text-field="user.full_name"
                  ></b-form-select>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2"> </b-col>
            <b-col md="5">
              <b-form-group>
                <div>
                  <div class="mb-2">Periodo</div>
                  <b-form-select
                    v-model="period"
                    :options="periodList"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Salón de Clases" label-for="first_name">
                <b-form-input
                  id="classroom"
                  v-model="classroom"
                  name="classroom"
                />
              </b-form-group>
            </b-col>
            <b-col md="2"> </b-col>
          </b-row>

          <hr align="center" noshade="noshade" size="2" width="100%" />

          <b-col md="12 text-right">
            <b-button type="submit" variant="outline-success" class="m-3"
              >Registrar nueva asignación <v-icon name="save" />
            </b-button>
          </b-col>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "NewStudent",
  data() {
    return {
      //TODO LIST OF FATHER
      fathers: [],
      //USER DATA
      user: {
        full_name: "",
        dni: "",
        phone: "",
      },
      studies: "",
      skill1: "",
      skill2: "",
        id_school:"",
      email: "",
      section:'',
      subject:'',
      period:'',
      teacher:'',
      tacher2:'',
      classroom:'',
      sectionList:[],
      subjectList:[],
      periodList:[],
      teacherList:[],


    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathers.length;
    },
  },
  mounted() {
      this.allSelects();
  },
  methods: {
    ...mapActions([
      "newStudentFromRepresentative",
      "findUserByEmail",
      "addToastMessage",
      "newTeacher",
      "loadSection_school",
      "loadSubjects_school",
      "loadPeriod_school",
      "loadTeachers_school",
      "newSubjectSection"
    ]),
     allSelects() {     
        this.id_school = this.me_rol.id_school;
        let form2 = {
          id_school:this.id_school
        }
        //SECTION
          this.loadSection_school(form2)
        .then(response => {
          if (response.code === 200) {
            this.sectionList = response.data;
          }
        })
        .catch(error => {console.log(error)});
        //SUBJECT
          this.loadSubjects_school(form2)
        .then(response => {
          if (response.code === 200) {
            this.subjectList = response.data;
          }
        })
        .catch(error => {console.log(error)});
         //PERIOD
          this.loadPeriod_school(form2)
        .then(response => {
          if (response.code === 200) {
            this.periodList = response.data;
          }
        })
        .catch(error => {console.log(error)});
         //SCHOOL
          this.loadTeachers_school(form2)
        .then(response => {
          if (response.code === 200) {
            this.teacherList = response.data;
          }
        })
        .catch(error => {console.log(error)});
        
    },   
    find_UserByEmail() {
      this.isBusy = true;
      let form = {
        email: this.email,
      };
      this.findUserByEmail(form)
        .then((response) => {
          if (response.code === 200) {
            this.user = response.data;
          }
          if (response.code === 404) {
            Swal.fire({
              title: "Usuario no encontrado",
              text: "No hemos encontrado un usuario con este correo",
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if (!this.section || !this.subject || !this.teacher || !this.period || this.classroom == "" ) {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de completar todos los campos requeridos",
          icon: "warning",
        });
      } else {
        this.loading = true;
        let form = {
          id_section: this.section,
          id_subject: this.subject,
          classroom: this.classroom,
          id_period: this.period,
          id_teacher: this.teacher,
          id_teacher2: this.teacher2 ? this.teacher2 : '0',
          id_school: this.me_rol.id_school, // datos de la escuela
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de registrar esta asignación?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.newSubjectSection(form)
              .then((response) => {
                console.log("RESPONSE");
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "Asignación registrada correctamente",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.push("/director/evaluaciones");
                  });
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                if (response.code == 409) {
                  Swal.fire({
                    title: "Asignación previamente registrada",
                    text:
                      "Encontramos una solicitud con estos datos previamente registrados",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                    //  this.$router.push({ name: "uecamag" });
                  });
                }
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
