import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "../vuex/store";
//const store from '../vuex/store')
import { sync } from "vuex-router-sync";
import Cookies from "js-cookie";

//const Permission from '../permission')
const Permission = {
    ROOT: 5,
    HEADMASTER: 4,
    REPRESENTATIVE: 3,
    TEACHER: 2,
    STUDENT: 1,
    PUBLIC: 0,

    LOGIN: 100,
};

//import Home from '../views/Home.vue'

import SchoolWelcome from '../components/pages/director/welcome/index'
import DirectorContainer from '../components/containers/DirectorContainer'
import RepresentativeContainer from '../components/containers/RepresentativeContainer'
import TeacherContainer from '../components/containers/TeacherContainer'
import StudentContainer from '../components/containers/StudentContainer'
import GoAdminContainer from '../components/containers/GoAdminContainer'
import FirstSteps from '../components/pages/first_steps'
//TODO AUTH

import AdminLogin from '../components/auth/admin_login'
import Login from '../components/auth/login'
import RootLogin from '../components/auth/RootLogin'
//import Register from '../components/auth/register'
import RegisterNew from '../components/auth/register/register_public'
import Check from '../components/auth/check'
import RegisterOnSchool from '../components/auth/register_on_school'

import Recovery from '../components/auth/recovery'
import WebPage from '../components/website-ticktaps/web_page'

import AllStudent from '../components/pages/director/students/all'
import NewStudent from '../components/pages/director/students/new'
import DetailStudent from '../components/pages/director/students/detail'
import paymentsReported from '../components/pages/director/payments/reported'

import AllRepresentatives from '../components/pages/director/representatives/all'
import NewRepresentative from '../components/pages/director/representatives/new'
import UpdateRepresentative from '../components/pages/director/representatives/update'

import AllRepresentativeRequest from '../components/pages/director/requests/all'

import ManageEvaluations from '../components/pages/director/evaluations/manage'
import DetailEvaluation from '../components/pages/director/evaluations/evaluations/detail'
//import AllTeacher from '../components/pages/director/teachers/all'
import NewTeacher from '../components/pages/director/evaluations/teachers/new'
import UpdateTeacher from '../components/pages/director/evaluations/teachers/update'

import NewAsignation from '../components/pages/director/evaluations/asignations/new'
import UpdateAsignation from '../components/pages/director/evaluations/asignations/update'


import AllNews from '../components/pages/director/news/all'
import NewNotification from '../components/pages/director/news/new'
import UpdateNotification from '../components/pages/director/news/update'

import AllConsults from '../components/pages/director/consults/all'
import AllReports from '../components/pages/director/reports/all'
import ConfigurationDirector from '../components/pages/director/configurations/detail'
import DirectorMessages from '../components/pages/director/messages/all'

//REPRESENTATIVE PAGES
import RepresentativeWelcome from '../components/pages/representative/welcome/index'
import RepresentativeNotification from '../components/pages/representative/notifications/all'
import RepresentativeConsult from '../components/pages/representative/consult/all'
import RepresentativeChildrens from '../components/pages/representative/students/all'
import RepresentativeChildrensNew from '../components/pages/representative/students/save'
import RepresentativeDownloads from '../components/pages/representative/downloads/all'
import RepresentativeMoney from '../components/pages/representative/balance/all'
import RepresentativeMessages from '../components/pages/representative/messages/all'
//import UnderConstruction from '../components/pages/construction'
import RepresentativeStudentPayment from '../components/pages/representative/students/register_pay'
import RepresentativeStudentArchives from '../components/pages/representative/students/archives'
//TEACHER COMPONENTS
import TeacherWelcome from '../components/pages/teacher/welcome/index'
import TeacherSubjects from '../components/pages/teacher/subjects/all'
import TeacherCalendar from '../components/pages/teacher/calendar/calendar'
import TeacherTrash from '../components/pages/teacher/trash/trash'
import TeacherNews from '../components/pages/teacher/news/all'
import TeacherNotifications from '../components/pages/teacher/notifications/all'
import TeacherSubjectDetail from '../components/pages/teacher/subjects/detail'
import TeacherSubjectEvaluationNew from '../components/pages/teacher/subjects/evaluation/new'
import TeacherSubjectEvaluationDetail from '../components/pages/teacher/subjects/evaluation/detail'

//EXAMS
//import AllExams from '../components/pages/teacher/subjects/exams/all'
import ExamsNew from '../components/pages/teacher/subjects/exams/new'
import ExamsDetail from '../components/pages/teacher/subjects/exams/details'

//REVIEWS
//import AllReviews from '../components/pages/teacher/subjects/exams/reviews/all'
//import DetailReview from '../components/pages/teacher/subjects/exams/reviews/details'

//SOLUTIONS
import AllSolutions from '../components/pages/teacher/subjects/exams/solutions/all'
import SolutionsDetail from '../components/pages/teacher/subjects/exams/solutions/details'

import TeacherStudents from '../components/pages/teacher/students/all'
import TeacherStudentsDetail from '../components/pages/teacher/students/detail'
import TeacherMessages from '../components/pages/teacher/messages/all'
import Page403 from '../components/pages/403'

//STUDENT COMPONENTES
import StudentWelcome from '../components/pages/student/welcome/index'
import StudentSubjects from '../components/pages/student/subjects/all'
import StudentCalendar from '../components/pages/student/calendar/calendar'
import StudentSubjectDetail from '../components/pages/student/subjects/detail'
import StudentSubjectEvaluationNew from '../components/pages/student/subjects/evaluation/new'
import StudentSubjectEvaluationDetail from '../components/pages/student/subjects/evaluation/detail'
import StudentExamPresentation from '../components/pages/student/subjects/exam_presentation'
import StudentNotifications from '../components/pages/student/notifications/all'
import StudentMessages from '../components/pages/student/messages/all'
//GOADMIN PAGES
import GoadminWelcome from '../components/pages/goadmin/welcome/index'
import GoLogin from '../components/auth/admin_login'

//PUBLIC UECAMAG PAGES
import PreRegister from '../components/website-schools/uecamag/pre_register'
import PreRequest from '../components/website-schools/uecamag/pre_request'
import UecamagWeb from '../components/website-schools/uecamag/uecamag'
import NotFound from "../components/NotFound";

//PUBLIC UECAMAG PAGES
//import PreRegister_2 from '../components/website-schools/anibal_lares/pre_register'
import PreRequest_2 from '../components/website-schools/anibal_lares/pre_request'
import UEEALWeb from '../components/website-schools/anibal_lares/ueeal'

//TODO SOLO ROOT
//TOD SCHOOL
import AllSchoolAdmin from '../components/pages/goadmin/schools/all'
import NewSchoolAdmin from '../components/pages/goadmin/schools/new'
import DetailSchoolAdmin from '../components/pages/goadmin/schools/detail'
//USERS
import AllStudentsAdmin from '../components/pages/goadmin/students/all'
import AllTeachersAdmin from '../components/pages/goadmin/teachers/all'
import AllURepresentativesAdmin from '../components/pages/goadmin/representatives/all'
//TODO USER
import AllUserAdmin from '../components/pages/goadmin/users/all'
import NewUserAdmin from '../components/pages/goadmin/users/new'
import DetailUserAdmin from '../components/pages/goadmin/users/detail'
//TODO TABLE TEACHER
import TableTeacherAdmin from '../components/pages/goadmin/tableMaster/all'

//TODO EVALUATIONS
import AllEvaluationsAdmin from '../components/pages/goadmin/evaluations/all'

import MassiveImport from '../components/pages/goadmin/massive/import'
//TODO HeadMasters
import HeadMastersAdmin from '../components/pages/goadmin/director/headMasters'
import Help from '../components/pages/general/help/all'
import Profile from '../components/pages/general/profile/all'

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};
console.log(ifNotAuthenticated)

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        component: Login,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "login"
    },
    {
        path: "/primerospasos",
        component: FirstSteps,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "first_steps"
    },
    {
        path: "/admino/login",
        component: RootLogin,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "login_root"
    },

    {
        path: "/registro",
        component: RegisterNew,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "register"
    },
    {
        path: "/gologin",
        component: GoLogin,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "gologin"
    },
    {
        path: "/",
        component: WebPage,
        // beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "webpage"
    },
    {
        path: "/recovery",
        component: Recovery,
        // beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "recovery"
    },
    {
        path: "/goadmint/login",
        component: AdminLogin,
        //beforeEnter: ifNotAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "adminlogin"
    },
    {
        path: "/check",
        component: Check,
        beforeEnter: ifAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "check"
    },
    {
        path: "/registro/escuela",
        component: RegisterOnSchool,
        beforeEnter: ifAuthenticated,
        meta: { permission: Permission.PUBLIC },
        name: "register_on_school"
    },

    /*{
      path: "/logout",
      name: "Logout",
      component: Logout,
      beforeEnter: ifAuthenticated
    },*/
    {
        path: "/preregister",
        name: "PreRegister",
        meta: { permission: Permission.PUBLIC },
        component: PreRegister
    },
    {
        path: "/uecamag",
        meta: { permission: Permission.PUBLIC },
        name: "uecamag",
        component: UecamagWeb,
    },
    {
        path: "/uecamag/representante/registro",
        name: "RepresentantiveRegister",
        meta: { permission: Permission.PUBLIC },
        component: RegisterNew
    },
    {
        path: "/uecamag/representante/solicitar",
        meta: { permission: Permission.PUBLIC },
        name: "RepresentantiveRequest",
        component: PreRequest
    },
    {
        path: "/ueeal",
        meta: { permission: Permission.PUBLIC },
        name: "ueealweb",
        component: UEEALWeb,
    },
    {
        path: "/ueeal/representante/registro",
        name: "RepresentantiveRegister2",
        meta: { permission: Permission.PUBLIC },
        component: RegisterNew
    },
    {
        path: "/ueeal/representante/solicitar",
        meta: { permission: Permission.PUBLIC },
        name: "RepresentantiveRequest2",
        component: PreRequest_2
    },
    {
        path: "/403",
        name: "403",
        component: Page403,
        meta: { permission: Permission.PUBLIC }
        //  meta: { permission: Permission.LOGIN }
    },
    /*  {
        path: "*",
        name: "404",
        component: Page404,
      //  meta: { permission: Permission.LOGIN }
      },*/
    {
        path: "/goadmin",
        //redirect: '',
        name: "goadmin",
        component: GoAdminContainer,
        meta: { permission: Permission.ROOT },

        children: [
            {
                path: "/goadmin",
                component: GoadminWelcome,
                name: "goadmin_welcome",
                meta: { permission: Permission.ROOT }
            },
            //TODO SCHOOL
            {
                path: "/goadmin/escuelas",
                component: AllSchoolAdmin,
                name: "goadmin-school-all",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/import",
                component: MassiveImport,
                name: "goadmin-massive-import",
                meta: { permission: Permission.ROOT }
            },
            
            {
                path: "/goadmin/escuela/nuevo",
                component: NewSchoolAdmin,
                name: "goadmin-school-new",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/escuela/detalle/:id",
                component: DetailSchoolAdmin,
                name: "goadmin-school-detail",
                meta: { permission: Permission.ROOT }
            },
            //TODO USER
            {
                path: "/goadmin/usuarios",
                component: AllUserAdmin,
                name: "goadmin-user-all",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/usuario/nuevo",
                component: NewUserAdmin,
                name: "goadmin-user-new",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/usuario/detalle/:id",
                component: DetailUserAdmin,
                name: "goadmin-user-detail",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/representantes",
                component: AllURepresentativesAdmin,
                name: "goadmin-representatives-all",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/estudiantes",
                component: AllStudentsAdmin,
                name: "goadmin-students-all",
                meta: { permission: Permission.ROOT }
            },
            {
                path: "/goadmin/profesores",
                component: AllTeachersAdmin,
                name: "goadmin-teachers-all",
                meta: { permission: Permission.ROOT }
            },
            //TODO TABLE TEACHER
            {
                path: "/goadmin/table/teacher",
                component: TableTeacherAdmin,
                name: "goadmin-table-teacher-all",
                meta: { permission: Permission.ROOT }
            },
            //TODO EVALUATIONS
            {
                path: "/goadmin/evaluations",
                component: AllEvaluationsAdmin,
                name: "goadmin-evaluations-all",
                meta: { permission: Permission.ROOT }
            },
            //TODO Head Masters
            {
                path: "/goadmin/headmasters",
                component: HeadMastersAdmin,
                name: "goadmin-headmasters",
                meta: { permission: Permission.ROOT }
            },


        ]
    },
    {
        path: "/profesor",
        //redirect: '',
        name: "teacher",
        component: TeacherContainer,
        meta: { permission: Permission.TEACHER },

        children: [
            {
                path: "/profesor",
                component: TeacherWelcome,
                name: "teacher_welcome",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/materias",
                component: TeacherSubjects,
                name: "teacher_subjects",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/calendario",
                component: TeacherCalendar,
                name: "teacher_calendar",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/papelera",
                component: TeacherTrash,
                name: "teacher_trash",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/materia/:id",
                component: TeacherSubjectDetail,
                name: "teacher_subject_detail",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/materia/evaluacion/nuevo/:type",
                component: TeacherSubjectEvaluationNew,
                name: "teacher_subject_evaluation_new",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/materia/evaluacion/detalle",
                component: TeacherSubjectEvaluationDetail,
                name: "teacher_subject_evaluation_detail",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/materia/:id/examen/nuevo",
                component: ExamsNew,
                // beforeEnter: ifNotAuthenticated,
                meta: { permission: Permission.TEACHER },
                name: "exams_new"
            },
            {
                path: "/profesor/materia/examen/:id/detalle",
                component: ExamsDetail,
                // beforeEnter: ifNotAuthenticated,
                meta: { permission: Permission.TEACHER },
                name: "exams_detail"
            },
            {
                path: "/profesor/materia/examen/:id/solucion",
                component: AllSolutions,
                // beforeEnter: ifNotAuthenticated,
                meta: { permission: Permission.TEACHER },
                name: "solutions"
            },
            {
                path: "/profesor/materia/examen/:id/solucion/detalle",
                component: SolutionsDetail,
                // beforeEnter: ifNotAuthenticated,
                meta: { permission: Permission.TEACHER },
                name: "solutions_details"
            },

            {
                path: "/profesor/estudiantes",
                component: TeacherStudents,
                name: "teacher_students",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/noticias",
                component: TeacherNews,
                name: "teacher_news",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/notificaciones",
                component: TeacherNotifications,
                name: "teacher_notifications",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/mensajes",
                component: TeacherMessages,
                name: "teacher_messages",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/estudiante/detalle/:id",
                component: TeacherStudentsDetail,
                name: "teacher-student-update",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/ayuda",
                component: Help,
                name: "teacher_help",
                meta: { permission: Permission.TEACHER }
            },
            {
                path: "/profesor/perfil",
                component: Profile,
                name: "teacher_profile",
                meta: { permission: Permission.TEACHER }
            },



        ]
    },
    {
        path: "/estudiante",
        //redirect: '',
        name: "student",
        component: StudentContainer,
        meta: { permission: Permission.STUDENT },

        children: [
            {
                path: "/estudiante",
                component: StudentWelcome,
                name: "student_welcome",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/materias",
                component: StudentSubjects,
                name: "student_subjects",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/calendario",
                component: StudentCalendar,
                name: "student_calendar",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/notificaciones",
                component: StudentNotifications,
                name: "student_notifications",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/materia/:id",
                component: StudentSubjectDetail,
                name: "student_subject_detail",
                meta: { permission: Permission.STUDENT }
            },
            {
                ///estudiante/materia/examen/"+exam.id+"/detalle
                path: "/estudiante/materia/examen/presentar",
                component: StudentExamPresentation,
                name: "student_exam_presentation",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/materia/evaluacion/nuevo/:type",
                component: StudentSubjectEvaluationNew,
                name: "student_subject_evaluation_new",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/materia/evaluacion/detalle",
                component: StudentSubjectEvaluationDetail,
                name: "student_subject_evaluation_detail",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/mensajes",
                component: StudentMessages,
                name: "student_messages",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/estudiantes",
                component: TeacherStudents,
                name: "student_students",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/estudiante/detalle/:id",
                component: TeacherStudentsDetail,
                name: "student-student-update",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/ayuda",
                component: Help,
                name: "student_help",
                meta: { permission: Permission.STUDENT }
            },
            {
                path: "/estudiante/perfil",
                component: Profile,
                name: "student_profile",
                meta: { permission: Permission.STUDENT }
            },



        ]
    },
    {
        path: "/director",
        //redirect: '',
        name: "director",
        component: DirectorContainer,
        meta: { permission: Permission.HEADMASTER },

        children: [
            {
                path: "/director",
                component: SchoolWelcome,
                name: "director_welcome",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/estudiantes",
                component: AllStudent,
                name: "director-students-all",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/estudiante/nuevo",
                component: NewStudent,
                name: "director-student-new",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/estudiante/detalle/:id",
                component: DetailStudent,
                name: "director-student-update",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/configuracion",
                component: ConfigurationDirector,
                name: "director-configuration",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/pagos/reportados",
                component: paymentsReported,
                name: "director-payments-reported",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/mensajes",
                component: DirectorMessages,
                name: "director-messages-all",
                meta: { permission: Permission.HEADMASTER }
            },

            //TODO FATHERS
            {
                path: "/director/representantes/solicitudes",
                component: AllRepresentativeRequest,
                name: "director-representative-request-all",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/representantes",
                component: AllRepresentatives,
                name: "director-representative-all",
                meta: { permission: Permission.HEADMASTER }
            },

            {
                path: "/director/representante/nuevo",
                component: NewRepresentative,
                name: "director-representative-new",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/representante/editar/:id",
                component: UpdateRepresentative,
                name: "director-representative-update",
                meta: { permission: Permission.HEADMASTER }
            },
            //TODO EVALUATIONS
            {
                path: "/director/evaluaciones",
                component: ManageEvaluations,
                name: "director-evaluations-all",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/evaluacion/detalle",
                component: DetailEvaluation,
                name: "director-evaluations-detail",
                meta: { permission: Permission.HEADMASTER }
            },

            //New Teacher
            {
                path: "/director/evaluaciones/profesor/nuevo",
                component: NewTeacher,
                name: "director-evaluations-teacher-new",
                meta: { permission: Permission.HEADMASTER }
            },
            //Update Teacher
            {
                path: "/director/evaluaciones/profesor/editar/:id",
                component: UpdateTeacher,
                name: "director-evaluations-teacher-update",
                meta: { permission: Permission.HEADMASTER }
            },
            //New Asignation
            {
                path: "/director/evaluaciones/asignacion/nuevo",
                component: NewAsignation,
                name: "director-evaluations-asignation-new",
                meta: { permission: Permission.HEADMASTER }
            },
            //Update Asignation
            {
                path: "/director/evaluaciones/asignacion/editar/:id",
                component: UpdateAsignation,
                name: "director-evaluations-asignation-update",
                meta: { permission: Permission.HEADMASTER }
            },
            //TODO NOTIFICATIONS
            {
                path: "/director/noticias",
                component: AllNews,
                name: "director-news-all",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/notification/nuevo",
                component: NewNotification,
                name: "director-notification-new",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/notification/editar/:id",
                component: UpdateNotification,
                name: "director-notification-update",
                meta: { permission: Permission.HEADMASTER }
            },
            //TODO CONSULTS
            {
                path: "/director/consultas",
                component: AllConsults,
                name: "director-consults",
                meta: { permission: Permission.HEADMASTER }
            },
            //TODO CONSULTS
            {
                path: "/director/reportes",
                component: AllReports,
                name: "director-reports",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/ayuda",
                component: Help,
                name: "director-help",
                meta: { permission: Permission.HEADMASTER }
            },
            {
                path: "/director/perfil",
                component: Profile,
                name: "headmaster_profile",
                meta: { permission: Permission.HEADMASTER }
            },

        ]
    },
    {
        path: "/representante",
        //redirect: '',
        name: "representative",
        component: RepresentativeContainer,
        meta: { permission: Permission.REPRESENTATIVE },
        children: [
            {
                path: "/representante",
                component: RepresentativeWelcome,
                name: "representative_welcome",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/ayuda",
                component: Help,
                name: "representative_help",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/notificaciones",
                component: RepresentativeNotification,
                name: "representative_notification",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            /*{
              path: "/en/construccion",
              component: Construccion,
              name: "representative_construccion",
              meta: { permission: Permission.REPRESENTATIVE }
            },*/
            {
                path: "/representante/estudiante/pago/:id",
                component: RepresentativeStudentPayment,
                name: "representative-student-register-pay",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/estudiante/archivos/:id",
                component: RepresentativeStudentArchives,
                name: "representative-student-archives",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            //TODO SONS
            {
                path: "/representante/representados",
                component: RepresentativeChildrens,
                name: "representative_childrens",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/representados/nuevo",
                component: RepresentativeChildrensNew,
                name: "representative_children-new",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/saldo",
                component: RepresentativeMoney,
                name: "representative-money",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/descargas",
                component: RepresentativeDownloads,
                name: "representative_downloads",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/mensajeria",
                component: RepresentativeMessages,
                name: "representative_messages",
                meta: { permission: Permission.REPRESENTATIVE }
            },


            {
                path: "/representante/consultas",
                component: RepresentativeConsult,
                name: "representative_consult",
                meta: { permission: Permission.REPRESENTATIVE }
            },
            {
                path: "/representante/perfil",
                component: Profile,
                name: "representative_profile",
                meta: { permission: Permission.REPRESENTATIVE }
            },
        ]
    }, {
        path: "*",
        meta: {
            layout: "not-found-layout"
        },
        component: NotFound
    },
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
})
// Sync Vuex and vue-router;
sync(store, router);

/*router.beforeEach((to, from, next) => {
  // store.dispatch('checkLoginSpa').then((data) => {
  //     console.log('checkLoginSpa');
  // }).catch(() => {
  //     console.log('Not checkLoginSpa');
  // }

  const accessToken = Cookies.get("access_token");
  console.log(store)
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !store.state.auth.me &&
    !accessToken
  ) {
    next("/login");
  }
  else if(store.state.auth.me && accessToken && !store.state.auth.permissions.includes(to.meta.permission)
  ) {
    if (to.name === "admin") {
      if (store.state.auth.permissions.includes(Permission.ADMIN)) {
        next("/admin");
      } else if (store.state.auth.permissions.includes(Permission.SCHOOL)) {
        next("/director");
      } else if (
        store.state.auth.permissions.includes(Permission.ADMIN_SCHOOL)
      ) {
        next("/admin/school");
      } else if (store.state.auth.permissions.includes(Permission.STUDENT)) {
        next("/estudiante");
      } else if (store.state.auth.permissions.includes(Permission.FATHER)) {
        next("/representante");
      } else if (store.state.auth.permissions.includes(Permission.PERSONAL)) {
        next("/personal");
      } else if (store.state.auth.permissions.includes(Permission.TEACHER)) {
        next("/profesor");
      } else if (store.state.auth.permissions.includes(Permission.COMMERCIAL)) {
        next("/area/comercial");
      }
    } else {
      // next("/403");
      next();
    }
  } else {
    next();
  }
});
*/
router.beforeEach((to, from, next) => {
    const accessToken = Cookies.get("access_token");
    const id_rol = Cookies.get("id_rol");
    //console.log('TO META')
    //console.log(to.meta)
    if (to.meta.permission == 0) {
        // console.log('PUBLIC ROUTE')
        next();
    } else if (to.matched.some(record => record.meta.requiresAuth) && !store.state.auth.me && !accessToken) {
        next("/login");
    } else if (store.state.auth.me && accessToken) {
        // console.log("LOGGED")
        // console.log(to.meta.permission)
        // console.log(store.state.auth.me_rol)
        if (to.meta.permission == id_rol) {
            //  console.log('Permission Acepted on Route')
            next();
        } else if (to.name != '403') {
            //  console.log('NAME'+to.name)
            //  console.log('Permission'+to.meta.permission)
            //  console.log(store.state.auth.me)
            next("/403");
        } else {
            next();
        }
    } else {
        next("/login");
    }


});

export default router
