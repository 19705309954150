import axios from "axios";
import * as Config from "../../../config";

const state = {
    typeSchool: {
        current_page: 1,
        data: []
    },
    type: {}
};
let mod = "gen/";
const actions = {
    load_TypeSchool({commit, dispatch}) {
        console.log(dispatch)
        console.log(commit)
        // commit('LOAD_School')

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod + "type/school/all")
                .then(response => {
                    //commit('LOAD_School_OK', response.data.School)
                    resolve(response.data);
                })
                .catch(error => {
                    //commit('LOAD_School_FAIL')
                    reject(error.response.data);
                });
        });
    },
    loadTypeSchool_Active({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod + "type/school/all/status/" + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    loadData_TypeSchool({commit, dispatch}, data) {
        console.log(dispatch)
        console.log(commit)
        commit("LOAD_DATA_TYPE_SCHOOL", data);
    },
    new_TypeSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        //commit('LOAD_SCHOOL')

        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + mod + "type/school/add", form)
                .then(response => {
                    // console.log(response)
                    // commit("UPDATE_SCHOOL_OK", {});
                    resolve(response.data);
                })
                .catch(error => {
                    // commit("UPDATE_SCHOOL_FAIL");
                    reject(error.response.data);
                });
        });
    },
    changeActiveTypeSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + mod + 'type/school/active/' + form.id + '/' + form.active)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    update_TypeSchool({commit, dispatch}, form) {
        console.log(dispatch)
        console.log(commit)
        // commit("UPDATE_SCHOOL");

        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + mod + "type/school/update", form)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    // commit("UPDATE_SCHOOL_FAIL");
                    reject(error.response.data);
                });
        });
    },
    delete_TypeSchool({commit, dispatch}) {
        console.log(dispatch)
        console.log(commit)
        //commit('DELETE_SCHOOL')

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + "user/all")
                // axios.post(Config.apiPath + 'SCHOOL/change' + id, {_method: 'DELETE'})
                .then(response => {
                    // commit('DELETE_SCHOOL_OK', id)
                    resolve(response.data);
                })
                .catch(error => {
                    //commit('DELETE_SCHOOL_FAIL')
                    reject(error.response.data);
                });
        });
    }
};

const mutations = {
    LOAD_DATA_TYPE_SCHOOL(state, data) {
        state.type = data;
    }
};

export default {
    state,
    actions,
    mutations
};
