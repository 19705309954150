<template>
  <div id="RegisterPayStudent">
    <br />

    <div class="content">
      <b-card :title="'Archivos del estudiante: '+full_name">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>En esta sección puede descargar, y cargar archivos del estudiante.</p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <b-button
              pill
              variant="outline-primary"
              :to="{name:'representative_childrens'}"
            >Volver a Representados</b-button>
          </b-col>
        </b-row>
        <br />
        <b-card no-body>
          <b-tabs card>
             <b-tab title="Planillas de Inscripción" active>
              <p>A continuación podrá descargar los archivos para proceder con la inscripción del alumno</p>
              <p>Si en las planillas aparece algun error, debe asegurarse de que sus datos esten bien registrados</p>
               <p><strong>Recuerde que para descargar estas planillas usted debe encontrarse SOLVENTE</strong></p>

              <b-list-group v-if="me_rol.id_school ==1">
              <b-list-group-item>
                <a target="_blank" :href="url3">Compromiso Lopna</a>
              </b-list-group-item>
              <b-list-group-item>
                <a target="_blank" :href="url4">Inscripción de Prosecución</a>
              </b-list-group-item>
             
               <b-list-group-item>
                <a target="_blank" :href="url1">Compromiso Estudiante</a>
              </b-list-group-item>
               <b-list-group-item>
                <a target="_blank" :href="url2">Compromiso Representante</a>
              </b-list-group-item>
            </b-list-group>
              
            </b-tab>
           <!-- <b-tab title="Subir un Nuevo Archivo">
              <p>A continuación seleccione un archivo de su computador o teléfono, índique un nombre y presione "Subir Archivo", si tiene dificultades para subirlo, compruebe que el archivo que esta intentando subir no sea demasiado pesado.</p>
              <br />
              <p><strong>Solo se admiten imagenes en formato JPG / PNG </strong></p>

              <b-form id="uploadForm" class="row" method="post" @submit.prevent="onSubmit">
                <div class="col-md-8">
                  <b-form-group label="Nombre del documento que va a subir" label-for="file_name">
                    <b-form-input
                      id="file_name"
                      v-model="file_name"
                      
                      name="file_name"
                      placeholder="Ej: Cédula de Juan"
                    ></b-form-input>
                  
                  </b-form-group>

                 
                  <b-form-file
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Seleccione un archivo o arrastrelo hasta aquí..."
                    drop-placeholder="Arrastre el archivo hasta aquí..."
                  ></b-form-file>
                  <div class="mt-3">Archivo Seleccionado: {{ file ? file.name : '' }}</div>
                </div>

                <div class="col-md-4"></div>

                <div class="col-12 text-left">
                  <b-button pill type="submit" variant="outline-success" class="m-3">Subir Archivo</b-button>

                  <b-spinner variant="outline-success" v-show="progress" label="Text Centered"></b-spinner>
                </div>
              </b-form>
            </b-tab>-->
            <b-tab title="Ver Archivos del estudiante">
               <b-list-group>
                <b-list-group-item v-for="file in files" :key="file.id">
                  <b-row>
                   
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <strong> {{file.name}}</strong>
                    </b-col>
                     <b-col md="3">
                        {{file.description}}
                    </b-col>
                      <b-col md="3">
                        Formato: {{file.format}}
                    </b-col>
                     <b-col md="3">
                       <a :href="file.url" target="a_blank">Ver Archivo</a>
                    </b-col>
                   
                  </b-row>
                 
                </b-list-group-item>
                      
              </b-list-group>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";

export default {
  
  name: "UpdateStudent",
  data() {
    return {
       file:{
        name:'',
        description:'',
        url:'',
        format:''
      },
      files:[],
      id: "",
      full_name: "",
      id_student: "",
      id_student_user: "",
      progress: false,
     // file: null,
      file_name: null,
      status: 1,
      url1:'',
      url2:'',
      url3:'',
      url4:'',
      archives:[]
    };
  },
  computed: {
    ...mapState({
      student: state => state.student.student,
      me_rol: state => state.auth.me_rol,
      me: state => state.auth.me
    })
  },
  mounted() {
   // this.getData();
    this.checkPayment();
    this.studentLoad();
    this.allFiles();
  },
  methods: {
    ...mapActions(["uploadArchive","loadStudent_archives","loadUserFiles"]),
      allFiles(){
        let params={
      id_user:this.student.user.id,
      id_school:this.me_rol.id_school,
      type:1
    }
   this.loadUserFiles(params)
        .then(response => {
          if (response.code === 200) {
            this.files = response.data;
          }
        })
        .catch(error => {console.log(error)});
    },
      checkPayment() {
     
       if(this.student.status_pay < 3){
         Swal.fire({
                        title: 'No tienes estado Solvente',
                        text: 'Este estudiante no se encuentra solvente, si ya hiciste el pago debes esperar a que la administración actualice tu estado',
                        icon: 'warning',
                      }).then(result2 => {
                          console.log(result2)
                        this.$router.push('/representante/representados');
                      });
       }
      
    
    },
    getData() {
      let form ={
        id_school:this.me_rol.id_school,
        id_user :this.student.id_user,
        type:2
      }
      
       this.loadStudent_archives(form)
        .then(response => {
          if (response.code === 200) {
            this.archives = response.data;
          }
        })
        .catch(error => {
            console.log(error)
        });
    },
    studentLoad() {
      if (this.student.id) {
        this.id = this.student.id;
        this.id_student = this.student.id;
        this.id_student_user = this.student.id_user;
        this.full_name = this.student.user.full_name;
        this.url1 = 'https://doc.ticktaps.com/download/compromiso/estudiante/gEJan8GDOl'+this.student.id+'XdEPwwxGNz'
         this.url2 = 'https://doc.ticktaps.com/download/compromiso/representante/gEJan8GDOl'+this.student.id+'XdEPwwxGNz'
          this.url3 = 'https://doc.ticktaps.com/download/compromiso/lopna/gEJan8GDOl'+this.student.id+'XdEPwwxGNz'
           this.url4 = 'https://doc.ticktaps.com/download/inscripcion/gEJan8GDOl'+this.student.id+'XdEPwwxGNz'
      } else {
        this.$router.push({ name: "representative_childrens" });
      }
    },
    onSubmit() {
      let data = new FormData();
      //let formData = new FormData(document.getElementById("uploadForm"));

      data.append("archive", this.file);
      let name = this.file_name;
      let id_user_uploaded = this.me.id;
      let id_type_reference = 2;
      let id_table_reference = this.id_student;
      let id_user = this.id_student_user;
      let id_school = this.me_rol.id_school;

      data.append("name", name);
      data.append("id_user_uploaded", id_user_uploaded);
      data.append("id_type_reference", id_type_reference);
      data.append("id_table_reference", id_table_reference);
      data.append("id_user", id_user);
      data.append("id_school", id_school);

      console.log(data);
      Swal.fire({
        title: "Confirmación",
        text: "¿Estás seguro de subir este Archivo?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Subir"
      }).then(result => {
        if (result.value) {
          this.progress = true;
          this.uploadArchive(data)
            .then(response => {
              if (response.code === 200) {
                (this.progress = false),
                  Swal.fire({
                    title: "Subida de Archivo Completada",
                    text: "Su archivo ha sido almacenado correctamente",
                    icon: "success"
                  }).then(result2 => {
                      console.log(result2)
                    this.$router.push({ name: "representative_childrens" });
                  });
              }
            })
            .catch(error => {console.log(error)});
        }
      });
    }
  }
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
