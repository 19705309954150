<template>
  <div id="NewStudent">
    <br />
    <div class="content">
      <b-card title="Registrar un Nuevo Estudiante">
        <b-row>
          <b-col md="8" style="text-align: -webkit-left">
            <p>
              Por favor completa los campos para registrar un nuevo estudiante,
              que será tu representado
            </p>
          </b-col>
          <b-col md="4" style="text-align: -webkit-right">
            <router-link
              class="btn btn-outline-primary"
              :to="{ name: 'representative_childrens' }"
            >
              <v-icon name="angle-left" /> Volver a Representados</router-link
            >
          </b-col>
          <br />
          <hr align="center" noshade="noshade" size="2" width="100%" />
        </b-row>

        <form
          style="width: 100% !important"
          method="post"
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col md="12">
              <h3>Datos Personales del estudiante</h3>
              <br />
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Nombre del estudiante"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="first_name"
                  name="first_name"
                  placeholder="Nombre completo"
                />
              </b-form-group>
              <b-form-group
                label="Apellido del estudiante"
                label-for="last_name"
              >
                <b-form-input
                  id="last_name"
                  v-model="last_name"
                  name="last_name"
                  placeholder="Apellidos"
                />
              </b-form-group>
              <b-form-group label="Cédula del estudiante" label-for="dni">
                <b-input-group>
                  <template v-slot:prepend>
                    <b-form-select
                      value-field="id"
                      text-field="name"
                      v-model="dni_selected"
                      :options="dnis"
                    ></b-form-select>
                  </template>
                  <b-form-input
                    id="dni"
                    v-model="dni"
                    name="dni"
                    placeholder="Solo Números"
                  ></b-form-input>
                  <p style="font-size: 10px">
                    Si el estudiante aún no posee cédula indique R, para
                    utilizar la misma "cédula" del representante.
                  </p>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Teléfono de estudiante (opcional)"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="phone"
                  type="number"
                  name="phone"
                  placeholder="Teléfono"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Fecha de Nacimiento" label-for="birthday">
                <date-picker
                  id="birthday"
                  name="birthday"
                  mode="single"
                  :max-date="new Date()"
                  v-model="birthday"
                  :popover="{ placement: 'bottom', visibility: 'click' }"
                />
              </b-form-group>
              <b-form-group label="Seguro de Vida HCM" label-for="birthday">
                <b-form-input
                  id="security_health"
                  v-model="security_health"
                  name="security_health"
                  placeholder="Código de Seguro"
                ></b-form-input>
              </b-form-group>
              <p style="font-size: 10px">
                El código de seguro HCM, permite tener información ante
                cualquier emergencia (Opcional)
              </p>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <div>
                  <div class="mb-2">Género</div>
                  <b-form-select
                    v-model="gender"
                    :options="genderList"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                </div>
              </b-form-group>
              <b-form-group>
                <div>
                  <div class="mb-2">Tipo de Sangre</div>
                  <b-form-select
                    v-model="blood_type"
                    :options="type_blood_list"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                  <p style="font-size: 10px">
                    El tipo de sangre es un valor informativo, que le sirve a la
                    escuela ante cualquier emergencia
                  </p>
                </div>
              </b-form-group>
              <b-form-group label="Observación" label-for="observation">
                <b-form-textarea
                  id="observation"
                  v-model="observations"
                  name="observation"
                  placeholder="Datos adicionales del estudiante"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col md="6"></b-col>
            <b-col md="6"></b-col>
            <b-col md="6"></b-col>
          </b-row>

          <hr align="center" noshade="noshade" size="2" width="100%" />
          
          <b-col md="12 text-right">
            <b-button type="submit" variant="outline-success" class="m-3"
              >Registrar nuevo estudiante <v-icon name="save" />
            </b-button>
          </b-col>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Moment from "moment";
export default {
  name: "NewStudent",
  data() {
    return {
      //TODO LIST OF FATHER
      fathers: [],
      //USER DATA
      status: 0,
      first_name: "",
      last_name: "",
      dni: "",
      birthday: new Date(),
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      gender: "",
      id_blood_type: 1,
      observations: "",
      image: "",
      id_rol_access: 1,
      blood_type: 1,
      //STUDENT DATA
      id_user: "",
      id_parent1: "",
      id_parent2: "",
      security_health: "",
      id_condition: 1,
      id_student_type: "",
      id_section: "",
      dni_selected: "V",
      dnis: [
        {
          id: "V",
          name: "V",
        },
        {
          id: "E",
          name: "E",
        },
        {
          id: "R",
          name: "R",
        },
      ],
      type_blood_list: [],
      genderList: [
        {
          id: 1,
          name: "Masculino",
        },
        {
          id: 2,
          name: "Femenino",
        },
      ],
      rols_user: [],
      rols_access: [{ value: 1, text: "Puerta Principal" }],

      conditionArray: [],
      id_typeStudent: 1,
      typeStudentArray: [],
      id_typeLevel: 1,
      typeLevelArray: [
        { id: 1, name: "Estatico T Nivel 1" },
        { id: 2, name: "Estatico T Nivel 2" },
      ],
      loading: false,
      error: "",
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: new Date(),
      isM: "",
      popperProps: {
        arrowPosition: "start",
        arrowOffsetScaling: 1,
        popperOptions: {
          placement: "bottom-start",
          positionFixed: true,
        },
      },
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.fathers.length;
    },
  },
  mounted() {
    this.allRoles();
  },
  methods: {
    ...mapActions([
      "newStudentFromRepresentative",
      "new_Father",
      "loadFather_school",
      "loadRol_Active",
      "loadBlood_Active",

      "addToastMessage",
    ]),
    changeEmail(){
      if ( this.status == 1 )
      this.email = this.first_name + this.last_name + "@escuela.com"
      else
      this.email = ""
    },
    allRoles() {
      let form = {
        active: 1,
      };

      this.loadBlood_Active(form)
        .then((response) => {
          if (response.code === 200) {
            this.type_blood_list = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectFathers(record) {
      if (this.selectRow.indexOf(record.item) == -1) {
        if (this.selectRow.length == 2) {
          this.addToastMessage({
            text: "Solo puedes agregar 2 padres.",
            icon: "danger",
          });
          return false;
        } else {
          this.selectRow.push(record.item);
        }
      } else {
        this.selectRow.splice(record.item, 1);
      }
    },
    listFather() {
      let form = {
        id_school: this.me.school.id,
      };
      this.fathers = [];
      this.loadFather_school(form)
        .then((response) => {
          if (response.code === 200) {
            this.fathers = response.data;
            const arrayTmp = [];
            for (let i in this.fathers) {
              for (let v in this.selectRow) {
                if (this.selectRow[v].id == this.fathers[i].id) {
                  arrayTmp.push(this.fathers[i]);
                }
              }
            }
            this.selectRow = arrayTmp;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if (
        this.firstName == "" ||
        this.last_name == "" ||
        this.birthday == "" ||
        this.gender == "" 
      ) {
        Swal.fire({
          title: "Completa los campos",
          text: "Asegúrate de indicar nombres, fecha de nacimiento y género como mínimo, e intenta completar toda la información",
          icon: "warning",
        });
      } else if (this.password != this.password_confirm) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          text: "Asegúrate de que las contraseñas sean las mismas para continuar",
          icon: "warning",
        });
      } else {
        this.loading = true;
        console.log(this.me);
        let form = {
          first_name: this.first_name,
          last_name: this.last_name,
          dni: this.dni_selected + "-" + this.dni,
          birthday: Moment(this.birthday).format("YYYY-MM-DD"),
          /* email: this.email,
          password: this.password, */
          phone: this.phone,
          gender: this.gender,
          id_blood_type: this.blood_type,
          observations: this.observations,
          id_parent1: this.me_rol.id_representative,
          security_health: this.security_health,
          id_school: this.me_rol.id_school, // datos de la escuela
        };

        Swal.fire({
          title: "Confirmación",
          text: "¿Estás seguro de registrar a este estudiante?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: "#009900",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.newStudentFromRepresentative(form)
              .then((response) => {
                console.log("RESPONSE");
                if (response.code == 200) {
                  Swal.fire({
                    title: "Listo",
                    text: "El Estudiante ha sido registrado",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                    this.$router.push("/representante/representados");
                  });
                }
                if (response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                if (response.code == 410) {
                  Swal.fire({
                    title: "Cédula previamente registrada",
                    text: "Encontramos una solicitud con esta cédula previamente registrada",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                    //  this.$router.push({ name: "uecamag" });
                  });
                }
                if (response.code == 411) {
                  Swal.fire({
                    title: "Correo Previamente Registrado",
                    text: "Por favor comprueba el email",
                    icon: "warning",
                  }).then((result2) => {
                    console.log(result2);
                    //   this.$router.push({ name: "uecamag" });
                  });
                }
              })
              .catch((error) => {
                console.log("ERROR");
                if (error.response.code == 500) {
                  Swal.fire({
                    title: "Ocurrio un error",
                    text: "Intenta de nuevo, completa todos los campos",
                    icon: "success",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
              });
          }
        });
      }
    },
    clearFather() {
      this.username_father = "";
      this.firstName = "";
      this.lastName = "";
      this.dni_father = "";
      this.birthday_father = new Date();
      this.email_father = "";
      this.password_father = "";
      this.password_confirm_father = "";
      this.phone_father = "";
      this.blood_type_father = 1;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.vue-input {
  padding: 0.5rem !important;
}

.valid-feedback,
.invalid-feedback {
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-feedback {
  color: #fff;
  background-color: #28a745;
}

.invalid-feedback {
  color: #fff;
  background-color: #dc3545;
}
</style>
