
<template>
  <div class="row" style="margin-top:50px">
    <br />

    <div class="col-sm-3"></div>
    <div class="col-sm-6 log-in shadow-div">
      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <img width="80%" src="../../assets/img/logonew.png" />
        </div>
        <div class="col-sm-4"></div>
      </div>
      <br />
      <br />
      <form method="post" @submit.prevent="onSubmit" v-if="step==1">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <h5
              style="font-size:17px"
            >Por favor indica el correo con el que te registraste para solicitar un código de recuperación, luego este código se te pedirá para recuperar tu contraseña</h5>
            <br />
            <div class="floating-label">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">
                    <v-icon name="user"></v-icon>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  class="LoginInput"
                  size="lg"
                  placeholder="Correo"
                  v-model="email"
                  name="email"
                ></b-form-input>
              </b-input-group>
            </div>
            <br />
            <div>
              <b-button type="submit" class="col-sm-12 btn-success mt-0 ml-2">
                <v-icon name="envelope"></v-icon>Solicitar Código al correo
              </b-button>
              <br />
              <br />
              <router-link :to="{name: 'login'}" class>Volver al inicio</router-link>
            </div>
          </div>
          <div class="col-sm-1"></div>
        </div>
      </form>

      <form method="post" @submit.prevent="onSubmitValidate" v-if="step==2">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <h5
              style="font-size:17px"
            >Por favor ingresa el código que llegó a tu correo para verificarlo</h5>
            <br />
            <div class="floating-label">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">
                    <i class="fa fa-asterisk fa-lg"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  class="LoginInput"
                  size="lg"
                  placeholder="Código de Recuperación"
                  v-model="code"
                  name="code"
                ></b-form-input>
              </b-input-group>
            </div>

            <div>
              <b-button type="submit" class="col-sm-12 btn-success mt-0 ml-2">Validar Código</b-button>
              <br />
              <br />
            </div>
          </div>
          <div class="col-sm-1"></div>
        </div>
      </form>

      <form method="post" @submit.prevent="onSubmitNewPassword" v-if="step==3">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <h5 style="font-size:17px">Por favor, ingrese su nueva contraeña</h5>
            <br />
            <div class="floating-label">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">
                    <v-icon name="key"></v-icon>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  class="LoginInput"
                  size="lg"
                  placeholder="Nueva Contraseña"
                  v-model="password"
                  name="code"
                ></b-form-input>
              </b-input-group>
              <br />
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">
                    <v-icon name="key"></v-icon>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  class="LoginInput"
                  size="lg"
                  placeholder="Repita contraseña"
                  v-model="password2"
                  name="code"
                ></b-form-input>
              </b-input-group>
            </div>

            <div>
              <b-button
                type="submit"
                class="col-sm-12 btn-success mt-0 ml-2"
              >Reestablecer Contraseña</b-button>
              <br />
              <br />
            </div>
          </div>
          <div class="col-sm-1"></div>
        </div>
      </form>
    </div>
    <div class="col-sm-3">
      <!--<button v-on:click="step+=1">Add</button>
      <button v-on:click="step-=1">Back</button>-->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
//import Permission from "../../permission";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      btnLogin: false,
      email: "",
      auth: {},
      step: 1,
      code: "",
      password: "",
      password2: "",
    };
  },

  computed: {
    ...mapState({}),
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  methods: {
    ...mapActions([
      "recovery_request",
      "recovery_validate",
      "recovery_save",
      "getAllRolsByUser",
      "addToastMessage",
    ]),
    onSubmit() {
      console.log("Submit");

      this.btnLogin = true;
      this.recovery_request({ email: this.email })
        .then((data) => {
          console.log("RECOVERY 200");
          console.log(data);
          if (data.code == 200) {
            Swal.fire({
              title: "Solicitud Enviada",
              text: "Revisa tu correo, recibirás un código de validación",
              icon: "warning",
            }).then((result2) => {
              console.log(result2);
              this.step = 2;
            });
          }
          if (data.code == 404) {
            Swal.fire({
              title: "Correo no registrado",
              text:
                "No hemos podido encontrar este correo registrado en nuestro sistema",
              icon: "warning",
            }).then((result2) => {
              console.log(result2);
              //  this.$router.push({ name: "uecamag" });
            });
          }
        })

        .catch((data) => {
          this.btnLogin = false;
          if (data.code == 404) {
            Swal.fire({
              title: "Correo no registrado",
              text:
                "No hemos podido encontrar este correo registrado en nuestro sistema",
              icon: "warning",
            }).then((result2) => {
              console.log(result2);
              //  this.$router.push({ name: "uecamag" });
            });
          }
          if (data.code == 401) {
            this.addToastMessage({
              text: "Contraseña Incorrecta",
              icon: "warning",
            });
          }
        });
    },
    onSubmitValidate() {
      console.log("Submit");
      if (this.code == "") {
        Swal.fire({
          title: "Indica un Código",
          text:
            "Debes indicar un código de recuperación que ha sido enviado al correo que indicaste",
          icon: "warning",
        }).then((result2) => {
          console.log(result2);
          //  this.$router.push({ name: "uecamag" });
        });
      } else {
        this.btnLogin = true;
        this.recovery_validate({ email: this.email, pin_code: this.code })
          .then((data) => {
            console.log("RECOVERY 200");
            console.log(data);
            if (data.code == 200) {
              Swal.fire({
                title: "Código Validado",
                text: "Ahora podrás ingresar tu nueva clave",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                this.step = 3;
              });
            }
            if (data.code == 404) {
              Swal.fire({
                title: "Código inválido",
                text: "Comienza de nuevo tu proceso de recuperación",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                //  this.$router.push({ name: "uecamag" });
              });
            }
            if (data.code == 409) {
              Swal.fire({
                title: "Código Incorrecto",
                text:
                  "Por favor comprueba el código que recibiste en tu correo",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                //  this.$router.push({ name: "uecamag" });
              });
            }
            if (data.code == 403) {
              Swal.fire({
                title: "Código Expirado",
                text: "El código expiro, debes solicitar un nuevo código",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                this.step = 1;
              });
            }
          })

          .catch((data) => {
            this.btnLogin = false;
            if (data.code == 404) {
              Swal.fire({
                title: "Correo no registrado",
                text:
                  "No hemos podido encontrar este correo registrado en nuestro sistema",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                //  this.$router.push({ name: "uecamag" });
              });
            }
            if (data.code == 401) {
              this.addToastMessage({
                text: "Contraseña Incorrecta",
                icon: "warning",
              });
            }
          });
      }
    },
    onSubmitNewPassword() {
      console.log("Submit");
      if (this.password == "") {
        Swal.fire({
          title: "Indica una contraseña",
          text: "Debes indicar una nueva contraseña",
          icon: "warning",
        }).then((result2) => {
          console.log(result2);
          //  this.$router.push({ name: "uecamag" });
        });
      } else if (this.password != this.password2) {
        Swal.fire({
          title: "Las contraseñas deben coincidir",
          text: "Por favor asegurate de que la contraseña sea igual",
          icon: "warning",
        }).then((result2) => {
          console.log(result2);
          //  this.$router.push({ name: "uecamag" });
        });
      } else {
        this.btnLogin = true;
        this.recovery_save({
          email: this.email,
          new_password: this.password,
        })
          .then((data) => {
            console.log("RECOVERY 200");
            console.log(data);
            if (data.code == 200) {
              Swal.fire({
                title: "Contraseña Actualizada",
                text: "Ahora podrás ingresar con tu nueva clave",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                this.$router.push({ name: "login" });
              });
            }
            if (data.code == 404) {
              Swal.fire({
                title: "Código inválido",
                text: "Comienza de nuevo tu proceso de recuperación",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                //  this.$router.push({ name: "uecamag" });
              });
            }
          })

          .catch((data) => {
            this.btnLogin = false;
            if (data.code == 404) {
              Swal.fire({
                title: "Correo no registrado",
                text:
                  "No hemos podido encontrar este correo registrado en nuestro sistema",
                icon: "warning",
              }).then((result2) => {
                console.log(result2);
                //  this.$router.push({ name: "uecamag" });
              });
            }
            if (data.code == 401) {
              this.addToastMessage({
                text: "Contraseña Incorrecta",
                icon: "warning",
              });
            }
          });
      }
    },
  },
};
</script>

