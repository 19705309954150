import axios from "axios";
import * as Config from "../../../config";

const state = {
  representatives: {
    current_page: 1,
    data: []
  },
  representative: {}
};
let mod = "gen/";
const actions = {
  // TODO CON API READY
  loadRepresentatives({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
 
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "representative/all/" + form.id_school)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadData_Representative({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)

    commit("LOAD_DATA_REPRESENTATIVE", data);
  },
  loadRepresentative({ commit, dispatch }, id) {
    
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "representative/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadRepresentativeRequest({ commit, dispatch }, id) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "representative/all/request/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  loadPaymentStatus({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "representative/check_pay/" + form.id_representative)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  findRepresentativeByEmail({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + mod + "representative/find_by_email/" + form.email
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "representative/save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  publicRegisterRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "representative/public_save", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  publicRequestRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + mod + "representative/request", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + mod + "representative/update/" + form.id, form)
        .then(response => {
          commit("UPDATE_REPRESENTATIVE_OK", {});
          resolve(response.data);
        })
        .catch(error => {
        //  commit("UPDATE_REPRESENTATIVE_FAIL");
          reject(error.response.data);
        });
    });
  },
  changeActiveRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            mod +
            "representative/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
        .put(
          Config.apiPath +
            mod +
            "representative/change_status/" +
            form.id +
            "/" +
            form.active
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTotalRepresentative({ commit, dispatch }, form) {
    console.log(dispatch)
    console.log(commit)
    return new Promise((resolve, reject) => {
      axios
      .post(Config.apiPath + mod + "representative/delete", form)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  loadCity({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "representative/list/city")
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  loadRepresentativePoint({ commit, dispatch }, data) {
    console.log(dispatch)
    console.log(commit)
    commit("LOAD_REPRESENTATIVE_POINT");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            "representativepoint/acumfilterby/" +
            data.idtypepoint +
            "/" +
            data.idrepresentative
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_REPRESENTATIVE_POINT_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_REPRESENTATIVE_POINT_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_REPRESENTATIVE_POINT_FAIL");
          reject(error);
        });
    });
  },
  loadGender({ commit, dispatch }) {
    console.log(dispatch)
    console.log(commit)
    //commit('UPDATE_PASSWORD')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + mod + "representative/list/gender")
        .then(response => {
          //commit('UPDATE_PASSWORD_OK', response.data)
          resolve(response.data);
        })
        .catch(error => {
          //commit('UPDATE_PASSWORD_FAIL')
          reject(error.response.data);
        });
    });
  },
  async preRegister({ commit }, form) {
   
    console.log(commit)
    try {
      const response = await axios.post(
        `${Config.apiPath}gen/representative/save`,
        form
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
};

const mutations = {
  LOAD_DATA_REPRESENTATIVE(state, data) {
    state.representative = data;
  },

  LOAD_REPRESENTATIVES_OK(state, representatives) {
    state.representatives = representatives;
  },

  LOAD_REPRESENTATIVE_OK(state, representative) {
    state.representative = representative;
  },

  LOAD_REPRESENTATIVE_POINT_OK(state, representative) {
    state.representative = representative;
  },
  
  UPDATE_REPRESENTATIVE_OK(state, representative) {
    state.representative = representative;
  }
};

export default {
  state,
  actions,
  mutations
};
