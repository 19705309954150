import axios from "axios";
import * as Config from "../../../config";

const state = {
    section: {}
};
let mod = "eval/";

const actions = {
    loadLevels({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + mod + "level/all/" + form.id_school + form.active + "")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    newLevel({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + mod + "level/save", form)
                .then(response => {
                    if (response.data.data.id) {
                        resolve(response.data);
                    }
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    deleteLevel({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + mod +
                    "level/change_status/" +
                    form.id +
                    "/" +
                    form.active
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    changeActiveLevel({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios
            .put(
              Config.apiPath + mod +
                "level/change_status/" +
                form.id +
                "/" +
                form.active
            )
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      updateLevel({ commit, dispatch }, form) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios
            .put(Config.apiPath + mod + "level/update/" + form.id, form)
            .then(response => {
              if (response.data.data.id) {
                resolve(response.data);
              }
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations
};