<template>
  <div>
    <br />
    <div class="content">
      <b-card title="Notificaciones">
        <b-row>
          <b-col md="10">
            <p>En este panel recibes notificaciones sobre actividad en tus manterias</p>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
        <br />
        <b-row>
          <b-col md="12">
              <p v-if="notifications.length < 1">Al parecer no tienes notificaciones</p>
            <div v-for="not in notifications" :key="not.id">
              <div class="card">
                
                <div class="card-body">
                  <h5 class="card-title">{{not.description}}</h5>
                
                 
                    <p style="font-size:11px">Fecha: {{not.created_at}}</p>
                    <b-button size="sm" variant="outline-primary" pill><v-icon name="angle-right"/></b-button>
                </div>
              </div>
              <br />
            </div>
            <br />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
 import {mapState, mapActions} from "vuex";
//import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "AllNotification",
  data() {
    return {
      notifications: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [5, 10, 15, 20],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_user: {},
      loading: false,
      error: ""
    };
  },
  computed: {
     ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.notifications.length;
    }
  },
  mounted() {
    this.allNotification();
  },

  methods: {
    
    ...mapActions([
      "load_push_Notification", 
    ]),
    
    allNotification() {
      let form = {
        id_school: this.me_rol.id_school,
        id_user: this.me.id
      };
      console.log('ID_SCHOOL:'+this.me_rol.id_school)
      this.load_push_Notification(form)
        .then(response => {
          if (response.code === 200) {
            this.notifications = response.data;
          }
        })
        .catch(error => {console.log(error)});
    },
   /* editNotification(data) {
      this.loadData_Notification(data).then(response => {
          console.log(response)
        this.$router.push("/notificacion/editar/" + data.id);
      });
    }, */
  
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
}
</style>
